/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  UserService,
  TempleService,
  PanchangService,
  CommonService,
  OrderService,
  GlobalSearchService,
  TransliterationService,
} from "td_server";
import {
  setVerifiedTempleList,
  setUnverifiedTempleList,
  setLoading,
  setPanchangData,
  setAllTempleList,
  setShaktipeeth,
  setSelectedTempleDetails,
  setBenefitsList,
  setDarshanList,
  setMergedList,
  setAllPujaList,
  setGlobalSearchResult,
  setAllVerifiedAndUnverifiedTempleList,
} from "./../slices/templeSlice";
import { LOCALSTORAGE_KEY } from "../../utils/Constants";
export const getTempleAddressOnCoordinatesAction =
  (coordinate: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const { latitude, longitude } = coordinate;
      const response: any = await UserService.getUserAddressOnGeoLocation(
        latitude,
        longitude
      );
      if (response?.results) {
        dispatch(setLoading(false));
        return Promise.resolve(response?.results[0]);
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTempleAddressOnPinCodeAction =
  (pinCode: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = ""; //await UserService.getAddressOnPinCode(pinCode);
      if (response) {
        dispatch(setLoading(false));
        return Promise.resolve(response);
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addNewTempleDetails =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;

      const response: any = await TempleService.addNewTempleDetails(
        formData,
        userInfo
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const addNewShivlingSaktipethDetails =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;

      const response: any = await TempleService.addShivlingSaktipeethTemple(
        formData,
        userInfo
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const verifyNewTempleDetails =
  (formData: any, orgId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const userInfo = getState().user.userInfo;
      const response: any = await TempleService.approveExistingTempleDetails(
        formData,
        userInfo,
        orgId
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
export const getUnverifiedTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getUnverifiedTempleList();
      dispatch(setUnverifiedTempleList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getVerrifiedTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getVerifiedTempleList();
      dispatch(setVerifiedTempleList(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getAllTempleList =
  (locationData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllTempleList();
      dispatch(setAllTempleList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPanchangData =
  (requestInfo: any) => async (dispatch: any, getState: any) => {
    // console.log("Error : ", requestInfo);
    try {
      let lang = (await getSelectedLanguage()) ?? "en";
      lang = lang === "bn" ? "en" : lang;
      requestInfo.language = lang;
      dispatch(setLoading(true));
      const response: any = await PanchangService.getPanchangData(requestInfo);
      dispatch(setPanchangData(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
const getSelectedLanguage = async () => {
  const languageCode = await localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE);
  return languageCode;
};

export const getAllShaktipeethAction =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllShaktipeeth();
      dispatch(setShaktipeeth(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getShaktipeethAndShivlingAction =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllShaktipeeth();
      dispatch(setShaktipeeth(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTempleDetailsById =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsById(
        templeId
      );
      dispatch(setSelectedTempleDetails(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTempleDetailsByNameKey =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsByNameKey(
        templeId
      );
      dispatch(setSelectedTempleDetails(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
export const getBenefitsList = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await CommonService.getAllBenefitList();
    dispatch(setBenefitsList(response?.benefitsList));
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getDarshanList = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await TempleService.getAllDarshanList();
    dispatch(setDarshanList(response));
    dispatch(setLoading(false));
    return Promise.resolve(response);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getTempleAndPujaList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getTempleAndPujaList();
      const { mergedArray, pujaArray, templeArray } = response;
      if (mergedArray && mergedArray.length > 0) {
        dispatch(setMergedList(mergedArray));
      }
      if (pujaArray && pujaArray.length > 0) {
        dispatch(setAllPujaList(pujaArray));
      }
      if (templeArray && templeArray.length > 0) {
        templeArray.sort((a: any, b: any) => b?.position - a?.position);
        dispatch(setAllTempleList(templeArray));
      }
      // dispatch(setDarshanList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPriceInfoOfPujaAndPackage =
  (payload: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await OrderService.getPriceDetailsOfPuja(payload);
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updateOrganization =
  (orgId: string, key: string, value: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response3: any = await TempleService.updateOrganization(
        orgId,
        key,
        value
      );
      // const response: any = await TempleService.getAllTempleList();
      // dispatch(setAllTempleList(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response3);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getAddressFromPinCodeAction =
  (pinCode: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const response = await UserService.getAddressOnPinCode(pinCode);
      if (response) {
        const countryCode = extractCountryCode(response.results);
        dispatch(setLoading(false));
        if (countryCode) {
          return Promise.resolve({ results: response.results, countryCode });
        } else {
          return Promise.reject("Country code not found");
        }
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

const extractCountryCode = (results: any[]): string | null => {
  for (const result of results) {
    const countryComponent = result.address_components.find((component: any) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      return countryComponent.short_name;
    }
  }
  return null;
};

export const syncSearchDataBase =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await GlobalSearchService.updateSearchCollection();
      // dispatch(setAllTempleList(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

// all verified and unverified temples
export const getAllTempleListVerifiedAndUnverified =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getAllTempleList();
      dispatch(setAllVerifiedAndUnverifiedTempleList(response.organisation));
      dispatch(setLoading(false));

      return Promise.resolve(response.organisation);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

// AddEditTempleDetails

export const addEditTempleDetailsFromWeb =
  (payload: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response3: any = await TempleService.AddEditTempleDetailsFromWeb(
        payload
      );
      const response: any = await TempleService.getAllTempleList();
      dispatch(setAllVerifiedAndUnverifiedTempleList(response.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getTranslatedTempleName =
  (scriptTobeTranslated: string, lang: string, targetScript: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response = TransliterationService.transliterateText(
        scriptTobeTranslated,
        lang,
        targetScript
      );
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getGlobalSearchResultAction =
  (searchText: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const response = await GlobalSearchService.globalSearchWithSearchKey(
        searchText.toLowerCase()
      );
      if (response) {
        dispatch(setGlobalSearchResult(response));
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
