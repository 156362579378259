import { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { Box } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "300px",
};

function GoogleMapV2(props: any) {
  const { place, setTempleSelectedInMap, showAddressSection } = props;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDAx2xJ9TEpGOR0MwOpNeZ3CpnLJPYAHyU",
    libraries: ["places"],
  });

  const [markerPosition, setMarkerPosition] = useState<any>({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (place && isLoaded) {
      setMarkerPosition({
        lat: place?.geometry?.location?.lat || 0,
        lng: place?.geometry?.location?.lng || 0,
      })
    }
  }, [place, isLoaded]);

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if(showAddressSection) return;
    try {
      const lat = e.latLng?.lat();
    const lng = e.latLng?.lng();
    if (lat && lng) {
      setMarkerPosition({ lat, lng });
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      const latLng = new google.maps.LatLng(lat, lng);
      const request = {
        location: latLng,
        radius: 50,
        types: ["hindu_temple"],
        componentRestrictions: { country: "in" },
      };

      service.nearbySearch(request, (places, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && places) {
          if (places?.length > 0) {
            const request: any = {
              placeId: places[0]?.place_id,
              radius: 50,
              types: ["hindu_temple"],
            };

            // Get place details from the Places API
            service.getDetails(request, (place, status) => {
              if (
                status === google.maps.places.PlacesServiceStatus.OK &&
                place
              ) {
                console.log("---places-getplace-", place);
                const location = place?.geometry?.location;
                setMarkerPosition({
                  lat: location?.lat(),
                  lng: location?.lng(),
                });
                let tempPlace = JSON.parse(JSON.stringify(place))
                const lat = place?.geometry?.location?.lat();
                const lng = place?.geometry?.location?.lng();
                tempPlace.geometry.location.lat = lat;
                tempPlace.geometry.location.lng = lng;
                setTempleSelectedInMap(tempPlace);
              } else {
                console.log("Error fetching place details");
              }
            });
          } 
        }
      });
    }
    } catch (error) {
      console.log("Error: ",error)
    }
  };

  if (markerPosition?.lat && markerPosition?.lng && isLoaded) {
    
    return (
      <Box>
        <GoogleMap
          
          onClick={handleMapClick}
          mapContainerStyle={containerStyle}
          center={markerPosition}
          zoom={15}
        >
          <MarkerF position={markerPosition} />
        </GoogleMap>
      </Box>
    );
  } else {
    return <>Map not available</>;
  }
}

export default GoogleMapV2;
