import { useState } from "react";
import SignupView from "../../Views/Auth/Signup/SignupView";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  logoutAction,
  submitRegistrationInfoAction,
} from "../../store/actions/authAction";
import ParentView from "../../Views/PrarentView/ParentView";
function SignupScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  var { userInfo } = useSelector((state: any) => state.user);
  // const userDetailsFromState = useSelector((state: any) => state.auth.user);
  const profileSelectedWhileSignup = useSelector(
    (state: any) => state.auth.signUpProfile
  );
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [isErrorInForm, setIsErrorInForm] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: userInfo && userInfo.phoneNumber ? userInfo.phoneNumber : "",
    email: "",
    dateOfBirth: "",
    gender: "",
    userType: profileSelectedWhileSignup
      ? profileSelectedWhileSignup.userType
      : "NORMALUSER",
    paymentProfile: {},
  });

  // useEffect(()=>{
  //   if(!userInfo||!userInfo.phoneNumber){
  //     navigate("/choose-profile")
  //   }
  // },[userInfo])

  const submitSignUp = () => {
    let newUserDetails: any = { ...userDetails };
    newUserDetails["countryCode"] = userInfo?.dialCode;

    if (
      userDetails.firstName &&
      userDetails.phoneNumber
      // userDetails.dateOfBirth
    ) {
      //This should be uncommented once ready
      dispatch(submitRegistrationInfoAction(newUserDetails, {}))
        .then((data: any) => {
          navigate("/");
        })
        .catch((error: any) => {
          setIsErrorInForm(true);
          setErrorDialog(true);
        });
    } else {
      setIsErrorInForm(true);
      setErrorDialog(true);
    }
    // dispatch(submitRegistrationInfoAction(userDetails, {}));
  };

  const cancelAndGoToLogin = () => {
    dispatch(logoutAction());
    navigate("/login");
  };

  return (
    <div>
      <ParentView>
        <SignupView
          cancelAndGoToLogin={cancelAndGoToLogin}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          submitSignUp={submitSignUp}
          profileSelectedWhileSignup={profileSelectedWhileSignup}
          datePickerOpen={datePickerOpen}
          setDatePickerOpen={setDatePickerOpen}
          isErrorInForm={isErrorInForm}
          errorDialog={errorDialog}
          setErrorDialog={setErrorDialog}
        />
      </ParentView>
    </div>
  );
}

export default SignupScreen;
