import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import PrivacyPolicyView from "../../Views/PrivacyPolicy/PrivacyPolicyView";

const PrivacyPolicyScreen = (props: any) => {
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const location = useLocation();
  useEffect(() => {

    const pathData = location.pathname;

    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, [location]);


  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <PrivacyPolicyView parentViewCheck={parentViewCheck} />
        </ParentView>
      ) : (
        <PrivacyPolicyView parentViewCheck={parentViewCheck}/>
      )}
    </>
  );
};

export default PrivacyPolicyScreen;
