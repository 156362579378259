import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
import { getPrasadTempleList } from "../../store/actions/prasadAction";
import PrasadView from "../../Views/Prasad/PrasadView";

function PrasadScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const {prasadTempleList, isLoading} = useSelector((state: any) => state.prasad);
  useEffect(() => {
   dispatch(getPrasadTempleList())
   
  }, [])

  const selectChadhavaTemple = (templeDetails:any)=>{
    navigate(`/prasadDetails/${templeDetails.nameKey}`)
  }
    return (
      <ParentView>
        <PrasadView templeList={prasadTempleList} selectTemple={selectChadhavaTemple} isLoading={isLoading}/>
      </ParentView>
    );
  }
  
  export default PrasadScreen;
  