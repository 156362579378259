import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

interface ManagePrasadOverviewDialogProps {
  open: boolean;
  onClose: () => void;
  handleTempleOverViewSubmit: ()=>void;
  prasadOverViewForTemple: any;
  setPrasadOverViewForTemple: (data:boolean)=> any;
}

const ManagePrasadOverviewDialog: React.FC<ManagePrasadOverviewDialogProps> = ({
  open,
  onClose,
  handleTempleOverViewSubmit,
  prasadOverViewForTemple,
  setPrasadOverViewForTemple
}) => {
  const [overviewError, setOverviewError] = useState<boolean>(false)
  const handleSubmit = () => {
    if(prasadOverViewForTemple.length < 1){
      setOverviewError(true)
      return;
    }
    handleTempleOverViewSubmit();
    onClose();
  };

  const handlePrasadOverviewChange = (e:any)=>{
    e.preventDefault();
    setOverviewError(false)
    setPrasadOverViewForTemple(e.target.value)
  }

  const closeDialog = ()=>{
    onClose();
    setOverviewError(false);
  }

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Add Prasad Overview</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
      <TextField
          multiline
          maxRows={10}
          variant="outlined"
          fullWidth
          value={prasadOverViewForTemple}
          onChange={handlePrasadOverviewChange}
          error={overviewError}
          helperText = { overviewError ? "Prasad overview can not be blank." : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagePrasadOverviewDialog;
