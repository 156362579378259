import { useEffect, useState } from "react";
import ChadhavaView from "../../Views/Chadhava/ChadhavaView";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
import { getChadhavaTempleList } from "../../store/actions/ChadhawaAction";

function ChadhavaScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const {cartInfo} = useSelector((state: any) => state.cart);
  const {allTempleList} = useSelector((state: any) => state.temple);
  const {chadhavaTempleList, isLoading} = useSelector((state: any) => state.chadhawa);
  const [templeList,setTempleList] = useState([])
  useEffect(() => {
   dispatch(getChadhavaTempleList())
   
  }, [])

  const selectChadhavaTemple = (templeDetails:any)=>{
    navigate(`/chadhavaDetails/${templeDetails.nameKey}`)
  }
    return (
      <ParentView>
        <ChadhavaView templeList={chadhavaTempleList} selectTemple={selectChadhavaTemple} cartDetails={cartInfo} isLoading={isLoading}/>
      </ParentView>
    );
  }
  
  export default ChadhavaScreen;
  