/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonService } from "td_server";
import {
  setAnalyticsList,
  setLoading,
  setPaginatedAnalyticsList,
} from "../slices/analyticsSlice";


export const getLoadingState = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const updateAnalyticsStatusAction =
  (formData: any, userId: any,dateRange:any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const status = await CommonService.updateAnalyticsStatusForUser(
        formData,
        userId,
        dateRange
      );
      dispatch(setLoading(false));
      return Promise.resolve("");
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getNewAnalyticsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getAllAnalytics();
      const fdata = response.analyticsList.filter((d: any) => !d.status);
      dispatch(setAnalyticsList(fdata));
      dispatch(setLoading(false));
      return Promise.resolve(fdata);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const addAnalytics = (data:any) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await CommonService.addAnalytics(data);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject("");
    }
  };

export const getNewPaginatedAnalyticsList =
  (lastSnapshot: any, queryLimit: any) =>
  async (dispatch: any, getState: any) => {
    try {
      var currentState = getState();
      var currentAnalyticsData = currentState.analytics.analyticsList;
      dispatch(setLoading(true));
      const response: any = await CommonService.getAnalyticsPaginated(
        lastSnapshot,
        queryLimit
      );

      dispatch(setPaginatedAnalyticsList(response));
      var updatedResponse = response.queriedAnalytics;
      const fdata = updatedResponse.filter((d: any) => !d.status);
      var tempData = [...currentAnalyticsData, ...fdata];

      var sortedData = tempData;
      dispatch(setAnalyticsList(sortedData));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log("ERROR : ", e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getViewedAnalyticsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getAllAnalytics();
      const fdata = response.analyticsList.filter(
        (d: any) => d?.status && d?.status?.length > 0
      );
      dispatch(setAnalyticsList(fdata));
      dispatch(setLoading(false));
      return Promise.resolve(fdata);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getViewedPaginatedAnalyticsList =
  (lastSnapshot: any, queryLimit: any) =>
  async (dispatch: any, getState: any) => {
    try {
      var currentState = getState();
      var currentAnalyticsData = currentState.analytics.analyticsList;
      dispatch(setLoading(true));
      const response: any = await CommonService.getAnalyticsPaginated(
        lastSnapshot,
        queryLimit
      );
      dispatch(setPaginatedAnalyticsList(response));
      var updatedResponse = response.queriedAnalytics;
      const fdata = updatedResponse.filter(
        (d: any) => d?.status && d?.status?.length > 0
      );
      var tempData = [...currentAnalyticsData, ...fdata];

      var sortedData = tempData;
      dispatch(setAnalyticsList(sortedData));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };
