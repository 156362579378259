import Paper from "@mui/material/Paper";

export default function TextArea(props: any) {
  return (
    <Paper elevation={props.elevation}>
      {props.iconClassName ? <i className={props.iconClassName}></i> : null}

      {props.title}
    </Paper>
  );
}
