import { useEffect, lazy, useState, Suspense } from "react";
import "./App.scss";
import { useSelector } from "react-redux";
import "./i18n";
import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import Header from "./components/header/Header";
import LoginScreen from "./Screen/Auth/LoginScreen";
import SignupScreen from "./Screen/Auth/SignupScreen";
import ResetPasswordScreen from "./Screen/Auth/ResetPasswordScreen";
import ChooseProfileScreen from "./Screen/Auth/ChooseProfileScreen";
import PrivateRoutes from "./routes/PrivateRoutes";
import AuthHandlerRoutes from "./routes/AuthHandlerRoutes";
import HomeScreen from "./Screen/Home/HomeScreen";
import AdminScreen from "./Screen/Admin/AdminScreen";
import UserAnalyticsScreen from "./Screen/Admin/UserAnalyticsScreen";
import ProfileScreen from "./Screen/Profile/ProfileScreen";
import PanditBookingScreen from "./Screen/PanditBooking/PanditBookingScreen";
import DonationScreen from "./Screen/Donation/DonationScreen";
import PujaScreen from "./Screen/Puja/PujaScreen";
import TempleScreen from "./Screen/Temple/TempleScreen";
import PersonalInfoScreen from "./Screen/Profile/Screen/PersonalInfo/PersonalInfoScreen";
import AddressScreen from "./Screen/Profile/Screen/Address/AddressScreen";
import TempleDetailsScreen from "./Screen/Temple/TempleDetailsScreen";
import VirtualPujaBookingScreen from "./Screen/Puja/VirtualPoojaBookingScreen";
import { useDispatch } from "react-redux";
import CouponCodeScreen from "./Screen/Admin/CouponCode/CouponCodeScreen";
import { useNavigate } from "react-router-dom";
import AboutUsScreen from "./Screen/AboutUs/AboutUsScreen";
import MyOrdersScreen from "./Screen/MyOrders/MyOrdersScreen";
import CheckUserFromOAuth from "./handlers/AuthReload";
import LoadInitialData from "./handlers/LoadInitialData";
import SendPushNotificationScreen from "./Screen/Admin/SendPushNotificationScreen";

import AdminHandlerRoutes from "./routes/AdminHandlerRoutes";
import PujaInfoScreen from "./Screen/Puja/PujaInfoScreen";
import PanchangScreen from "./Screen/Panchang/PanchangScreen";
import ShaktipethaScreen from "./Screen/Temple/ShaktipethaScreen";
import PromotioanlPujaScreen from "./Screen/Puja/PromotionalPujaScreen";
import LiveDarshanScreen from "./Screen/LiveDarshan/LiveDarshanScreen";
import LiveDarshanVideoScreen from "./Screen/LiveDarshan/LiveDarshanVideoScreen";
import SearchScreen from "./Screen/Search/SearchScreen";
import {
  formatNumber,
  getCurrentPosition,
  getRandomNumber,
} from "./utils/HelperFunctions";
import PageLinkScreen from "./Screen/PageLink/PageLinkScreen";
import PrivacyPolicyScreen from "./Screen/PrivacyPolicy/PrivacyPolicyScreen";
import TermsAndConditionsScreen from "./Screen/TermsAndConditions/TermsAndConditionsScreen";
import ContactUsScreen from "./Screen/ContactUs/ContactUsScreen";
import AboutScreen from "./Screen/About/AboutScreen";
import RefundsCancellationsScreen from "./Screen/RefundsCancellations/RefundsCancellationsScreen";
import CommunityGuidelinesScreen from "./Screen/CommunityGuidelines/CommunityGuidelinesScreen";
import PujaSubCategoriesScreen from "./Screen/Puja/PujaSubCategoriesScreen";
import ErrorScreen from "./Screen/Error/ErrorScreen";
import SuccessScreen from "./Screen/Success/SuccessScreen";
import OfflineDetector from "./components/offlinedetector/OfflineDetector";
import OffLineScreen from "./Screen/Offline/OffLineScreen";
import ChadhavaScreen from "./Screen/Chadhava/ChadhavaScreen";
import ChadhavaDetailsScreen from "./Screen/Chadhava/ChadhavaDetailsScreen";
import HoroscopeManageScreen from "./Screen/Admin/HoroscopeManageScreen";
import HoroscopeScreen from "./Screen/Horoscope/HoroscopeScreen";
import HoroscopeDetailsScreen from "./Screen/Horoscope/HoroScopeDetailsScreen";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PujaStepsScreen from "./Screen/PujaSteps/PujaStepsScreen";
import PujaVideoScreen from "./Screen/PujaVideo/PujaVideoScreen";
import ChadhawaItemsScreen from "./Screen/Admin/ChadhawaItemsScreen";
import CheckoutV2Screen from "./Screen/CheckoutV2/CheckoutV2Screen";
import AddDevoteeDetailsScreen from "./Screen/CheckoutV2/AddDevotee/AddDevoteeScreen";
import ReviewCartScreen from "./Screen/CheckoutV2/ReviewCart/ReviewCartScreen";
import BackToTop from "./components/BackToTopBtn/BackToTop";
import AIGurujiHOD from "./Screen/AIGurujiHOD/AIGurujiHOD";
import AIGurujiIcon from "../src/assets/valmiki-avatar.png";
import LiveGif from "../src/assets/live.gif";
import { Box, Typography } from "@mui/material";
import EventScreen from "./Screen/Event/EventScreen";
import PostsScreen from "./Screen/Blogs/PostsScreen";
import PostsContentScreen from "./Screen/Blogs/PostsContentScreen";
import ManageBlogScreen from "./Screen/Admin/ManageBlogScreen";
import BlogCategoryScreen from "./Screen/Blogs/BlogCategoryScreen";
import AIGurujiLoginDialog from "./components/dialog/AIGurujiLoginDialog";
import { setMessageToReactNative } from "./utils/WebNativeFunctions";
import { checkUserDetails, logoutAction } from "./store/actions/authAction";
import AdminContenWriterHandlerRoutes from "./routes/AdminContenWriterHandlerRoutes";
import TempleAdminScreen from "./Screen/Admin/TempleAdminScreen";
import { GurujiHomeIcon } from "./utils/ConstantsMessages";
import ManageSpecialPujaScreen from "./Screen/Admin/SpecialPuja/ManageSpecialPujaScreen";
import AIGurujiChatScreen from "./Screen/Admin/AIGurujiChatScreen";
import TempleOnboardingScreen from "./Screen/Admin/TempleOnboarding/TempleOnboardingScreen";
import PrasadDetailsScreen from "./Screen/Prasad/PrasadDetailsScreen";
import ManagePrasadScreen from "./Screen/Admin/ManagePrasadScreen";
import PrasadScreen from "./Screen/Prasad/PrasadScreen";
import SettingsScreen from "./Screen/Admin/SettingsScreen";
import AddLiveDarshanScreen from "./Screen/Admin/ManageLiveDarshan/ManageLiveDarshanScreen";
import GlobalSearchScreen from "./Screen/GlobalSearch/GlobalSearchScreen";
import ManagePujaScreen from "./Screen/Admin/ManagePujaScreen";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "./utils/localization";
import { LOCALSTORAGE_KEY } from "./utils/Constants";

function App() {
  var { userInfo } = useSelector((state: any) => state.user);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [isMobileView, setIsMobileView] = useState(false);
  const location = useLocation();

  const { t, i18n } = useTranslation();
  // code for download app prompt
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isChatWindowOpen, setChatWindowOpen] = useState(false);
  const [isShowAlertForLogin, setShowAlertForLogin] = useState(false);

  const [liveCount, setLiveCount] = useState(getRandomNumber());
  const [selectedMobileLanguage, setSelectedMobileLanguage] = useState("");
  const [searchParams] = useSearchParams();
  const ln = searchParams.get("ln")
    ? searchParams.get("ln")
    : localStorage?.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";

  useEffect(() => {
    const interval = setInterval(() => {
      setLiveCount(getRandomNumber());
    }, 120000);
    return () => clearInterval(interval);
  }, []);
  // const allRoutes = generateRoutesForRRD();
  // const postLoginRoutes = generatePostLoginRoutesForRRD();
  useEffect(() => {
    CheckUserFromOAuth(dispatch);
    LoadInitialData(dispatch);
  }, []);

  useEffect(() => {
    if (ln) {
      i18n.changeLanguage(ln);
      localStorage.setItem(LOCALSTORAGE_KEY.LANG_CODE, ln);
    }
  }, [ln]);

  useEffect(() => {
    // if (userInfo && userInfo.newUser) {
    // navigate("/choose-profile");
    // }
    // if (userInfo?.id) {

    const addressPermissionForPages = ["/"];
    if (
      addressPermissionForPages.includes(location.pathname) ||
      /^\/chadhavaDetails\/[^/]+$/.test(location.pathname)
    ) {
      getCurrentPosition(dispatch);
    }
    if (location.pathname === "/ai-valmiki" && userInfo) {
      setChatWindowOpen(true);
      setShowAlertForLogin(false);
    }
    if (location.pathname === "/ai-valmiki" && !userInfo) {
      setChatWindowOpen(false);
      setShowAlertForLogin(true);
    }
    // }
  }, [userInfo, location.pathname]);

  //Fetch user location data

  // useEffect(() => {
  //   // Show prompt in few days

  //   const { pathname } = location;

  //   let compare = true;
  //   let extractedMoment = localStorage.getItem("nextDownloadPrompt");
  //   if (extractedMoment) {
  //     compare = moment().isSameOrAfter(extractedMoment);
  //   }
  //   if (
  //     isMobile() &&
  //     !localStorage.getItem("appDownloadClicked") &&
  //     compare &&
  //     !pathname.includes("nativeViews")
  //   ) {
  //     setIsDialogOpen(true);
  //   }
  // }, []);

  // const handleClose = () => {
  //   let lastDownloadPrompt = moment()
  //     .local()
  //     .add(promptInterValInDays, "days")
  //     .format();
  //   localStorage.setItem("nextDownloadPrompt", lastDownloadPrompt);
  //   setIsDialogOpen(false);
  // };

  // const handleDownload = (platform: string) => {
  //   localStorage.setItem("appDownloadClicked", "true");
  //   setIsDialogOpen(false);
  //   if (platform === "ios") {
  //     window.open("https://apps.apple.com/us/app/id6467179618");
  //   } else if (platform === "android") {
  //     window.open(
  //       "https://play.google.com/store/apps/details?id=com.omkara.templedekho.dev"
  //     );
  //   }
  // };

  //
  useEffect(() => {
    function handleEvent(message: any) {
      //setMessage(message.data);
      if (message.data.isChatWindow) {
        dispatch(checkUserDetails(message.data.phoneNumber));
        setChatWindowOpen(true);
        setIsMobileView(true);
        let mobileLang: string = "";
        if (
          message.data.language &&
          (message.data.language === "EN" ||
            message.data.language === "HI" ||
            message.data.language === "BN")
        ) {
          mobileLang =
            message.data.language === "BN" ? "EN" : message.data.language;
          setSelectedMobileLanguage(mobileLang);
        }
      } else if (message.data.loginFromMobile) {
        setIsMobileView(true);
        dispatch(checkUserDetails(message.data.phoneNumber));
      } else if (message.data.logoutFromMobile) {
        dispatch(logoutAction());
      }
    }
    document.addEventListener("message", handleEvent);

    return () => document.removeEventListener("message", handleEvent);
  }, []);

  // useEffect(()=>{
  //   if(location.pathname === "/ai-valmiki" && userInfo){
  //     setChatWindowOpen(true)
  //     setShowAlertForLogin(false)
  //   }
  //   if(location.pathname === "/ai-valmiki" && !userInfo){
  //     setChatWindowOpen(false)
  //     setShowAlertForLogin(true)
  //   }
  // },[location.pathname,userInfo])

  const handleChatWindowClosed = () => {
    //alert(`Close`)
    setChatWindowOpen(false);
    setMessageToReactNative(JSON.stringify({ isCloseAIChat: true }));
    if (location.pathname === "/ai-valmiki") {
      navigate("/");
    }
  };
  const closeAlertForLogin = (type: string) => {
    //alert(`Close`)
    setShowAlertForLogin(false);
    setMessageToReactNative(JSON.stringify({ isCloseAIChat: true }));
    if (type === "close") {
      navigate("/");
    }
  };
  return (
    <div className="templeApp">
      <Suspense fallback={<div>Loading...</div>}>
        {/* {userInfo?.id ? <Header /> : null} */}
        {!isMobileView ? <Header ln={ln} /> : null}
        {/* TODO */}
        <div className="App">
          <OfflineDetector>
            <ScrollToTop />
            {/* ---- AI Guruji Floting Icon ---- */}
            {!isChatWindowOpen &&
              !isShowAlertForLogin &&
              (window.location.pathname === "/" ||
                window.location.pathname === "/home") && (
                // <Box
                //   component="img"
                //   src={AIGurujiIcon}
                //   className="aiGuruFloatingIcon"
                //   onClick={(e) => {
                //     e.preventDefault();
                //     if (userInfo) {
                //       setChatWindowOpen(true);
                //     } else {
                //       setShowAlertForLogin(true);
                //     }
                //   }}
                // />

                <Box
                  className="aiGuruFloatingIconContainer"
                  onClick={(e) => {
                    e.preventDefault();
                    if (userInfo) {
                      setChatWindowOpen(true);
                    } else {
                      setShowAlertForLogin(true);
                    }
                  }}
                >
                  <Box
                    component="img"
                    src={AIGurujiIcon}
                    alt="Valmiki"
                    className="gurujiAvatar"
                  />
                  <Box className="textSection">
                    <Box className="liveTag">
                      <img src={LiveGif} alt="Live" />
                    </Box>
                    <Box className="textRightPart">
                      <Typography className="chatText">
                        {t(LOCALIZATION.CHAT_WITH_VALMIKI)}
                      </Typography>
                      <Box className="onlineStatus">
                        <span className="greenDot" />
                        <Typography className="onlineCount">
                          {formatNumber(liveCount)} {t(LOCALIZATION.ONLINE)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

            <Routes>
              <Route path="/apps" element={<AboutUsScreen />} />
              <Route path="*" element={<AboutUsScreen />} />
              <Route path="/error" element={<ErrorScreen />} />
              <Route path="/success" element={<SuccessScreen />} />
              <Route path="/offline" element={<OffLineScreen />} />

              {/* <Route path="/pageLink" element={<AboutUsScreen />} /> */}

              {/* Protected real application routes  and routes need to be handeled from setup routes according to prelogin ,post login and admin Screen*/}
              {/* TODO */}
              <Route element={<PrivateRoutes />}>
                {/* <Route path="/" element={<HomeScreen />} /> */}
                <Route path="/" element={<HomeScreen />} />
                <Route path="/ai-valmiki" element={<HomeScreen />} />
                {/* <Route path="/home" element={<HomeScreen />} /> */}
                <Route path="/profile" element={<ProfileScreen />}>
                  <Route path="personalInfo" element={<PersonalInfoScreen />} />
                  <Route path="address" element={<AddressScreen />} />
                  {/* <Route path="payment" element={<PaymentInfoScreen />} />
                <Route path="family" element={<FamilyMembersScreen />} />
                <Route path="account" element={<AccountSettingsScreen />} /> */}
                </Route>
                <Route path="/pujas" element={<PujaScreen />} />
                {/* <Route path="/puja-info" element={<PujaInfoScreen />} /> */}
                <Route
                  path="/templePujaList/:templeId"
                  element={<VirtualPujaBookingScreen keyType="id" />}
                />
                {/* Below is New route for templePuja list*/}
                <Route
                  path="/templePujas"
                  element={<VirtualPujaBookingScreen keyType="nameKey" />}
                />

                <Route
                  path="/temples/:templeId/pujaInfo/:pujaId"
                  element={<PujaInfoScreen keyType="id" />}
                />
                {/* Below is New route for Puja info*/}
                <Route
                  path="/templePujaInfo"
                  element={<PujaInfoScreen keyType="nameKey" />}
                />
                <Route
                  path="/pandit-booking"
                  element={<PanditBookingScreen />}
                />
                <Route path="/temples" element={<TempleScreen />} />
                <Route path="/livedarshan" element={<LiveDarshanScreen />} />
                <Route path="/horoscope" element={<HoroscopeScreen />} />
                <Route
                  path="/horoscopeDetails"
                  element={<HoroscopeDetailsScreen />}
                />
                <Route
                  path="/livedarshantemple/:id"
                  element={<LiveDarshanVideoScreen />}
                />
                <Route path="/panchang" element={<PanchangScreen />} />
                <Route
                  path="/specialTemples/:templeType"
                  element={<ShaktipethaScreen />}
                />
                <Route
                  path="/promotionalPuja/:id"
                  element={<PromotioanlPujaScreen keyType="id" />}
                />
                {/*New route for special puja*/}
                <Route
                  path="/specialPuja"
                  element={<PromotioanlPujaScreen keyType="nameKey" />}
                />

                <Route
                  path="/temples/:id"
                  element={<TempleDetailsScreen keyType="id" />}
                />
                {/*New route for templeInfo*/}
                <Route
                  path="/templeInfo"
                  element={<TempleDetailsScreen keyType="nameKey" />}
                />
                <Route
                  path="/pujaSubCategory/:id"
                  element={<PujaSubCategoriesScreen />}
                />
                <Route path="/chadhavaTemples" element={<ChadhavaScreen />} />
                <Route
                  path="/chadhavaDetails/:id"
                  element={<ChadhavaDetailsScreen />}
                />
                <Route path="/prasadTemples" element={<PrasadScreen />} />
                <Route
                  path="/prasadDetails/:id"
                  element={<PrasadDetailsScreen />}
                />
                <Route path="/donation" element={<DonationScreen />} />
                {/* <Route path="/aiguruji" element={<AIGurujiHOD openModal={true} />} /> */}
                {/* <Route path="/checkout" element={<CheckoutScreen />}>
                <Route path="puja-details" element={<PujaDetailsScreen />} />
                <Route path="cart" element={<CartScreen />} />
              </Route> */}

                <Route path="/checkoutV2" element={<CheckoutV2Screen />}>
                  <Route
                    path="addDevotee"
                    element={<AddDevoteeDetailsScreen />}
                  />
                  <Route path="reviewCart" element={<ReviewCartScreen />} />
                </Route>
                {/* <Route path="/notification" element={<NotificationScreen />} /> */}
                <Route path="/search" element={<SearchScreen />} />
                <Route path="/search/category/:id" element={<SearchScreen />} />
                <Route path="/globalsearch" element={<GlobalSearchScreen />} />

                {/*Routes For blogs*/}

                <Route path="/posts" element={<PostsScreen />}></Route>
                <Route
                  path="posts/content/:id"
                  element={<PostsContentScreen />}
                />
                <Route
                  path="posts/category/:type"
                  element={<BlogCategoryScreen />}
                />

                <Route path="/myOrders" element={<MyOrdersScreen />} />
                {/* <Route path="/order-details" element={<OrderDetailsScreen />} /> */}
                {/* Protected Admin routes */}

                <Route
                  path="/externaladmin"
                  element={<AdminContenWriterHandlerRoutes />}
                >
                  <Route path="posts" element={<ManageBlogScreen />} />
                </Route>

                <Route path="/admin" element={<AdminHandlerRoutes />}>
                  <Route path="" element={<AdminScreen />} />
                  <Route
                    path="useranalytics"
                    element={<UserAnalyticsScreen />}
                  />
                  <Route
                    path="sendpush"
                    element={<SendPushNotificationScreen />}
                  />
                  <Route path="couponcode" element={<CouponCodeScreen />} />
                  <Route
                    path="horoscopes"
                    element={<HoroscopeManageScreen />}
                  />
                  <Route path="chadhawa" element={<ChadhawaItemsScreen />} />
                  <Route path="posts" element={<ManageBlogScreen />} />
                  <Route path="temples" element={<TempleAdminScreen />} />
                  <Route
                    path="specialpuja"
                    element={<ManageSpecialPujaScreen />}
                  />
                  <Route
                    path="onboardtemple"
                    element={<TempleOnboardingScreen />}
                  />
                  <Route
                    path="livedarshan"
                    element={<AddLiveDarshanScreen />}
                  />
                  <Route path="gurujichats" element={<AIGurujiChatScreen />} />
                  <Route path="manageprasad" element={<ManagePrasadScreen />} />
                  {/* <Route path="settings" element={<SettingsScreen/>} /> */}
                  <Route path="managepuja" element={<ManagePujaScreen />} />
                </Route>

                <Route path="/nativeViews">
                  <Route
                    path="horoscope"
                    element={
                      <HoroscopeScreen setIsMobileView={setIsMobileView} />
                    }
                  />
                  <Route
                    path="horoscopeDetails"
                    element={
                      <HoroscopeDetailsScreen
                        setIsMobileView={setIsMobileView}
                      />
                    }
                  />
                  <Route
                    path="privacypolicy"
                    element={
                      <PrivacyPolicyScreen setIsMobileView={setIsMobileView} />
                    }
                  />
                  <Route
                    path="event"
                    element={<EventScreen setIsMobileView={setIsMobileView} />}
                  />
                  <Route
                    path="posts/content/:id"
                    element={
                      <PostsContentScreen setIsMobileView={setIsMobileView} />
                    }
                  />
                  <Route
                    path="posts"
                    element={<PostsScreen setIsMobileView={setIsMobileView} />}
                  />
                  <Route
                    path="posts/category/:type"
                    element={
                      <BlogCategoryScreen setIsMobileView={setIsMobileView} />
                    }
                  />
                </Route>
              </Route>
              {/* Routes Redirect between Auth and Home */}
              <Route element={<AuthHandlerRoutes />}>
                <Route
                  path="/choose-profile"
                  element={<ChooseProfileScreen />}
                />
                <Route path="/login" element={<LoginScreen />} />
                {/* <Route path="/" element={<LoginScreen />} /> */}
                <Route path="/signup" element={<SignupScreen />} />
                <Route
                  path="/reset-password"
                  element={<ResetPasswordScreen />}
                />
              </Route>
              <Route path="/pagelink" element={<PageLinkScreen />} />
              <Route path="/privacypolicy" element={<PrivacyPolicyScreen />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditionsScreen />}
              />
              <Route path="/contactus" element={<ContactUsScreen />} />
              <Route path="/about" element={<AboutScreen />} />
              <Route
                path="/refundscancellations"
                element={<RefundsCancellationsScreen />}
              />
              <Route
                path="/communityguidelines"
                element={<CommunityGuidelinesScreen />}
              />
              <Route path="/pujaVideo" element={<PujaVideoScreen />} />
              <Route path="/pujaSteps" element={<PujaStepsScreen />} />

              {/* Separate admin functionality in this route and separate auth ahndler need to be added*/}
            </Routes>
          </OfflineDetector>
          {/* <MobileAppPrompt
          open={isDialogOpen}
          onClose={handleClose}
          onDownload={handleDownload}
        /> */}
        </div>

        <AIGurujiHOD
          openModal={isChatWindowOpen}
          closeModal={handleChatWindowClosed}
          isMobileView={isMobileView}
          selectedMobileLanguage={selectedMobileLanguage}
        />

        <AIGurujiLoginDialog
          openModal={isShowAlertForLogin}
          closeModal={closeAlertForLogin}
        />

        <BackToTop isMobileView={isMobileView} />
      </Suspense>
    </div>
  );
}

export default App;
