import React, { useState, useEffect } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import { googleMapKey } from "../../configs/firebaseConfig";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { convertMinutesToOffset } from "../../utils/HelperFunctions";
const libraries: "places"[] = ["places"];

const GooglePlaceAutoComplete: React.FC<any> = ({
  onChangeHandle,
  variant = "outlined",
  label = "Search Temple",
  placeholder = "Start typing temple name...",
  cityValue,
  placeId = "",
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapKey,
    libraries,
  });

  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    if (placeId) {
    }
  }, [placeId]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    const request = {
      input: inputValue,
      types: ["hindu_temple"],
      componentRestrictions: { country: "in" },
    };

    service.getPlacePredictions(request, (predictions: any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setOptions(
          predictions.map((prediction: any) => ({
            templeName: prediction?.structured_formatting?.main_text,
            address: prediction?.structured_formatting?.secondary_text,
            place_id: prediction.place_id,
          }))
        );
      }
    });
  }, [inputValue]);

  const handlePlaceSelect = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    const request = {
      placeId,
    };

    service.getDetails(request, (place: any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        onChangeHandle(place);
      }
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => {
        return option.templeName;
      }}
      filterOptions={(x) => x}
      defaultValue={cityValue || null}
      isOptionEqualToValue={(option, value) =>
        option.place_id === value.place_id
      }
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
        // onChangeHandle(value);
        if (value) {
          handlePlaceSelect(value.place_id);
        }
        // else {
        //   onChangeHandle(null);
        // }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || undefined}
          variant={variant}
          placeholder={placeholder}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <LocationOnIcon style={{ marginRight: 8 }} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body2">{option?.templeName}</Typography>
            <Typography variant="body2">{option?.address}</Typography>
          </Box>
        </Box>
      )}
    />
  );
};

export default GooglePlaceAutoComplete;
