import {createSlice} from '@reduxjs/toolkit';

const couponCodeSlice = createSlice({
  name: 'couponcode',
  initialState: {
    isLoading: false,
    couponcodeList:[],
    activeCouponCodeList:[]
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCouponCodeList: (state, action) => {
      state.couponcodeList = action.payload;
    },
    setActiveCouponCodeList: (state, action) => {
      state.activeCouponCodeList = action.payload;
    },
    
  },
});

export const { setLoading,setCouponCodeList,setActiveCouponCodeList } = couponCodeSlice.actions;
export default couponCodeSlice.reducer;
