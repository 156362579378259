// OfflineDetector.tsx
import React, { useEffect, useState, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
interface OfflineDetectorProps {
  children: ReactNode;
}

const OfflineDetector: React.FC<OfflineDetectorProps> = ({ children }) => {
  const navigate = useNavigate();
  const [online, setOnline] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const handleOffline = () => {
      setOnline(false);
      navigate("/offline");
    };

    const handleOnline = () => {
      setOnline(true);
      setShowSnackbar(true);
    };

    const handleClick = (event: MouseEvent) => {
      if (!navigator.onLine) {
        event.preventDefault();
        navigate("/offline");
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    document.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      document.removeEventListener("click", handleClick);
    };
  }, [navigate]);


  return (
    <>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          severity="success"
          icon={<WifiRoundedIcon fontSize="inherit" sx={{color: "#fff"}} />}
          sx={{ backgroundColor: "green", color: "#ffffff" }}
        >
          You are back online!
        </Alert>
      </Snackbar>
    </>
  );
};

export default OfflineDetector;
