import React, { useState, useRef, useEffect } from "react";
import "./PrasadDetailsView.scss";
import viewBless from "../../assets/bless-your-self.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";

import RemoveIcon from "@mui/icons-material/Remove";
import { AddIcon } from "@chakra-ui/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShareLink from "../../components/sharelink/sharelink";
import "react-multi-carousel/lib/styles.css";
// import "./Chadhava.scss";
import { disclaimerText, POP_UP_MESSAGE } from "../../utils/ConstantsMessages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AlertDialog from "../../components/dialog/AlertDialog";
import { formatNumberWithK, getTempleName } from "../../utils/HelperFunctions";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";
import { LOCALSTORAGE_KEY } from "../../utils/Constants";

function PrasadDetailsView(props: any) {
  const { t, i18n } = useTranslation();
  const {
    templeProfilePic,
    templeDetails,
    templePersonalInfo,
    handleIncrement,
    handleDecrement,
    filteredprasadList,
    navigateToSeeVideoView,
    getAvilableChadhavaDate,
    totalPrice,
    totalPriceDisplay,
    setIsDateTimePicker,
    isSingleDatePuja,
    isDateTimePicker,
    shouldDisableDate,
    setPujaDateAndTime,
    pujaDateAndTime,
    cartInfo,
    setPopupItemAlreadyAddedInCart,
    preprocessingAndProceedToAddmemberScreen,
    popupItemAlreadyAddedInCart,
    overSeasRate,
    currencySymbols,
    isOverseas,
    isCountryDisabledForBooking,
    isLoading,
  } = props;

  const templeProfilePicture = templeProfilePic();
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isActiveTemple, setisActiveTemple] = useState<any>(false);
  // Chadhava Add Popup
  const [isPopupOpen, setPopupOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [
    openAlertDialogForBookingNotAllowed,
    setOpenAlertDialogForBookingNotAllowed,
  ] = useState<any>(false);

  const handleButtonClick = (el: any) => {
    setSelectedItem(el);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setPopupOpen(false);
    }
  };

  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeTitle = getTempleName(props.templeDetails, savedLanguage);

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupOpen]);

  const [urlToShare, setUrlToShare] = useState<string>("");
  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?prasadOftemple=${templeDetails?.nameKey}`
    );
  }, [templeDetails]);

  const [templeName, setTempleName] = useState<string>("");
  const [templeAddress, setTempleAddress] = useState<string>("");
  const [templeDescriptions, setTempleDescriptions] = useState<string>("");
  const [prasadBenefits, setParsadBenefits] = useState<any>([]);
  const { city, state } = templePersonalInfo?.address || {};
  let filteredArray = filteredprasadList || [];

  useEffect(() => {
    if (templePersonalInfo?.name) {
      setTempleName(templePersonalInfo?.name);
    }
    const overview = templeDetails?.prasadOverview
      ? templeDetails?.prasadOverview
      : templePersonalInfo?.descriptions;
    setTempleDescriptions(overview || "");
  }, [templePersonalInfo, templeDetails?.prasadOverview]);

  useEffect(() => {
    if (templeDetails?.prasadBenefits) {
      setParsadBenefits(templeDetails?.prasadBenefits);
    }
  }, [templeDetails]);

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const increment = (item: any) => {
    handleIncrement(item);
  };

  const decrement = (item: any) => {
    handleDecrement(item);
  };

  const addChadhavaItem = (item: any) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      props.addChadhavaItem(item);
    }
  };

  // For Temple Details Read More & Less //
  const [isExpandedTempleDetails, setIsExpandedTempleDetails] = useState<any>(
    []
  );

  const toggleReadMore = (id: number) => {
    let tempArr = [...isExpandedTempleDetails];
    if (isExpandedTempleDetails.includes(id)) {
      tempArr = tempArr.filter((item: any) => item !== id);
    } else {
      tempArr.push(id);
    }
    setIsExpandedTempleDetails([...tempArr]);
  };
  const [wordLimit, setWordLimit] = React.useState(
    window.innerWidth < 600 ? 10 : 25
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWordLimit(window.innerWidth < 450 ? 10 : 20);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // END

  const [isAboveFooter, setIsAboveFooter] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footerArea");
      const button = document.querySelector(".sticky-button");
      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const buttonRect = button.getBoundingClientRect();
        if (
          footerRect.top < window.innerHeight &&
          footerRect.bottom >= buttonRect.bottom
        ) {
          setIsAboveFooter(true);
        } else {
          setIsAboveFooter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleCloseAlertDialogForBookingNotAllowed = () => {
    setOpenAlertDialogForBookingNotAllowed(false);
  };

  const handleSetIsDateTimePicker = (isOpen: boolean) => {
    if (isCountryDisabledForBooking) {
      setOpenAlertDialogForBookingNotAllowed(true);
    } else {
      if (cartInfo && cartInfo.length > 0) {
        setPopupItemAlreadyAddedInCart(true);
      } else {
        preprocessingAndProceedToAddmemberScreen();
      }
    }
  };
  const handleChange1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    let _isActiveTemple = false;
    if (templeDetails) {
      _isActiveTemple =
        templeDetails?.isPrasadEnabled === false ||
        templeDetails?.isClosed === true
          ? false
          : true;
    }
    setisActiveTemple(_isActiveTemple);
  }, [templeDetails]);

  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={templeProfilePicture} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="chadhavaNameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "35px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={templeTitle} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "35px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            sx={{ color: "black !important" }}
                            primary={`${city}, ${state}`}
                          />
                        </ListItem>
                        <ListItem>
                          <Box className="devoteeInfomation">
                            <Typography className="countText">
                              <span>
                                {`${
                                  filteredArray && filteredArray.length > 0
                                    ? formatNumberWithK(
                                        filteredArray.length * 135
                                      )
                                    : formatNumberWithK(13 * 145)
                                } `}
                                +
                              </span>{" "}
                              {t(LOCALIZATION.devotees_booked_Prasad)}
                            </Typography>
                          </Box>
                        </ListItem>
                        <ListItem>
                          <Typography className="disclaimerText">
                            <ErrorOutlineIcon />
                            {t(
                              LOCALIZATION.Prasad_delivery_currently_limited_to_locations_within_India
                            )}
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary title="About “Temple Dekho”"></SubtitleTypographyPrimary>
                <Typography>
                  Please watch our feature video to discover the capabilities of
                  the <span>Temple Dekho</span> app.
                </Typography>
                <Button
                  onClick={() => navigateToSeeVideoView()}
                  variant="contained"
                  className="ChadhavSeeVideoBtn"
                >
                  See Video
                </Button>
              </Box> */}

              <Box className="chadhavaItemTitle" mb={2}>
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Prasad_Package)}
                ></SubtitleTypographyPrimary>
              </Box>
              {isLoading === false &&
                filteredArray?.map((item: any, index: number) => (
                  <Box
                    className={`chadhavaDetailsBlock chadhavaItems ${
                      item.disabled === true || isActiveTemple === false
                        ? "boxDisable"
                        : ""
                    }`}
                    key={"PrasadItem" + index}
                  >
                    <Box className="chadhavaItem">
                      <Box className="chadhavaLeft">
                        <Box className="mostOfferTag">
                          {item?.isMostOfferedTag && (
                            <span className="offerItem">
                              {t(LOCALIZATION.Most_Booked)}
                            </span>
                          )}
                        </Box>
                        <Typography sx={{ fontWeight: 500 }}>
                          <span className="itemNameInfo">{item?.name}</span>
                        </Typography>

                        {item?.disabled === true && (
                          <Typography sx={{ color: "red" }}>
                            {getAvilableChadhavaDate()}
                          </Typography>
                        )}

                        <ParagraphTypography
                          title={
                            isExpandedTempleDetails.includes(item.id)
                              ? item?.itemDescription || ""
                              : `${
                                  item?.itemDescription
                                    ?.split(" ")
                                    .slice(0, wordLimit)
                                    .join(" ") || ""
                                }`
                          }
                        />
                        {item?.itemDescription.split(" ")?.length >
                          wordLimit && (
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => toggleReadMore(item.id)}
                            className="readMoreLessBtn"
                          >
                            {isExpandedTempleDetails.includes(item.id)
                              ? t(LOCALIZATION.Read_Less)
                              : t(LOCALIZATION.Read_More)}
                          </Button>
                        )}
                        <TitleTypography
                          title={` ₹ ${item?.priceInfo?.finalPrice}`}
                        />

                        {/* <CalculateOverSeasPrice  inrPrice={item?.priceInfo?.finalPrice} priceFor="chadhava"/> */}
                      </Box>
                      <Box className="chadhavaRight">
                        <Box className="itemImg">
                          <img src={item?.image}></img>
                        </Box>
                        {item?.count === 0 ? (
                          <Box className="btnAdd">
                            <Button
                              className="chadhavaAdd"
                              variant="outlined"
                              onClick={(e) => {
                                e.preventDefault();
                                addChadhavaItem(item);
                              }}
                            >
                              {t(LOCALIZATION.ADD)}
                            </Button>
                          </Box>
                        ) : (
                          <Box className="addItemsBtns">
                            <ButtonGroup className="btnsGroup">
                              <Button
                                variant="contained"
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrement(item);
                                }}
                              >
                                <RemoveIcon fontSize="small" />
                              </Button>
                              <Button variant="contained">{item?.count}</Button>
                              <Button
                                variant="contained"
                                onClick={(e) => increment(item)}
                              >
                                <AddIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              {isLoading === true && (
                <Box className="loadingCircle">
                  <CircularProgress color="primary" />
                </Box>
              )}
              {/* <Box className="pujaBenefits">
                <SubtitleTypographyPrimary title="Benefits of Prasad"></SubtitleTypographyPrimary>
                {prasadBenefits.map((item: any, id: any) => {
                  return (
                    <Box className="benefitsCard">
                      <SubtitleTypography
                        title={`${item.name}`}
                      ></SubtitleTypography>
                      <Box className="benefitCardImg">
                        <img src={`${item.url}`} alt=""></img>
                      </Box>
                      {item.description && (
                        <ParagraphTypography
                          title={`${item.description}`}
                        ></ParagraphTypography>
                      )}
                    </Box>
                  );
                })}
              </Box> */}
              {templeDescriptions && templeDescriptions?.length > 0 && (
                <Box className="overViewText1">
                  <SubtitleTypographyPrimary
                    title={t(LOCALIZATION.Overview)}
                  ></SubtitleTypographyPrimary>
                  <ParagraphTypography
                    title={`${templeDescriptions}`}
                  ></ParagraphTypography>
                </Box>
              )}

              <Box className="prasadFaq">
                <SubtitleTypographyPrimary
                  title={t(LOCALIZATION.Frequently_asked_questions)}
                ></SubtitleTypographyPrimary>
                <Box className="chadhavaFaqList">
                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel1"}
                    onChange={handleChange1("panel1")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel1" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel1"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.What_is_the_Prasad_Delivery_Service)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.What_is_the_Prasad_Delivery_ServiceAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel2"}
                    onChange={handleChange1("panel2")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel2" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel2"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel2-content"
                      id="panel2-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.Which_temples_are_available_for_Prasad_Delivery
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.Which_temples_are_available_for_Prasad_DeliveryAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel3"}
                    onChange={handleChange1("panel3")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel3" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel3"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel3-content"
                      id="panel3-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(
                        LOCALIZATION.What_items_are_included_the_Prasad_package
                      )}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(
                        LOCALIZATION.What_items_are_included_the_Prasad_packageAnswer
                      )}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel4"}
                    onChange={handleChange1("panel4")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel4" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel4"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel4-content"
                      id="panel4-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.When_will_I_receive_my_Prasad)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.When_will_I_receive_my_PrasadAnswer)}
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    className="accordionInfo"
                    expanded={expanded === "panel5"}
                    onChange={handleChange1("panel5")}
                    sx={{
                      "&.Mui-expanded::before": {
                        opacity: 1,
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color: expanded === "panel5" ? "#d56939" : "#000",
                            transform:
                              expanded === "panel5"
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            transition: "transform 0.3s ease",
                          }}
                        />
                      }
                      aria-controls="panel5-content"
                      id="panel5-header"
                      sx={{
                        p: 0,
                        fontWeight: "600",
                      }}
                    >
                      {t(LOCALIZATION.Is_the_Prasad_authentic)}
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      {t(LOCALIZATION.Is_the_Prasad_authenticAnswer)}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
              {totalPrice > 0 && isActiveTemple === true && (
                <Box
                  className="chadhavaContinueBtn desktopOnly sticky-button"
                  sx={{ position: `${isAboveFooter ? "absolute" : "fixed"}` }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      {" "}
                      {`₹ ${
                        totalPriceDisplay ? totalPriceDisplay : totalPrice
                      }`}
                      /-
                    </Typography>
                    {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                    <Button
                      className={
                        totalPrice === 0
                          ? "desktopContinueBtnGrey"
                          : "desktopContinueBtn"
                      }
                      onClick={() => handleSetIsDateTimePicker(true)}
                      disabled={totalPrice === 0}
                    >
                      {" "}
                      {t(LOCALIZATION.BOOK_PRASAD)}
                    </Button>
                  </Stack>
                </Box>
              )}
              <Box className="blessContinueChadhava"></Box>
              {totalPrice > 0 && isActiveTemple === true && (
                <Box className="blessContinue">
                  <Box className="viewBless">
                    <img src={viewBless}></img>
                  </Box>
                  <Box className="continueCart">
                    <Box className="continueCenterBlock">
                      <Box className="continueLt">
                        {` ₹ ${
                          totalPriceDisplay ? totalPriceDisplay : totalPrice
                        }`}
                        /-
                        {/* <CalculateOverSeasPrice  inrPrice={Number(props?.totalPrice)} priceFor="chadhava-continue"/> */}
                      </Box>

                      <Box className="continueRt">
                        <Button
                          type="button"
                          disabled={totalPrice === 0}
                          className={
                            totalPrice === 0 ? "continueBtnDsbl" : "continueBtn"
                          }
                          onClick={() => {
                            if (isSingleDatePuja) {
                              //bookedSingleDatePuja()
                            } else {
                              handleSetIsDateTimePicker(true);
                            }
                          }}
                        >
                          {t(LOCALIZATION.BOOK_PRASAD)}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
        {/* <Dialog
          className="dateTimingSelection"
          onClose={() => setIsDateTimePicker(false)}
          open={isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <TitleTypography title="Select Date">Select Date</TitleTypography>
              <IconButton
                aria-label="close"
                onClick={() => setIsDateTimePicker(false)}
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 6,
                  fontSize: "12px",
                  color: "#d56939",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack> */}

        {/* <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={shouldDisableDate}
                  disablePast={true}
                  onChange={(value: any) => {
                    setPujaDateAndTime({
                      ...pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box> */}
        {/* <Box className="btnContinue">
              <RoundedButton
                title="Continue"
                // disabled={
                //   pujaDateAndTime && pujaDateAndTime?.pujaDate ? false : true
                // }
                onClick={() => {
                  setIsDateTimePicker(false);
                  if (cartInfo && cartInfo.length > 0) {
                    setPopupItemAlreadyAddedInCart(true);
                  } else {
                    preprocessingAndProceedToAddmemberScreen();
                  }
                  //
                }}
              ></RoundedButton>
            </Box> */}
        {/* </Box>
        </Dialog> */}

        <Dialog
          // className="dateTimingSelection"
          onClose={() => setPopupItemAlreadyAddedInCart(false)}
          open={popupItemAlreadyAddedInCart}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              {t(LOCALIZATION.A_puja_already_added_in_your_cart)}
            </Typography>
            <Box sx={{ textAlign: "end", mt: 1 }}>
              <Button onClick={() => setPopupItemAlreadyAddedInCart(false)}>
                {t(LOCALIZATION.No)}
              </Button>
              <Button
                onClick={() => preprocessingAndProceedToAddmemberScreen()}
              >
                {t(LOCALIZATION.Yes)}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <AlertDialog
        open={openAlertDialogForBookingNotAllowed}
        onClose={handleCloseAlertDialogForBookingNotAllowed}
        message={t(LOCALIZATION.Bookings_are_not_permitted_in_your_country)}
      />
    </Box>
  );
}

export default PrasadDetailsView;
