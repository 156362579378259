import "./PujaView.scss";
import { Box, Grid, Typography, Stack } from "@mui/material";

import vertorArtLeft from "../../assets/vector-art-left.png";
import vertorArtRight from "../../assets/vector-art-right.png";
import omLiner from "../../assets/om-liner.png";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import ImageViewerWithBlur from "../../components/imageviewer/ImageViewerWithBlur";

function PromotionalPujaView(props: any) {
  const [urlToShare, setUrlToShare] = useState<string>("");
  const [
    slectedPromotionalPujaOrganisations,
    setSlectedPromotionalPujaOrganisations,
  ] = useState<any>([]);
  useEffect(() => {
    // const params = new URLSearchParams(location.search);
    // const promotionalPujaId = params.get('pujaNameKey')
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?specialPuja=${props?.promotionalPujaDetails?.pujaNameKey}`
    );
  }, [props.promotionalPujaDetails]);

  return (
    <Box className="pageContainer promotionalPujaPage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Box className="promoPujaCenterBlock">
              <Box>
                <img
                  className="promoPujaBanner"
                  src={props.promotionalPujaDetails?.pujaListBg?.uri}
                  alt={"Image"}
                />
                {/* <ImageViewerWithBlur
                  id={"id32323"}
                  url={props.promotionalPujaDetails?.pujaListBg?.uri}
                /> */}
              </Box>
              <Box className="shareIcon">
                <ShareLink urlToShare={urlToShare}></ShareLink>
              </Box>

              {/* <Box className="pujaBookingCloseSec">
                <Typography className="bookingCloseTitle">Puja Booking Closes In</Typography>
                <Stack 
                  className="bookingCloseTimer" 
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Typography>99 Days</Typography>
                  <Typography>23 Hours</Typography>
                  <Typography>54 Mins</Typography>
                  <Typography>54 Secs</Typography>
                </Stack>
              </Box> */}

              {/* {props.slectedPromotionalPujaOrganisations &&
              props.slectedPromotionalPujaOrganisations.length > 0
                ? props.slectedPromotionalPujaOrganisations.map(
                    (item: any, index: number) => {
                      return (
                        <Box key={item.id || `pujaBooking-${index}`}>
                          <Box className="pujaBookingTitle">
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                            
                              <Typography>
                                {item?.personalInfo?.name}
                              </Typography>
                              <Box className="textBottomLiner">
                                <img src={omLiner} alt="" />
                              </Box>
                            </Stack>
                          </Box>

                          <Box className="templeCenterBlock">
                            {item.templePuja && item.templePuja.length > 0
                              ? item.templePuja.map(
                                  (itemPuja: any, idPuja: any) => {
                                    // return null;

                                    // let templeProfilePic = getOneImageForTemple(
                                    //   item.personalInfo?.media?.pictures
                                    // );
                                    let templeProfilePic = "";
                                    return (
                                      <PujaListCard
                                        key={idPuja}
                                        pujaData={itemPuja}
                                        pujaName={itemPuja?.pujaName}
                                        templeProfilePic={
                                          itemPuja.pujaBg?.uri ||
                                          templeProfilePic
                                        }
                                        description={itemPuja.description}
                                        benefits={itemPuja.benefits}
                                        acctualPrice={itemPuja.acctualPrice}
                                        onClickAddPuja={() => {
                                          props.onClickParticipate(
                                            item.nameKey,
                                            itemPuja.pujaNameKey
                                          );
                                        }}
                                        temple={item.personalInfo}
                                        devoteeCount={itemPuja.maxMemberAllowed}
                                        benefitList={itemPuja?.benefitList}
                                        isPujaDisable={itemPuja?.isPujaDisable}
                                        isPromotionalPuja={true}
                                      />
                                    );
                                  }
                                )
                              : null}
                          </Box>
                        </Box>
                      );
                    }
                  )
                : null} */}

              {props.slectedPromotionalPujaOrganisations &&
              props.slectedPromotionalPujaOrganisations.length > 0
                ? props.slectedPromotionalPujaOrganisations.map(
                    (item: any, index: number) => {
                      if (!item.templePuja || item.templePuja.length === 0) {
                        return <Box key={`empty-${index}`} />;
                      }

                      return (
                        <Box key={item.id || `pujaBooking-${index}`}>
                          <Box className="pujaBookingTitle">
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography>
                                {item?.personalInfo?.name}
                              </Typography>
                              <Box className="textBottomLiner">
                                <img src={omLiner} alt="" />
                              </Box>
                            </Stack>
                          </Box>

                          <Box className="templeCenterBlock">
                            {item.templePuja.map(
                              (itemPuja: any, idPuja: number) => {
                                let templeProfilePic = ""; // Adjust this if needed
                                return (
                                  <PujaListCard
                                    key={idPuja}
                                    pujaData={itemPuja}
                                    pujaName={itemPuja?.pujaName}
                                    templeProfilePic={
                                      itemPuja.pujaBg?.uri || templeProfilePic
                                    }
                                    description={itemPuja.description}
                                    benefits={itemPuja.benefits}
                                    acctualPrice={itemPuja.acctualPrice}
                                    onClickAddPuja={() => {
                                      props.onClickParticipate(
                                        item.nameKey,
                                        itemPuja.pujaNameKey
                                      );
                                    }}
                                    temple={item.personalInfo}
                                    devoteeCount={itemPuja.maxMemberAllowed}
                                    benefitList={itemPuja?.benefitList}
                                    isPujaDisable={itemPuja?.isPujaDisable}
                                    isPromotionalPuja={true}
                                  />
                                );
                              }
                            )}
                          </Box>
                        </Box>
                      );
                    }
                  )
                : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PromotionalPujaView;
