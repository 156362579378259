import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import PostsViews from "../../Views/Blogs/PostsViews";
import { useDispatch, useSelector } from "react-redux";
import { getAllPublishedBlog } from "../../store/actions/BlogAction";
function PostsScreen(props: any) {
  const dispatch: any = useDispatch();
  const { isLoading, error, allPublishedPosts } = useSelector(
    (state: any) => state.blogs
  );
  const [publishedPost, setPublishedPost] = useState<any>([]);
  const [parentViewCheck, setParentViewCheck] = useState(true);
  const location = useLocation();
  useEffect(() => {

    const pathData = location.pathname;

    if (pathData.includes("nativeViews")) {
      props.setIsMobileView(true);
      setParentViewCheck(false);
    }
  }, [location]);
  useEffect(() => {
    dispatch(getAllPublishedBlog());
  }, [dispatch]);

  const groupByTypes = (list: any) => {
    return list.reduce((acc: any, item: any) => {
      item.types.forEach((type: any) => {
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(item);
      });
      return acc;
    }, {});
  };

  useEffect(() => {
    const groupList = groupByTypes(allPublishedPosts);
    if (groupList) {
      setPublishedPost(groupList);
    }
  }, [allPublishedPosts]);

  return (
    <>
      {parentViewCheck ? (
        <ParentView>
          <PostsViews
            parentViewCheck={parentViewCheck}
            allPublishedPosts={publishedPost}
          />
        </ParentView>
      ) : (
        <PostsViews allPublishedPosts={publishedPost} parentViewCheck={parentViewCheck} />
      )}
    </>
  );
}

export default PostsScreen;
