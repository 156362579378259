import { useEffect, useState } from "react";
import { BlogService } from "td_server";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ManageBlogView from "../../Views/Admin/ManageBlogView/ManageBlogView";
import BlogPostList from "../../Views/Admin/ManageBlogView/BlogPostList";
import {
  createBlog,
  deleteBlogPost,
  getAllBlog,
  getAllPostTypes,
  updateBlog,
} from "../../store/actions/BlogAction";
import { APPROLES, postTypes } from "../../utils/Constants";
import { CheckIcon } from "@chakra-ui/icons";
function ManageBlogScreen() {
  const dispatch: any = useDispatch();

  const { isLoading, AllPosts, error, allPostTypes } = useSelector(
    (state: any) => state.blogs
  );

  const [singlePostData, setSinglePostData] = useState<any>(null);
  const [blogList, setBlogList] = useState<any>([]);
  const { userInfo } = useSelector((state: any) => state.user);
  const [editMode, setEditMode] = useState<string>("list");
  const [openDeleteConformetionModel, SetOpenDeleteConformetionModel] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openSuccessToast, setOpenSuccessToast] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllBlog());
  }, []);

  useEffect(() => {
    setBlogList(AllPosts);
    dispatch(getAllPostTypes());
  }, [AllPosts]);

  const allOfPostTypes = Array.from(
    new Set([...Object.values(postTypes).map((item) => item), ...allPostTypes])
  );
  const handleSubmit = async (item: any) => {
    // return ;
    let imageUrl = "";

    let data: any = Object.assign({}, item);
    delete data.id;
    delete data.imageFile;
    if (item?.imageFile) {
      imageUrl = await BlogService.uploadCoverImageForBlog({
        uri: item?.imageFile,
        platform: "web",
      });

      data.coverImageUrl = imageUrl;
    }
    if (editMode !== "edit") {
      data.author =
        userInfo?.personalInfo?.firstName +
        " " +
        userInfo?.personalInfo?.lastName;
      data.userId = userInfo?.id;
      data.userType = userInfo?.userType;
    }
    if (
      editMode === "edit" &&
      userInfo?.userType === APPROLES.BLOGADMIN.roleId
    ) {
      data.isPublished = false;
    }
    if (item?.id && item?.id?.trim().length > 0) {
      dispatch(updateBlog(item.id, data)).then(() => {
        dispatch(getAllBlog());
      });
      setOpenSuccessToast(true);
    } else {
      dispatch(createBlog(data)).then((res: any) => {
        dispatch(getAllBlog());
      });
      setOpenSuccessToast(true);
    }

    setEditMode("list");
  };
  const handleEditModeChange = (editMode: string) => {
    setEditMode(editMode);
  };
  const handleAdd = () => {
    setSinglePostData(null);
    setEditMode("add");
  };
  const handleEdit = (row: any) => {
    setEditMode("edit");
    setSinglePostData(row);
  };
  const handleBack = () => {
    setEditMode("list");
  };

  const handleDeleteBlogPost = () => {
    SetOpenDeleteConformetionModel(false);
    dispatch(deleteBlogPost(selectedRow.id)).then(() => {
      dispatch(getAllBlog());
    });
  };

  const handleDeleteOpen = (row: any) => {
    setSelectedRow(row);
    SetOpenDeleteConformetionModel(true);
  };

  const handleCloseDeleteConformetion = () => {
    SetOpenDeleteConformetionModel(false);
    setSelectedRow(null);
  };

  const handleCloseSucessToast = () => {
    setOpenSuccessToast(false);
  };

  return (
    <Box>
      {editMode !== "list" && (
        <ManageBlogView
          postList={singlePostData}
          onSubmit={handleSubmit}
          handleBack={handleBack}
          editMode={editMode}
          postTypes={allOfPostTypes}
        />
      )}
      {editMode === "list" && (
        <BlogPostList
          postList={blogList}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleDeleteOpen}
          postTypes={allOfPostTypes}
          userInfo={userInfo}
        />
      )}
      <Dialog
        open={openDeleteConformetionModel}
        onClose={handleCloseDeleteConformetion}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConformetion} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteBlogPost} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSuccessToast}
        autoHideDuration={1000}
        onClose={handleCloseSucessToast}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          icon={<CheckIcon fontSize="inherit" />}
          severity="success"
          sx={{ width: "100%" }}
        >
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default ManageBlogScreen;
