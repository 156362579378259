import { setDarshanList, setLoading } from "../slices/LiveDarshanSlice";
import {
    UserService,
    TempleService,
    PanchangService,
    CommonService,
    OrderService,
  } from "td_server";

export const addNewLiveItem =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.addDarshanItem(formData,'web');
      const responseDarshanList: any = await TempleService.getAllDarshanList();
      console.log('darshan response = ', response);
      dispatch(setDarshanList(responseDarshanList));
      dispatch(setLoading(false));
      console.log('addNewLiveItem response', JSON.stringify(response));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple ADD ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

  export const updateLiveItem =
  (formData: any, id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
 
      const response: any = await TempleService.updateDarshanItem(formData, id,'web');
      const responseDarshanList: any = await TempleService.getAllDarshanList();
    //   console.log('darshan response = ', response);
      dispatch(setDarshanList(responseDarshanList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

  export const deleteLiveItem =
  (formData: any, id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.deleteDarshanItem(formData, id);
      const responseDarshanList: any = await TempleService.getAllDarshanList();
    //   console.log('darshan response = ', response);
      dispatch(setDarshanList(responseDarshanList));
      dispatch(setLoading(false));
      //console.log('updateLiveItem response', JSON.stringify(response));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Temple ADD ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };
  export const getAllDarshanList =
    () => async (dispatch: any, getState: any) => {
      try {
        dispatch(setLoading(true));
        const responseDarshanList: any = await TempleService.getAllDarshanList();
         dispatch(setDarshanList(responseDarshanList));
         dispatch(setLoading(false));
        return Promise.resolve(responseDarshanList);
      } catch (e) {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    };