/* eslint-disable @typescript-eslint/no-unused-vars */

import { HoroscopeService } from "td_server";
import { setLoading, setHoroscope } from "../slices/HoroscopeSlice";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};
export const getHoroscopeByDate =
  (date: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setHoroscope([]));
      const response: any = await HoroscopeService.getHoroscopeByDate(date);
      if (response && response.length > 0) {
        dispatch(setHoroscope(response));
      }
      //  dispatch(setHoroscope(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updateHoroScopeAction =
  (formData: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const status = await HoroscopeService.updateHoroscopeData(formData);
      dispatch(setLoading(false));
      // dispatch(getViewedHoroScopeList());
      return Promise.resolve("");
    } catch (error: any) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };




