import React, { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatTimestamp } from "../../../utils/HelperFunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import { APPROLES, postTypes } from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";

interface PostListProps {
  postList: any[];
  handleEdit: (row: any) => void;
  handleAdd: () => void;
  handleDelete: (row: any) => void;
  postTypes: any;
  userInfo?: any;
}

const BlogPostList: React.FC<PostListProps> = ({
  postList,
  handleEdit,
  handleAdd,
  handleDelete,
  postTypes,
  userInfo,
}) => {
  const [filter, setFilter] = useState({
    filterPostType: "All",
    filterPostPublished: "All",
  });

  const navigate = useNavigate();

  const getIsDeleteAccess = (rowData: any) => {
    if (
      (rowData?.userId && rowData.userId === userInfo?.id) ||
      userInfo?.userType === APPROLES.SUPERADMIN.roleId ||
      userInfo?.userType === APPROLES.ADMIN.roleId
    ) {
      return true;
    } else {
      return false;
    }
  };
  const filterPosts =
    filter.filterPostType !== "All" && filter.filterPostPublished !== "All"
      ? postList.filter((item) => {
          const isPublished = filter.filterPostPublished === "Published";
          return (
            item.types.includes(filter.filterPostType) &&
            isPublished === item.isPublished
          );
        })
      : filter.filterPostPublished !== "All" && filter.filterPostType === "All"
      ? postList.filter((item) => {
          const isPublished = filter.filterPostPublished === "Published";
          return isPublished === item.isPublished;
        })
      : filter.filterPostPublished === "All" && filter.filterPostType !== "All"
      ? postList.filter((item) => {
          return item.types.includes(filter.filterPostType);
        })
      : postList;

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", width: 200 },
    { field: "types", headerName: "Post Type", flex: 1 },
    {
      field: "isPublished",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            className={params.value ? "statusPublished" : "statusUnpublished"}
          >
            {params.value ? "Published" : "Unpublished"}
          </span>
        );
      },
    },
    { field: "shortDescription", headerName: "Short Description", flex: 1 },
    {
      field: "createdAt",
      headerName: "Created Date",
      flex: 1,
      renderCell: (params) => {
        const date = params?.value?.seconds;
        const formattedDate = formatTimestamp(date, "MMMM DD, YYYY HH:mm a");
        return <span>{formattedDate}</span>;
      },
    },

    {
      field: "updatedAt",
      headerName: "Modified Date",
      flex: 1,
      renderCell: (params) => {
        const date = params?.value?.seconds;
        const formattedDate = formatTimestamp(date, "MMMM DD, YYYY HH:mm a");
        return <span>{formattedDate}</span>;
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleEdit(params.row as any)}>
            <EditIcon sx={{ color: "#dea400" }} />
          </IconButton>
          {getIsDeleteAccess(params?.row) && (
            <IconButton
              onClick={() => handleDelete(params?.row as any)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          )}

          <IconButton
            onClick={() => {
              const hostname =
                window.location.hostname === "localhost"
                  ? "http://localhost:3000"
                  : "https://" + window.location.hostname;
              window.open(
                `${hostname}/pagelink?blogId=${params?.row?.id}`,
                "_blank"
              );
            }}
            color="primary"
          >
            <VisibilityOutlinedIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFilterValueChange = (e: any) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Box className="pageContainer" mt={10}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Box sx={{ width: 300 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Post Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="filterPostType"
              label="Select Post Type"
              value={filter.filterPostType}
              onChange={handleFilterValueChange}
            >
              <MenuItem value="All">All</MenuItem>
              {postTypes.map((type: any) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ width: 300 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Post Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter.filterPostPublished}
              name="filterPostPublished"
              label="Select Post Type"
              onChange={handleFilterValueChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Published">Published</MenuItem>
              <MenuItem value="UnPublished">UnPublished</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Stack>

      <Box className="couponCodeView">
        <Paper elevation={3}>
          <Box mb={2} className="btnAddCoupon">
            <Button onClick={handleAdd} variant="contained">
              <AddCircleOutlineIcon
                sx={{ fontSize: "20px", marginRight: "5px" }}
              />{" "}
              Add Post{" "}
            </Button>
          </Box>
          {filterPosts && filterPosts.length > 0 && (
            <Box className="couponDataList">
              <DataGrid
                className="couponDataGrid"
                rows={filterPosts}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                      page: 0,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                autoHeight
              />
            </Box>
          )}
          {filterPosts && filterPosts.length == 0 && (
            <Typography sx={{ padding: "1rem" }}>Empty List</Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default BlogPostList;
