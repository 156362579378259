import { Box, Typography, Badge, Radio } from "@mui/material";

import { useEffect, useState } from "react";
import { getPriceInfoOfPujaAndPackage } from "../../../store/actions/templeAction";
import { useDispatch, useSelector } from "react-redux";
import { calculateOverSeasPrice } from "../../../utils/HelperFunctions";
import { LOCALIZATION } from "../../../utils/localization";
import { useTranslation } from "react-i18next";

//
// ,overSeasRate
// currencySymbols,
// isOverseas,
// isCountryDisabledForBooking
export default function PujaPackageCard(props: any) {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const [pujaPriceInfo, setPujaPriceInfo]: any = useState(null);
  var { currentGPSAddress } = useSelector((state: any) => state.user);
  useEffect(() => {
    if (
      props.packageDetails &&
      props.selectedTempleDetails &&
      props.pujaDetails
    ) {
      getPriceInfo(
        props.selectedTempleDetails.id,
        props.pujaDetails.pujaId,
        props.packageDetails.packageId
      );
    }
  }, [
    props.packageDetails,
    props.selectedTempleDetails,
    props.pujaDetails,
    currentGPSAddress,
  ]);

  const getPriceInfo = async (templeId: any, pujaId: any, packageId: any) => {
    let payload = {
      templeId: templeId,
      pujaId: pujaId,
      packageId: packageId,
      isOverseas: props.isOverseas,
      // isPrasad: !props.isOverseas,
      isPrasad: false,
    };
    try {
      dispatch(getPriceInfoOfPujaAndPackage(payload))
        .then((response: any) => {
          setPujaPriceInfo(response);
        })
        .catch((error: any) => {
          console.log("Error:", error);
        });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Box
      className={`packageCard ${props.activeFlg}`}
      onClick={() => props.setPackageSelected(props.packageDetails)}
    >
      <img
        src={props.getDevoteeImage(props.packageDetails?.maxMemberAllowed)}
        alt=""
      />
      <Typography component="p">
        {props.packageDetails?.maxMemberAllowed}{" "}
        {props.packageDetails?.maxMemberAllowed > 1
          ? t(LOCALIZATION.Devotees)
          : t(LOCALIZATION.Devotee)}
      </Typography>
      <Typography variant="h6">
        {props.currencySymbols}
        {calculateOverSeasPrice(pujaPriceInfo?.finalPrice, props.overSeasRate)}
      </Typography>
      <Radio
        checked={props.activeFlg === "active"}
        onChange={props?.handleChange}
        value="option1"
      />
      {props.packageDetails?.isMostPopular ? (
        <Badge className="nwlyAddedbadge">{t(LOCALIZATION.Most_Popular)}</Badge>
      ) : null}
    </Box>
  );
}
