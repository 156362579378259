const POP_UP_MESSAGE = {
  replacePujaOrChadhavaMessage:
    "An item has already been added to your cart. Would you like to replace it ?",
  bookingErrorMessagePujaChadhava: "Oops! Bookings are not permitted in your country. We apologize for the inconvenience. Please note that bookings are accepted only in India, Great Britain, USA, and Canada.",
  bookingErrorMessagePrasad: "Oops! Prasad Bookings are not permitted in your country. We apologize for the inconvenience. Please note that bookings are accepted only in India."
};

const CalculationChart = {
  description:
    "All calculations and charts are derived from the following input:",
  editText: "Edit Details",
  dateOfBirth: "Date of Birth",
  gender: "Gender",
  timeOfBirth: "Time of Birth",
  cityOfBirth: "City of Birth",
  countryOfBirth: "Country of Birth",
};

const labels = {
  EN: {
    fullName: "Full Name*",
    fullNamePlaceholder: "Enter here",
    dateOfBirth: "Date of Birth*",
    timeOfBirth: "Time of Birth (HH:MM)*",
    birthLocation: "Birth Location*",
    cityPlaceholder: "Start typing city...",
    generate: "Generate",
    title: "Enter Birth Details",
    KundaliMatchingTitle: "Enter Birth Details (Guna Milan)",
    fullNameError: "Full Name is required",
    dateOfBirthError: "Date of Birth is required",
    timeOfBirthError: "Time of Birth is required",
    birthLocationError: "Birth Location is required",
    dateOfBirthIncompleteError: "Please provide a valid date (day, month, year).",
    timeOfBirthIncompleteError: "Please provide a valid time (hour and minutes).",
    matchKundli: "Match Kundli",
    GirlsBirthDetails: "Girl’s Birth Details",
    girlFullName: "Girl's Full Name*",
    girlFullNamePlaceholder: "Enter girl's name",
    BoysBirthDetails: "Boy’s Birth Details",
    boyFullName: "Boy's Full Name*",
    boyFullNamePlaceholder: "Enter boy's name",
    gender: "Gender",
    male: "Male",
    female: "Female",
    others: "Others",
    selectGender: "Select here",
    preferNotToSay: "Prefer not to say",
    horoscope: "GENERATE ASTROLOGY",
    numerology: "GENERATE NUMEROLOGY",
    VastuTitle: "Upload a professionally crafted floor plan of your house or property to instantly receive a detailed Vastu report.",
    VastuShastra: "GENERATE VASTU",
    VastuVDragNdropLabel: "Choose file to upload",
    VastuSupportedFormatTitle: "Supported formats",
    VastuUploadImgSize : "Maximum size",
    VastuImgRefer: "House Blueprint",
    VastuImgSample: "Sample",
    VastuUploadTitle: "Upload House/Property Blueprint",
    UploadFileSizeError:"The file size exceeds 20 MB",
    UploadFileFormateError:"Unsupported file format. Supported formats are: PNG, JPG, JPEG, PDF",
    VastuReportSummaryTitle: "Vastu Report Summary",
    AstrologyMoonSignText: "Rashi(Vedic) according to Moon Sign",
    AstrologySunSignText: "Rashi according to Sun Sign",
  },
  HI: {
    fullName: "पूरा नाम*",
    fullNamePlaceholder: "यहां दर्ज करें",
    dateOfBirth: "जन्म तिथि*",
    timeOfBirth: "जन्म का समय (HH:MM)*",
    birthLocation: "जन्म स्थान*",
    cityPlaceholder: "शहर लिखना प्रारंभ करें...",
    generate: "उत्पन्न करें",
    title: "अपनी जानकारी दर्ज करें",
    KundaliMatchingTitle: "जन्म विवरण दर्ज करें (गुण मिलान)",
    fullNameError: "पूरा नाम आवश्यक है",
    dateOfBirthError: "जन्मतिथि आवश्यक है",
    timeOfBirthError: "जन्म का समय आवश्यक है",
    birthLocationError: "जन्म स्थान आवश्यक है",
    matchKundli: "कुंडली मिलाएं",
    GirlsBirthDetails: "लड़की का जन्म विवरण",
    girlFullName: "लड़की का पूरा नाम*",
    girlFullNamePlaceholder: "लड़की का नाम दर्ज करें",
    BoysBirthDetails: "लड़के का जन्म विवरण",
    boyFullName: "लड़के का पूरा नाम*",
    boyFullNamePlaceholder: "लड़के का नाम दर्ज करें",
    gender: "लिंग",
    male: "पुरुष",
    female: "महिला",
    others: "अन्य",
    selectGender: "यहाँ चुनें",
    preferNotToSay: "नहीं कहना पसंद करते हैं",
    horoscope: "राशिफल जाने",
    numerology: "अंकज्योतिष जाने",
    dateOfBirthIncompleteError: "कृपया एक वैध तिथि (दिन, महीना, वर्ष) प्रदान करें।",
    timeOfBirthIncompleteError: "कृपया एक वैध समय (घंटा और मिनट) प्रदान करें।",
    VastuTitle: "अपनी प्रॉपर्टी या घर का प्रोफेशनल रूप से तैयार किया गया फ्लोर प्लान अपलोड करें और तुरंत एक डिटेल्ड वास्तु रिपोर्ट पाएं।",
    VastuShastra: "वास्तु जाने",
    VastuVDragNdropLabel: "अपलोड करने के लिए फाइल चुनें",
    VastuSupportedFormatTitle: "सपोर्टेड फॉर्मेट्स",
    VastuUploadImgSize : "अधिकतम साइज़",
    VastuImgRefer: "घर/प्रॉपर्टी का ब्लूप्रिंट",
    VastuImgSample: "सैंपल",
    VastuUploadTitle: "घर/प्रॉपर्टी का ब्लूप्रिंट अपलोड करें",
    UploadFileSizeError:"फाइल का साइज 20 MB से ज्यादा है।",
    UploadFileFormateError:"फाइल फॉर्मेट स्वीकार्य नहीं है। स्वीकार्य फॉर्मेट हैं: PNG, JPG, JPEG, PDF।",
    VastuReportSummaryTitle: "वास्तु रिपोर्ट सारांश",
    AstrologyMoonSignText: "चंद्र राशि के आधार पर वैदिक राशि",
    AstrologySunSignText: "सूर्य राशि के आधार पर राशि",
  },
};
 const suggestionLabels: { [key: string]: string } = {
  EN: "Click on a Service below to start chatting with Guruji:",
  HI: "गुरुजी से बातचीत शुरू करने के लिए नीचे दी गई सेवा पर क्लिक करें:",
  loading: "Loading..."
};


const TableName ={
  girlNameTitle:"Girl’s Name",
  boyNameTitle: "Boy’s Name"
};

const GurujiHomeIcon = {
  CHAT_WITH_VALMIKI: "Chat With Valmiki",
  liveCount:"2K ONLINE"
};

const ADDRESS_PAGE_LABELS = {
  addNewAdressButton: "Add New address",
  streetLabel: "Street",
  cityLabel: "City",
  areaLabel: "Area",
  stateProvinceAreaLabel: "State/province/area",
  phoneNumberLabel: "Phone Number",
  zipCodeLabel: "Zip code",
  locationButtonLabel: "Use My current location",
  nameLabel: "Name",
  streetHouseAptLabel: "Street/House/Apt No",
  cityDistrictTownLabel: "City/District/Town",
  stateLabel: "State",
  pinCodeLabel: "Zipcode/Pincode",
  alternatePhoneNumberLabel: "Alternate Phonenumber",
  formattedAddressLabel: "Formatted Address",
  saveButtonLabel: "Save",
  cancelButtonLabel: "Cancel",
  editAddresLabel: "Edit Address",
  addressNameErrorMessage: "Name is required.",
  cityErrorMessage: "City/District/Town is required.",
  stateErrorMessage: "State is required.",
  zipCodeErrorMessage: "Enter a valid Zipcode/Pincode.",
  areaErrorMessage: "Area is required.",
  invalidPhoneNumberMessage: "Invalid phone number format.",
  countyCode: "Country Code"
  
}

const ADD_DEVOTEE_LABEL = {
  pujaNameLabel : "Puja Name",
  templeNameLabel: "Temple Name",
  pujaDateLabel: "Puja Date",
  chadavaDateLabel :"Chadhava Booking Date",
  whatsAppNumberLabel: "Provide Your WhatsApp Number *",
  whatsAppNumberErrorMessage:"Please enter a valid 10-digit WhatsApp number.",
  addDevoteeFromPreviousBookingLabel: "Add devotee from previous bookings",
  devoteeNameError: "Please enter your full name",
  gotramLabel: 'Gotram (Default Kashyap)',
  gotramCheckboxLabel: "Don’t know my Gotram",
  manokamnaLabel: "Manokamna/ Wish (Optional)",
  addDevoteeDialogTitle: "Previous Booking Devotee List",
  addDevoteeDialogContinueButtonLabel:"Select & Continue",
  saveAndContinueButtonLabel:"Save And Continue"
}

const chooseDeliveryAddressTitle = {
  chooseTitle: "Choose a delivery address",
};

const disclaimerText = {
  textMessage: "Prices may vary depending on your Country",
  textMessagePrasad:"Prasad delivery currently limited to locations within India."
};

const disclaimerPrasadText = {
  textMessage: "Prasad delivery is only available in India. Outside India, this service is not offered.",
};

const languageChooseText = {
  textMessage: "Please choose your preferred language to chat with Valmiki / वाल्मिकी के साथ चैट करने के लिए कृपया अपनी पसंदीदा भाषा चुनें।",
};

const templeClickAddIcon = {
  textMessageTempleMobileView: "Please click the plus icon at the bottom right to view the offerings available in this temple.",
};

const adminChadhawaViewLabel= {
 title: "Chadhava Items for Temple",
 weightage : " Add Minimum price/Chadhawa Weightage",
 ChadhavaItem: "Add Chadhava Item",
 ChadhavaBenefits : "Add Chadhava Benefits",
 SelectBenefits  : "Select Benefits",
 deleatemassage : "Are you sure you want to empty your cart?",
 chadhavaWeightageDialogTitle: "Add Chadhawa Weightage",
 weightageLabel: "Enter Weightage",
 indianPriceLabel: "Enter Indian minimum price for chadhava",
 overseasPriceLabel: "Enter overseas minimum price for chadhava",
 weightageErrorMessage: "Please Enter Weightage.",
 indianPriceErrorMessage: "Please Enter indian price.",
 overseasErrorMessage: "Please Enter overseas price.",
 noteIndia: "Note: This is minimum booking price for chadhava from india.",
 noteOverSeas: "Note: This is minimum booking price for chadhava form overseas(USA, Canada, GB).",
};

const adminPrasadViewLabel= {
  Title: "Prasad Items for Temple",
  Weightage : " Add Prasad Weightage",
  PrasadItem: "Add Prasad Item",
  PrasadBenefits : "Add Prasad Benefits",
  SelectBenefits  : "Select Benefits",
  DeleteMessage : "Are you sure you want to empty your cart?",
  PrasadWeightageDialogTitle: "Add Prasad Weightage",
  WeightageLabel: "Enter Weightage",
  WeightageErrorMessage: "Please Enter Weightage.",
 };

const adminSpecielPujaViewLabel= {
  title: "Manage Special Puja for Temple",
 button: "Add Special Puja"
 };
 const addLivedarshan= {
title: "Add/Edit Live Darshan ",
 button: "Add Live Temple"
 };

export {
  CalculationChart,
  POP_UP_MESSAGE,
  labels,
  TableName,
  ADDRESS_PAGE_LABELS,
  ADD_DEVOTEE_LABEL,
  suggestionLabels,
  GurujiHomeIcon,
  chooseDeliveryAddressTitle,
  disclaimerText,
  disclaimerPrasadText,
  languageChooseText,
  templeClickAddIcon,
  adminChadhawaViewLabel,
  adminSpecielPujaViewLabel,
  adminPrasadViewLabel,
  addLivedarshan
};

