import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import namasteIcon from "../../assets/namaste-icon.png";
import namasteIconLiked from '../../assets/post-liked-icon.png';
import "./PostsContentViews.scss";
import { formatTimestamp } from "../../utils/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ShareLinkBox from "../../components/dialog/ShareLinkBox";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";


const PostsContentViews: React.FC<any> = ({ post, parentViewCheck, onLikeClick , likeCount , checkUserLiked,userLikedBlog, backToBlog }) => {
  const { userInfo } = useSelector((state: any) => state.user);
  const dispatch: any = useDispatch();

  
  const [likesCount, setLikesCount] = useState<number>(post?.likes || 0);
  const [isLiked, setIsLiked] = useState<boolean>(false); 

  // function handleLikeClick(): void {
  //   if(userInfo){
  //      dispatch(countLikesOnBlog(post?.id,userInfo.id))
  //   }
  // }
  const handleLikeClick = (): void => {
    onLikeClick()
  };
  const [urlToShare, setUrlToShare] = useState<string>("");
  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(`${hostname}/pagelink?blogId=${post?.id}`);
  }, [post?.id]);

  const handleBackToBlog = () => {
    backToBlog()
  }

  return (
    <Box
      className={`${parentViewCheck ? "pageContainer" : "pageContainerPostContNative"
        } postContentPage`}
    >
      <Box className="postContentBlock">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={1}
            lg={1}
            xl={1}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={10} lg={10} xl={10}>
            <Box className="postContentCenterBlock">
              <Box className="backToBlockBtn" onClick={e => { e.preventDefault(); handleBackToBlog() }}>
                {t(LOCALIZATION.Back_to_Blog)}
              </Box>
              <Box className="postContentInfo">
                <Typography className="postContentTitle">
                  {post?.title}
                </Typography>
                <Typography className="postContentSubTitle">
                  {post?.author} |{" "}
                  {formatTimestamp(post?.updatedAt?.seconds, "MMMM DD,YYYY")} |{" "}
                  {post?.readingTime} {t(LOCALIZATION.mins_read)}
                </Typography>
                <Box className="postContentBanner">
                  <Box component="img" src={post?.coverImageUrl} />
                </Box>
                <Box className="postBlindContent">
                  <Box dangerouslySetInnerHTML={{ __html: post?.description }}></Box>
                </Box>
              </Box>
              {/* --- Post Content Footer --- */}
              <Box className="postContentFooter">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={3}
                >
                  <Box>
                    <Stack
                      className="footerLeftInfo"
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <IconButton onClick={handleLikeClick}>
                        <Box
                          component="img"
                          src={ checkUserLiked ? namasteIconLiked : namasteIcon}
                          alt="Namaste Icon"
                          // style={{
                          //   filter: userLikedBlog ? "none" : "grayscale(100%)", 
                          //   color: userLikedBlog ? "red" : "inherit", 
                          // }}    
                         />
                      </IconButton>
                      <Typography>{likeCount}</Typography>
                    </Stack>
                  </Box>

                  <Box>
                    {/* <Stack
                      className="footerRightInfo"
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={{ xs: 2, md: 3 }}
                    >
                      <Typography>Share</Typography>
                      <Box component="img" src={postContentFacebook} />
                      <Box component="img" src={postContentTwitter} />
                      <Box component="img" src={postContentLinkedin} />
                      <Box component="img" src={postContentInstagram} />
                    </Stack> */}
                    <ShareLinkBox url={urlToShare} />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1} lg={1} xl={1} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PostsContentViews;
