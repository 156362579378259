import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddLiveDarshanView from "../../../Views/Admin/AddLiveDarshan/AddLiveDarshanView";
import {
  addNewLiveItem,
  deleteLiveItem,
  getAllDarshanList,
  updateLiveItem,
} from "../../../store/actions/LiveDarshanAction";
import { CommonService } from "td_server";

function ManageLiveDarshanScreen() {
  const dispatch: any = useDispatch();
  const { allTempleList } = useSelector((state: any) => state?.temple);
  const [templeList, setTempleList] = useState<any>([]);
  const [allPujaList, setAllPujaList] = useState<any>([]);
  const [isAddEditLiveDarshanModelOpen, setIsAddEditLiveDarshanModelOpen] =
    useState(false);
  const [seletedTempleOptions, setSeletedTempleOptions] = useState<any>([]);
  const [seletedPujaOptions, setSeletedPujaOptions] = useState<any>([]);
  const [selectedLiveItem, setSelectedLiveItem] = useState<any>(null);
  const { darshanList } = useSelector((state: any) => state?.liveDarshan);
  const [liveDarshanTempleList, setliveDarshanTempleList] = useState<any>([])


  useEffect(() => {
    try {
      dispatch(getAllDarshanList())
        .then((res: any) => {
         
        })
        .catch((err: any) => {
          console.log("Error:",err);
        });
    } catch (error) {
      console.log("Error:",error);
    }
  }, [dispatch]);



  useEffect(() => {
      setliveDarshanTempleList(darshanList)
  }, [darshanList]);


  // useEffect(() => {
  //   if (allTempleList && allTempleList?.length) {
  //     let formatedList = allTempleList?.map((item: any) => {
  //       return { value: item?.id, label: item?.personalInfo?.name };
  //     });
  //     formatedList = [...formatedList];
  //     setTempleList(formatedList);
  //   }
  // }, [allTempleList]);


  const handleEditLiveDarshanItem = (item: any) => {
    setSelectedLiveItem(item);
    setIsAddEditLiveDarshanModelOpen(true);
  };

  const handleCloseAddEditLiveDarshanTempleForm = () => {
    setIsAddEditLiveDarshanModelOpen(false);
    setSelectedLiveItem(null);
    setSeletedTempleOptions([]);
    setSeletedPujaOptions([]);
  };

  const handleDeleteLiveDarshan = (liveItem: any) => {
    try {
      dispatch(deleteLiveItem(liveItem, liveItem?.id)).then(() => {});
    } catch (error) {
      console.log("Error:",error);
    }
  };

  const handleSearchSpPujalist = (str: any) => {
    if (str?.length > 0) {
      const newStr = str?.toLowerCase();
      const newList = liveDarshanTempleList.filter((item: any) =>
        item?.templeName?.toLowerCase()?.includes(newStr)
      );
      setliveDarshanTempleList(newList);
    } else {
      setliveDarshanTempleList(darshanList);
    }
  };

  const handleSubmitLiveDarsanTemple = async(liveItem:any)=>{
    const newItem = Object.assign({}, liveItem);
    if (newItem.file) {
      const imageUrl = await CommonService.fileUpload(
        {
          uri: newItem.file,
          platform: "web",
        },
        "live_darshan"
      );
      newItem.media = { url: imageUrl, isPrimary: true,platform: "web" };
    }
    if (newItem.id.trim().length > 0) {
       dispatch(updateLiveItem(newItem, newItem.id));
    } else {
       delete newItem.id;
       dispatch(addNewLiveItem(newItem));
    }
  }

  return (
    <AddLiveDarshanView
      isAddEditLiveDarshanModelOpen={isAddEditLiveDarshanModelOpen}
      setIsAddEditLiveDarshanModelOpen={setIsAddEditLiveDarshanModelOpen}
      liveDarshanTempleList={liveDarshanTempleList}
      templeList={templeList}
      allPujaList={allPujaList}
      //handlePujaSelect={handlePujaSelect}
      handleDeleteLiveDarshan={handleDeleteLiveDarshan}
      seletedTempleOptions={seletedTempleOptions}
      seletedPujaOptions={seletedPujaOptions}
      selectedLiveItem={selectedLiveItem}
      setSelectedLiveItem={setSelectedLiveItem}
      setSeletedTempleOptions={setSeletedTempleOptions}
      setSeletedPujaOptions={setSeletedPujaOptions}
      handleSearchSpPujalist={handleSearchSpPujalist}
      handleSubmitLiveDarsanTemple={handleSubmitLiveDarsanTemple}
      handleCloseAddEditLiveDarshanTempleForm={handleCloseAddEditLiveDarshanTempleForm}
      handleEditLiveDarshanItem={handleEditLiveDarshanItem}
    />
  );
}

export default ManageLiveDarshanScreen;
