
import { PrasadService} from "td_server";
import {
  setLoading,
  setPrasadTempleList,
  setPrasadForTemple,
  setTemplePrasadList,
} from "../slices/PrasadSlice";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getPrasadTempleList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.getPrasadTempleList();
      
      if (response && response?.prasadOrganisations?.length > 0) {
        dispatch(setPrasadTempleList(response?.prasadOrganisations));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPrasadForTemple =
  (
    id: string,
    isOverseas: boolean = false,
    platformFeeForChadhava: number = 0
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.getPrasadForTemple(
        id,
        isOverseas,
        platformFeeForChadhava
      );
      if (response && response.length > 0) {
        dispatch(setPrasadForTemple(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const addPrasadDetailsForTemple =
  (id: string, data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.addPrasadDetailsForTemple(
        id,
        data
      );
      if (response && response.length > 0) {
        dispatch(setPrasadForTemple(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updatePrasadDetailsForTemple =
  (orgId: string, id: string, data: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.updatePrasadDetailsForTemple(
        orgId,
        id,
        data
      );
      if (response && response.length > 0) {
        dispatch(setPrasadForTemple(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const deletePrasadItem =
  (orgId: string, id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.deletePrasadItem(orgId, id);
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updatePrasadBenefitsForTemple =
  (orgId: string, data: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));

      const response: any = await PrasadService.updatePrasadBenefitsForTemple(
        orgId,
        data
      );
      if (response && response.length > 0) {
        dispatch(setPrasadForTemple(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const updatePrasadDateForTemple =
  (orgId: string, data: string[]) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));

      //   const response: any = await PrasadService.updatePrasadDateForTemple(orgId,data);
      //   if (response && response.length > 0) {
      //     dispatch(setPrasadForTemple(response));
      //   }
      //   dispatch(setLoading(false));

      //   return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getPrasadForTempleList =
  (
    id: string,
    isOverseas: boolean = false,
    platformFeeForChadhava: number = 0,
    rate = 1
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PrasadService.getPrasadForTemple(
        id,
        isOverseas,
        platformFeeForChadhava,
        rate
      );
     
      
      if (response && response.length > 0) {
        dispatch(setTemplePrasadList(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const resetPrasadList = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setPrasadForTemple([]));
    dispatch(setLoading(false));
    return Promise.resolve([]);
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};
