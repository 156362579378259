import { useEffect, useState } from "react";
import { TempleService } from "td_server";
import { useDispatch, useSelector } from "react-redux";
import ManageSpecialPujaView from "../../../Views/Admin/ManageSpecialPuja/ManageSpecialPujaView";
import {
  addPromotionalPujaAction,
  deletePromotionalPujaAction,
  getSpecialPujaList,
} from "../../../store/actions/pujaAction";
import { replaceSpacesWithHyphen } from "../../../utils/HelperFunctions";


const getMaxAllowedMember = (pujaDetails: any): any => {
  return pujaDetails?.multiplePujaDetails &&
    pujaDetails?.multiplePujaDetails?.length > 0
    ? pujaDetails?.multiplePujaDetails[
        pujaDetails?.multiplePujaDetails?.length - 1
      ].maxMemberAllowed
    : pujaDetails?.maxMemberAllowed || 1;
};

function ManageSpecialPujaScreen() {
  const dispatch: any = useDispatch();
  const { allTempleList } = useSelector((state: any) => state?.temple);
  const [templeList, setTempleList] = useState<any>([]);
  const [allPujaList, setAllPujaList] = useState<any>([]);
  const [isAddEditSpecialPujaModalOpen, setIsAddEditSpecialPujaModalOpen] =
    useState(false);
  const [seletedTempleOptions, setSeletedTempleOptions] = useState<any>([]);
  const [seletedPujaOptions, setSeletedPujaOptions] = useState<any>([]);
  const [promotionalList, setPromotionalList] = useState([]);
  const [allPromotionalList, setAllPromotionalList] = useState([]);
  const [selectedSpecialPuja, setSelectedSpecialPuja] = useState<any>(null);

  useEffect(() => {
    try {
      dispatch(getSpecialPujaList())
        .then((res: any) => {
          setAllPromotionalList(res?.promotionalList);
        })
        .catch((err: any) => {
          console.log("Error:",err);
        });
    } catch (error) {
      console.log("Error:",error);
    }
  }, [dispatch]);

  useEffect(() => {
    setPromotionalList(allPromotionalList);
  }, [allPromotionalList]);

  useEffect(() => {
    if (allTempleList && allTempleList?.length) {
      let formatedList = allTempleList?.map((item: any) => {
        return { value: item?.id, label: item?.personalInfo?.name };
      });
      formatedList = [...formatedList];
      setTempleList(formatedList);
    }
  }, [allTempleList]);

  const handleTempleSelect = (event: any) => {
    setSeletedTempleOptions(event);
    let pujas: { value: string; label: string }[] = [];
    const selectedTempleIds = event?.map((item: any) => item?.value);
    const selectedTemples = allTempleList?.filter((item: any) =>
      selectedTempleIds?.includes(item?.id)
    );
    selectedTemples?.forEach((templs: any) => {
      templs.templePuja.forEach((puja: any) => {
        pujas.push({
          value: `${templs?.id}_${puja?.pujaId}`,
          label: `${templs?.personalInfo?.name}-${puja?.pujaName}`,
        });
      });
    });
    setAllPujaList(pujas);
  };

  const handlePujaSelect = (event: any) => {
    setSeletedPujaOptions(event);
  };

  const handleEditSpecialPujaItem = (item: any) => {
    setSelectedSpecialPuja(item);
    setIsAddEditSpecialPujaModalOpen(true);
    let pujas: { value: string; label: string }[] = [];
    const selectedTempleIdsAll = item?.templeList?.map((item: any) => item?.id);
    const selectedTemples = allTempleList?.filter((item: any) =>
      selectedTempleIdsAll?.includes(item?.id)
    );
    selectedTemples?.forEach((templs: any) => {
      templs.templePuja.forEach((puja: any) => {
        pujas.push({
          value: `${templs?.id}_${puja?.pujaId}`,
          label: `${templs?.personalInfo?.name}-${puja?.pujaName}`,
        });
      });
    });
    setAllPujaList(pujas);
    let selectedTempleIds: string[] = [];
    let selectedPujaIds: string[] = [];
    if (item?.templeList?.length > 0) {
      item?.templeList?.forEach((el: any) => {
        selectedTempleIds.push(el?.id);
        if (el?.templePuja?.length > 0) {
          el?.templePuja?.forEach((elp: any) => {
            let templePujaId = `${elp?.templeId}_${elp?.pujaId}`;
            selectedPujaIds.push(templePujaId);
          });
        }
      });
    }
    setSeletedTempleOptions([...selectedTempleIds]);
    setSeletedPujaOptions([...selectedPujaIds]);
  };

  const handleSubmitSpecialPuja = async (formData: any) => {
    try {
      let payload: any = { ...formData };
      let modifiedPujaName = replaceSpacesWithHyphen(payload?.pujaName);
      let pujaNameKey =
        selectedSpecialPuja?.pujaNameKey &&
        selectedSpecialPuja?.pujaNameKey?.length > 0
          ? selectedSpecialPuja?.pujaNameKey
          : modifiedPujaName;
      payload["pujaNameKey"] = pujaNameKey;

      if (payload?.dashBoardImage instanceof File) {
        payload["dashBoardBg"] = {
          uri: await TempleService.uploadPromotionalPujaImageFromWeb({
            uri: payload?.dashBoardImage,
            platform: "web",
          }),
        };
        delete payload["dashBoardImage"];
      } else {
        payload["dashBoardBg"] = { ...selectedSpecialPuja?.dashBoardBg };
        delete payload["dashBoardImage"];
      }
      if (payload?.pujaListImage instanceof File) {
        payload["pujaListBg"] = {
          uri: await TempleService.uploadPromotionalPujaImageFromWeb({
            uri: payload?.pujaListImage,
            platform: "web",
          }),
        };
        delete payload["pujaListImage"];
      } else {
        payload["pujaListBg"] = { ...selectedSpecialPuja?.pujaListBg };
        delete payload["pujaListImage"];
      }

      if (!selectedSpecialPuja) {
        delete payload["id"];
        const tempTempleList =
          seletedTempleOptions?.length > 0
            ? reStructureTempleAndPujaForDb()
            : [];
        payload["templeList"] = tempTempleList;
      }

      if (selectedSpecialPuja) {
        // checking if seletedTempleOptions is greater than zero and 0th position of seletedTempleOptions is string value true then
        //again cheking for puja using seletedPujaOptions for the same like firts check length of seletedPujaOptions is greater than zero and on 0th position of seletedPujaOptions contains string true the directly returning selectedSpecialPuja?.templeList which consist previous temple list(no changes made by user) false in next line
        // again run loop to match the data and create the objects of temple inside templePuja list else
        // if only changes present in puja list then checking using seletedPujaOptions length is greater than zero and 0th position of seletedPujaOptions containes string then
        // run loop om seletedTempleOptions to create temple list of selected value else next line
        // create and update at same time of all the puja list object inside templeList

        //---- after all condition create and add data inside payload

        const tempTempleList =
          seletedTempleOptions?.length > 0 &&
          typeof seletedTempleOptions[0] === "string"
            ? (seletedPujaOptions.length > 0 &&
                typeof seletedPujaOptions[0] === "string") ||
              seletedPujaOptions.length === 0
              ? selectedSpecialPuja?.templeList
              : selectedSpecialPuja?.templeList?.map((tmpl: any) => {
                  let temData: any = { ...tmpl };
                  const selTemple = allTempleList?.filter(
                    (el: any) => el?.id === tmpl?.id
                  );
                  const filteredSelectedPuja = seletedPujaOptions?.filter(
                    (it: any) => {
                      const names = it?.label?.split("-");
                      const ids = it?.value?.split("_");
                      if (ids[0] === tmpl?.id) {
                        return it;
                      }
                    }
                  );

                  const pujaListForTemple =
                    filteredSelectedPuja.length > 0
                      ? filteredSelectedPuja?.map((puj: any) => {
                          const names = puj?.label?.split("-");
                          const ids = puj?.value?.split("_");
                          const filteredMainPuja =
                            selTemple[0]?.templePuja?.filter(
                              (it: any) => it?.pujaId === ids[1]
                            );
                          let maxDevoteeinPuja = getMaxAllowedMember(
                            filteredMainPuja[0]
                          );
                          let tempPuja = {
                            ...filteredMainPuja[0],
                            pujaName: names?.length > 1 ? names[1] : "",
                            pujaId: ids?.length > 1 ? ids[1] : "",
                            templeId: ids?.length > 1 ? ids[0] : "",
                            templeAddress: selTemple[0]?.personalInfo?.address,
                            benefitList: filteredMainPuja[0]?.benefitList,
                            maxMemberAllowed: maxDevoteeinPuja,
                          };
                          return tempPuja;
                        })
                      : [];
                  temData["templePuja"] = pujaListForTemple;
                  return temData;
                })
            : (seletedPujaOptions.length > 0 &&
                typeof seletedPujaOptions[0] === "string") ||
              seletedPujaOptions.length === 0
            ? seletedTempleOptions.map((tmple: any) => {
                const selTemple = allTempleList?.filter(
                  (el: any) => el?.id === tmple?.value
                );
                let templePayload: any = {
                  name: tmple?.label,
                  id: tmple?.value,
                  nameKey: selTemple[0]?.nameKey,
                  personalInfo: selTemple[0]?.personalInfo,
                };

                const filteredSelectedPuja = seletedPujaOptions
                  ?.filter((puj: any) => {
                    //const names = puj?.label?.split("-");
                    const ids = puj?.split("_");
                    if (tmple?.value === ids[0]) {
                      return ids[1];
                    }
                  })
                  ?.map((puj: any) => puj?.split("_")[1]);
                const pujaListForTemple =
                  filteredSelectedPuja.length > 0
                    ? filteredSelectedPuja?.map((puj: any) => {
                        //  const names = puj?.label?.split("-");
                        const ids = puj;
                        const filteredMainPuja =
                          selTemple[0]?.templePuja?.filter(
                            (it: any) => it?.pujaId === ids
                          );
                        let maxDevoteeinPuja = getMaxAllowedMember(
                          filteredMainPuja[0]
                        );
                        let tempPuja = {
                          ...filteredMainPuja[0],
                          pujaName: filteredMainPuja[0].pujaName,
                          pujaId: ids,
                          templeId: selTemple[0].id,
                          templeAddress: selTemple[0]?.personalInfo?.address,
                          benefitList: filteredMainPuja[0]?.benefitList,
                          maxMemberAllowed: maxDevoteeinPuja,
                        };
                        return tempPuja;
                      })
                    : [];
                templePayload["templePuja"] = pujaListForTemple;
                return templePayload;
              })
            : reStructureTempleAndPujaForDb();
        payload["templeList"] = tempTempleList;
      }
      payload["version"] = "0.1";
      try {
        dispatch(addPromotionalPujaAction(payload)).then(() => {
          dispatch(getSpecialPujaList()).then((res: any) => {
            setAllPromotionalList(res?.promotionalList);
          });
        });
      } catch (err: any) {
        console.log("Error:", err);
      }
    } catch (error) {
      console.log("Error:",error);
    }
    setSelectedSpecialPuja(null);
    setIsAddEditSpecialPujaModalOpen(false);
    setSeletedTempleOptions([]);
    setSeletedPujaOptions([]);
  };

  const reStructureTempleAndPujaForDb = () => {
    return seletedTempleOptions.map((tmple: any) => {
      const selTemple = allTempleList?.filter(
        (el: any) => el?.id === tmple?.value
      );
      let templePayload: any = {
        name: tmple?.label,
        id: tmple?.value,
        nameKey: selTemple[0]?.nameKey,
        personalInfo: selTemple[0]?.personalInfo,
      };
      const filteredSelectedPuja = seletedPujaOptions?.filter((puj: any) => {
        const names = puj?.label?.split("-");
        const ids = puj?.value?.split("_");
        if (tmple?.value === ids[0]) {
          return puj;
        }
      });
      const pujaListForTemple =
        filteredSelectedPuja.length > 0
          ? filteredSelectedPuja?.map((puj: any) => {
              const names = puj?.label?.split("-");
              const ids = puj?.value?.split("_");
              const filteredMainPuja = selTemple[0]?.templePuja?.filter(
                (it: any) => it?.pujaId === ids[1]
              );
              let maxDevoteeinPuja = getMaxAllowedMember(filteredMainPuja[0]);
              let tempPuja = {
                ...filteredMainPuja[0],
                pujaName: names.length > 1 ? names[1] : "",
                pujaId: ids.length > 1 ? ids[1] : "",
                templeId: ids.length > 1 ? ids[0] : "",
                templeAddress: selTemple[0]?.personalInfo?.address,
                benefitList: filteredMainPuja[0]?.benefitList,
                maxMemberAllowed: maxDevoteeinPuja,
              };
              return tempPuja;
            })
          : [];
      templePayload["templePuja"] = pujaListForTemple;
      return templePayload;
    });
  };

  const handleCloseAddEditSpecialPujaForm = () => {
    setIsAddEditSpecialPujaModalOpen(false);
    setSelectedSpecialPuja(null);
    setSeletedTempleOptions([]);
    setSeletedPujaOptions([]);
  };

  const handleDeleteSpecialPuja = (specialPuja: any) => {
    try {
      dispatch(deletePromotionalPujaAction(specialPuja, specialPuja?.id)).then(
        () => {
          dispatch(getSpecialPujaList()).then((res: any) => {
            setAllPromotionalList(res?.promotionalList);
          });
        }
      );
    } catch (error) {
      console.log("Error:",error);
    }
  };

  const handleSearchSpPujalist = (str: any) => {
    if (str?.length > 0) {
      const newStr = str?.toLowerCase();
      const newList = allPromotionalList.filter((item: any) =>
        item?.pujaName?.toLowerCase()?.includes(newStr)
      );
      setPromotionalList(newList);
    } else {
      setPromotionalList(allPromotionalList);
    }
  };

  return (
    <ManageSpecialPujaView
      isAddEditSpecialPujaModalOpen={isAddEditSpecialPujaModalOpen}
      setIsAddEditSpecialPujaModalOpen={setIsAddEditSpecialPujaModalOpen}
      promotionalList={promotionalList}
      templeList={templeList}
      handleTempleSelect={handleTempleSelect}
      allPujaList={allPujaList}
      handlePujaSelect={handlePujaSelect}
      seletedTempleOptions={seletedTempleOptions}
      seletedPujaOptions={seletedPujaOptions}
      selectedSpecialPuja={selectedSpecialPuja}
      setSelectedSpecialPuja={setSelectedSpecialPuja}
      setSeletedTempleOptions={setSeletedTempleOptions}
      setSeletedPujaOptions={setSeletedPujaOptions}
      handleEditSpecialPujaItem={handleEditSpecialPujaItem}
      handleSubmitSpecialPuja={handleSubmitSpecialPuja}
      handleCloseAddEditSpecialPujaForm={handleCloseAddEditSpecialPujaForm}
      handleDeleteSpecialPuja={handleDeleteSpecialPuja}
      handleSearchSpPujalist={handleSearchSpPujalist}
    />
  );
}

export default ManageSpecialPujaScreen;
