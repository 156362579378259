import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
import "./GoogleMapDialog.scss";
import GoogleMapV2 from "../../map/GoogleMapV2";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';

interface GoogleMapDialogProps {
  open: boolean;
  onClose: (e:any,reason:any) => void;
  formData: any;
  handleChangeTemple: (templeData: any) => void;
  handleAddressChange: (e:any) => void;
  editMode: String;
}
const GoogleMapDialog: React.FC<GoogleMapDialogProps> = (props: any) => {
  const { open, onClose, formData, handleChangeTemple, handleAddressChange,editMode } = props;
  const [showAddressSection, setShowAddressSection] = useState<boolean>(false);
  const [templeSelectedInMap, setTempleSelectedInMap] = useState<any>(null);
  const [addressError, setAddressError] = useState<any>({
    street: '',
    city: '',
    state: '',
    zip: '',
  })

  const resetAddressError = ()=>{
    setAddressError({
      street: '',
      city: '',
      state: '',
      zip: '',
    })
  }

  const validateAddress = () => {
    const newErrors:any = {};

    if (!formData?.address?.street?.trim()) {
      newErrors.street = 'Street is required';
    }

    if (!formData?.address?.city?.trim()) {
      newErrors.city = 'City is required';
    }

    if (!formData?.address?.state?.trim()) {
      newErrors.state = 'State is required';
    }

    if (!formData?.address?.zip?.trim()) {
      newErrors.zip = 'Zip code must be 5 digits';
    }

    setAddressError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const selectTempleFinal = () => {
    if (!templeSelectedInMap) {
      handleChangeTemple(formData?.googleData);
      setShowAddressSection(true);
      return;
    }
    handleChangeTemple(templeSelectedInMap);
    setShowAddressSection(true);
  };

  const handleSaveAndContinueMapDialog = ()=>{
    if(!validateAddress()){
      return;
    }
    resetAddressError()
    onClose()
    setShowAddressSection(false)
  }

  const handleCloseGoogleDialog = () =>{
    setShowAddressSection(false);
    onClose()
    resetAddressError()
  }
  
  return (
    <Dialog
      className="locationSelectDialog"
      open={open}
      onClose={handleCloseGoogleDialog}
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <DialogTitle className="topTitle">
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center" 
          spacing={2}
        >
          <Typography>Google Map</Typography>
          {/* --- For Close Purpose */}
          <IconButton className="btnClose" onClick={handleCloseGoogleDialog}>
            <CloseIcon />
          </IconButton>
        </Stack>
        
      </DialogTitle>
      <DialogContent className="innerContent">
        <GoogleMapV2
          place={formData?.googleData}
          setTempleSelectedInMap={setTempleSelectedInMap}
          showAddressSection={showAddressSection}
        />
        {formData?.googleData && (
          <Box className="templeNameInfo">
            <Typography className="templeName">{formData?.googleData?.name}</Typography>
            <Typography className="cityName"><span><LocationOnIcon /></span>{formData?.address?.city}</Typography>
            <Typography>{formData?.googleData?.formatted_address}</Typography>
          </Box>
        )}
        {!showAddressSection && (
          <Stack 
            direction="row" 
            justifyContent="center" 
            alignItems="center" 
            spacing={2} 
            className="okBtn"
          >
            <Button
              onClick={selectTempleFinal}
              variant="contained"
              disabled= {editMode === "Verify"}
            >
              Confirm Location
            </Button>
          </Stack>
        )}
        {showAddressSection && (
          <Box className="addressSelection">
            <Box className="messageText">
              <Typography>
                A detailed address will help the devotees to locate the temple.
              </Typography>
            </Box>
            <Grid container spacing={2} className="textFillBox">
              <Grid xs={12}>
                <TextField
                  className="textAreaSec"
                  label="Address"
                  variant="standard"
                  value={formData?.address?.street || ""}
                  name="street"
                  onChange={handleAddressChange}
                  error={!!addressError?.street}
                  helperText={addressError?.street || ""}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  className="textAreaSec"
                  label="City"
                  variant="standard"
                  value={formData?.address?.city || ""}
                  name="city"
                  onChange={handleAddressChange}
                  error={!!addressError?.city}
                  helperText={addressError?.city || ""}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  className="textAreaSec"
                  label="State"
                  variant="standard"
                  value={formData?.address?.state || ""}
                  name="state"
                  onChange={handleAddressChange}
                  error={!!addressError?.state}
                  helperText={addressError?.state || ""}
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  className="textAreaSec"
                  label="Zip"
                  variant="standard"
                  value={formData?.address?.zip || ""}
                  name="zip"
                  onChange={handleAddressChange}
                  error={!!addressError?.zip}
                  helperText={addressError?.zip || ""}
                />
              </Grid>
            </Grid>
            <Box className="okBtn">
              <Button
                variant="contained"
                onClick={handleSaveAndContinueMapDialog}
              >
                Save and Continue
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: "10px" }}></DialogActions>
    </Dialog>
  );
};

export default GoogleMapDialog;
