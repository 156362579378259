import VirtualPoojaBookingView from "../../Views/Puja/VirtualPoojaBookingView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getImageListFromTemple,
  getOneImageForTemple,
} from "../../utils/HelperFunctions";
import {
  getTempleDetailsById,
  getTempleDetailsByNameKey,
} from "../../store/actions/templeAction";
import {
  setSelectedPuja,
  setSelectedTempleDetailsForPuja,
} from "../../store/slices/pujaSlice";
import ParentView from "../../Views/PrarentView/ParentView";

function VirtualPujaBookingScreen(props: any) {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [askUserToModifyCartPopUp, setAskUserToModifyCartPopUp] =
    useState(false);
  const [selectedPujaDetails, setSelectedPujaDetails] = useState(null);
  const { selectedTempleDetails } = useSelector((state: any) => state.temple);

  const onClickAddPuja = (pujaDetails: any) => {
    dispatch(setSelectedTempleDetailsForPuja(selectedTempleDetails));
    dispatch(setSelectedPuja(pujaDetails));
    // navigate("/checkout/puja-details");
    //Old
    // navigate(`/temples/${templeIdComing}/pujaInfo/${pujaDetails?.pujaNameKey}`)
    //new

    navigate(
      `/templePujaInfo?templeNameKey=${selectedTempleDetails?.nameKey}&pujaNameKey=${pujaDetails?.pujaDetails?.pujaNameKey}`
    );
    // navigate("/checkout/puja-details");
  };

  const userConfirmUpdatePujaDetails = () => {
    // dispatch(setPujaDetails(selectedPujaDetails));
    // navigate("/checkout/puja-details");
  };

  const templePersonalInfo =
    selectedTempleDetails && selectedTempleDetails.personalInfo
      ? selectedTempleDetails.personalInfo
      : {};
  let templeMediaList: any = getImageListFromTemple(
    templePersonalInfo,
    "pujaPictures"
  );

  const templeProfilePic = () => {
    let imageArrayForTemple = templeMediaList;
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple);
    return templeProfilePic;
  };

  useEffect(() => {
    let keyType = props?.keyType;
    if (keyType && keyType === "id") {
      const templeIdComing = location.pathname.split("/")[2];
      dispatch(getTempleDetailsById(templeIdComing));
    } else {
      const params = new URLSearchParams(location.search);
      const templeId = params.get("templeNameKey");
      const templeIdComing = templeId;
      dispatch(getTempleDetailsByNameKey(templeIdComing));
    }
  }, []);
  return (
    <ParentView>
      <VirtualPoojaBookingView
        templeDetails={selectedTempleDetails}
        templePersonalInfo={templePersonalInfo}
        templeMediaList={templeMediaList}
        onClickAddPuja={onClickAddPuja}
        templeProfilePic={templeProfilePic}
        setAskUserToModifyCartPopUp={setAskUserToModifyCartPopUp}
        askUserToModifyCartPopUp={askUserToModifyCartPopUp}
        userConfirmUpdatePujaDetails={userConfirmUpdatePujaDetails}
      />
    </ParentView>
  );
}

export default VirtualPujaBookingScreen;
