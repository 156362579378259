import PujaSubCategoriesView from "../../Views/Puja/PujaSubcategoriesView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
function PujaSubCategoriesScreen() {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [filteredPujaList, setFilterEdPujaList]: any = useState([]);
  const [subCategoryDetails, setSubCategoryDetails]: any = useState([]);
  const { allPujaList } = useSelector((state: any) => state.temple);
  const { pujaMergedSubCategoryList } = useSelector((state: any) => state.puja);
  const subcategoryPujaId = location.pathname.split("/")[2];
  useEffect(() => {
    // dispatch(getPromotionalPujaListById(promotionalPujaId))
    if (allPujaList && allPujaList.length > 0) {
      getPujaForSubCategory(allPujaList, subcategoryPujaId);
    }

    if (pujaMergedSubCategoryList && pujaMergedSubCategoryList.length > 0) {
      let resFilter = pujaMergedSubCategoryList.filter(
        (item: any) => item.subCategoryId === subcategoryPujaId
      );
      if (resFilter && resFilter.length > 0) {
        setSubCategoryDetails(resFilter[0]);
      }
    }
  }, [allPujaList, pujaMergedSubCategoryList]);

  function getPujaForSubCategory(allPujaList: any, subcategoryIncoming: any) {
    let finalData: any = [];
    for (let i = 0; i < allPujaList.length; i++) {
      let pujaDetails = allPujaList[i].pujaDetails;
      if (pujaDetails && pujaDetails.pujaSubCategory) {
        let subCategoryForPuja = pujaDetails.pujaSubCategory;
        let filterRes = subCategoryForPuja.filter(
          (item: any) => item.subCategoryId === subcategoryIncoming
        );
        if (filterRes.length > 0) {
          finalData = [...finalData, allPujaList[i]];
        }
      }
    }

    setFilterEdPujaList(finalData);
  }

  const onClickParticipate = (pujaDetails: any) => {
    let pujaId = pujaDetails.pujaDetails.pujaId;
    let templeId = pujaDetails.id;
    navigate(`/temples/${templeId}/pujaInfo/${pujaId}`);
  };

  return (
    <ParentView>
      <PujaSubCategoriesView
        allPujaList={filteredPujaList}
        subCategoryDetails={subCategoryDetails}
        onClickAddPuja={onClickParticipate}
      />
    </ParentView>
  );
}

export default PujaSubCategoriesScreen;
