import { Box, Grid, Typography, Button } from "@mui/material";
import templeEmoji from "../../../assets/temple-emoji.png";
import pujaEmoji from "../../../assets/puja-emoji.png";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

export default function CartNoItem(props: any) {
  const navigate = useNavigate();

  return (
    <Box className="noItemCart">
      <Typography className="noItemText"> {t(LOCALIZATION.No_items_added_in_cart)}</Typography>
      <Box className="redirectBtn">
        <Button variant="outlined" onClick={() => navigate("/temples")}>{t(LOCALIZATION.Explore_temples)} <img src={templeEmoji} alt="" /></Button>
        <Button variant="outlined" onClick={() => navigate("/pujas")}>{t(LOCALIZATION.Explore_pujas)} <img src={pujaEmoji} alt="" /></Button>
      </Box>
    </Box>
  );
}
