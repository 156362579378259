import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./AddLiveDarshanView.scss";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import {
  addLivedarshan,
} from "../../../utils/ConstantsMessages";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
// import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import LIveDarshanTempleForm from "./LIveDarshanTempleForm";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";

interface TempleListProps {
  isAddEditLiveDarshanModelOpen: boolean;
  setIsAddEditLiveDarshanModelOpen: (modalState: boolean) => void;
  liveDarshanTempleList: any;
  templeList: any;
  allPujaList: any;
  seletedTempleOptions: any;
  seletedPujaOptions: any;
  selectedLiveItem: any;
  setSeletedTempleOptions: ([]: any) => any;
  setSeletedPujaOptions: ([]: any) => any;
   handleSearchSpPujalist: any;
  handleDeleteLiveDarshan:any
  handleSubmitLiveDarsanTemple: any;
  handleCloseAddEditLiveDarshanTempleForm: any;
  setSelectedLiveItem:(selectedPuja: any) => any;
  handleEditLiveDarshanItem:any
}

const AddLiveDarshanView: React.FC<TempleListProps> = (props: any) => {
  const {
    isAddEditLiveDarshanModelOpen,
    setIsAddEditLiveDarshanModelOpen,
    liveDarshanTempleList,
    handleTempleSelect,
    handlePujaSelect,
    selectedLiveItem,
    handleEditLiveDarshanItem,
    handleSubmitLiveDarsanTemple,
    handleCloseAddEditLiveDarshanTempleForm,
    handleDeleteLiveDarshan,
    handleSearchSpPujalist,
  } = props;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedItem) {
      handleDeleteLiveDarshan(selectedItem);
      setSelectedItem(null);
    }
    handleCloseDeleteDialog();
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };

  const handleOpenAddEditLiveDarshanForm = () => {
    setIsAddEditLiveDarshanModelOpen(true);
  };

  return (
    <Box className="pageContainer specialPujaAdmin">
      <Box className="topTitle">
        <Typography className="adminTitleText">
          {addLivedarshan.title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={1}
      >
        <Box>
          <SearchFilter handleSearchSpPujalist={handleSearchSpPujalist} />
        </Box>
        <Box className="btnAddSpecialPuja">
          <Button
            onClick={handleOpenAddEditLiveDarshanForm}
            variant="contained"
            aria-label="Add special puja"
          >
            <AddCircleOutlineIcon sx={{ fontSize: "20px", mr: 1 }} />
            {addLivedarshan.button}
          </Button>
        </Box>
      </Box>

      {liveDarshanTempleList && liveDarshanTempleList?.length > 0 ? (
        <List className="specialPujaItemListAdmin">
          {liveDarshanTempleList.map(
            (_liveTempleDarshanItem: any, index: number) => (
              <ListItem
                key={_liveTempleDarshanItem?.id}
                className="specialPujaAdminCard"
                sx={{
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={_liveTempleDarshanItem?.media?.url}
                    sx={{
                      width: 80,
                      height: 80,
                      marginRight: 2,
                      borderRadius: "10px",
                      border: "1px solid #cdcdcd",
                    }}
                  />
                </ListItemAvatar>

                <ListItemText
                  className="listText"
                  primary={
                    <Box>
                      <Typography variant="body1" fontWeight="bold">
                        {_liveTempleDarshanItem?.templeName}
                      </Typography>
                      <Typography variant="body2">
                        {_liveTempleDarshanItem?.city},{" "}
                        {_liveTempleDarshanItem?.state}
                      </Typography>
                    </Box>
                  }
                />
                <IconButton
                  className="spPujaEditBtn"
                  aria-label="edit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditLiveDarshanItem(_liveTempleDarshanItem);
                  }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                </IconButton>

                <IconButton
                  className="spPujaDeleteBtn"
                  aria-label="delete"
                  onClick={() => handleDeleteClick(_liveTempleDarshanItem)}
                >
                  <DeleteIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </ListItem>
            )
          )}
        </List>
      ) : (
        <NoDataFound />
      )}

      <LIveDarshanTempleForm
        isOpen={isAddEditLiveDarshanModelOpen}
        onClose={handleCloseAddEditLiveDarshanTempleForm}
        onSave={handleSubmitLiveDarsanTemple}
        selectedLiveItem={selectedLiveItem}
      />
      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete this item?"
      />
    </Box>
  );
};
export default AddLiveDarshanView;
