import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Snackbar,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import TempleOnboardingView from "../../../Views/Admin/TempleOnboarding/TempleOnboardingView";
import TempleListView from "../../../Views/Admin/TempleOnboarding/TempleListView";
import { replaceSpacesWithHyphen } from "../../../utils/HelperFunctions";
import { CommonService } from "td_server";
import {
  addEditTempleDetailsFromWeb,
  getAllTempleListVerifiedAndUnverified,
  getTranslatedTempleName,
} from "../../../store/actions/templeAction";

function TempleOnboardingScreen() {
  const dispatch: any = useDispatch();
  const { allVerifiedAndUnverifiedTempleList } = useSelector(
    (state: any) => state.temple
  );
  const { userInfo } = useSelector((state: any) => state?.user);
  const [editMode, setEditMode] =
    useState<string>("List"); /* Add/List/Edit/Verify */
  const [openSuccessToast, setOpenSuccessToast] = useState<any>({
    isToastOpen: false,
    toastSeverity: "",
    toastMessage: "",
  });

  const [singleTempleData, setSingleTempleData] = useState<any>(null);

  useEffect(() => {
    try {
      dispatch(getAllTempleListVerifiedAndUnverified());
    } catch (error) {
      console.log("Error:", error);
    }
  }, []);

  const uploadImageToCloud = (newItem: any) => {
    try {
      const newImg = CommonService.fileUpload(
        {
          uri: newItem,
          platform: "web",
        },
        "users_details/temple_picture"
      ).then((imageUrl: any) => {
        return { pictureUrl: imageUrl, isPrimary: true, platform: "web" };
      });
      return newImg;
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const onSubmit = async (data: any, isTempleVerified:boolean) => {
    try {
      let payload: any = {};
      if (editMode === "Edit" && singleTempleData) {
        payload = { ...singleTempleData };
      }
      let images: any = [];
      if (data?.coverImageUrls?.length > 0) {
        images = data?.coverImageUrls?.map((newItem: any) => {
          if (newItem instanceof File) {
            try {
              let newImg = uploadImageToCloud(newItem);
              return newImg;
            } catch (error) {
              console.log("Error: ", error);
            }
          } else {
            return newItem;
          }
        });
      }
      let uploadedImages: any = [];
      await Promise.all(images).then((imgs: any) => {
        uploadedImages = [...imgs];
      });

      let pujaImages: any = [];
      if (data?.coverImageUrls?.length > 0) {
        pujaImages = data?.coverImageUrlsPuja?.map((newItem: any) => {
          if (newItem instanceof File) {
            try {
              let newImg = uploadImageToCloud(newItem);
              return newImg;
            } catch (error) {
              console.log("Error: ", error);
            }
          } else {
            return newItem;
          }
        });
      }
      let uploadedPujaImages: any = [];
      await Promise.all(pujaImages).then((imgs: any) => {
        uploadedPujaImages = [...imgs];
      });

      let prasadImages: any = [];
      if (data?.coverImageUrls?.length > 0) {
        prasadImages = data?.coverImageUrlsPrasad?.map((newItem: any) => {
          if (newItem instanceof File) {
            try {
              let newImg = uploadImageToCloud(newItem);
              return newImg;
            } catch (error) {
              console.log("Error: ", error);
            }
          } else {
            return newItem;
          }
        });
      }
      let uploadedPrasadImages: any = [];
      await Promise.all(prasadImages).then((imgs: any) => {
        uploadedPrasadImages = [...imgs];
      });

      let chadavaImages: any = [];
      if (data?.coverImageUrls?.length > 0) {
        chadavaImages = data?.coverImageUrlsChadava?.map((newItem: any) => {
          if (newItem instanceof File) {
            try {
              let newImg = uploadImageToCloud(newItem);
              return newImg;
            } catch (error) {
              console.log("Error: ", error);
            }
          } else {
            return newItem;
          }
        });
      }
      let uploadedChadavaImages: any = [];
      await Promise.all(chadavaImages).then((imgs: any) => {
        uploadedChadavaImages = [...imgs];
      });

      if (editMode === "Add" || editMode === "Edit") {
        let nameKey =
          editMode === "Add"
            ? replaceSpacesWithHyphen(data?.title)
            : singleTempleData?.nameKey; 
        let dataType = "temple";
        let templeName:any = {};
        templeName['en'] = data?.title || "";
        const hindiTempleName = await dispatch(getTranslatedTempleName(data?.title, 'hi', 'Deva'))
        const bengTempleName = await dispatch(getTranslatedTempleName(data?.title, 'bn', 'Beng'))
        templeName['hi'] = hindiTempleName || '';
        templeName['bn'] = bengTempleName || '';
        let personalInfo: any = {
          templeName: { ...templeName},
          descriptions: data?.shortDescription || "",
          primaryGodName: data?.primaryGodName || "",
          phoneNumber:
            data?.googleData?.international_phone_number ||
            data?.googleData?.formatted_phone_number ||
            "",
          yearOfEstablishment: data?.yearOfEstablishment || "",
          officialEmail: data?.officialEmail || "",
          name: data?.title,
          officialSite: data?.officialWebsiteURL || "",
          onBoardingUserInfo: {
            id: userInfo?.id || "",
            phoneNumber: userInfo?.phoneNumber || "",
            name: `${userInfo?.personalInfo?.firstName} ${userInfo?.personalInfo?.lastName}`,
          },
          operatingHours: { ...data?.timings },
          media: {
            pictures: [...uploadedImages],
            pujaPictures: [...uploadedPujaImages],
            prasadPictures: [...uploadedPrasadImages],
            chadavaPictures: [...uploadedChadavaImages],
            videoURL: data?.videoURL || "",
          },
          address: {
            ...data?.address,
            phoneNumber: userInfo?.phoneNumber || "",
            name: `${userInfo?.personalInfo?.firstName} ${userInfo?.personalInfo?.lastName}`,
          },
        };
        payload = {
          ...payload,
          googleData: data?.googleData || null,
          nameKey,
          searchKey: data?.searchKey,
          dataType,
          googlePlaceId: data?.googleData?.place_id,
          position: data?.position || "0",
          showInHomePage: data?.showInHomePage,
          isPublished: data?.isPublished ,
          isClosedBooking: data?.isClosedBooking,
          isClosed: data?.isClosed,
          personalInfo: { ...personalInfo },
          selectedAdminList: [...data?.selectedAdmin],
        };
        if(editMode === 'Add'){
          payload["verifications"] = {
            registrationCardUrlFront: "",
            idCardType: "",
            status: "",
            orgRegistrationNumber: "",
          };
        }
      }
      if (editMode === "Verify" && singleTempleData) {
        payload = { ...singleTempleData };
        payload["verifications"] = {
          registrationCardUrlFront: "",
          idCardType: "OTHERS",
          status: isTempleVerified ? "VERRIFIED" : "",
          orgRegistrationNumber: "",
        };

        payload["selectedAdminList"] = data?.selectedAdmin || [];
      }
      dispatch(addEditTempleDetailsFromWeb(payload));
      handleBack();
      setSingleTempleData(null);
      setOpenSuccessToast({
        isToastOpen: true,
        toastSeverity: "success",
        toastMessage: "Form submitted successfully",
      });
    } catch (error: any) {
      console.error("Error:", error);
      setOpenSuccessToast({
        isToastOpen: true,
        toastSeverity: "error",
        toastMessage: error || "Failed to Submit Form",
      });
  
    }
  };

  const handleAdd = () => {
    setSingleTempleData(null);
    setEditMode("Add");
  };
  const handleEdit = (row: any) => {
    setEditMode("Edit");
    setSingleTempleData(row);
  };
  const handleBack = () => {
    setEditMode("List");
  };
  const handleVerify = (row: any) => {
    setEditMode("Verify");
    setSingleTempleData(row);
  };

  const handleCloseSucessToast = () => {
    setOpenSuccessToast({
      isToastOpen: false,
      toastSeverity: "",
      toastMessage: "",
    });
  };

  return (
    <Box>
      {editMode !== "List" && (
        <TempleOnboardingView
          handleBackClick={handleBack}
          onSubmit={onSubmit}
          editMode={editMode}
          singleTempleData={singleTempleData}
        />
      )}
      {editMode === "List" && (
        <TempleListView
          templeList={allVerifiedAndUnverifiedTempleList}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleVerify={handleVerify}
        />
      )}
      {openSuccessToast?.isToastOpen &&
        openSuccessToast?.toastSeverity &&
        openSuccessToast?.toastMessage && (
          <Snackbar
            open={!!openSuccessToast?.isToastOpen}
            autoHideDuration={1000}
            onClose={handleCloseSucessToast}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              icon={openSuccessToast?.toastSeverity === 'success'? <CheckIcon fontSize="inherit" /> : <CloseIcon fontSize="inherit"/>}
              severity={openSuccessToast?.toastSeverity}
              sx={{ width: "100%" }}
            >
              {openSuccessToast?.toastMessage}
            </Alert>
          </Snackbar>
        )}
    </Box>
  );
}

export default TempleOnboardingScreen;
