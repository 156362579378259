import React from "react";
import {
  Box,
  Grid,
  Paper,
} from "@mui/material";
import "./ManageBlog.scss";
import BlogPostForm from "./BlogPostForm";

interface CouponFormProps {
  onSubmit: ({}: any) => void;
  postList: any;
  handleBack: () => any;
  editMode: string;
  postTypes: any;
}

const ManageBlogView: React.FC<CouponFormProps> = ({
  onSubmit,
  postList,
  handleBack,
  editMode,
  postTypes,
}) => {
  const handleSubmit = async (data: any) => {
    onSubmit(data);
  };
  const handleCloseSM = () => {
  };
  return (
    <>
      <Box className="pageContainer manageBlogPage">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={1}
            xl={1}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={10} xl={10} className="pageCenterBlock">
            <Paper elevation={3} className="manageBlogContainer">
              <BlogPostForm
                postList={postList}
                onSubmit={handleSubmit}
                handleBack={handleBack}
                editMode={editMode}
                postTypes={postTypes}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={1}
            xl={1}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ManageBlogView;
