import {createSlice} from '@reduxjs/toolkit';

const LiveDarshanSlice = createSlice({
  name: 'liveDarshan',
  initialState: {
    isLoading: false,
    error:"",
    darshanList:[]
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDarshanList: (state, action) => {
        state.darshanList = action.payload;
      },
  },
});

export const {
  setLoading,
  setError,
  setDarshanList
} = LiveDarshanSlice.actions;

export default LiveDarshanSlice.reducer;
