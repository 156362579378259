import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./SignupView.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Dialog, Grid, InputAdornment, Typography } from "@mui/material";
import RoundedButton from "../../../components/button/RoundedButton";
import TitleTypography from "../../../components/typography/TitleTypography";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getFormattedDate } from "../../../utils/HelperFunctions";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

function SignupView(props: any) {
  const profileSelectedTitle =
    props.profileSelectedWhileSignup && props.profileSelectedWhileSignup.title
      ? props.profileSelectedWhileSignup.title
      : "";
  const profileSelectedImage =
    props.profileSelectedWhileSignup && props.profileSelectedWhileSignup.img
      ? props.profileSelectedWhileSignup.img
      : "";
  const profileSelectedType =
    props.profileSelectedWhileSignup &&
    props.profileSelectedWhileSignup.userType
      ? props.profileSelectedWhileSignup.userType
      : "";

  // switch(profileSelectedType){
  //   case "NORMALUSER": return ()
  // }

  const dateOfBirthSelected =
    props.userDetails && props.userDetails.dateOfBirth
      ? props.userDetails.dateOfBirth
      : "";
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={12}
        md={2}
        lg={3}
        xl={3}
        className="pagehdLeftBlock"
      ></Grid>
      <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
        <Box className="createNewAccount">
          <Typography className="mainTitle">
            {t(LOCALIZATION.Create_a_New_Account)}
          </Typography>
          <Typography>
            {t(LOCALIZATION.Spiritual_Experience_with_Temple_Dekho)}
          </Typography>

          <Box className="signupDetails">
            <Typography className="signUpText">
              {t(LOCALIZATION.Register)}
            </Typography>
            <Typography style={{ marginTop: "1em" }}>
              {t(LOCALIZATION.Profile)} : {profileSelectedTitle}
            </Typography>
            <img src={profileSelectedImage} style={{ marginTop: "1em" }}></img>

            <Box className="textArea">
              <TextField
                style={{ width: "100%" }}
                className="customerInfoInput"
                id="standard-basic"
                label={t(LOCALIZATION.First_Name)}
                variant="standard"
                error={
                  !props.userDetails.firstName && props.isErrorInForm
                    ? true
                    : false
                }
                onChange={(e: any) =>
                  props.setUserDetails({
                    ...props.userDetails,
                    firstName: e.target.value,
                  })
                }
              />

              <TextField
                style={{ width: "100%" }}
                className="customerInfoInput"
                id="standard-basic"
                label={t(LOCALIZATION.Last_Name)}
                error={
                  !props.userDetails.lastName && props.isErrorInForm
                    ? true
                    : false
                }
                variant="standard"
                onChange={(e: any) =>
                  props.setUserDetails({
                    ...props.userDetails,
                    lastName: e.target.value,
                  })
                }
              />

              <TextField
                style={{ width: "100%" }}
                className="customerInfoInput"
                id="standard-basic"
                label={t(LOCALIZATION.Phone_number)}
                variant="standard"
                disabled
                error={
                  !props.userDetails.phoneNumber && props.isErrorInForm
                    ? true
                    : false
                }
                defaultValue={props.userDetails.phoneNumber}
              />

              <TextField
                style={{ width: "100%" }}
                className="customerInfoInput"
                id="standard-basic"
                // error={
                //   !props.userDetails.email && props.isErrorInForm ? true : false
                // }
                label={t(LOCALIZATION.Email)}
                variant="standard"
                onChange={(e: any) =>
                  props.setUserDetails({
                    ...props.userDetails,
                    email: e.target.value,
                  })
                }
              />

              <Box>
                <TextField
                  style={{ width: "100%" }}
                  label={t(LOCALIZATION.Date_of_Birth)}
                  className="dateField"
                  id="input-with-sx"
                  onClick={() => props.setDatePickerOpen(true)}
                  value={dateOfBirthSelected}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarMonthIcon
                          sx={{ color: "action.active", ml: 1, my: 0.5 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            label="Birth Day"
            variant="standard"
            onChange={(e: any) =>
              props.setUserDetails({
                ...props.userDetails,
                dateOfBirth: e.target.value,
              })
            }
          /> */}
            </Box>

            <Box className="genderSelect">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  {t(LOCALIZATION.Gender)}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={props.userDetails?.gender}
                  onChange={(e: any) => {
                    props.setUserDetails({
                      ...props.userDetails,
                      gender: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value={t(LOCALIZATION.Female)}
                    control={<Radio />}
                    label={t(LOCALIZATION.Female)}
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label={t(LOCALIZATION.Male)}
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label={t(LOCALIZATION.Other)}
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            {profileSelectedType === "PANDIT" ? (
              <Box>
                <Button variant="contained" component="label">
                  {t(LOCALIZATION.Document_front)}
                  <input type="file" hidden />
                </Button>
                <Button
                  style={{ marginLeft: "25px" }}
                  variant="contained"
                  component="label"
                >
                  {t(LOCALIZATION.Document_back)}
                  <input type="file" hidden />
                </Button>
              </Box>
            ) : null}

            <Box className="updateBtn">
              <Button
                className="btn-primary btnRound confirmBtn"
                variant="contained"
                onClick={() => props.submitSignUp()}
              >
                {t(LOCALIZATION.Continue)}
              </Button>
            </Box>
            <Box className="loginText">
              <Typography>
                {t(LOCALIZATION.Joined_us_before)}{" "}
                <a onClick={() => props.cancelAndGoToLogin()}>
                  {t(LOCALIZATION.Login)}
                </a>
              </Typography>
            </Box>
          </Box>

          <Dialog
            className="dateTimingSelection"
            onClose={() => props.setDatePickerOpen(false)}
            open={props.datePickerOpen}
          >
            <Box className="dateTimePopup">
              <TitleTypography title={t(LOCALIZATION.Select_Date)}>
                {t(LOCALIZATION.Select_Date)}
              </TitleTypography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  // value={dayjs(dateOfBirthSelected)}
                  disableFuture={true}
                  onChange={(e: any) => {
                    const dateData = getFormattedDate(e.toString());
                    props.setUserDetails({
                      ...props.userDetails,
                      dateOfBirth: dateData,
                    });
                  }}
                />
              </LocalizationProvider>
              <Box className="btnContinue">
                <RoundedButton
                  title="Continue"
                  onClick={() => {
                    props.setDatePickerOpen(false);
                  }}
                ></RoundedButton>
              </Box>
            </Box>
          </Dialog>

          <Dialog
            className="ErrorformDialog"
            onClose={() => {
              props.setErrorDialog(false);
            }}
            open={props.errorDialog}
          >
            <Box className="errorForm">
              {/* <TitleTypography title="Status" /> */}
              <Typography>
                {t(LOCALIZATION.Please_enter_all_necessary_fields_to_register)}
              </Typography>
              <Box className="btnContinue">
                <Button
                  variant="outlined"
                  title="Ok"
                  onClick={() => {
                    props.setErrorDialog(false);
                  }}
                >
                  OK
                </Button>
              </Box>
            </Box>
          </Dialog>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        lg={3}
        xl={3}
        className="pagehdRightBlock"
      ></Grid>
    </Grid>
  );
}

export default SignupView;
