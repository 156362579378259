import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";

interface ConfirmLogoutModalProps {
  open: boolean;
  onConfirm: (event: any) => void;
  onCancel: () => void;
}

const ConfirmLogoutModal: React.FC<ConfirmLogoutModalProps> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        id="logout-dialog-title"
        sx={{ color: "primary.main", fontWeight: "bold" }}
      >
        {t(LOCALIZATION.Confirm_Logout)}
      </DialogTitle>
      <DialogContent>
        <Typography
          id="logout-dialog-description"
          sx={{ fontSize: 16, color: "text.secondary" }}
        >
          {t(LOCALIZATION.Are_you_sure_you_want_to_log_out_You_will_need_to_log_in_again_continue_booking_pujas_or_making_offerings)}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "8px 24px 20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
            width: "100%",
          }}
        >
          <Button variant="outlined" color="primary" onClick={onCancel}>
            {t(LOCALIZATION.Cancel)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}
            sx={{
              background: "linear-gradient(90deg, #ff5858, #ff9e41)",
              boxShadow: "none",
              transition: "0.3s ease",

              "&:hover": {
                background: "linear-gradient(90deg, #ff4949, #ff8b2d)",
                boxShadow: "none",
              },
            }}
          >
            {t(LOCALIZATION.Logout)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmLogoutModal;
