import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LazyLoadingScreen from "../Screen/LazyLoading/LazyLoadingScreen";
function PrivateRoutes(props: any) {
  //This should be mapped with global loader later once redux setup completes
  var { userInfo } = useSelector((state: any) => state.user);
  var { isLoading } = useSelector((state: any) => state.loader);
  var userInfoFromExtractLocal = localStorage.getItem("userInfo") || "{}";
  var userInfoLocalJsonParsed = null
  if(userInfoFromExtractLocal&&userInfoFromExtractLocal!=='undefined'&&userInfoFromExtractLocal!=='null'){
     userInfoLocalJsonParsed = JSON.parse(userInfoFromExtractLocal);
  }

  return (
    <>
      <Outlet />
    </>
  );


  //Older code
  if (userInfoLocalJsonParsed && userInfoLocalJsonParsed.id) {
    if (isLoading || !userInfo?.id) {
      return <LazyLoadingScreen />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
  // var userInfoLocal = userInfoFromExtractLocal
  // if (isLoading || (!userInfo?.id)) {

  // return isUserLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

// else if ((userInfoLocal?.id)|| (userInfo?.id)) {
//   return <Outlet />;
// }
// else if ((userInfoLocal?.newUser)|| (userInfo?.newUser)) {
//   return <Navigate to="/choose-profile" />;
// }
//     else {
//     return <Navigate to="/login" />;
//   }
export default PrivateRoutes;
