import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import "./PujaEndTimer.scss";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface PujaEndTimerProps {
  endDate: any;
}

const PujaEndTimer: React.FC<PujaEndTimerProps> = ({ endDate }) => {
  const { t, i18n } = useTranslation();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!endDate || !moment(endDate).isValid()) return; // Exit if endDate is invalid

    const timerId = setInterval(() => {
      const now = moment();
      const timeDifference = moment(endDate).diff(now);

      if (timeDifference <= 0) {
        clearInterval(timerId);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const duration = moment.duration(timeDifference);
        setTimeLeft({
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [endDate]);

  // Check if endDate is valid and in the future before rendering
  if (!endDate || !moment(endDate).isValid() || moment().isAfter(endDate)) {
    return null;
  }

  return (
    <Box className="pujaBookingCloseSec">
      <Typography className="bookingCloseTitle">
        {t(LOCALIZATION.Puja_Booking_Closes_In)}
      </Typography>
      <Stack
        className="bookingCloseTimer"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={{ xs: 1, sm: 2 }}
      >
        <Typography>{timeLeft.days} Days</Typography>
        <Typography>{timeLeft.hours} Hours</Typography>
        <Typography>{timeLeft.minutes} Mins</Typography>
        <Typography>{timeLeft.seconds} Secs</Typography>
      </Stack>
    </Box>
  );
};

export default PujaEndTimer;
