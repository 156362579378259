import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import edit from "../../../../assets/edit.png";
import deleteicon from "../../../../assets/delete.png";
import plus from "../../../../assets/plus-icon.png";
import MyLocationRoundedIcon from "@mui/icons-material/MyLocationRounded";
import { Box, Grid, Typography } from "@mui/material";
import { LOCALIZATION } from "../../../../utils/localization";
import { t } from "i18next";

export default function AddressView(props: any) {
  const { addressList, resetFormErrors, resetForm } = props;
  const handleSelectAddress = (id: string) => {
    props.handleSelectAddress(id);
  };
  return (
    <Box>
      <CreateOrEditAddressView {...props}></CreateOrEditAddressView>
      <Box className="addressView">
        <Button
          variant="contained"
          onClick={() => {
            resetFormErrors();
            resetForm();
            props.setCreateNewOrUpdateAddressView({
              isCreate: true,
              isUpdate: false,
            });
          }}
        >
          <img src={plus} alt="" /> {t(LOCALIZATION.add_New_Adress_Button)}
        </Button>
        <Box className="divider"></Box>
        {addressList.map((item: any, id: any) => {
          return (
            <Box
              className={`homeAddress ${
                props.activeAdress === item?.id ? "active" : ""
              } `}
              onClick={(e) => {
                e.preventDefault();
                handleSelectAddress(item.id);
              }}
            >
              <Typography className="homeTitle">{item.savedAs}</Typography>
              <Box className="homeAddressContent">
                <Typography>{`${t(LOCALIZATION?.street_Label)}: ${
                  item.street
                }`}</Typography>
                <Typography>{item.formatted_address}</Typography>
                <Typography>{`${t(LOCALIZATION?.city_Label)}: ${
                  item.city
                }`}</Typography>
                <Typography>{`${t(LOCALIZATION?.state_Province_Area_Label)}: ${
                  item.state
                }`}</Typography>
                {item.phoneNumber ? (
                  <Typography>{`${t(LOCALIZATION?.Phone_Number)}: ${
                    item.phoneNumber
                  }`}</Typography>
                ) : null}
                <Typography>
                  {t(LOCALIZATION?.zip_Code_Label)} : {item.zip}
                </Typography>
              </Box>
              <Box className="homeAddressBtn">
                <img
                  src={edit}
                  alt=""
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.setCreateNewOrUpdateAddressView({
                      isCreate: false,
                      isUpdate: true,
                    });
                    props.setUpdateAddressDetails(item);
                    resetFormErrors();
                    resetForm();
                  }}
                />
                <img
                  src={deleteicon}
                  alt=""
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.deleteAddress(item.id);
                  }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

const CreateOrEditAddressView = (props: any) => {
  const {
    createNewOrUpdateAddressView,
    getCurrentPosition,
    newAddressDetails,
    setNewAddressDetails,
    saveNewAddress,
    updateAddressDetails,
    setUpdateAddressDetails,
    saveUpdatedAddress,
    setCreateNewOrUpdateAddressView,
    addressFormErrors,
    cancelAddress,
    setAddressFormErrors,
  } = props;

  const handleInputChangeAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressFormErrors((prevErrors: any) => {
      return { ...prevErrors, [name]: "" };
    });
    setNewAddressDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleInputChangeEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressFormErrors((prevErrors: any) => {
      return { ...prevErrors, [name]: "" };
    });
    setUpdateAddressDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  return (
    <>
      {createNewOrUpdateAddressView?.isCreate && (
        <Box
          sx={{
            marginBottom: "1rem",
            borderBottom: "1px solid #ebedf6",
            padding: "20px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "0.8rem",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t(LOCALIZATION?.add_New_Adress_Button)}
          </Typography>
          <Button
            variant="contained"
            sx={{ boxShadow: "none" }}
            startIcon={<MyLocationRoundedIcon />}
            onClick={() => {
              getCurrentPosition();
              props.setUserUsedGPSPosition(true);
            }}
          >
            {t(LOCALIZATION?.location_Button_Label)}
          </Button>
          <br></br>
          <br></br>

          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.Name)}
                variant="standard"
                value={newAddressDetails.savedAs}
                name="savedAs"
                onChange={handleInputChangeAdd}
                error={addressFormErrors?.savedAs}
                helperText={
                  addressFormErrors?.savedAs && addressFormErrors?.savedAs
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.street_House_Apt_Label)}
                variant="standard"
                value={newAddressDetails.street}
                name="street"
                onChange={handleInputChangeAdd}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.areaLabel)}
                variant="standard"
                value={newAddressDetails.area}
                name="area"
                onChange={handleInputChangeAdd}
                error={addressFormErrors?.area}
                helperText={addressFormErrors?.area && addressFormErrors?.area}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.city_District_Town_Label)}
                variant="standard"
                value={newAddressDetails.city}
                name="city"
                onChange={handleInputChangeAdd}
                error={addressFormErrors?.city}
                helperText={addressFormErrors?.city && addressFormErrors?.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.state_Label)}
                variant="standard"
                value={newAddressDetails.state}
                name="state"
                onChange={handleInputChangeAdd}
                error={addressFormErrors?.state}
                helperText={
                  addressFormErrors?.state && addressFormErrors?.state
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.pin_Code_Label)}
                variant="standard"
                value={newAddressDetails.zip}
                name="zip"
                onChange={handleInputChangeAdd}
                error={addressFormErrors?.zip}
                helperText={addressFormErrors?.zip && addressFormErrors?.zip}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                sx={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.alternate_PhoneNumber_Label)}
                variant="standard"
                value={newAddressDetails.phoneNumber}
                name="phoneNumber"
                error={addressFormErrors?.phoneNumber}
                helperText={
                  addressFormErrors?.phoneNumber &&
                  addressFormErrors?.phoneNumber
                }
                onChange={handleInputChangeAdd}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label={t(LOCALIZATION?.formatted_Address_Label)}
            id="fullWidth"
            placeholder="By GPS"
            value={newAddressDetails.formatted_address}
            name="formatted_address"
            onChange={handleInputChangeAdd}
            sx={{ mt: "30px" }}
          />

          <Box mt={3}>
            <Button
              variant="outlined"
              onClick={() => cancelAddress()}
              sx={{
                boxShadow: "none",
                width: "86px",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              {t(LOCALIZATION?.Cancel)}
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                marginLeft: "10px",
                width: "86px",
                background: "linear-gradient(to right, #ff5858, #ff9e41)",
                transition: "0.3s ease",
                "&:hover": {
                  boxShadow: "none",
                  background: "linear-gradient(to right, #ff4949, #ff8b2d)",
                },
              }}
              onClick={() => saveNewAddress()}
            >
              {t(LOCALIZATION?.save_Button_Label)}
            </Button>
          </Box>
        </Box>
      )}

      {createNewOrUpdateAddressView?.isUpdate && (
        <Box
          sx={{
            marginBottom: "1rem",
            borderBottom: "1px solid #ebedf6",
            padding: "20px",
          }}
        >
          <Typography
            sx={{
              marginBottom: "0.8rem",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            {t(LOCALIZATION?.edit_Addres_Label)}
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.Name)}
                variant="standard"
                value={updateAddressDetails.savedAs}
                name="savedAs"
                onChange={handleInputChangeEdit}
                error={addressFormErrors?.savedAs}
                helperText={
                  addressFormErrors?.savedAs && addressFormErrors?.savedAs
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.street_House_Apt_Label)}
                variant="standard"
                value={updateAddressDetails.street}
                name="street"
                onChange={handleInputChangeEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.areaLabel)}
                variant="standard"
                value={updateAddressDetails.area}
                name="area"
                onChange={handleInputChangeEdit}
                error={addressFormErrors?.area}
                helperText={addressFormErrors?.area && addressFormErrors?.area}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.city_District_Town_Label)}
                variant="standard"
                value={updateAddressDetails.city}
                name="city"
                onChange={handleInputChangeEdit}
                error={addressFormErrors?.city}
                helperText={addressFormErrors?.city && addressFormErrors?.city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.state_Label)}
                variant="standard"
                value={updateAddressDetails.state}
                name="state"
                onChange={handleInputChangeEdit}
                error={addressFormErrors?.state}
                helperText={
                  addressFormErrors?.state && addressFormErrors?.state
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.pin_Code_Label)}
                variant="standard"
                value={updateAddressDetails.zip}
                name="zip"
                onChange={handleInputChangeEdit}
                error={addressFormErrors?.zip}
                helperText={addressFormErrors?.zip && addressFormErrors?.zip}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                label={t(LOCALIZATION?.alternate_PhoneNumber_Label)}
                variant="standard"
                value={updateAddressDetails.phoneNumber}
                name="phoneNumber"
                error={addressFormErrors?.phoneNumber}
                helperText={
                  addressFormErrors?.phoneNumber &&
                  addressFormErrors?.phoneNumber
                }
                onChange={handleInputChangeEdit}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label={t(LOCALIZATION?.formatted_Address_Label)}
            id="fullWidth"
            placeholder="By GPS"
            value={updateAddressDetails.formatted_address}
            name="formatted_address"
            onChange={handleInputChangeEdit}
            sx={{ mt: "30px" }}
          />

          <Box mt={3}>
            <Button
              variant="outlined"
              onClick={() => {
                cancelAddress();
                props.setUserUsedGPSPosition(false);
              }}
              sx={{
                boxShadow: "none",
                width: "86px",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              {t(LOCALIZATION?.Cancel)}
            </Button>
            <Button
              variant="contained"
              sx={{
                boxShadow: "none",
                marginLeft: "10px",
                width: "86px",
                background: "linear-gradient(to right, #ff5858, #ff9e41)",
                transition: "0.3s ease",
                "&:hover": {
                  boxShadow: "none",
                  background: "linear-gradient(to right, #ff4949, #ff8b2d)",
                },
              }}
              onClick={() => {
                saveUpdatedAddress();
                props.setUserUsedGPSPosition(false);
              }}
            >
              {t(LOCALIZATION?.save_Button_Label)}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
