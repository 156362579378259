import * as React from "react";

export default function ImageViewer(props: any) {
  return (
    <img
      key={props.id}
      alt=""
      src={props.pictureUrl}
      style={{ width: props.width, height: props.height }}
    ></img>
  );
}
