import ganpati from "../../assets/ganpati-temple.jpg";
import "./TempleView.scss";
import TempleDetailsCard from "../../components/card/Temple/TempleDetailsCard";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "../../utils/localization";
import { getTempleName } from "../../utils/HelperFunctions";
import { LOCALSTORAGE_KEY } from "../../utils/Constants";

function TempleView({
  templeList,
  searchTempleForUser,
  selectTemple,
  cartDetails,
}: any) {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state: any) => state.temple);
  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <TextField
              className="searchView"
              onChange={(e: any) => searchTempleForUser(e.target.value)}
              id="outlined-basic"
              // label="Search for temples and pujas"
              variant="outlined"
              placeholder={t(LOCALIZATION.Search_for_temples)}
              sx={{
                "& fieldset": { border: "none" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "#000" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box className="templeCenterBlock templeBlock">
              {templeList &&
                templeList.length > 0 &&
                templeList.map((item: any, id: any) => {
                  const templeName = getTempleName(item, savedLanguage);
                  return (
                    <TempleDetailsCard
                      id={id}
                      templeData={item}
                      templePictures={
                        item.personalInfo &&
                        item.personalInfo.media &&
                        item.personalInfo.media.pictures
                          ? item.personalInfo.media.pictures
                          : []
                      }
                      templeImage={
                        item.personalInfo &&
                        item.personalInfo.media &&
                        item.personalInfo.media.pictures &&
                        item.personalInfo.media.pictures.length > 0
                          ? item.personalInfo.media.pictures[0].pictureUrl
                          : ganpati
                      }
                      verificationStatus={item.verifications.status}
                      templeName={templeName}
                      descriptions={item.personalInfo.descriptions}
                      // descriptions='The Jagannath Temple is an important Hindu temple dedicated to Jagannath, a form of Vishnu–one of the trinity of supreme'
                      selectTemple={selectTemple}
                      googleData={item.googleData}
                    />
                  );
                })}
              {templeList.length === 0 && !isLoading && <NoDataFound />}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TempleView;
