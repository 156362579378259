import { Box, Grid, List, ListItem, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./SuccessView.scss";
import successIcon from "../../assets/success.png";
import { useNavigate } from "react-router-dom";
import { env } from "../../env";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
export const PaymentBlock: React.FC<any> = ({ payment }) => {
  const { t, i18n } = useTranslation();
  const windowRefrence: any = window;

  useEffect(() => {
    if (env === "prod") {
      windowRefrence?.fbq("track", "Purchase", {
        value: payment?.orderValue,
        currency: "INR",
      });
    }
  }, [payment]);

  return (
    <>
      <Grid item xs={12} className="paymentId">
        <Typography component="p" align="center" gutterBottom>
          {t(LOCALIZATION.Order_id)} :{payment?.orderId} |{" "}
          {t(LOCALIZATION.Transaction_ID)} : {payment?.transectionId}
        </Typography>
      </Grid>
      <Grid item xs={12} className="orderInfo">
        <Typography
          className="secTitle"
          variant="h6"
          align="center"
          gutterBottom
        >
          {t(LOCALIZATION.Payment_Details)}
        </Typography>
        <List>
          <ListItem key={34}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography>{t(LOCALIZATION.Total_Amount)}</Typography>
              <Typography>
                {payment?.currencySymbols ? payment?.currencySymbols : "₹"}{" "}
                {payment?.orderValueOv
                  ? payment?.orderValueOv
                  : payment?.orderValue}{" "}
                /-
              </Typography>
            </Box>
          </ListItem>
          {/* { orderType.chadhava !== payment?.orderType && (
            <>
              <ListItem key={35}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography>Delivery</Typography>
                  <Typography>₹{payment?.priceBreakup?.delivery}/-</Typography>
                </Box>
              </ListItem>
              <ListItem key={39}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography>GST</Typography>
                  <Typography>₹{payment?.priceBreakup?.gst}/-</Typography>
                </Box>
              </ListItem>
            </>
          )} */}
        </List>
      </Grid>
    </>
  );
};

const SuccessView: React.FC<any> = ({ data }) => {
  const navigate = useNavigate();
  const navigaeToPage = () => {
    navigate("/");
  };

  const [title, setTitle] = useState(" ");
  const [message, setMessage] = useState(" ");
  const [type, setType] = useState(" ");
  const [restOfData, setTestOfData] = useState<any>({});
  useEffect(() => {
    if (data?.title) {
      setTitle(data.title);
    }
    if (data?.message) {
      setMessage(data.message);
    }
    if (data?.type) {
      setType(data.type);
    }
    if (data) {
      setTestOfData(data);
    }
  }, [data]);

  return (
    <Box className="pageContainer successPage">
      <Box className="successBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3}>
            {/* <LeftBlockCard /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="successCenterBlock">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className="orderSuccessPage"
              >
                <Grid item xs={12} className="iconContainer">
                  {/* <CheckCircleOutlineIcon className="icon" /> */}
                  <img src={successIcon} alt="" />
                </Grid>
                <Grid item xs={12} className="paymentStatus">
                  <Typography variant="h5" align="center" gutterBottom>
                    {title}
                  </Typography>
                  <Typography component="p" align="center" gutterBottom>
                    {message}
                  </Typography>
                </Grid>
                {type === "payment" ? (
                  <PaymentBlock payment={restOfData} />
                ) : (
                  <Box sx={{ height: "200px" }}></Box>
                )}

                <Grid item xs={12} className="bottomBtn">
                  <Button variant="outlined" onClick={(e) => navigaeToPage()}>
                    {t(LOCALIZATION.OK)}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3}>
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SuccessView;
