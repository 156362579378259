import PujaView from "../../Views/Puja/PujaView";
import ParentView from "../../Views/PrarentView/ParentView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
function PujaScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const {cartInfo} = useSelector((state: any) => state.cart);
  const {allTempleList,allPujaList} = useSelector((state: any) => state.temple);
  const [pujaList,setPujaList]:any = useState([])
  const [listToshow, setListToShow]: any = useState([]);

  const onClickAddPuja = (pujaDetails: any) => {
    // dispatch(setSelectedPuja(pujaDetails))
    // navigate("/checkout/puja-details");
    navigate(`/templePujaInfo?templeNameKey=${pujaDetails.nameKey}&pujaNameKey=${pujaDetails?.pujaDetails?.pujaNameKey}`)
    // navigate(`/templeInfo/${pujaDetails.nameKey}/pujaData/${pujaDetails?.pujaDetails?.pujaNameKey}`)
  };

  const selectTemple = (templeDetails:any)=>{
    navigate("/templePujaList/"+templeDetails.id)
  }

  useEffect(()=>{
    if(allPujaList&&allPujaList.length){
      makeLisIndexForSearch(allPujaList)
    }

  },[allPujaList])

  const makeLisIndexForSearch = (allPujaList:any) => {
    let finalList: any = [];
    for (let i = 0; i < allPujaList.length; i++) {
      let searchString = "";

      const pujaName = allPujaList[i]?.pujaDetails?.pujaName;
      const templeName = allPujaList[i]?.personalInfo.name;
      const templeAddress =
      allPujaList[i]?.personalInfo?.address?.formatted_address;
      searchString = pujaName + templeName + templeAddress;
      finalList = [
        ...finalList,
        { ...allPujaList[i], searchString: searchString },
      ];
      } 
    
    setPujaList(finalList);
    setListToShow(sortPujaList(finalList));

  };

  const searchPujaForUser = (searchInput: any) => {
    const userInputLC = searchInput.toLowerCase();

    let visibleList: any = [];
    for (let i = 0; i < pujaList.length; i++) {
      const searchStrinLC =
      pujaList[i] && pujaList[i].searchString
          ? pujaList[i].searchString.toLowerCase()
          : "";
      if (searchStrinLC.includes(userInputLC)) {
        visibleList = [...visibleList, pujaList[i]];
      }
    }

    setListToShow(sortPujaList(visibleList));
  };

   const sortPujaList = (params:any)=>{
    return params;
    //return params?.sort((a:any, b:any) => (a.pujaDetails.pujaNameKey === 'Surya-Puja-Pr' ? -1 : 1));
   }

   
    return (
      <ParentView>
        <PujaView allPujaList={listToshow} searchPujaForUser={searchPujaForUser} selectTemple={selectTemple} cartDetails={cartInfo} onClickAddPuja={onClickAddPuja}/>
      </ParentView>
    );
  }
  
  export default PujaScreen;
  