import { orderType } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { getTempleDetailsByNameKey } from "../../store/actions/templeAction";
import {
  dayNameToNumber,
  getOneImageForTemple,
  uid,
} from "../../utils/HelperFunctions";
import ParentView from "../../Views/PrarentView/ParentView";
import dayjs from "dayjs";
import { updateCartAction } from "../../store/actions/cartAction";
import PrasadDetailsView from "../../Views/Prasad/PrasadDetailsView";
import { getPrasadForTempleList } from "../../store/actions/prasadAction";

function PrasadDetailsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { selectedTempleDetails } = useSelector((state: any) => state.temple);
  const { templePrasadList } = useSelector((state: any) => state.prasad);
  const [localRestrictionChadavaDate, setLocalRestrictionChadavaDate] =
    useState<string[]>([]);
  const [templePersonalInfo, setTemplePersonalInfo] = useState<any>({});
  const [templeId, setTempleId] = useState<string>("");
  const [prasadList, setprasadList] = useState<any>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPriceDisplay, setTotalPriceDisplay] = useState<number>(0);
  const [selectedPrasad, setSelectedPrasad] = useState<any>([]);
  const [isDateTimePicker, setIsDateTimePicker] = useState(false);
  const [filteredprasadList, setFilteredprasadList] = useState(false);
  const [firstSelectedItemDate, setFirstSelectedItemDate] = useState("");
  const [pujaDateAndTime, setPujaDateAndTime] = useState<any>(null);
  const [popupItemAlreadyAddedInCart, setPopupItemAlreadyAddedInCart] =
    useState(false);
  const { cartInfo } = useSelector((state: any) => state.cart);
  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const {
    rate = 1,
    currencySymbols = "₹",
    isOverseas = false,
    countryCode = null,
    platformFeeForChadhava,
  } = priceConversionState.priceConversionRate || {};
  const { isLoading } = priceConversionState;

  useEffect(() => {
    if (templePrasadList?.length > 0) {
      const updatedResponse = templePrasadList.map((item: any) => ({
        ...item,
        count: 0,
        disabled: false,
      }));
      setprasadList(updatedResponse);
    }
  }, [templePrasadList]);

  useEffect(() => {
    const templePersonalInfo =
      selectedTempleDetails && selectedTempleDetails.personalInfo
        ? selectedTempleDetails.personalInfo
        : {};
    setTemplePersonalInfo(templePersonalInfo);
  }, [selectedTempleDetails]);

  useEffect(() => {
    const templeId = location.pathname.split("/")[2];
    setTempleId(templeId);
  }, []);

  const templeProfilePic = () => {
    let imageArrayForTemple =
      templePersonalInfo &&
      templePersonalInfo.media &&
      templePersonalInfo.media.pictures &&
      templePersonalInfo.media.pictures.length
        ? templePersonalInfo.media.pictures
        : [];
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple);
    return templeProfilePic;
  };

  const goToBookPujaScreen = () => {
    navigate("/templePujaList/" + templeId);
  };

  useEffect(() => {
    if (templeId) {
      dispatch(getTempleDetailsByNameKey(templeId));
      dispatch(
        getPrasadForTempleList(templeId, false, platformFeeForChadhava, 1)
      )
        .then((response: any) => {
          if (typeof response === "undefined") {
            navigate("/prasadTemples");
          }
        })
        .catch((error: any) => {
          navigate("/prasadTemples");
        });
    }
  }, [templeId, priceConversionState.priceConversionRate]);

  const navigateToPujaStepsView = () => {
    navigate("/pujaSteps");
  };
  const navigateToSeeVideoView = () => {
    navigate("/pujaVideo");
  };

  // const handleSetChadhawaPrice = (item: any) => {
  //   const price = item?.count * item?.acctualPrice;
  //   setTotalPrice((prevPrice) => prevPrice + price);
  //   setSelectedPrasad((prev: any) => [item, ...prev]);

  //   setprasadList((prev: any) =>
  //     prev.map((chadhava: any) => (chadhava.id === item.id ? item : chadhava))
  //   );
  // };
  const handleIncrement = (item: any) => {
    setprasadList((prev: any) =>
      prev.map((chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count + 1;
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      })
    );
  };

  const getCalculateTotalPrice = (items: any[]): number => {
    return items.reduce((total, item) => {
      const itemTotal = item.count * Number(item?.priceInfo?.finalPrice);
      return total + itemTotal;
    }, 0);
  };
  // const getCalculateTotalPriceDisp = (items: any[]): any => {
  //   const chadhavaTotalValue = items.reduce((total, item) => {
  //     const itemTotal = Number(
  //       item.count * Number(item?.priceInfo?.finalPriceOv)
  //     ).toFixed(2);

  //     return Number((total + Number(itemTotal)).toFixed(2));
  //   }, 0);

  //   return chadhavaTotalValue;
  // };
  useEffect(() => {
    const selectedPrasad = prasadList.filter((item: any) => item?.count > 0);
    setTotalPrice(getCalculateTotalPrice(selectedPrasad));
    // setTotalPriceDisplay(getCalculateTotalPriceDisp(selectedPrasad));
  }, [prasadList]);

  const preprocessingAndProceedToAddmemberScreen = () => {
    // const formattedDateToSave = getDateFormatedForMUISelection(
    //   pujaDateAndTime?.pujaDate?.toString()
    // );
    // let dateTimeData = {
    //   pujaDate: formattedDateToSave,
    //   pujaTime: "10:30AM",
    // };

    let cartDataPayload: any = {
      id: uid(),
      members: [],
      selectedChadhvaList: filteredprasadList,
      templeDetails: selectedTempleDetails,
      pujaDateTime: null,
      price: totalPrice,
      orderType: orderType.prasad,
    };

    let newCartData = [];
    newCartData.push(cartDataPayload);
    dispatch(updateCartAction(newCartData));
    navigate("/checkoutV2/addDevotee");
  };

  useEffect(() => {
    setFilteredprasadList(prasadList);
  }, [prasadList]);

  const addChadhavaItem = (el: any) => {
    if (localRestrictionChadavaDate?.length > 0) {
      const newDates = localRestrictionChadavaDate.filter((item) =>
        el?.restrictionPrasadDate.includes(item)
      );
      setLocalRestrictionChadavaDate(newDates);
    } else {
      setLocalRestrictionChadavaDate(el?.restrictionPrasadDate);
    }
    handleIncrement(el);
  };
  const [needToSetLocalDate, setNeedToSetLocalDate] = useState(false);
  const handleDecrement = (item: any) => {
    setprasadList((prev: any) =>
      prev.map((chadhava: any) => {
        if (chadhava.id === item.id) {
          const updatedCount = chadhava.count > 0 ? chadhava.count - 1 : 0;
          if (updatedCount === 0) {
            setNeedToSetLocalDate(true);
          }
          return { ...chadhava, count: updatedCount };
        } else {
          return chadhava;
        }
      })
    );
  };

  const getAvilableChadhavaDate = (): string => {
    return (
      "This chadhava item is not available on the given date " +
        localRestrictionChadavaDate?.toString() || ""
    );
  };

  const shouldDisableDate = (date: any): any => {
    const restrictionDate: any = localRestrictionChadavaDate;
    if (
      typeof restrictionDate === "undefined" ||
      restrictionDate?.length === 0
    ) {
      return false;
    }

    const dayNames: string[] = restrictionDate?.filter((item: any) =>
      isNaN(Date.parse(item))
    );

    const selectedDate = dayjs();
    const twoDaysBeforeSelected = selectedDate.add(3, "day");
    if (date.isBefore(twoDaysBeforeSelected, "day")) {
      return true;
    }

    const specificDates: Date[] = restrictionDate
      ?.filter((item: any) => !isNaN(Date.parse(item)))
      ?.map((dateString: any) => new Date(dateString));
    const disableWeekDays: number[] = dayNames
      ?.map(dayNameToNumber)
      ?.filter((day) => day !== null) as number[];
    const day: number = date.day();
    if (disableWeekDays?.includes(day)) {
      return false;
    }

    return !specificDates?.some(
      (disabledDate) =>
        date?.date() === disabledDate?.getDate() &&
        date?.month() === disabledDate?.getMonth() &&
        date?.year() === disabledDate?.getFullYear()
    );
  };

  return (
    <ParentView>
      <PrasadDetailsView
        templeDetails={selectedTempleDetails}
        templePersonalInfo={templePersonalInfo}
        goToBookPujaScreen={goToBookPujaScreen}
        templeProfilePic={templeProfilePic}
        navigateToPujaStepsView={navigateToPujaStepsView}
        navigateToSeeVideoView={navigateToSeeVideoView}
        prasadList={prasadList}
        selectedPrasad={selectedPrasad}
        totalPrice={totalPrice}
        isDateTimePicker={isDateTimePicker}
        setIsDateTimePicker={setIsDateTimePicker}
        pujaDateAndTime={pujaDateAndTime}
        setPujaDateAndTime={setPujaDateAndTime}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        filteredprasadList={filteredprasadList}
        preprocessingAndProceedToAddmemberScreen={
          preprocessingAndProceedToAddmemberScreen
        }
        shouldDisableDate={shouldDisableDate}
        firstSelectedItemDate={firstSelectedItemDate}
        getAvilableChadhavaDate={getAvilableChadhavaDate}
        addChadhavaItem={addChadhavaItem}
        cartInfo={cartInfo}
        popupItemAlreadyAddedInCart={popupItemAlreadyAddedInCart}
        setPopupItemAlreadyAddedInCart={setPopupItemAlreadyAddedInCart}
        currencySymbols={"₹"}
        totalPriceDisplay={totalPriceDisplay}
        isOverseas={isOverseas}
        isCountryDisabledForBooking={
          countryCode === null || countryCode !== "IN" ? true : false
        }
        isLoading={isLoading}
      />
    </ParentView>
  );
}

export default PrasadDetailsScreen;
