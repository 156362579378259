import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ManagePuja.scss";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import ManagePujaFormModal from "./ManagePujaForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface ManagePujaViewProps {
  temples: any[];
  setSelectedTemple: any;
  pujaListAdmin: any[];
  savePujaItem: (temple: any) => any;
  benefitsList: any[];
  selectedTempleId: string;
  deletePujaItem: (item: any) => void;
  pujaMergedSubCategoryList: any[];
  selectedItem: any;
  setSelectedItem: (item: any) => any;
  isFormSubmit: boolean;
}

const ManagePujaView: React.FC<ManagePujaViewProps> = ({
  temples,
  setSelectedTemple,
  pujaListAdmin,
  savePujaItem,
  benefitsList,
  selectedTempleId,
  deletePujaItem,
  pujaMergedSubCategoryList,
  selectedItem,
  setSelectedItem,
  isFormSubmit,
}) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleEditItem = (item: any) => {
    setSelectedItem(item);
    setIsFormModalOpen(true);
  };

  const handleAddItem = () => {
    setSelectedItem(null);
    setIsFormModalOpen(true);
  };
  const handleSavePujaItem = (temple: any) => {
    savePujaItem(temple)
      .then(() => {
        setIsFormModalOpen(false);
      })
      .catch(() => {
        setIsFormModalOpen(false);
      });
  };

  const handleDeleteClick = (item: any) => {
    setIsDeleteDialogOpen(true);
    setSelectedItem(item);
  };

  const handleCloseDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };

  const handleConfirmDelete = () => {
    deletePujaItem(selectedItem);
    setIsDeleteDialogOpen(false);
  };

  return (
    <Box className="pageContainer managePujaAdmin">
      <Box sx={{ textAlign: "center" }}>
        <Typography className="dpDnText">Puja Onboarding for Temple</Typography>
      </Box>
      <Stack
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Autocomplete
          options={temples}
          getOptionLabel={(option) => option?.personalInfo?.name}
          style={{ width: 300 }}
          onChange={(event, newValue) => setSelectedTemple(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose Temples" variant="outlined" />
          )}
        />
        <Box>
          {selectedTempleId && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="flex-end"
              spacing={2}
            >
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleAddItem();
                }}
              >
                <AddCircleOutlineIcon
                  sx={{ fontSize: "20px", marginRight: "5px" }}
                />{" "}
                Add Puja Item
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>

      {pujaListAdmin && pujaListAdmin?.length > 0 && (
        <List className="chadhavaItemListAdmin">
          {pujaListAdmin &&
            pujaListAdmin?.map((puja: any, index: number) => (
              <ListItem
                key={`listItem ${puja?.pujaId}`}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a", // Different color for odd items
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={puja?.pujaBg?.uri}
                    sx={{
                      width: 80,
                      height: 80,
                      marginRight: 2,
                      borderRadius: "10px",
                      //border: "none"
                      border: "1px solid #cdcdcd",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  className="listText"
                  primary={puja?.pujaName}
                  secondary={
                    <>
                      <span className="templeDesc">
                        {puja?.itemDescription}
                      </span>

                    </>
                  }
                />
                <IconButton
                  sx={{
                    background: `linear-gradient(-45deg, rgba(0, 0, 0, 0) 33%, hsla(0, 0%, 100%, 0.5) 50%, rgba(0, 0, 0, 0) 66%), 
                  linear-gradient(-45deg, #d8a820, #d66f35)`,
                    backgroundSize: "250% 250%, 100% 100%",
                    animation: "gradient 15s ease infinite",
                    "@keyframes gradient": {
                      "0%": { backgroundPosition: "0% 50%" },
                      "50%": { backgroundPosition: "100% 50%" },
                      "100%": { backgroundPosition: "0% 50%" },
                    },
                    color: "white",
                    marginRight: "8px",
                  }}
                  aria-label="edit"
                  onClick={(e) => {
                    handleEditItem(puja);
                  }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    transition: "0.5s ease",
                    "&:hover": {
                      backgroundColor: "#af1919",
                    },
                  }}
                  aria-label="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteClick(puja);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </ListItem>
            ))}
        </List>
      )}

      <ManagePujaFormModal
        isOpen={isFormModalOpen}
        pujaInfo={selectedItem}
        benefitsList={benefitsList}
        onClose={() => setIsFormModalOpen(false)}
        onSave={handleSavePujaItem}
        pujaMergedSubCategoryList={pujaMergedSubCategoryList}
        isFormSubmit={isFormSubmit}
      />

      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete item?"
      />
    </Box>
  );
};

export default ManagePujaView;
