import React from "react";
import { Box, Grid, Paper } from "@mui/material";
import TempleOnboardingForm from "./TempleOnboardingForm";
import "./TempleOnboardingView.scss";

interface TempleOnboardingViewProps {
  handleBackClick: () => void;
  onSubmit: (data: any,isTempleVerified:boolean) => void;
  editMode: string;
  singleTempleData: any;
}

const TempleOnboardingView: React.FC<TempleOnboardingViewProps> = ({
  onSubmit,
  editMode,
  handleBackClick, 
  singleTempleData,
}) => {


  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={2} lg={1} xl={1}></Grid>
      <Grid item xs={12} md={8} lg={10} xl={10}>
        <Paper className="manageTempleOnboardContainer">
          <Box className="templeOnboardContainer">
            <TempleOnboardingForm
              handleBack={handleBackClick}
              onSubmit={onSubmit}
              editMode={editMode}
              singleTempleData={singleTempleData}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TempleOnboardingView;
