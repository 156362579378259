import footerLogo from "../../assets/footerlogo.png";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ReactComponent as LiveCamImg } from "../../assets/live-darshan.svg";
import { ReactComponent as BlogIconImg } from "../../assets/blog-icon.svg";
import "./Footer.scss";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function Footer(props: any) {
  const { cartInfo } = useSelector((state: any) => state.cart);
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const { isToShowBottomNavForMobile = false } = props;
  return (
    <Box className="footerArea">
      <Box className="lgFooter" sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} className="lgFtrmainGrid">
          <Grid item xs={0} sm={0} md={3} className="lgLtGrid"></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={2} className="lgMainGrid">
              <Grid item xs={12} sm={4} md={4}>
                <Box className="wiget fsec1">
                  <img src={footerLogo} alt="" />
                  {/* <p>It will take you to a new spiritual experience.</p>
                  <p>Elevate your spiritual journey with Temple Dekho.</p> */}

                  <List className="companyInfoList">
                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-house me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary={t(LOCALIZATION.Footer_address)}
                      />
                    </ListItem>

                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-phone me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary="+91 90733 00055"
                      />
                    </ListItem>

                    <ListItem className="infoListItem">
                      <ListItemAvatar className="iconSec">
                        <i className="bi bi-envelope me-2"></i>
                      </ListItemAvatar>
                      <ListItemText
                        className="textSec"
                        primary="support@templedekho.com"
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={2}
                className="col-sm-5 col-6 lgCentGrid"
              >
                <Box className="wiget fsec2">
                  <h6>{t(LOCALIZATION.Links)}</h6>
                  <Box className="menuFooter">
                    <Link to="/">{t(LOCALIZATION.Home)}</Link>
                    <Link to="/temples">{t(LOCALIZATION.Temples)}</Link>
                    <Link to="/pujas">{t(LOCALIZATION.Pujas)}</Link>
                    <Link to="/livedarshan">{t(LOCALIZATION.Darshan)}</Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Box className="wiget fsec3">
                  <h6>{t(LOCALIZATION.About)}</h6>
                  <Box className="menuFooter">
                    <Box className="bottomMenuList2">
                      <ul>
                        <li>
                          <a href="/about">{t(LOCALIZATION.About_Us)}</a>
                        </li>
                        <li>
                          <a href="/contactus">{t(LOCALIZATION.Contact_Us)}</a>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={0} sm={0} md={4} className="lgRtGrid">
                <Box className="wiget fsec3">
                  <h6>{t(LOCALIZATION.Information)}</h6>
                  <div className="bottomMenuList2">
                    <ul>
                      {/* <li>
              <a href="#">
                <InsertCommentIcon />
                Feedback
              </a>
            </li> */}
                      <li>
                        <a href="/refundscancellations">
                          {/* <PaymentsIcon /> */}
                          {t(LOCALIZATION.Refunds_and_Cancellations)}
                        </a>
                      </li>

                      <li>
                        <a href="/communityguidelines">
                          {/* <LocalLibraryIcon /> */}
                          {t(LOCALIZATION.Content_Community_Guidlines)}
                        </a>
                      </li>

                      <li>
                        <a href="/privacypolicy">
                          {/* <PolicyIcon /> */}
                          {t(LOCALIZATION.Privacy_Policy)}
                        </a>
                      </li>
                      <li>
                        <a href="/termsandconditions">
                          {/* <GavelIcon /> */}
                          {t(LOCALIZATION.Terms_and_Conditions)}
                        </a>
                      </li>

                      {/* <li>
              <a href="#">
                <SettingsIcon />
                Settings
              </a>
            </li> */}
                    </ul>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={0} md={3} className="lgLtGrid"></Grid>
        </Grid>
        <Typography className="copyright-text">
          &#169; Copyright {currentYear},{" "}
          <a href="/privacypolicy">Temple Dekho</a> All Rights Reserved.
        </Typography>
      </Box>
      {isToShowBottomNavForMobile ? (
        <Box className="smFooter">
          <Grid container spacing={3} className="footerMenu">
            <Grid item xs={3} className="menuItems">
              <NavLink to="/">
                <HomeIcon />
                <span>{t(LOCALIZATION.Home)}</span>
              </NavLink>
            </Grid>
            {/* <Grid item xs={3} className="menuItems">
              <NavLink to="/search">
                <SearchIcon />
                <span>Search</span>
              </NavLink>
            </Grid> */}
            <Grid item xs={3} className="menuItems">
              <NavLink to="/posts">
                <BlogIconImg className="blogIcon" />
                <span>{t(LOCALIZATION.Blog)}</span>
              </NavLink>
            </Grid>
            <Grid item xs={3} className="menuItems">
              <NavLink to="/livedarshan">
                <LiveCamImg className="darshanIcon" />
                <span>{t(LOCALIZATION.Darshan)}</span>
              </NavLink>
            </Grid>
            <Grid item xs={3} className="menuItems">
              <Box className="cartBlock">
                <NavLink to="/checkoutV2/reviewCart">
                  <Box className="smCartIcon">
                    <ShoppingCartIcon />
                    {cartInfo && cartInfo.length > 0 ? (
                      <span className="translate-middle badge rounded-pill smBadge">
                        {cartInfo.length}
                      </span>
                    ) : null}
                  </Box>
                  <span>{t(LOCALIZATION.Cart)}</span>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </Box>
  );
}

export default Footer;
