import React, { useEffect } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
import RefundsCancellationsView from "../../Views/RefundsCancellations/RefundsCancellationsView";

const RefundsCancellationsScreen: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <ParentView>
      <RefundsCancellationsView />
    </ParentView>
  );
};

export default RefundsCancellationsScreen;
