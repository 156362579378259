import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
  TextField,
  Stack,
  CardMedia,
  CardContent,
  Card,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CardHeader,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  capitalizeWords,
  getDateFormatedOrderTime,
  splitCamelCase,
  formatEmptyValue,
} from "../../../utils/HelperFunctions";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import "./styles.scss";
import { ANALYTICS_STATUS } from "../../../utils/Constants";
import { updateAnalyticsStatusAction } from "../../../store/actions/AnalyticsAction";
import { calculateActualPujaPrice } from "../../../utils/PriceCalculation";
import { setAnalyticsList } from "../../../store/slices/analyticsSlice";

interface TempleCardProps {
  imageUrl: string;
  templeList: any[];
  pujaName: string;
}

const TempleCard: React.FC<TempleCardProps> = ({
  imageUrl,
  templeList,
  pujaName,
}) => {
  return (
    <Card className="promotionPujaCard">
      <CardMedia
        component="img"
        height="auto"
        image={imageUrl}
        alt="Temple Image"
      />
      <CardContent>
        <Typography variant="body1" sx={{ mb: "5px" }}>
          <span style={{ fontWeight: "bold" }}> Puja Name </span> : {pujaName}
        </Typography>
        <Typography gutterBottom variant="body1">
          <span style={{ fontWeight: "bold" }}>Temples List</span>
        </Typography>

        <Grid container spacing={1} sx={{ paddingLeft: "8px" }}>
          {templeList.map((temple, index) => (
            <Grid item xs={12} key={index}>
              <Typography
                variant="body2"
                className="promotionPujaTemple"
                color="textSecondary"
                component="p"
              >
                <CircleIcon />
                {temple.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

interface AnalyticsModelProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  dateRange: any;
}

function getKeyByValue(
  object: { [key: string]: string },
  value: string
): string | undefined {
  return Object.keys(object).find((key: string) => object[key] === value);
}

const MembersTable: React.FC<any> = ({ membersData }) => {
  return (
    <TableContainer component={Paper} className="tableContents">
      <Table className="infoTable">
        <TableHead>
          <TableRow>
            <TableCell>Full Name</TableCell>
            <TableCell>Date of Birth</TableCell>
            <TableCell>Birth Location</TableCell>
            <TableCell>Gender</TableCell>
            <TableCell>Rashi</TableCell>
            <TableCell>Gotram</TableCell>
            <TableCell>Puja Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {membersData &&
            membersData.map((member: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{member?.fullName}</TableCell>
                <TableCell>{member?.dateOfBirth}</TableCell>
                <TableCell>
                  {member?.birthLocation ? member.birthLocation : " "}
                </TableCell>
                <TableCell>{member?.gender}</TableCell>
                <TableCell>{member?.rashi}</TableCell>
                <TableCell>{member?.gotram ? member.gotram : " "}</TableCell>
                <TableCell>{member?.pujaName}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const AnalyticsModel: React.FC<AnalyticsModelProps> = ({
  isOpen,
  onClose,
  data,
  dateRange,
}) => {
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    null
  );
  var { userInfo } = useSelector((state: any) => state.user);
  var { analyticsList } = useSelector((state: any) => state.analytics);
  const dispatch: any = useDispatch();

  const [selectedOption, setSelectedOption] = useState<string>("");

  const [remark, setRemark] = useState<string>("");
  const handleRemakeChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setRemark(selectedValue);
  };
  const handleStatusChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setSelectedOption(selectedValue);
  };

  const changeStatus = async (event: any) => {
    if (selectedOption.length === 0) {
      alert("Please select Analytics Status");
      return true;
    }
    const eventKey: any = getKeyByValue(ANALYTICS_STATUS, selectedOption);
    const formDataObject = {
      event: {
        [eventKey]: selectedOption,
      },
      updatedBy: {
        name:
          userInfo.personalInfo.firstName +
          "  " +
          userInfo?.personalInfo?.lastName,
        userId: userInfo.id,
        remark: remark,
      },
      dateUTC: new Date().toUTCString(),
    };

    try {
      const userId =
        data && data.eventData && data.eventData.userId
          ? data.eventData.userId
          : null;
      removeUpdatedUserFromView(data);
      await dispatch(
        updateAnalyticsStatusAction(formDataObject, userId, dateRange)
      ).then((e: any) => {
        setSelectedOption("");
        setRemark("");
        onClose();
      });
    } catch (error) {}
  };

  const removeUpdatedUserFromView = (data: any) => {
    const userId = data.eventData?.userId;
    var updatedAnalyticsList = [];
    for (let i = 0; i < analyticsList.length; i++) {
      if (analyticsList[i].eventData?.userId === userId) {
      } else {
        updatedAnalyticsList.push(analyticsList[i]);
      }
    }
    dispatch(setAnalyticsList(updatedAnalyticsList));
  };

  const renderSelectedPromotionPujaItem = (data: any[]) => {
    return (
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <span>
            <b>Selected Promotion Puja Item</b>
          </span>
        </Grid>
        {data &&
          data?.map((item, index) => (
            <Grid item xs={6} key={index}>
              <TempleCard
                imageUrl={item?.dashBoardBg?.uri}
                templeList={item.templeList}
                pujaName={item.pujaName}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  const renderCartInfo = (data: any) => {
    return (
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Cart Information</Typography>
        </Grid>
        {data &&
          data.length &&
          data?.map((cartItem: any, index: number) => (
            <Grid item xs={12}>
              <Card key={index} className="cartInfoCard">
                <CardHeader
                  title={`Item ${index + 1}`}
                  className="cartInfoCardHeader"
                />
                <CardContent className="infoCardBody">
                  <Box className="member-details">
                    <Typography variant="h6">Puja Details</Typography>
                    <TableContainer component={Paper} className="tableContents">
                      <Table
                        aria-label="puja-member-details-table"
                        className="infoTable"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableHeaderCell">
                              Temple Name
                            </TableCell>
                            <TableCell className="tableHeaderCell">
                              Puja Name
                            </TableCell>
                            <TableCell className="tableHeaderCell">
                              Selected Date For Puja
                            </TableCell>
                            <TableCell className="tableHeaderCell">
                              Puja Price Including GST@18%
                            </TableCell>
                            <TableCell className="tableHeaderCell">
                              Promotional Puja?
                            </TableCell>
                            <TableCell className="tableHeaderCell">
                              For Family?
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={index}>
                            <TableCell>
                              {formatEmptyValue(
                                cartItem?.templeDetails?.personalInfo?.name
                              )}
                            </TableCell>

                            <TableCell>
                              {formatEmptyValue(
                                cartItem?.pujaDetails?.pujaName
                              )}
                            </TableCell>

                            <TableCell>
                              {formatEmptyValue(
                                `${
                                  cartItem?.date
                                    ? cartItem?.date
                                    : cartItem?.pujaDateTime?.pujaDate
                                }`
                              )}
                            </TableCell>

                            <TableCell>
                              {calculateActualPujaPrice(
                                cartItem?.pujaDetails?.acctualPrice
                              )}
                            </TableCell>
                            <TableCell>
                              {formatEmptyValue(
                                cartItem?.pujaDetails?.isPromotion.toString()
                              )}
                            </TableCell>
                            <TableCell>
                              {cartItem?.pujaDetails?.isForFamily.toString()}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <div className="member-details mt-4">
                    <Typography variant="h6">Members Details</Typography>
                    {cartItem && cartItem?.membersData && (
                      <MembersTable membersData={cartItem?.membersData} />
                    )}
                    {cartItem && cartItem?.members && (
                      <MembersTable membersData={cartItem?.members} />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  };

  useEffect(() => {
    if (data?.status && data?.status.length > 0) {
      let statusKey = Object.keys(
        data?.status[data?.status?.length - 1]?.event
      )[0];
      let statusValPrev =
        data?.status[data?.status?.length - 1]?.event[statusKey];

      setSelectedOption(statusValPrev);
      setRemark(data?.status[data?.status?.length - 1]?.updatedBy?.remark);
    }
  }, [data]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setSelectedOption("");
        setRemark("");
        onClose();
      }}
      aria-labelledby="change-status-title"
      className="analyticsModal"
    >
      <Box className="modalContents infoModal">
        <Stack className="modalHeader">
          <Typography id="change-status-title" variant="h6" gutterBottom>
            {capitalizeWords(data?.key ? data?.key : "")}
          </Typography>
          <Button
            onClick={() => {
              setSelectedOption("");
              setRemark("");
              onClose();
            }}
            variant="outlined"
            className="btnClose"
          >
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <Grid
            container
            direction="column"
            spacing={2}
            className="mainDetailList"
          >
            <Grid className="orderInfoText" item md={12}>
              <Box className="orderInfoLabel">
                <Typography variant="body1">
                  <CalendarMonthRoundedIcon />
                  <span style={{ fontWeight: "bold" }}> Date </span> :
                  {getDateFormatedOrderTime(data.dateUTC)}
                </Typography>

                {data?.eventData &&
                  Object.entries(data?.eventData).map((item: any, index) => {
                    const containsObject = item.some(
                      (localItem: any) => typeof localItem === "object"
                    );

                    if (containsObject) {
                      if (containsObject && item[0] === "currentAddress") {
                        return (
                          <Typography variant="body1" key={index}>
                            <PhoneRoundedIcon />
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {splitCamelCase(item[0])}{" "}
                            </span>{" "}
                            : {item[1]?.formatted_address}
                          </Typography>
                        );
                      }
                      return null;
                    }
                    return (
                      <Typography variant="body1" key={index}>
                        <LabelOutlinedIcon />
                        <span style={{ fontWeight: "bold" }}>
                          {" "}
                          {splitCamelCase(item[0])}{" "}
                        </span>{" "}
                        : {item[1]}
                      </Typography>
                    );
                  })}
              </Box>
              <Box className="pujaMemberDetails">
                {data?.data &&
                  Object.entries(data?.data).map((item: any, index) => (
                    <Typography variant="body1" key={index}>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {splitCamelCase(item[0])}{" "}
                      </span>{" "}
                      : {item[1]}
                    </Typography>
                  ))}

                {data?.selectedPromotionPujaItem &&
                  renderSelectedPromotionPujaItem(
                    data?.selectedPromotionPujaItem
                  )}
                {data?.cartInfo &&
                  data?.cartInfo?.length > 0 &&
                  renderCartInfo(data?.cartInfo)}
              </Box>
              <Typography variant="h6" key={"Status010"}>
                {" "}
                {"Status Modified By :"}
              </Typography>

              {data?.status &&
                Object.entries(
                  data?.status[data?.status?.length - 1].updatedBy
                ).map((item: any, index) => {
                  if (item[0] === "remark") {
                    return null;
                  }
                  return (
                    <Typography variant="body1" key={index}>
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        {splitCamelCase(item[0])}{" "}
                      </span>{" "}
                      : {item[1]}
                    </Typography>
                  );
                })}
            </Grid>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-helper-label"
                  value={selectedOption}
                  displayEmpty
                  onChange={handleStatusChange}
                >
                  <MenuItem value="">---Select Status---</MenuItem>
                  {Object.entries(ANALYTICS_STATUS).map(
                    (analyticsItem: any, index) => {
                      return (
                        <MenuItem
                          value={analyticsItem[1]}
                          key={analyticsItem[1]}
                        >
                          {splitCamelCase(analyticsItem[0])}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={12}>
              <TextField
                label="Remarks"
                multiline
                fullWidth
                value={remark}
                rows={5}
                onChange={handleRemakeChange}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="modalFooter">
          <Button
            className="btnCancel grey"
            onClick={() => {
              setSelectedOption("");
              setRemark("");
              onClose();
            }}
          >
            Close
          </Button>
          <Button
            className="bottomSubmit"
            variant="outlined"
            onClick={changeStatus}
          >
            Change Status
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AnalyticsModel;
