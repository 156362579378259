import React from 'react';
import ParentView from '../../Views/PrarentView/ParentView';
import OffLineView from '../../Views/OffLineView/OffLineView';

const OffLineScreen: React.FC = () => {
  

    return (
        <ParentView>
            <OffLineView />
        </ParentView>
    );
};

export default OffLineScreen;
