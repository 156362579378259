import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Dialog, InputAdornment, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import TitleTypography from "../../../../components/typography/TitleTypography";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import RoundedButton from "../../../../components/button/RoundedButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getFormattedDate } from "../../../../utils/HelperFunctions";
import { LOCALIZATION } from "../../../../utils/localization";
import { t } from "i18next";

export default function PersonalInfoView(props: any) {
  const {
    setPersonalInfoEditMode,
    cancelUpdate,
    userPersonalInfo,
    personalInfoEditMode,
    setUserPersonalInfo,
    phoneNumberNonEditable,
    setDatePickerOpen,
    submitUpdate,
    datePickerOpen,
  } = props;
  return (
    // <Box className="personalInfo">
    //   <Box className="personalInfoTitle">
    //     <Typography>Personal Information</Typography>
    //     {!props.personalInfoEditMode ? (
    //       <Link onClick={() => props.setPersonalInfoEditMode(true)}>
    //         <i className="bi bi-pencil-fill"></i> Edit Profile
    //       </Link>
    //     ) : (
    //       <Link onClick={() => props.cancelUpdate()}>
    //         Cancel edit
    //       </Link>
    //     )}
    //   </Box>

    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props?.userPersonalInfo?.firstName  ? props?.userPersonalInfo?.firstName : " " }
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           firstName: e.target.value,
    //         });
    //       }
    //     }}
    //     label="First Name"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.userPersonalInfo.lastName}
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           lastName: e.target.value,
    //         });
    //       }
    //     }}
    //     label="Last Name"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.userPersonalInfo.email}
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           email: e.target.value,
    //         });
    //       }
    //     }}
    //     label="Email"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.phoneNumberNonEditable}
    //     label="Mobile Number"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     label="Birth Day"
    //     value={props.userPersonalInfo.dateOfBirth}
    //     onChange={(e) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           dateOfBirth: e.target.value,
    //         });
    //       }
    //     }}
    //     variant="standard"
    //   />

    //   <br></br>
    //   <br></br>
    //   {props.personalInfoEditMode ? (
    //     <Box className="updateBtn">
    //       <Button variant="contained" onClick={() => props.submitUpdate()}>Update profile</Button>
    //     </Box>
    //   ) : null}
    // </Box>
    <Box className="personalInfo">
      <Box className="personalInfoTitle">
        <Typography>{t(LOCALIZATION.Personal_Information)}</Typography>
        {!props.personalInfoEditMode ? (
          <Link onClick={() => setPersonalInfoEditMode(true)}>
            <i className="bi bi-pencil-fill"></i> {t(LOCALIZATION.Edit_Profile)}
          </Link>
        ) : (
          <Link onClick={() => cancelUpdate()}>
            {t(LOCALIZATION.Cancel_edit)}
          </Link>
        )}
      </Box>

      <TextField
        className="inputField"
        id="first-name"
        value={userPersonalInfo?.firstName || ""}
        disabled={!personalInfoEditMode}
        onChange={(e: any) => {
          if (personalInfoEditMode) {
            setUserPersonalInfo({
              ...userPersonalInfo,
              firstName: e.target.value,
            });
          }
        }}
        label={t(LOCALIZATION.First_Name)}
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="last-name"
        value={userPersonalInfo?.lastName || ""}
        disabled={!personalInfoEditMode}
        onChange={(e: any) => {
          if (personalInfoEditMode) {
            setUserPersonalInfo({
              ...userPersonalInfo,
              lastName: e.target.value,
            });
          }
        }}
        label={t(LOCALIZATION.Last_Name)}
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="email"
        value={userPersonalInfo.email || ""}
        disabled={!personalInfoEditMode}
        onChange={(e: any) => {
          if (personalInfoEditMode) {
            setUserPersonalInfo({
              ...userPersonalInfo,
              email: e.target.value,
            });
          }
        }}
        label={t(LOCALIZATION.Email)}
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="mobile-number"
        value={phoneNumberNonEditable || ""}
        label={t(LOCALIZATION.Mobile_Number)}
        variant="standard"
        disabled
        InputProps={{
          readOnly: true,
        }}
      />
      <br />

      <Box>
        <TextField
          style={{ width: "100%" }}
          label={t(LOCALIZATION.Date_of_Birth)}
          className="dateField"
          id="input-with-sx"
          value={userPersonalInfo.dateOfBirth || ""}
          disabled={!personalInfoEditMode}
          onClick={() => {
            if (personalInfoEditMode) {
              setDatePickerOpen(true);
            }
          }}
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CalendarMonthIcon
                  sx={{ color: "action.active", ml: 1, my: 0.5 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <br />
      <br />

      {props.personalInfoEditMode && (
        <Box className="updateBtn">
          <Button variant="contained" onClick={() => submitUpdate()}>
            {t(LOCALIZATION.Update_Profile)}
          </Button>
        </Box>
      )}
      <Dialog
        className="dateTimingSelection"
        onClose={() => setDatePickerOpen(false)}
        open={datePickerOpen}
      >
        <Box className="dateTimePopup">
          <TitleTypography title={t(LOCALIZATION.Select_Date)}>
            {t(LOCALIZATION.Select_Date)}
          </TitleTypography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disableFuture={true}
              onChange={(e: any) => {
                const dateData = getFormattedDate(e.toString());
                if (personalInfoEditMode) {
                  setUserPersonalInfo({
                    ...userPersonalInfo,
                    dateOfBirth: dateData,
                  });
                }
              }}
            />
          </LocalizationProvider>
          <Box className="btnContinue">
            <RoundedButton
              title={t(LOCALIZATION.Continue)}
              onClick={() => {
                setDatePickerOpen(false);
              }}
            ></RoundedButton>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
