import TempleDetailsView from "../../Views/Temple/TempleDetailsView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getTempleDetailsById,
  getTempleDetailsByNameKey,
} from "../../store/actions/templeAction";
import { getOneImageForTemple } from "../../utils/HelperFunctions";
import ParentView from "../../Views/PrarentView/ParentView";
function TempleDetailsScreen(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { selectedTempleDetails } = useSelector((state: any) => state.temple);

  const templePersonalInfo =
    selectedTempleDetails && selectedTempleDetails.personalInfo
      ? selectedTempleDetails.personalInfo
      : {};

  const templeProfilePic = () => {
    let imageArrayForTemple =
      templePersonalInfo &&
      templePersonalInfo.media &&
      templePersonalInfo.media.pictures &&
      templePersonalInfo.media.pictures.length
        ? templePersonalInfo.media.pictures
        : [];
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple);
    return templeProfilePic;
  };
  const goToBookPujaScreen = () => {
    navigate("/templePujas?templeNameKey=" + selectedTempleDetails?.nameKey);
  };
  const goToBookChadhavaScreen = () => {
    navigate("/chadhavaDetails/" + selectedTempleDetails?.nameKey);
  };
  const goToBookPrasadScreen = () => {
    navigate("/prasadDetails/" + selectedTempleDetails?.nameKey);
  };

  useEffect(() => {
    let keyType = props?.keyType;
    if (keyType && keyType === "id") {
      const templeIdComing = location.pathname.split("/")[2];
      dispatch(getTempleDetailsById(templeIdComing))
        .then((res: any) => {
          if(typeof res === 'undefined'){
            navigate('/temples')
          }
        })
        .catch((err: any) => {
          console.log("Error:", err)
          navigate('/temples')
        });
    } else {
      const params = new URLSearchParams(location.search);
      const templeId = params.get("templeNameKey");
      const templeIdComing = templeId;
      dispatch(getTempleDetailsByNameKey(templeIdComing))
        .then((res: any) => {
          if(typeof res === 'undefined'){
            navigate('/temples')
          }
        })
        .catch((err: any) => {
          console.log("Error:", err)
          navigate('/temples')
        });
    }
  }, []);

  return (
    <ParentView>
      <TempleDetailsView
        templeDetails={selectedTempleDetails}
        templePersonalInfo={templePersonalInfo}
        goToBookPujaScreen={goToBookPujaScreen}
        goToBookChadhavaScreen={goToBookChadhavaScreen}
        goToBookPrasadScreen={goToBookPrasadScreen}
        templeProfilePic={templeProfilePic}
      />
    </ParentView>
  );
}

export default TempleDetailsScreen;
