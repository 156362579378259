import { Box, Typography } from "@mui/material";
import React from "react";
import "./NoDataFound.scss";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";

const NoDataFound = () => {
   const { t, i18n } = useTranslation();
  return (
    <Box className="noDataFound">
      <Typography className="noDataFoundText"> {t(LOCALIZATION.No_data_found)}. </Typography>
    </Box>
  );
};

export default NoDataFound;
