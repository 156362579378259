import { FiCalendar } from "react-icons/fi";
import ViewAllDetailsModal from "../../components/modal/ViewAllDetailsModel";
import ChangeStatusModal from "../../components/modal/ChangeStatusModal";
import UpdatePaymentModal from "../../components/modal/UpdatePaymentModal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import OrderTable from "./OrderTable";
import SettlementTable from "./SettlementTable";
import { OrderAdminColumnData, SettelementColumnData } from "./columnsData";
import {
  Heading,
  Box,
  SimpleGrid,
  HStack,
  VStack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import MiniCalendar from "../../components/calendar/MiniCalendar";
import "./analytics.css";
import { useEffect, useRef, useState } from "react";

const UTC_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

function AdminView({
  allTempleList,
  allOrder,
  settlementsOrder,
  handleDownloadExcel,
  getDataSelectedDateRange,
}: any) {
  const calenderRef = useRef<any>();
  const [selectedButton, setSelectedButton] = useState({
    isToday: true,
    is7days: false,
    is30Days: false,
    isCustom: false,
  });

  const [selectedDateRange, setSelectedDateRange] = useState({});
  useEffect(() => {
    getDataSelectedDateRange(selectedDateRange);
  }, [selectedDateRange]);

  const getTodaysData = () => {
    setSelectedButton({
      ...selectedButton,
      isToday: true,
      is7days: false,
      is30Days: false,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: moment()
        .utc()
        .set({ hours: 0, minutes: 0, seconds: 0 })
        .format(UTC_DATE_TIME_FORMAT),
      endDate: moment().utc().format(UTC_DATE_TIME_FORMAT),
    });
    setDateFromCalendar(null);
  };

  const getSevenDaysData = () => {
    setSelectedButton({
      ...selectedButton,
      is7days: true,
      isToday: false,
      is30Days: false,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: moment()
        .utc()
        .set({ hours: 0, minutes: 0, seconds: 0 })
        .subtract(6, "days")
        .format(UTC_DATE_TIME_FORMAT),
      endDate: moment().utc().format(UTC_DATE_TIME_FORMAT),
    });
    setDateFromCalendar(null);
  };

  const getThirtyDaysData = () => {
    setSelectedButton({
      ...selectedButton,
      isToday: false,
      is7days: false,
      is30Days: true,
      isCustom: false,
    });
    setSelectedDateRange({
      ...selectedDateRange,
      startDate: moment()
        .utc()
        .set({ hours: 0, minutes: 0, seconds: 0 })
        .subtract(30, "days")
        .format(UTC_DATE_TIME_FORMAT),
      endDate: moment().utc().format(UTC_DATE_TIME_FORMAT),
    });
    setDateFromCalendar(null);
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [dateFromCalendar, setDateFromCalendar] = useState<any>(null);
  useEffect(() => {
    if (dateFromCalendar?.length === 2) {
      setSelectedDateRange({
        ...selectedDateRange,
        startDate:
          moment(dateFromCalendar[0]).format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDate:
          moment(dateFromCalendar[1]).utc().format("YYYY-MM-DD") +
          "T23:59:00.000Z",
      });
    }
  }, [dateFromCalendar]);

  const [isViewDetailsModalOpen, setIsViewDetailsModalOpen] = useState(false);
  const [selectedOrderId, setselectedOrderId] = useState("");
  const [selectedOrderIds, setselectedOrderIds] = useState([]);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [isPaymentStatusChangeModalOpen, setIsPaymentStatusChangeModalOpen] =
    useState(false);

  const handleCloseChangeStatusModal = () => {
    setIsStatusChangeModalOpen(false);
    setselectedOrderId("");
  };

  const handleOpenChangeStatusModal = (orderIds: []) => {
    setIsStatusChangeModalOpen(true);
    setselectedOrderIds(orderIds);
  };
  const handleOpenChangePaymentStatusModal = (orderId: string) => {
    setIsPaymentStatusChangeModalOpen(true);
    setselectedOrderId(orderId);
  };

  const handleClosedChangePaymentStatusModal = () => {
    setIsPaymentStatusChangeModalOpen(false);
    setselectedOrderId("");
  };

  const OpenViewDetailsModal = (orderId: string) => {
    setIsViewDetailsModalOpen(true);
    setselectedOrderId(orderId);
  };

  const handleCloseViewDetailsModal = () => {
    setIsViewDetailsModalOpen(false);
    setselectedOrderId("");
  };

  const getOrderView = () => {
    return (
      <>
        <HStack spacing="12px" className="sbscoAnalyticsDBContainer">
          <Box className="sbscoTable">
            <SimpleGrid
              mb="20px"
              columns={1}
              spacing={{ base: "20px", xl: "20px" }}
            >
              <div className="sbscoTableContainer mcaTable orderTable">
                <OrderTable
                  templeList={allTempleList}
                  columnsData={OrderAdminColumnData}
                  userOrder={allOrder}
                  OpenViewDetailsModal={OpenViewDetailsModal}
                  handleDownloadExcel={handleDownloadExcel}
                  OpenChangeStatusModal={handleOpenChangeStatusModal}
                ></OrderTable>
              </div>
              <div className="sbscoTableContainer mcaTable settlementTable">
                <SettlementTable
                  templeList={allTempleList}
                  columnsData={SettelementColumnData}
                  userOrder={settlementsOrder}
                  OpenViewDetailsModal={OpenViewDetailsModal}
                  handleDownloadExcel={handleDownloadExcel}
                  OpenChangePaymentStatusModal={
                    handleOpenChangePaymentStatusModal
                  }
                ></SettlementTable>
              </div>
            </SimpleGrid>
          </Box>
        </HStack>
      </>
    );
  };

  const handleCloseCalendar = () => {
    setSelectedButton({
      ...selectedButton,
      is7days: false,
      isToday: false,
      is30Days: false,
      isCustom: false,
    });
  };

  return (
    <div className="analyticsDashboard">
      <HStack className="sbscoHeadingRow">
        <Box w="50%">
          <Heading as="h4" size="md" noOfLines={1}>
            {"ORDER"}
          </Heading>
        </Box>
        <Box className="sbscoHeadingRight">
          <Box className="sbscoBtnGroup">
            <Button
              onClick={() => {
                getSevenDaysData();
              }}
              size="sm"
              color={selectedButton.is7days === true ? "primary" : "inherit"}
              variant={selectedButton.is7days ? "solid" : "outline"}
              border="1px"
            >
              7 Days
            </Button>
            <Button
              onClick={() => {
                getTodaysData();
              }}
              size="sm"
              color={selectedButton.isToday === true ? "primary" : "inherit"}
              variant={selectedButton.isToday ? "solid" : "outline"}
              border="1px"
            >
              Today
            </Button>
            <Button
              onClick={() => {
                getThirtyDaysData();
              }}
              size="sm"
              color={selectedButton.is30Days === true ? "primary" : "inherit"}
              variant={selectedButton.is30Days ? "solid" : "outline"}
              border="1px"
            >
              30 Days
            </Button>
          </Box>
          <Box className="dateRangeBlock">
            <Box ref={calenderRef}>
              <VStack className="sbscoBtn">
                <Button
                  onClick={() => {
                    setSelectedButton({
                      ...selectedButton,
                      isToday: false,
                      is7days: false,
                      is30Days: false,
                      isCustom: true,
                    });
                    setDateFromCalendar(null);
                  }}
                  size="sm"
                  // leftIcon={<FiCalendar />}
                  // colorScheme={selectedButton.isCustom ? "blue" : "gray"}
                  sx={{
                    color: selectedButton.isCustom ? "#fff" : "default",
                    backgroundColor: selectedButton.isCustom
                      ? "#d56939"
                      : "default",
                  }}
                  variant={selectedButton.isCustom ? "solid" : "outline"}
                  border="1px"
                >
                  {dateFromCalendar?.length !== 2
                    ? "Date Range Selection"
                    : `${moment(dateFromCalendar[0]).format(
                        "YYYY-MM-DD"
                      )} to ${moment(dateFromCalendar[1]).format(
                        "YYYY-MM-DD"
                      )}`}
                </Button>
                {selectedButton.isCustom && dateFromCalendar?.length !== 2 ? (
                  <Box className="rangeCalendar">
                    <MiniCalendar
                      className="dateCalendar"
                      selectRange={true}
                      pos="absolute"
                      m="20px 0 0 0"
                      border="2px solid black"
                      zIndex="1"
                      transform="scale(0.6)"
                      transformOrigin="top"
                      dateFromCalendar={dateFromCalendar}
                      setDateFromCalendar={setDateFromCalendar}
                      handleCloseCalendar={handleCloseCalendar}
                    />
                  </Box>
                ) : null}
              </VStack>
            </Box>
          </Box>
        </Box>
      </HStack>
      {getOrderView()}
      <ViewAllDetailsModal
        isOpen={isViewDetailsModalOpen}
        onClose={handleCloseViewDetailsModal}
        orderId={selectedOrderId}
      />
      <ChangeStatusModal
        selectedDateRange={selectedDateRange}
        isOpen={isStatusChangeModalOpen}
        onClose={handleCloseChangeStatusModal}
        orderIds={selectedOrderIds}
      />
      <UpdatePaymentModal
        isOpen={isPaymentStatusChangeModalOpen}
        onClose={handleClosedChangePaymentStatusModal}
        orderId={selectedOrderId}
      />
    </div>
  );
  return (
    <>
      <div className="pageContainer homePage">
        <p style={{ fontSize: "22px", fontWeight: "bold" }}>Admin panel</p>
        <div className="row">
          <div className="col-2">Left Content</div>
          <div className="col-10">
            <div style={{ marginBottom: "1em" }}>
              <p>Select Temple</p>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={allTempleList?.map(
                    (option: any) => option?.personalInfo?.name
                  )}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="temple" />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminView;
