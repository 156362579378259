import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Paper,
  makeStyles,
  Box,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.scss";
import {
  getDateFormatedOrderTime,
  getDateFormatedForBookingDate,
  calculateOverSeasPrice,
  removeTimeFromOrderDate,
} from "../../utils/HelperFunctions";
import { APPROLES, orderType } from "../../utils/Constants";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";
interface Data {
  [key: string]: string;
}

interface ViewAllDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const ViewOrderDetailsModal: React.FC<ViewAllDetailsProps> = ({
  isOpen,
  onClose,
  orderId,
}) => {
  const { userInfo } = useSelector((state: any) => state.user);
  const { userOrder } = useSelector((state: any) => state.order);
  const [orderDetails, setOrderDetails] = React.useState<any>({});
  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    "panel-3"
  );

  useEffect(() => {
    if (orderId && orderId.length > 0) {
      const orderDetails = userOrder.filter(
        (item: any) => item.orderId == orderId
      );
      setOrderDetails(orderDetails[0]);
    }
  }, [orderId]);

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === APPROLES.SUPERADMIN.roleId ||
        userInfo.userType === APPROLES.ADMIN.roleId)
    ) {
      setIsSuperAdmin(true);
    } else {
      setIsSuperAdmin(true);
      setExpandedAccordion("panel-3");
    }
  }, [userInfo]);

  const scrollRef: any = useRef(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      //scrollRef.scrollIntoView({ behavior: "smooth" })
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    //scrollToBottom();
  }, [orderDetails]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : null);
      if (isExpanded) {
        scrollToBottom();
      }
    };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="change-status-title"
      className="orderModal"
    >
      <Box className="modalContents">
        <Stack className="modalHeader">
          <Typography id="change-status-title" variant="h6" gutterBottom>
            {t(LOCALIZATION.Order_id)} : <span> {orderId} </span>
          </Typography>
          <Button onClick={onClose} variant="outlined" className="btnClose">
            <CloseIcon />
          </Button>
        </Stack>

        <Box className="modalBody">
          <Box ref={scrollRef} className="orderDetails">
            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6} lg={4}>
                <Grid container direction="column" spacing={{ xs: 1, md: 2 }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Temple_Name)}
                      </span>{" "}
                      : {orderDetails?.templeDetails?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Puja_Name)}{" "}
                      </span>{" "}
                      :{" "}
                      {orderDetails?.orderType === orderType.puja
                        ? orderDetails?.pujaName
                        : orderDetails?.orderType === orderType.chadhava
                        ? "Chadhava"
                        : "Prasad"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Puja_Value)}
                      </span>{" "}
                      :{" "}
                      {orderDetails?.orderData?.currencySymbols
                        ? orderDetails?.orderData?.currencySymbols
                        : "₹"}
                      {/* { orderDetails?.orderData?.isOverseas ? calculateOverSeasPrice(orderDetails?.orderData?.payment?.orderValue,orderDetails?.orderData?.rate) : orderDetails?.orderData?.payment?.orderValue} */}
                      {orderDetails?.orderData?.isOverseas
                        ? orderDetails?.orderData?.payment?.orderValueOv
                          ? orderDetails?.orderData?.payment?.orderValueOv
                          : calculateOverSeasPrice(
                              orderDetails?.orderData?.payment?.orderValue,
                              orderDetails?.orderData?.rate
                            )
                        : orderDetails?.orderData?.payment?.orderValue}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Grid container direction="column" spacing={{ xs: 1, md: 2 }}>
                  <Grid item xs={12} md={6} lg={4}>
                    {isSuperAdmin && (
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>
                          {t(LOCALIZATION.Order_Status)}{" "}
                        </span>{" "}
                        :{" "}
                        {orderDetails?.orderStatus?.length
                          ? orderDetails?.orderStatus[
                              orderDetails?.orderStatus?.length - 1
                            ]?.orderStatus
                          : ""}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Order_User_Name)}{" "}
                      </span>{" "}
                      : {orderDetails?.orderUserDetails?.orderUserName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Phone_Number)}{" "}
                      </span>{" "}
                      :{" "}
                      {orderDetails?.deliveryAddress?.phoneNumber &&
                      orderDetails.deliveryAddress.phoneNumber.length > 3
                        ? orderDetails?.deliveryAddress?.phoneNumber
                        : orderDetails?.orderUserDetails?.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Grid container direction="column" spacing={{ xs: 1, md: 2 }}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.WhatsApp_number)}{" "}
                      </span>{" "}
                      :{" "}
                      {orderDetails?.whatsAppNumberdialCode
                        ? orderDetails?.whatsAppNumberdialCode
                        : "+91"}{" "}
                      {orderDetails?.whatsAppNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Booked_on)}{" "}
                      </span>{" "}
                      :{" "}
                      {getDateFormatedOrderTime(orderDetails?.orderBookingDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {orderDetails.orderType !== orderType.prasad && (
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>
                          {t(LOCALIZATION.Booked_for)}{" "}
                        </span>{" "}
                        : {removeTimeFromOrderDate(orderDetails?.pujaDate)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                {[orderType.puja, orderType.prasad]?.includes(
                  orderDetails?.orderType
                ) &&
                  orderDetails?.deliveryAddress && (
                    <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {t(LOCALIZATION.Delivery_address)}
                        {""}
                      </span>{" "}
                      :
                      {`${orderDetails?.deliveryAddress?.street},${
                        orderDetails?.deliveryAddress?.area || " "
                      },${orderDetails?.deliveryAddress?.city},${
                        orderDetails?.deliveryAddress?.state
                      },${orderDetails?.deliveryAddress?.zip}`}
                    </Typography>
                  )}
              </Grid>
            </Grid>
            <Divider sx={{ opacity: 0.6, paddingBottom: "5px" }} />
            <Box className="accordionList">
              {[orderType.chadhava, orderType.prasad]?.includes(
                orderDetails?.orderType
              ) && (
                <>
                  <Accordion
                    sx={{ boxShadow: "none" }}
                    expanded={expandedAccordion === `panel-4`}
                    onChange={handleChange("panel-4")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{ padding: "0px 16px" }}
                    >
                      <Typography variant="body1">
                        <span style={{ fontWeight: "bold" }}>
                          {orderDetails?.orderType === orderType.puja
                            ? orderDetails?.pujaName
                            : orderDetails?.orderType === orderType.chadhava
                            ? t(LOCALIZATION.Chadhava_Item_List)
                            : t(LOCALIZATION.Prasad_Item_List)}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table aria-label="chadhavalist">
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableHeaderCell">
                                {orderDetails?.orderType === orderType.puja
                                  ? orderDetails?.pujaName
                                  : orderDetails?.orderType ===
                                    orderType.chadhava
                                  ? t(LOCALIZATION.Chadhava_Image)
                                  : t(LOCALIZATION.Prasad_Image)}
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                {orderDetails?.orderType === orderType.puja
                                  ? orderDetails?.pujaName
                                  : orderDetails?.orderType ===
                                    orderType.chadhava
                                  ? t(LOCALIZATION.Chadhava_Item)
                                  : t(LOCALIZATION.Prasad_Item)}
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                {t(LOCALIZATION.Qty)}.
                              </TableCell>
                              <TableCell className="tableHeaderCell">
                                {t(LOCALIZATION.Price)}
                                {`(${
                                  orderDetails?.orderData?.currencySymbols
                                    ? orderDetails?.orderData?.currencySymbols
                                    : "₹"
                                })`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails?.chadhavaList?.map(
                              (el: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <img
                                      width="50px"
                                      height="50px"
                                      src={el.image}
                                    ></img>
                                  </TableCell>
                                  <TableCell>{el?.name}</TableCell>
                                  <TableCell>{el?.count}</TableCell>
                                  <TableCell>
                                    {" "}
                                    {orderDetails?.orderData?.isOverseas
                                      ? calculateOverSeasPrice(
                                          el.priceInfo?.finalPrice,
                                          orderDetails?.orderData?.rate
                                        )
                                      : el.priceInfo?.finalPrice}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <Accordion
                sx={{ boxShadow: "none" }}
                expanded={expandedAccordion === `panel-3`}
                onChange={handleChange("panel-3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ padding: "0px 16px" }}
                >
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {t(LOCALIZATION.Puja_Members_Details)}
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table aria-label="puja-member-details-table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="tableHeaderCell">
                            {t(LOCALIZATION.Full_Name)}
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            {t(LOCALIZATION.Gotram)}
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            {t(LOCALIZATION.Puja_Name)}
                          </TableCell>
                          <TableCell className="tableHeaderCell">
                            {t(LOCALIZATION.Manokamna_Wish)}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails?.orderUserDetails?.groupDetails?.map(
                          (member: any, index: number) => (
                            <TableRow key={index}>
                              <TableCell>{member.fullName}</TableCell>
                              <TableCell>
                                <TableCell>
                                  {orderDetails?.orderType !== orderType.prasad
                                    ? member.gotram || ""
                                    : ""}
                                </TableCell>
                              </TableCell>
                              <TableCell>
                                {" "}
                                {orderDetails?.pujaName?.trim().length > 0
                                  ? orderDetails?.pujaName
                                  : orderDetails?.orderType ===
                                    orderType.chadhava
                                  ? "Chadhava"
                                  : "Prasad"}
                              </TableCell>
                              <TableCell>{member.manokamna}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
        <Box className="modalFooter">
          <Button
            onClick={onClose}
            variant="outlined"
            className="btnCancel grey"
          >
            {t(LOCALIZATION.Close)}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewOrderDetailsModal;
