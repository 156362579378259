import React, { ReactNode } from "react";
import { Box } from "@mui/material";
interface ChatBgProps {
   children: ReactNode;
  // setHandleClose: () => void;
  // sendInpute: any;
  // isLoading: boolean;
  // isChatInputBoxDisebal: boolean;
  // selectedLang: string;
  // handleLanguageSwitch: any;
  // chatMessageLength: any;
  // handleBackToMainMenu: any;
  // lastChatItem: any;
  // handleDownload: any;
  // isMobileView: boolean;
}

const AIGurujiChatBackgroundWithoutFooter: React.FC<ChatBgProps> = ({
  children,
  // setHandleClose,
  // sendInpute,
  // isLoading,
  // isChatInputBoxDisebal,
  // selectedLang,
  // handleLanguageSwitch,
  // chatMessageLength,
  // handleBackToMainMenu,
  // lastChatItem,
  // handleDownload,
  // isMobileView,
}) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "10px",
            position: "absolute",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f1f1",
            borderRadius: "14px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "14px",
            border: "2px solid #f1f1f1",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

export default AIGurujiChatBackgroundWithoutFooter;
