import {
  Box,
  Button,
  TextField,
  Dialog,
  FormControl,
  RadioGroup,
  Radio,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import SubtitleTypography from "../../../components/typography/SubtitleTypography";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import { ADD_DEVOTEE_LABEL } from "../../../utils/ConstantsMessages";

import {
  getDateFormatedForPujaDetails,
  getDevoteeSerial,
} from "../../../utils/HelperFunctions";
import { useState } from "react";
import React from "react";
import { orderType } from "../../../utils/Constants";
import WhatsappNumberInput from "../../../components/WhatsappNumberInput/WhatsappNumberInput";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "../../../utils/localization";

function AddDevoteeDetailsView(props: any) {
  const { t, i18n } = useTranslation();
  const { cartInfo } = props;
  const singleCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;
  let maxMemberAllowed = props.membersInfo?.length || 0;
  if (
    (singleCartData?.pujaPackage?.maxMemberAllowed ||
      singleCartData?.pujaDetails?.maxMemberAllowed) &&
    singleCartData?.orderType == orderType.puja
  ) {
    maxMemberAllowed = Number(
      singleCartData?.pujaPackage?.maxMemberAllowed ||
        singleCartData?.pujaDetails?.maxMemberAllowed
    );
  }

  let previousUserBookList = props?.prevUserGroup || [];

  console.log(`previousUserBookList------`, previousUserBookList);

  // --- For Add Optional Details Modal ---
  const [open, setOpen] = React.useState(false);
  const [validationMessage, setValidationMessage] = useState<string>(""); // State for validation message
  const prasadEnablity =
    singleCartData?.orderType === orderType.prasad ? true : false;
  const chadhavaEnablity =
    singleCartData?.orderType === orderType.chadhava ? true : false;
  const pujaEnablity =
    singleCartData?.orderType === orderType.puja ? true : false;

  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [timeOfBirth, setTimeOfBirth] = useState(null);

  const dateLabel = pujaEnablity
    ? ADD_DEVOTEE_LABEL?.pujaDateLabel
    : ADD_DEVOTEE_LABEL?.chadavaDateLabel;

  return (
    <Box className="firstStep AddDevoteeDetails">
      <Box className="chadhavaDetails">
        {singleCartData?.orderType === orderType.puja ? (
          <SubtitleTypography
            title={t(LOCALIZATION.Puja_Booking_Details)}
          ></SubtitleTypography>
        ) : (
          <SubtitleTypography
            title={`${t(
              singleCartData?.orderType === orderType.prasad
                ? "Prasad"
                : "Chadhava"
            )} ${t(LOCALIZATION.Details)}`}
          ></SubtitleTypography>
        )}
        {pujaEnablity && (
          <Box className="infoDevotee">
            <ErrorOutlineOutlinedIcon />
            {/* You can add maximum {maxMemberAllowed}{" "}
            devotee at a time. */}
            {t(LOCALIZATION.You_Can_Add_Maximum)} {maxMemberAllowed}{" "}
            {t(LOCALIZATION.Members_At_A_Time)}
          </Box>
        )}
        <Box className="addDevotee">
          <SubtitleTypography
            title={t(LOCALIZATION.Add_Devotee_Details)}
          ></SubtitleTypography>
          <Box className="formGroup">
            {singleCartData?.orderType === orderType.puja && (
              <Box className="formField lightOpa">
                <label>{t(LOCALIZATION.Puja_Name)} </label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={singleCartData?.pujaDetails?.pujaName}
                  variant="standard"
                />
              </Box>
            )}

            <Box className="formField lightOpa">
              <label>{t(LOCALIZATION.Temple_Name)}</label>
              <TextField
                fullWidth
                id="standard-helperText"
                value={singleCartData?.templeDetails?.personalInfo?.name}
                variant="standard"
              />
            </Box>
            {singleCartData?.orderType !== orderType.prasad && (
              <Box className="formField lightOpa">
                <label>{t(LOCALIZATION.Puja_Date)}</label>
                <TextField
                  fullWidth
                  id="standard-helperText"
                  value={getDateFormatedForPujaDetails(
                    singleCartData?.pujaDateTime?.pujaDate
                  )}
                  variant="standard"
                />
              </Box>
            )}

            {pujaEnablity && (
              <Box className="formField">
                <label>{t(LOCALIZATION.Provide_Your_WhatsApp_Number)}</label>
                <WhatsappNumberInput
                  setWhatsAppNumber={(value: string[]) => {
                    if (value && value[0] === "0") {
                      props?.setWhatsAppNumber("");
                    } else {
                      props?.setWhatsAppNumber(value);
                    }
                  }}
                  whatsAppNumber={props?.whatsAppNumber}
                  error={!!props?.whatsAppNumberError}
                  setSelectedDialCode={props?.setSelectedDialCode}
                  selectedDialCode={props?.selectedDialCode}
                  selectedCountryForWhatapp={props?.selectedCountryForWhatapp}
                  setSelectedCountryForWhatapp={
                    props?.setSelectedCountryForWhatapp
                  }
                />
                <FormHelperText
                  className="errorText"
                  style={{
                    display:
                      props?.whatsAppNumberError &&
                      (props?.whatsAppNumber === undefined ||
                        props?.whatsAppNumber?.length < 10)
                        ? "block"
                        : "none",
                  }}
                >
                  {t(LOCALIZATION.Please_enter_valid_number)}
                </FormHelperText>
              </Box>
            )}

            {pujaEnablity && previousUserBookList?.length > 0 && (
              <Box className="formField">
                <Button
                  className="addDevt"
                  onClick={() => props.setPopUpDevoteePreviousBooking(true)}
                >
                  <AddCircleRoundedIcon />{" "}
                  {t(LOCALIZATION.Add_devotee_from_previous_bookings)}
                </Button>
              </Box>
            )}

            {pujaEnablity &&
              props.membersInfo.map((item: any, id: any) => {
                return (
                  <div>
                    <Box className="formField mbAdd">
                      <label>
                        {t(LOCALIZATION.Full_Name_of_x_number_Devotee, {
                          stringCounting: t(getDevoteeSerial(id)),
                        })}
                        {id === 0 ? "*" : ""}
                      </label>
                      <TextField
                        fullWidth
                        name="fullName"
                        id="fullName"
                        value={item.fullName}
                        variant="standard"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (
                            value === "" ||
                            /^[a-zA-Z][a-zA-Z ]*$/.test(value)
                          ) {
                            props.updateMemberInfo(id, {
                              ...item,
                              fullName: value,
                            });
                          }
                        }}
                        error={!!props.fullNameError}
                      />
                      <FormHelperText
                        className="errorText"
                        style={{
                          display:
                            props.fullNameError && id < 1 ? "block" : "none",
                        }}
                      >
                        {t(LOCALIZATION.Please_Enter_Your_Full_Name)}
                      </FormHelperText>
                    </Box>
                  </div>
                );
              })}
            {/* for prsad only */}

            {(prasadEnablity || chadhavaEnablity) &&
              previousUserBookList?.length > 0 && (
                <Box className="formField">
                  <Button
                    className="addDevt"
                    onClick={() => props.setPopUpDevoteePreviousBooking(true)}
                  >
                    <AddCircleRoundedIcon />{" "}
                    {t(LOCALIZATION.Add_devotee_from_previous_bookings)}
                  </Button>
                </Box>
              )}
            {(prasadEnablity || chadhavaEnablity) &&
              props.membersInfo.map((item: any, id: any) => {
                return (
                  <div>
                    <Box className="formField mbAdd">
                      <label>
                        {t(LOCALIZATION.Full_Name_of_First_Devotee)}
                        {id === 0 ? "*" : ""}
                      </label>
                      <TextField
                        fullWidth
                        name="fullName"
                        id="fullName"
                        value={item.fullName}
                        variant="standard"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          if (
                            value === "" ||
                            /^[a-zA-Z][a-zA-Z ]*$/.test(value)
                          ) {
                            props.updateMemberInfo(id, {
                              ...item,
                              fullName: value,
                            });
                          }
                        }}
                        error={!!props.fullNameError}
                      />
                      <FormHelperText
                        className="errorText"
                        style={{
                          display:
                            props.fullNameError && id < 1 ? "block" : "none",
                        }}
                      >
                        {t(LOCALIZATION.Please_Enter_Your_Full_Name)}
                      </FormHelperText>
                    </Box>
                  </div>
                );
              })}

            {(prasadEnablity || chadhavaEnablity) && (
              <Box className="formField">
                <label>{t(LOCALIZATION.Provide_Your_WhatsApp_Number)}</label>
                <WhatsappNumberInput
                  setWhatsAppNumber={(value: string[]) => {
                    if (value && value[0] === "0") {
                      props?.setWhatsAppNumber("");
                    } else {
                      props?.setWhatsAppNumber(value);
                    }
                  }}
                  whatsAppNumber={props?.whatsAppNumber}
                  error={!!props?.whatsAppNumberError}
                  setSelectedDialCode={props?.setSelectedDialCode}
                  selectedDialCode={props?.selectedDialCode}
                  selectedCountryForWhatapp={props?.selectedCountryForWhatapp}
                  setSelectedCountryForWhatapp={
                    props?.setSelectedCountryForWhatapp
                  }
                />
                <FormHelperText
                  className="errorText"
                  style={{
                    display:
                      props?.whatsAppNumberError &&
                      (props?.whatsAppNumber === undefined ||
                        props?.whatsAppNumber?.length < 10)
                        ? "block"
                        : "none",
                  }}
                >
                  {t(LOCALIZATION.WhatsApp_Number_Error_Message)}
                </FormHelperText>
              </Box>
            )}

            {singleCartData?.orderType !== orderType.prasad && (
              <>
                <Box className="formField">
                  <label>{t(LOCALIZATION.Gotram_Optional)}</label>
                  <TextField
                    fullWidth
                    name="Gotram"
                    id="Gotram"
                    value={
                      props.checked ? t(LOCALIZATION.Kashyap) : props.gotram
                    }
                    variant="standard"
                    onChange={(e: any) => {
                      const newValue = e.target.value.replace(
                        /[^A-Za-z ]/g,
                        ""
                      );
                      props.addGotram(newValue);
                    }}
                  />
                </Box>

                <Box className="formField">
                  <FormGroup className="formCheck">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props.checked}
                          onChange={props.handleChange}
                        />
                      }
                      label={t(LOCALIZATION.Dont_know_my_Gotram)}
                    />
                  </FormGroup>
                </Box>
              </>
            )}

            {singleCartData?.orderType === orderType.puja && (
              <Box className="formField">
                <label>{t(LOCALIZATION.Manokamna_Wish_Optional)}</label>

                <TextField
                  className="monokamnaDetails"
                  id="standard-basic"
                  placeholder={t(LOCALIZATION.Enter_here)}
                  multiline
                  rows={4}
                  fullWidth
                  value={props?.manokamna}
                  onChange={(e: any) => {
                    props.addmanokamna(e.target.value);
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* ---- Previous Booking Devotee Modal ---- */}
      <Dialog
        className="startPostInput"
        onClose={() => props.setPopUpDevoteePreviousBooking(false)}
        open={props.popUpDevoteePreviousBooking}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "14px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, pb: 1, fontSize: "17px", fontWeight: "600" }}
        >
          {t(LOCALIZATION.Previous_Booking_Devotee_List)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          color="primary"
          onClick={() => props.setPopUpDevoteePreviousBooking(false)}
          className="topCloseIconBtn"
          sx={{
            position: "absolute",
            right: 14,
            top: 16,
            backgroundColor: "transparent",
            border: "2px solid #d65758",
            borderRadius: "50%",
            padding: "2px",
            transition: "background-color 0.3s ease, color 0.3s ease",
            "& .MuiSvgIcon-root": {
              color: "#d65758",
              transition: "color 0.3s ease",
              fontSize: "16px",
            },
            "&:hover": {
              backgroundColor: "#f13a3a",
              border: "2px solid #f13a3a",
              "& .MuiSvgIcon-root": {
                color: "#fff",
              },
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 1, width: "400px", maxWidth: "100%" }}>
          <Box sx={{ display: "flex", marginLeft: "15px" }}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {previousUserBookList.map((item: any) => {
                  return (
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label={item.fullName}
                      onClick={(e) => {
                        e.preventDefault();
                        props.selectPrevGroupMember(item);
                        // Write function to add that devotee to next blank area
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              width: "100%",
              background: "linear-gradient(90deg, #db4250, #f49e49)",
              color: "#fff",
              m: 1,
              mb: "12px",
              padding: "8px",
              borderRadius: "30px",
              transition: "0.3s ease",
              "&:hover": {
                background: "linear-gradient(90deg, #ff4949, #ff8b2d)",
              },
            }}
          >
            {t(LOCALIZATION.Select_Continue)}
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        className="saveAndContinueBtn"
        onClick={() => props.proceedToCart()}
      >
        {t(LOCALIZATION.Save_and_continue)}
      </Button>
    </Box>
  );
}

export default AddDevoteeDetailsView;
