import React, { useEffect, useRef, useState } from "react";
import AIGurujiView from "../../Views/AIGurujiView/AIGurujiView";
import { connectSocket, disconnectSocket } from "../../utils/socketConnection";
import { v4 as uuidv4 } from "uuid";
import { Socket } from "socket.io-client";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { AiGurujiChatService } from "td_server";
import {
  detectLanguage,
  generateUniqueNumberString,
  getCurrentTime,
  getDateToday,
  logger,
} from "../../utils/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  createChat,
  disableAIChatByUserId,
  downloadGurujiChatPDF,
  getChatByUserId,
  updateChat,
} from "../../store/actions/gurujiChatAction";
import dayjs from "dayjs";

function parseTable(tableString: any) {
  const lines = tableString.trim().split("\n");
  const header = lines[1]
    .split("|")
    .map((item: any) => item.trim())
    .filter(Boolean);
  const rows = lines.slice(3, -1).map((line: any) =>
    line
      .split("|")
      .map((item: any) => item.trim())
      .filter(Boolean)
  );

  return { header, rows };
}
interface AIGurujiHODProps {
  openModal: boolean;
  closeModal: () => void;
  isMobileView: boolean;
  selectedMobileLanguage: any;
}

const AIGurujiHOD: React.FC<AIGurujiHODProps> = ({
  openModal,
  closeModal,
  isMobileView,
  selectedMobileLanguage,
}) => {
  const strRoomId = uuidv4();
  const dispatch: any = useDispatch();
  const [messages, setMessages] = useState<any[]>([]);
  const [roomId, setRoomId] = useState<string>(strRoomId);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [selectedLang, setSelectedLang] = useState<string>("");
  const [selectedServices, setSelectedServices] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChatInputBoxDisebal, setIsChatInputBoxDisebal] = useState(true);
  const selectedServicesRef = useRef(selectedServices);
  const [currentPayload, setcurrentPayload] = useState<any>(null);
  const [isPlayingAudioId, setIsPlayingAudioId] = useState<any>(null);
  const currentPayloadRef = useRef(currentPayload);
  const messagesRef = useRef(messages);

  const [availableButton, SetAvailableButton] = useState<any>([]);
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const { userInfo, currentGPSAddress } = useSelector(
    (state: any) => state.user
  );
  const { isDownloading } = useSelector((state: any) => state.gurujiChat);
  const [userChat, setUserChat] = useState<any | null>(null);
  const [userId, setUserId] = useState<any>(null);
  const [userChatHistory, setUserChatHistory] = useState<any>(null);
  const [exportChatId, setExportChatId] = useState<any>(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState<string>("");
  const [lastPayload, setLastPayload] = useState<any>(null);
  const lastPayloadRef = useRef(lastPayload);
  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const { clientIp = "" } = priceConversionState.priceConversionRate || {};

  useEffect(() => {
    if (currentGPSAddress?.address?.formatted_address) {
      setUserCurrentLocation(currentGPSAddress?.address?.formatted_address);
    }
  }, [currentGPSAddress?.address?.formatted_address]);

  useEffect(() => {
    if (selectedMobileLanguage) {
      if (messagesRef?.current?.length === 0) {
        handleLanguageSwitch(selectedMobileLanguage);
      }
      setSelectedLang(selectedMobileLanguage);
    }
  }, [selectedMobileLanguage]);

  useEffect(() => {
    if (!isDownloading) {
      setExportChatId(null);
    }
  }, [isDownloading]);
  useEffect(() => {
    selectedServicesRef.current = selectedServices;
  }, [selectedServices]);

  useEffect(() => {
    currentPayloadRef.current = currentPayload;
  }, [currentPayload]);

  useEffect(() => {
    lastPayloadRef.current = lastPayload;
  }, [lastPayload]);

  useEffect(() => {
    messagesRef.current = messages;
    if (userId && selectedServices && isLoading === false) {
      updateChatHistory();
    }
  }, [messages]);

  useEffect(() => {
    const newSocket = connectSocket();
    setSocket(newSocket);
    newSocket.on("connect", () => {
      newSocket.emit("validate_session", { sessionId: roomId });
      if (lastPayloadRef.current) {
        setIsLoading(true);
        setIsChatInputBoxDisebal(true);
        if (newSocket) {
          let serviceToSendToBot = "";
          // Below we are tying to extract parent service name(e.g "ai_numerology"), because in services like (numeology,astrology,vastu,matchmaker) has child events like (ai_horosope,ai_horoscope_chat)
          // but backend needs parent service
          if (
            lastPayloadRef.current?.extra_parameters?.service.includes("_chat")
          ) {
            const tempArr =
              lastPayloadRef.current?.extra_parameters?.service.split("_chat");
            serviceToSendToBot = tempArr[0];
          } else {
            serviceToSendToBot =
              lastPayloadRef.current?.extra_parameters?.service;
          }
          // logger(
          //   "---selectedServices---",
          //   serviceToSendToBot,
          //   "---lastPayloadRef----",
          //   lastPayloadRef.current
          // );
          newSocket.emit(serviceToSendToBot, lastPayloadRef.current);
        }
      }
    });

    newSocket.on("disconnect", () => {
      setIsLoading(false);
      //setIsChatInputBoxDisebal(true);
    });
    newSocket.on("connect_error", () => {
      setIsLoading(false);
      setIsChatInputBoxDisebal(true);
      setTimeout(() => {
        newSocket.connect();
      }, 1000);
    });

    newSocket.on("available_actions", (data) => {
      data["type"] = "receive";
      data["id"] = generateUniqueNumberString();
      if (messagesRef?.current?.length < 2) {
        setMessages([data]);
        setIsLoading(false);
        setIsChatInputBoxDisebal(true);
      }
      if (messagesRef?.current?.length > 2) {
        setIsLoading(false);
        if (
          messagesRef.current[messagesRef.current.length - 1]?.contentType !==
            "menu" &&
          messagesRef.current[messagesRef.current.length - 1]?.type !== "send"
        ) {
          setIsChatInputBoxDisebal(false);
        }
        SetAvailableButton(data?.available_actions);
      }
    });

    newSocket.on("response", (data) => {
      deliverResponseMessages(data);
    });
    return () => {
      disconnectSocket();
    };
  }, [roomId]);

  useEffect(() => {
    if (userChat) {
      if (userChat.length > 0) {
        setRoomId(userChat[0]?.sessionId);
        setIsLoading(false);
        if (userChat[0].chatHistory?.length > 0) {
          setUserChatHistory(userChat);
          setMessages(userChat[0]?.chatHistory);
          if (userChat[0]?.currentPayload) {
            setcurrentPayload(userChat[0]?.currentPayload);
          }
          const lastItem =
            userChat[0].chatHistory[userChat[0].chatHistory?.length - 1];
          if (lastItem.contentType === "menu") {
            setIsChatInputBoxDisebal(true);
          } else {
            setIsChatInputBoxDisebal(false);
          }

          if (lastItem) {
            const lang = lastItem?.extra_parameter?.language
              ? lastItem?.extra_parameter?.language
              : lastItem?.language;

            const service = lastItem?.service
              ? lastItem?.service
              : lastItem?.extra_parameter?.service;
            SetAvailableButton(lastItem?.buttons);
            // if (selectedMobileLanguage) {
            //   setSelectedLang(selectedMobileLanguage);
            // } else {
            //   setSelectedLang(lang);
            // }
            setSelectedLang(lang);
            setSelectedServices(service);
          }
        }
      }
    }
  }, [userChat]);

  useEffect(() => {
    if (openModal === true) {
      if (userId) {
        dispatch(getChatByUserId(userId))
          .then((_response: any) => {
            if (_response) {
              setUserChat(_response);
            } else {
              setUserChat(null);
              handleSendMessage();
            }
          })
          .catch((error: any) => {
            setUserChat(null);
            handleSendMessage();
          });
      }
    } else {
      setMessages([]);
      handleSendMessage();
    }
  }, [openModal, userId]);

  useEffect(() => {
    if (userInfo) {
      setUserId(userInfo.id);
    }
  }, [userInfo]);
  const cleanJsonString = (jsonString: any) => {
    return jsonString
      .replace(/`/g, "")
      .replace(/\\n/g, " ")
      .replace(/\\t/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };
  const deliverResponseMessages = (rdata: any) => {
    logger(`Socket Recieve::`, rdata);
    setLastPayload(null);
    SetAvailableButton([]);
    setIsLoading(false);
    setIsChatInputBoxDisebal(false);
    const data = { ...rdata };
    const currentSelectedServices = selectedServicesRef.current;
    const tempCurrentPayload = JSON.parse(
      JSON.stringify(currentPayloadRef.current)
    );
    if (currentSelectedServices?.trim() === "ai_numerology") {
      if (data["service"] && data["service"] === "ai_numerology") {
        data["contentType"] = "json";
        data["formateResponse"] = JSON.parse(cleanJsonString(data["response"]));
        data["currentPayload"] = tempCurrentPayload;
        let tempCardSection = null;
        if (
          data["formateResponse"] &&
          data["formateResponse"]["cards_section"]
        ) {
          const tdata = [...data["formateResponse"]["cards_section"]];
          tempCardSection = tdata.map((item: any) => {
            const imgKey =
              item.title?.trim().replaceAll(" ", "_").toLowerCase() + "_svg";
            item["imageUrl"] = data["cards_section_icon"][imgKey];
            return item;
          });
        }
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data["error"];
      }
    } else if (currentSelectedServices?.trim() === "ai_horoscope") {
      if (data["service"] && data["service"] === "ai_horoscope") {
        data["contentType"] = "json";
        data["currentPayload"] = tempCurrentPayload;
        data["final_table_report"] = data?.final_table_report || [];
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data?.error;
        data["contentType"] = "error";
      }
    } else if (currentSelectedServices?.trim() === "ai_vastu") {
      if (data["service"] && data["service"] === "ai_vastu") {
        data["currentPayload"] = tempCurrentPayload;
        data["contentType"] = "json";
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data?.error;
        data["contentType"] = "error";
      }
    } else if (currentSelectedServices?.trim() === "ai_match_maker") {
      if (
        data?.extra_parameter?.service &&
        data?.extra_parameter?.service === "ai_match_maker"
      ) {
        data["contentType"] = "json";
        data["currentPayload"] = tempCurrentPayload;
        data["formateResponse"] = JSON.parse(cleanJsonString(data["response"]));
        SetAvailableButton(data?.buttons);
      } else {
        data["contentType"] = "text";
      }
      if (data?.error) {
        data["response"] = data?.error;
        data["contentType"] = "error";
      }
    } else {
      data["contentType"] = "text";
    }

    if (data?.buttons) {
      SetAvailableButton(data?.buttons);
    }
    data["id"] = generateUniqueNumberString();
    data["service"] = currentSelectedServices?.trim();
    data["type"] = "receive";
    data["currentTime"] = getCurrentTime();
    setMessages((prevMessages) => [...prevMessages, data]);
  };

  const handleSendMessage = () => {
    //handleLanguageSwitch("EN");
  };
  const getChatAfterUpdate = () => {
    dispatch(getChatByUserId(userId))
      .then((_response: any) => {
        if (_response) {
          setUserChat(_response);
        } else {
          setUserChat(null);
          handleSendMessage();
        }
      })
      .catch((error: any) => {
        setUserChat(null);
        handleSendMessage();
      });
  };

  const updateChatHistory = () => {
    const userPersonalInfo = userInfo?.personalInfo;
    const userName =
      userPersonalInfo.firstName + " " + userPersonalInfo.lastName;
    if (messages.length > 2) {
      if (userChat) {
        dispatch(
          updateChat(
            userId,
            userName,
            messages,
            currentPayload,
            roomId,
            userCurrentLocation,
            clientIp,
            false
          )
        )
          .then((_response: any) => {})
          .catch((error: any) => console.log("Error:", error));
      } else {
        dispatch(
          createChat(
            userId,
            userName,
            messages,
            userInfo.phoneNumber,
            roomId,
            userCurrentLocation,
            clientIp
          )
        )
          .then((_response: any) => {
            getChatAfterUpdate();
          })
          .catch((error: any) => console.log("Error:", error));
      }
    }
  };

  const handleLanguageSwitch = (lang: any) => {
    if (isLoading) return;

    setSelectedLang(lang);

    if (messages.length > 2) {
      setIsLoading(false);
      setIsChatInputBoxDisebal(false);
    }
    if (socket) {
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      socket.emit("initial_message", {
        action_id: "initial_message",
        language: lang,
        session_id: roomId,
      });
    }
  };

  const handleMenuSelect = (item: any) => {
    if (!item?.extra_parameters?.service) return;
    setSelectedServices(item?.extra_parameters?.service || "");
    setIsLoading(false);

    if (item?.extra_parameters?.service === "ai_guruji") {
      setIsChatInputBoxDisebal(false);
      setcurrentPayload(null);
    } else {
      setIsChatInputBoxDisebal(true);
    }

    const welcomeMessagesArray: any = [
      {
        serviceName: "ai_guruji",
        messages: {
          EN: `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I'm here to help you through all your spiritual and religious queries. Ask your question below and get guidance for every path in your life.`,
          HI: `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं यहाँ आपकी सभी आध्यात्मिक और धार्मिक प्रश्नों में आपकी सहायता के लिए हूँ। नीचे अपना प्रश्न पूछें और जीवन के हर पथ में मार्गदर्शन प्राप्त करें।`,
        },
      },
      {
        serviceName: "ai_vastu",
        messages: {
          EN: `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I'm here to help you with all your Vastu-related queries. I can guide you on how to create harmony and positivity in your home and environment. Please ask your question below!`,
          HI: `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं आपकी वास्तु से जुड़ी सभी प्रश्न का हल करने और घर के वातावरण में सकारात्मकता लाने में मदद कर सकता हूँ। कृपया अपना सवाल नीचे पूछें!`,
        },
      },
      {
        serviceName: "ai_horoscope",
        messages: {
          EN: `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I can provide insights into your life's journey through astrology. Please fill out the form below with your birth details so I can create your personalized Rashifal and offer you guidance.`,
          HI: `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं ज्योतिष के माध्यम से आपके जीवन की यात्रा के बारे में जानकारी प्रदान कर सकता हूँ। कृपया नीचे दिए गए फॉर्म में अपने जन्म का विवरण भरें ताकि मैं आपकी राशिफल बना सकूँ और आपको आपके जीवन के बारे में मार्गदर्शन कर सकूँ।`,
        },
      },
      {
        serviceName: "ai_numerology",
        messages: {
          EN: `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I can provide insights into your life's journey through numerology. Please fill out the form below with your birth details so I can create your personalized numerological reading and offer you guidance.`,
          HI: `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं अंक ज्योतिष के माध्यम से आपके जीवन की यात्रा के बारे में जानकारी प्रदान कर सकता हूँ। कृपया नीचे दिए गए फॉर्म में अपने जन्म का विवरण भरें ताकि मैं आपके लिए व्यक्तिगत अंक ज्योतिषीय रीडिंग तैयार कर सकूँ और आपको आपके जीवन के बारे में मार्गदर्शन कर सकूँ।`,
        },
      },
      {
        serviceName: "ai_match_maker",
        messages: {
          EN: `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I can help you with all your Kundli matching queries. Please provide the birth details of yourself and your partner. I will guide you in understanding the compatibility between the two of you for a harmonious life together.`,
          HI: `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं आपकी कुंडली मिलान से जुड़े सभी प्रश्न का हल कर सकता हूँ। कृपया नीचे दिए गए फॉर्म में अपना और अपने साथी का जन्म का विवरण भरें ताकि मैं आप दोनों की अनुकूलता जानने में मदद कर सकूँ।`,
        },
      },
    ];

    // const welcomeMessage =
    //   selectedLang === "HI"
    //     ? `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं यहाँ आपकी सभी आध्यात्मिक और धार्मिक प्रश्नों में आपकी सहायता के लिए हूँ। नीचे अपना प्रश्न पूछें और जीवन के हर पथ में मार्गदर्शन प्राप्त करें।`
    //     : `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I'm here to help you through all your spiritual and religious queries. Ask your question below and get guidance for every path in your life.`;

    const welcomeMessage: any = welcomeMessagesArray?.find(
      (service: any) => service.serviceName === item?.extra_parameters?.service
    )?.messages[selectedLang];
    const showInChatSentRightSelectedMenuData = {
      contentType: "text",
      response: item?.name,
      service: item?.extra_parameters?.service,
      type: "send",
      language: selectedLang,
      currentTime: getCurrentTime(),
      id: generateUniqueNumberString(),
    };
    setMessages((prevMessages) => [
      ...prevMessages,
      showInChatSentRightSelectedMenuData,
    ]);

    const showWelcomeMessageData: any = {
      contentType: "text",
      response: welcomeMessage,
      service: item?.extra_parameters?.service,
      type: "receive",
      language: selectedLang,
      id: generateUniqueNumberString(),
    };
    //if (messagesRef.current.length == 1) {
    setMessages((prevMessages) => [...prevMessages, showWelcomeMessageData]);
    //}

    if (item?.extra_parameters?.service.toLowerCase() !== "ai_guruji") {
      const sendMenuSelectActionData: any = {
        contentType: "menu",
        response: item?.name,
        service: item?.extra_parameters?.service,
        type: "send",
        language: selectedLang,
        id: generateUniqueNumberString(),
      };
      setMessages((prevMessages) => [
        ...prevMessages,
        sendMenuSelectActionData,
      ]);
    }
  };

  // const handleMenuSelect = (item: any) => {
  //   if (!item?.extra_parameters?.service) return;
  //   setSelectedServices(item?.extra_parameters?.service || "");
  //   setIsLoading(false);

  //   if (item?.extra_parameters?.service === "ai_guruji") {
  //     setIsChatInputBoxDisebal(false);
  //     setcurrentPayload(null);
  //   } else {
  //     setIsChatInputBoxDisebal(true);
  //   }

  //   const welcomeMessage =
  //     selectedLang === "HI"
  //       ? `प्रणाम भक्त! मैं वाल्मीकि, आपका अपना एआई गुरुजी। मैं यहाँ आपकी सभी आध्यात्मिक और धार्मिक प्रश्नों में आपकी सहायता के लिए हूँ। नीचे अपना प्रश्न पूछें और जीवन के हर पथ में मार्गदर्शन प्राप्त करें।`
  //       : `Pranam Bhakt! I'm Valmiki, your own AI Guruji. I'm here to help you through all your spiritual and religious queries. Ask your question below and get guidance for every path in your life.`;

  //   const showInChatSentRightSelectedMenuData = {
  //     contentType: "text",
  //     response: item?.name,
  //     service: item?.extra_parameters?.service,
  //     type: "send",
  //     language: selectedLang,
  //     currentTime: getCurrentTime(),
  //     id: generateUniqueNumberString(),
  //   };
  //   setMessages((prevMessages) => [
  //     ...prevMessages,
  //     showInChatSentRightSelectedMenuData,
  //   ]);

  //   const showWelcomeMessageData: any = {
  //     contentType: "text",
  //     response: welcomeMessage,
  //     service: item?.extra_parameters?.service,
  //     type: "receive",
  //     language: selectedLang,
  //     id: generateUniqueNumberString(),
  //   };
  //   if (messagesRef.current.length == 1) {
  //     setMessages((prevMessages) => [...prevMessages, showWelcomeMessageData]);
  //   }

  //   if (item?.extra_parameters?.service.toLowerCase() !== "ai_guruji") {
  //     const sendMenuSelectActionData: any = {
  //       contentType: "menu",
  //       response: item?.name,
  //       service: item?.extra_parameters?.service,
  //       type: "send",
  //       language: selectedLang,
  //       id: generateUniqueNumberString(),
  //     };
  //     setMessages((prevMessages) => [
  //       ...prevMessages,
  //       sendMenuSelectActionData,
  //     ]);
  //   }
  // };

  const sendMessageToBoat = (payload: any) => {
    setLastPayload(payload);
    logger(`Sent To Socket::`, payload, selectedServices);
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    if (socket) {
      socket.emit(selectedServices, payload);
    }
  };

  const handleSendInpute = (val: any) => {
    if (val?.trim().length > 0 && selectedLang?.trim().length > 0) {
      // const detectedLanguage = detectLanguage(val);
      // if (detectedLanguage !== "und" && detectedLanguage !== selectedLang) {
      //   setSelectedLang(detectedLanguage);
      // }
      const detectedLanguage = selectedLang;
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      if (currentPayload) {
        const newPayload = JSON.parse(JSON.stringify(currentPayload));
        newPayload["extra_parameters"]["service"] = selectedServices + "_chat";
        newPayload["extra_parameters"]["question"] = val;
        newPayload["extra_parameters"]["language"] =
          detectedLanguage !== "und" ? detectedLanguage : selectedLang;
        sendMessageToBoat(newPayload);
      } else {
        sendMessageToBoat({
          action_id: "selected_action",
          name: selectedServices,
          extra_parameters: {
            session_id: roomId,
            language:
              detectedLanguage !== "und" ? detectedLanguage : selectedLang,
            question: val,
            service: selectedServices,
          },
        });
      }

      const data = {
        contentType: "text",
        response: val,
        language: selectedLang,
        currentTime: getCurrentTime(),
        type: "send",
      };
      setMessages((prevMessages) => [...prevMessages, data]);
    }
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [player, setPlayer] = useState<any>(null);
  const [speechSynthesizerM, setSpeechSynthesizerM] = useState<any>(null);
  const setPlayerIconPlay = () => {
    setIsPlaying(false);
    setIsPlayingAudioId(null);
  };
  const translateAndSynthesize = async (
    transText: any,
    language = "EN",
    id: any
  ) => {
    if (isPlaying && id === isPlayingAudioId) {
      try {
        player?.pause();
        setIsPlaying(false);
        return;
      } catch (error) {
        setPlayerIconPlay();
        console.error("Error:", error);
      }
    } else if (!isPlaying && id === isPlayingAudioId) {
      setIsPlaying(true);
      player?.resume();
      return;
    } else if (isPlaying && transText.length > 5) {
      try {
        player?.pause();
        player?.close((e: any) => {});

        speechSynthesizerM.close();
        setIsPlaying((prev) => {
          setPlayer(null);
          return true;
        });
      } catch (error) {
        console.error("Error:", error);
      }
    } else if (isPlaying) {
      try {
        player?.pause();
        player?.close((e: any) => {});
        speechSynthesizerM?.close();
        setIsPlaying((prev) => {
          setPlayer(() => {
            return null;
          });
          return false;
        });
      } catch (error) {
        setPlayerIconPlay();
        console.error("Error:", error);
      }
    } else {
      setIsPlaying(true);
    }
    setIsPlayingAudioId(id);

    try {
      let xlang = "";
      let voiceName = "";
      if (language === "HI") {
        xlang = "hi-IN";
        //voiceName = "hi-IN-AaravNeural";
        voiceName = "hi-IN-KunalNeural";
      } else {
        //voiceName = "en-IN-AaravNeural";
        voiceName = "en-IN-KunalNeural";
        xlang = "en-IN";
      }

      const ssmlData = `
           <speak xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" xml:lang="${xlang}">
             <voice name="${voiceName}">
                <mstts:express-as style="customerservice">
                <prosody rate="1%" pitch="5%">${transText}</prosody>
                </mstts:express-as>
             </voice>
           </speak>`;

      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        "0aa3523aa55d4a0a95c94aac3ab9f987",
        "centralindia"
      );
      const iplayer = new SpeechSDK.SpeakerAudioDestination();
      setPlayer(iplayer);

      const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(iplayer);
      const speechSynthesizer = new SpeechSDK.SpeechSynthesizer(
        speechConfig,
        audioConfig
      );
      setSpeechSynthesizerM(speechSynthesizer);
      iplayer.onAudioStart = (e: any) => {};

      iplayer.onAudioEnd = function (e: any) {
        setPlayerIconPlay();
      };

      speechSynthesizer.synthesisStarted = function (s, e) {};
      speechSynthesizer.speakSsmlAsync(
        ssmlData,
        (result) => {
          if (
            result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
          ) {
          } else {
            console.error("Speech synthesis canceled, " + result.errorDetails);
            setPlayerIconPlay();
          }
          speechSynthesizer.close();
        },
        (error) => {
          console.error("Error:", error);
          speechSynthesizer.close();
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addFormDataToLastItem = (formData: any) => {
    setMessages((prevData) => {
      const updatedData = [...prevData];
      updatedData[updatedData.length - 1] = {
        ...updatedData[updatedData.length - 1],
        formData: formData,
      };
      return updatedData;
    });
  };

  const handleCalculateNumerology = (data: any, formData: any) => {
    if (formData) {
      addFormDataToLastItem(JSON.stringify(formData));
    }

    const payload: any = {
      action_id: "selected_action",
      name: "AI Numerology",
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
        ...data,
      },
    };
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    sendMessageToBoat(payload);
    setcurrentPayload(payload);
  };

  const handleCalculateHoroscope = (data: any, formData: any) => {
    if (formData) {
      addFormDataToLastItem(JSON.stringify(formData));
    }
    const payload: any = {
      action_id: "selected_action",
      name: "AI Horoscope",
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
        ...data,
      },
    };
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    sendMessageToBoat(payload);
    setcurrentPayload(payload);
  };

  const handleBackToMainMenu = () => {
    if (isPlaying) {
      translateAndSynthesize("", "EN", isPlayingAudioId);
    }
    setSelectedLang("");
    SetAvailableButton([]);
    setIsLoading(false);
    setIsChatInputBoxDisebal(true);
    setMessages([]);
    setcurrentPayload(null);
    if (userChat[0]?.id) {
      dispatch(disableAIChatByUserId(userId))
        .then(() => {
          setUserChat(null);
          // if (socket) {
          //   socket.emit("initial_message", {
          //     action_id: "initial_message",
          //     language: selectedLang,
          //     session_id: roomId,
          //   });
          // }
        })
        .catch((error: any) => {
          setUserChat(null);
        });
    }
  };

  const handleEditForm = (id: any) => {
    if (isLoading) return;
    const tempMessage = [...messages];
    const index = tempMessage.findIndex((tempMessage) => tempMessage.id === id);
    if (tempMessage[index - 1]) {
      const menuSelectedService =
        tempMessage[index - 1]?.service || selectedServices;
      setSelectedServices(menuSelectedService);
      setMessages((prevMessages) => [...prevMessages, tempMessage[index - 1]]);
    }
  };

  const onCloseAiChat = () => {
    if (isPlaying) {
      translateAndSynthesize("", "EN", isPlayingAudioId);
    }
    closeModal();
  };

  const handleDownload = (chat: any, matchedExport: any) => {
    if (messages?.length == 0) return;
    if (chat?.id) {
      setExportChatId(chat?.id);
    }
    if (
      chat?.service === "ai_horoscope" ||
      chat?.service === "ai_numerology" ||
      chat?.service === "ai_guruji" ||
      chat?.service === "ai_match_maker" ||
      chat?.service === "ai_vastu"
    ) {
      const serviceData =
        chat?.service !== "ai_guruji"
          ? messages
              ?.slice(
                matchedExport[chat?.service]?.start,
                matchedExport[chat?.service]?.end + 1
              )
              .reverse()
              .find(
                (item: any) =>
                  item?.type === "receive" &&
                  item?.contentType === "json" &&
                  item?.service === chat?.service
              )
          : messages
              ?.slice(
                matchedExport[chat?.service]?.start,
                matchedExport[chat?.service]?.end + 1
              )
              .reverse()
              .find(
                (item: any) =>
                  item?.type === "receive" &&
                  item?.contentType === "text" &&
                  item?.service === chat?.service
              );

      let payload: any = {
        service: serviceData?.service,
        contentType: serviceData?.contentType,
        type: serviceData?.type,
        defaultMessage: serviceData?.response,
        chat_history: chat.chat_history ? chat?.chat_history : [],
        currentTime: serviceData?.currentTime,
        language: serviceData?.extra_parameter?.language,
        CurrentDate: getDateToday(),
      };

      const shouldAddUserDetails =
        chat?.service === "ai_horoscope" || chat?.service === "ai_numerology";
      if (shouldAddUserDetails) {
        let year = serviceData?.currentPayload?.extra_parameters?.year;
        let month = serviceData?.currentPayload?.extra_parameters?.month;
        let day = serviceData?.currentPayload?.extra_parameters?.day;
        const date = new Date(year, month - 1, day);
        const usrDateOfBirth = dayjs(date).format("DD-MM-YYYY");
        let hour = serviceData?.currentPayload?.extra_parameters?.hour;
        let minutes = serviceData?.currentPayload?.extra_parameters?.minutes;
        const usrTimeOfBirth = `${hour < 10 ? "0" + hour : hour}:${
          minutes < 10 ? "0" + minutes : minutes
        }`;

        payload["userBirthDetails"] = {
          language: serviceData?.extra_parameter?.language,
          fullName: serviceData?.currentPayload?.extra_parameters?.name || "",
          gender: serviceData?.currentPayload?.extra_parameters?.gender || "",
          birthLocation:
            serviceData?.currentPayload?.extra_parameters?.city || "",
          nation: serviceData?.currentPayload?.extra_parameters?.nation || "",
          timeOfBirth: usrTimeOfBirth,
          dateOfBirth: usrDateOfBirth,
        };
      }

      if (chat?.service === "ai_horoscope") {
        const newSrting = serviceData?.table_report
          ?.replaceAll("-", "")
          ?.replaceAll("+", "");
        const lines: string[] = newSrting ? newSrting.trim().split("\n") : [];

        const header: string[] =
          lines.length > 2
            ? lines[2]
                ?.split("|")
                ?.map((item) => item.trim())
                .filter((item) => item)
            : [];
        const rows: string[][] =
          lines.length > 2
            ? lines.slice(3).map((line) =>
                line
                  .split("|")
                  .map((item) => item?.trim())
                  .filter((item) => item)
              )
            : [[]];
        let rowfinal = rows.filter((item: any) => item.length !== 0);

        const tables = serviceData.final_table_report
          ? serviceData?.final_table_report.map((item: any) => {
              const tableData = parseTable(item.tableValue);
              return { name: item.name, [item.name]: tableData };
            })
          : [];

        payload["birth_chart_url"] = serviceData?.birth_chart_url;
        payload["lagna_chart_url"] = serviceData?.lagna_chart_url;
        payload["planet_table_report"] = {
          header: [...header],
          row: [...rowfinal],
        };
        payload["finalTables"] = tables;
        payload["moon_sign"] = serviceData?.moon_sign;
        payload["sun_sign"] = serviceData?.sun_sign;
      }

      if (chat?.service === "ai_numerology") {
        payload["cards_section_icon"] = serviceData?.cards_section_icon;
        payload["cards_section"] = serviceData?.formateResponse?.cards_section;
        payload["number_calculations"] =
          serviceData?.formateResponse?.number_calculations;
      }
      if (chat?.service === "ai_match_maker") {
        payload["cards_section_icon_ai_match_maker"] =
          serviceData?.cards_section_icon;
        payload["card_section_ai_match_maker"] = serviceData?.formateResponse;
        payload["kundli_chart_blob_url"] = serviceData?.kundli_chart_blob_url;
        payload["girl_details"] =
          serviceData?.currentPayload?.first_person_details;
        payload["boy_details"] =
          serviceData?.currentPayload?.second_person_details;
      }
      if (chat?.service === "ai_vastu") {
        payload["vastu_image"] =
          serviceData?.currentPayload?.extra_parameters?.image_url;
      }
      dispatch(downloadGurujiChatPDF(payload, isMobileView));
    } else {
    }
  };

  const calculateMatchMaker = (allData: any) => {
    if (allData?.formData) {
      addFormDataToLastItem(JSON.stringify(allData?.formData));
    }

    const payload: any = {
      action_id: "selected_action",
      name: "AI Match Maker",
      ...allData?.data,
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
      },
    };
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    sendMessageToBoat(payload);
    setcurrentPayload(payload);
  };

  const sumitVastuForm = async (file: any) => {
    setIsLoading(true);
    setIsChatInputBoxDisebal(true);
    const imageUrl = await AiGurujiChatService.uploadImageForAiGurujiVastu({
      uri: file[0],
      platform: "web",
    });

    if (imageUrl) {
      addFormDataToLastItem(JSON.stringify({ imageUrl: imageUrl }));
      const payload: any = {
        action_id: "selected_action",
        name: "AI Vastu",
        extra_parameters: {
          session_id: roomId,
          language: selectedLang,
          service: selectedServices,
          question: "Tell me the vastu of the House",
          image_url: imageUrl,
          fileType: file[0]?.type,
        },
      };
      setIsLoading(true);
      setIsChatInputBoxDisebal(true);
      sendMessageToBoat(payload);
      setcurrentPayload(payload);
    } else {
      setIsLoading(false);
    }
  };

  const handleResetVastuForm = () => {
    const payload = {
      action_id: "selected_action",
      extra_parameters: {
        session_id: roomId,
        language: selectedLang,
        service: selectedServices,
      },
    };
    handleMenuSelect(payload);
  };
  //logger(`All Messages`, messages);
  return (
    <AIGurujiView
      open={openModal}
      closeModal={onCloseAiChat}
      onLanguageSwitch={handleLanguageSwitch}
      chatMessage={messages}
      onMenuSelect={handleMenuSelect}
      sendInpute={handleSendInpute}
      isLoading={isLoading}
      isChatInputBoxDisebal={isChatInputBoxDisebal}
      transcribeAudio={translateAndSynthesize}
      calculateNumerology={handleCalculateNumerology}
      selectedLang={selectedLang}
      calculateHoroscope={handleCalculateHoroscope}
      isPlayingAudioId={isPlayingAudioId}
      handleBackToMainMenu={handleBackToMainMenu}
      handleEditForm={handleEditForm}
      availableButton={availableButton}
      selectedServices={selectedServices}
      isPlaying={isPlaying}
      handleDownload={handleDownload}
      isDownloading={isDownloading}
      exportChatId={exportChatId}
      calculateMatchMaker={calculateMatchMaker}
      isMobileView={isMobileView}
      sumitVastuForm={sumitVastuForm}
      handleResetVastuForm={handleResetVastuForm}
    />
  );
};

export default AIGurujiHOD;
