import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ManageSpecialPujaView.scss";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import { adminSpecielPujaViewLabel } from "../../../utils/ConstantsMessages";
import SpecialPujaForm from "./SpecialPujaForm";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";

interface TempleListProps {
  isAddEditSpecialPujaModalOpen: boolean;
  setIsAddEditSpecialPujaModalOpen: (modalState: boolean) => void;
  promotionalList: any;
  templeList: any;
  handleTempleSelect: ([]: any) => any;
  allPujaList: any;
  handlePujaSelect: (pujaList: any) => any;
  seletedTempleOptions: any;
  seletedPujaOptions: any;
  setSelectedSpecialPuja: (selectedPuja: any) => any;
  selectedSpecialPuja: any;
  setSeletedTempleOptions: ([]: any) => any;
  setSeletedPujaOptions: ([]: any) => any;
  handleEditSpecialPujaItem: (item: any) => any;
  handleSubmitSpecialPuja: (formData: any) => any;
  handleCloseAddEditSpecialPujaForm: () => any;
  handleDeleteSpecialPuja: (specialPuja: any) => any;
  handleSearchSpPujalist: any;
}

const ManageSpecialPujaView: React.FC<TempleListProps> = (props: any) => {
  const {
    isAddEditSpecialPujaModalOpen,
    setIsAddEditSpecialPujaModalOpen,
    promotionalList,
    templeList,
    handleTempleSelect,
    allPujaList,
    handlePujaSelect,
    seletedTempleOptions,
    seletedPujaOptions,
    selectedSpecialPuja,
    setSeletedTempleOptions,
    setSeletedPujaOptions,
    handleEditSpecialPujaItem,
    handleSubmitSpecialPuja,
    handleCloseAddEditSpecialPujaForm,
    handleDeleteSpecialPuja,
    handleSearchSpPujalist,
  } = props;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleDeleteClick = (item: any) => {
    setSelectedItem(item);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setSelectedItem(null);
  };

  const handleConfirmDelete = () => {
    if (selectedItem) {
      handleDeleteSpecialPuja(selectedItem);
      setSelectedItem(null);
    }
    handleCloseDeleteDialog();
  };

  const handleOpenAddEditSpecialPujaForm = () => {
    setIsAddEditSpecialPujaModalOpen(true);
  };

  return (
    <Box className="pageContainer specialPujaAdmin">
      <Box className="topTitle">
        <Typography className="adminTitleText">
          {adminSpecielPujaViewLabel.title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        mb={1}
      >
        <Box>
          <SearchFilter handleSearchSpPujalist={handleSearchSpPujalist} />
        </Box>
        <Box className="btnAddSpecialPuja">
          <Button
            onClick={handleOpenAddEditSpecialPujaForm}
            variant="contained"
            aria-label="Add special puja"
          >
            <AddCircleOutlineIcon sx={{ fontSize: "20px", mr: 1 }} />
            {adminSpecielPujaViewLabel.button}
          </Button>
        </Box>
      </Box>

      {promotionalList && promotionalList?.length > 0 ? (
        <List className="specialPujaItemListAdmin">
          {promotionalList.map((_pujaItem: any, index: number) => (
            <ListItem
              key={_pujaItem?.id}
              className="specialPujaAdminCard"
              sx={{
                backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={_pujaItem?.dashBoardBg?.uri}
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 2,
                    borderRadius: "10px",
                    border: "1px solid #cdcdcd",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                className="listText"
                primary={_pujaItem?.pujaName}
              />
              <IconButton aria-label="delete" onClick={() => {}}>
                <Box
                  sx={{
                    color: "#fff",
                    padding: "2px 18px",
                    borderRadius: "4px",
                    fontSize: "14px",

                    backgroundColor: `${
                      _pujaItem?.hasOwnProperty("version")
                        ? "green"
                        : "rgb(209, 68, 68)"
                    }`,
                    fontFamily: "Roboto",
                  }}
                >
                  <span>
                    {_pujaItem?.hasOwnProperty("version") ? "Web" : "Mobile"}
                  </span>
                </Box>
              </IconButton>
              {_pujaItem?.hasOwnProperty("version") && (
                <IconButton
                  className="spPujaEditBtn"
                  aria-label="edit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEditSpecialPujaItem(_pujaItem);
                  }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              )}
              <IconButton
                className="spPujaDeleteBtn"
                aria-label="delete"
                onClick={() => handleDeleteClick(_pujaItem)}
              >
                <DeleteIcon sx={{ fontSize: "18px" }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <NoDataFound />
      )}

      <SpecialPujaForm
        isOpen={isAddEditSpecialPujaModalOpen}
        onClose={handleCloseAddEditSpecialPujaForm}
        onSave={handleSubmitSpecialPuja}
        templeList={templeList}
        handleTempleSelect={handleTempleSelect}
        allPujaList={allPujaList}
        handlePujaSelect={handlePujaSelect}
        seletedTempleOptions={seletedTempleOptions}
        seletedPujaOptions={seletedPujaOptions}
        selectedSpecialPuja={selectedSpecialPuja}
        setSeletedTempleOptions={setSeletedTempleOptions}
        setSeletedPujaOptions={setSeletedPujaOptions}
      />
      <DeleteConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        msg="Are you sure you want to delete this item?"
      />
    </Box>
  );
};
export default ManageSpecialPujaView;
