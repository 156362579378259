import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
  Typography,
} from "@mui/material";
import micIcon from "../../assets/mic-icon.png";
import SearchIcon from "@mui/icons-material/Search";
import "./GlobalSearchItem.scss";
import audioTrack from "../../assets/voice-icon-Animation.gif";
import {
  startSpeechRecognitionWithLangRealTime,
  stopContinuousRecognitionAsync,
  stopSpeechRecognition,
} from "../../utils/speechRecognition";
import { useNavigate } from "react-router-dom";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";

const placeholders = ["Puja", "Temples", "Chadhava"];

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

function GlobalSearchItem({ handelFocus, currentLanguage }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [speechModalOpen, setSpeechModalOpen] = useState(false);
  const [recognizedText, setRecognizedText] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);
  const recognizerRef = useRef<any>(null);
  const [listening, setListening] = useState(false);

  const handleClickOpen = () => {
    setSpeechModalOpen(true);
    handleMicClick();
  };

  const handleClose = () => {
    setSpeechModalOpen(false);
    stopSpeechRecognition(recognizerRef.current);
    setListening(false);
  };

  const handleMicClick = () => {
    if (listening) {
      stopSpeechRecognition(recognizerRef.current);
      setListening(false);
    } else {
      setListening(true);
      setSpeechModalOpen(true);

      recognizerRef.current = startSpeechRecognitionWithLangRealTime(
        "EN",
        (recognizedText: string, detectedLanguage: string) => {
          setListening(false);
          setRecognizedText(recognizedText);
          setTimeout(() => {
            setSpeechModalOpen(false);
            stopContinuousRecognitionAsync(recognizerRef.current);
            navigate("/globalSearch", { state: { text: recognizedText } });
          }, 2000);
        },
        (recognizedText: string, detectedLanguage: string) => {
          setListening(false);
          setRecognizedText(recognizedText);
          setTimeout(() => {
            setSpeechModalOpen(false);
            stopContinuousRecognitionAsync(recognizerRef.current);
            navigate("/globalSearch", {
              state: { text: recognizedText },
              replace: true,
            });
          }, 2000);
        },
        (error: any) => {
          console.error("Recognition error:", error);
          setListening(false);
          setSpeechModalOpen(false);
        }
      );
    }
  };

  useEffect(() => {
    if (!inputValue && !isFocused) {
      const interval = setInterval(() => {
        setIsAnimating(true);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
          setIsAnimating(false);
        }, 500);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [inputValue]);

  const handleSearchFocus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handelFocus();
  };

  const dynamicPosition = () => {
    if (currentLanguage === "en") {
      return { left: "70px" };
    } else if (currentLanguage === "hi") {
      return { left: "80px" };
    } else if (currentLanguage === "bn") {
      return { left: "98px" };
    }
  };

  return (
    <Box className="globalSearchContainer">
      <Box className="searchWrapper">
        {/* Animated Placeholder */}
        {!inputValue && !isFocused && (
          <span className="animatedPlaceholder leftPosition">
            {t(LOCALIZATION.Search_for)}{" "}
            <span
              style={dynamicPosition()}
              className={`changingText ${isAnimating ? "slide-up" : ""}`}
            >
              {" "}
              '{t(placeholders[currentIndex])}'
            </span>
          </span>
        )}

        {/* Search Field */}
        <TextField
          className="GlobalSearchView"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          variant="outlined"
          onFocus={handleSearchFocus}
          onBlur={() => setIsFocused(false)}
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Mic Button */}
        <IconButton onClick={handleClickOpen} className="micButton">
          {listening ? (
            <div className="micRecordingEffect" />
          ) : (
            <Box component="img" src={micIcon} />
          )}
        </IconButton>
      </Box>

      {/* Speech Recognition Modal */}
      <Dialog
        open={speechModalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className="speechRecogModal"
      >
        <DialogContent className="modalInnerContent">
          <DialogContentText id="alert-dialog-slide-description">
            <Typography>
              {t(LOCALIZATION.Hi_I_am_listening_Try_saying)}
            </Typography>
            <Typography variant="h5" className="speechText">
              {listening
                ? t(LOCALIZATION.Listening)
                : `"${recognizedText || "Temples"}"`}
            </Typography>
            <Box className="audioTrackIcon">
              <iframe src="https://lottie.host/embed/ffce58a6-8260-403d-8ae0-d342b86f214a/aoe57tiKze.lottie"></iframe>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default GlobalSearchItem;
