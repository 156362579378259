import Drawer from "@mui/material/Drawer";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import RoomIcon from "@mui/icons-material/Room";
import PolicyIcon from "@mui/icons-material/Policy";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DescriptionIcon from "@mui/icons-material/Description";
import "./RightDrawer.scss";
// import { useSelector } from "react-redux";
import AvatarGenerator from "../profilePic/GenerateAvatar";
import { useEffect, useState } from "react";
import { logoutAction } from "../../store/actions/authAction";
import { APPROLES } from "../../utils/Constants";
import { isMobile } from "react-device-detect";
import GavelIcon from "@mui/icons-material/Gavel";
import PaymentsIcon from "@mui/icons-material/Payments";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import { emptyCartAction } from "../../store/actions/cartAction";
import { Box, Typography } from "@mui/material";
import ContactsIcon from "@mui/icons-material/Contacts";
import PersonIcon from "@mui/icons-material/Person";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ConfirmLogoutModal from "../modal/ConformLogoutModel";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";
function RightDrawer(props: any) {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  var { userInfo } = useSelector((state: any) => state.user);
  const personalInfo = userInfo?.personalInfo;

  const ppUrl = personalInfo?.profilePictureUrl || "";
  const userFName = personalInfo?.firstName || "";
  const userLName = personalInfo?.lastName || "";
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLogoutConfirmModalOpen, setIsLogoutConfirmModalOpen] =
    useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === APPROLES.SUPERADMIN.roleId ||
        userInfo.userType === APPROLES.ADMIN.roleId)
    ) {
      setIsSuperAdmin(true);
    }
  }, [userInfo]);

  const navigateToPage = (navId: string) => {
    props.setOpenRightSidePanel(false);
    navigate(navId);
  };

  const handleLogout = (event: any) => {
    event.preventDefault();
    dispatch(emptyCartAction());
    dispatch(logoutAction());
    navigate("/");
    props.setOpenRightSidePanel(false);
    setIsLogoutConfirmModalOpen(false);
  };
  const handleCancelLogout = () => {
    setIsLogoutConfirmModalOpen(false);
  };
  const getBlogAdminOnlyView = () => {
    if (userInfo && userInfo?.userType === APPROLES.BLOGADMIN.roleId) {
      return (
        <li
          onClick={() => {
            navigateToPage("/externaladmin/posts");
          }}
        >
          <a href="javascript:void(0)">
            <DescriptionIcon />
            Blogs
          </a>
        </li>
      );
    }

    return null;
  };
  return (
    <Drawer
      anchor={"right"}
      open={props.openRightSidePanel}
      onClose={() => props.setOpenRightSidePanel(false)}
    >
      <div className="rightPannelArea">
        {userInfo ? (
          <div
            className="pannelTop"
            onClick={() => {
              if (isMobile) {
                navigateToPage("/profile/");
              } else {
                navigateToPage("/profile/personalInfo");
              }
            }}
          >
            <div className="leftProfileInfo">
              {ppUrl ? (
                <img src={ppUrl} alt="" />
              ) : (
                <AvatarGenerator firstName={userFName} lastName={userLName} />
              )}
              <h6>
                {userFName} {userLName}{" "}
                <p>{t(LOCALIZATION.View_and_edit_profile)}</p>{" "}
              </h6>
            </div>
            <div className="rightArrow">
              <button>
                <ChevronRightRoundedIcon />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="pannelTop"
            onClick={() => {
              if (isMobile) {
                navigateToPage("/login");
              } else {
                navigateToPage("/login");
              }
            }}
          >
            <div className="leftProfileInfo">
              <i className="bi bi-person-circle"></i>

              <h6>{t(LOCALIZATION.Login_Or_SignUp)}</h6>
            </div>
            <div className="rightArrow">
              <button>
                <ChevronRightRoundedIcon />
              </button>
            </div>
          </div>
        )}

        <div className="bottomMenuList1">
          <ul>
            {/* <li>
              <a href="#">
                <CategoryRoundedIcon />
                Categories
              </a>
            </li> */}
            {userInfo ? (
              <li onClick={() => navigateToPage("/myOrders")}>
                <a href="javascript:void(0)">
                  <DashboardCustomizeRoundedIcon />
                  {t(LOCALIZATION.My_Orders)}
                </a>
              </li>
            ) : null}
            {userInfo ? (
              <li onClick={() => navigateToPage("/profile/address")}>
                <a href="javascript:void(0)">
                  <RoomIcon />
                  {t(LOCALIZATION.Address)}
                </a>
              </li>
            ) : null}

            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin");
                }}
                style={{ cursor: "pointer" }}
              >
                {/* <a href="javascript:void(0)"> */}
                <AdminPanelSettingsIcon />
                {t(LOCALIZATION.Admin_Panel)}
                {/* </a> */}
              </li>
            )}
            {getBlogAdminOnlyView()}
            <Box className="forMobileOnly">
              <li>
                <a href="/about">
                  <PersonIcon />
                  {t(LOCALIZATION.About_Us)}
                </a>
              </li>

              <li>
                <a href="/refundscancellations">
                  <PaymentsIcon />
                  {t(LOCALIZATION.Refunds_and_Cancellations)}
                </a>
              </li>

              <li>
                <a href="/communityguidelines">
                  <LocalLibraryIcon />
                  {t(LOCALIZATION.Content_Community_Guidlines)}
                </a>
              </li>

              <li>
                <a href="/privacypolicy">
                  <PolicyIcon />
                  {t(LOCALIZATION.Privacy_Policy)}
                </a>
              </li>
              <li>
                <a href="/termsandconditions">
                  <GavelIcon />
                  {t(LOCALIZATION.Terms_and_Conditions)}
                </a>
              </li>

              <li>
                <a href="/contactus">
                  <ContactsIcon />
                  {t(LOCALIZATION.Contact_Us)}
                </a>
              </li>
            </Box>
            {/* {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/useranalytics");
                }}
              >
                <a href="javascript:void(0)">
                  <AnalyticsIcon />
                  User Analytics
                </a>
              </li>
            )}
            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/sendpush");
                }}
              >
                <a href="javascript:void(0)">
                  <NotificationAddIcon />
                  Send Push Notification
                </a>
              </li>
            )}
            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/couponcode");
                }}
              >
                <a href="javascript:void(0)">
                  <ConfirmationNumberIcon />
                  Manage Coupon Code
                </a>
              </li>
            )} */}
          </ul>
        </div>

        <Box className="appDownloadInfo">
          <li>
            <a href="/apps" className="downloadBtnText" target="_blank">
              <DownloadForOfflineIcon />
              <Typography>{t(LOCALIZATION.Download_App)}</Typography>
            </a>
          </li>
        </Box>

        {userInfo ? (
          <div
            className="logOutBtn"
            onClick={() => setIsLogoutConfirmModalOpen(true)}
          >
            <ul>
              <li>
                <a>
                  <LogoutIcon />
                  {t(LOCALIZATION.Logout)}
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      <ConfirmLogoutModal
        open={isLogoutConfirmModalOpen}
        onConfirm={handleLogout}
        onCancel={handleCancelLogout}
      />
    </Drawer>
  );
}

export default RightDrawer;
