import { useEffect, useState } from "react";
import RoundedButton from "../../button/RoundedButton";
import {
  Box,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Badge,
  Stack,
} from "@mui/material";
import TextArea from "../../textarea/Textarea";
import templeIcon from "../../../assets/temple-icon.png";
import locationIcon from "../../../assets/loc-icon.png";
import pujaNameIcon from "../../../assets/puja-name-icon.png";
import devoteeIcon from "../../../assets/devotee-icon.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

import "./PujaListCard.scss";
import { useSelector } from "react-redux";
import {
  calculateOverSeasPrice,
  getTempleName,
} from "../../../utils/HelperFunctions";
import { LOCALIZATION } from "../../../utils/localization";
import { useTranslation } from "react-i18next";
import { LOCALSTORAGE_KEY } from "../../../utils/Constants";

export default function PujaListCard(props: any) {
  const { t, i18n } = useTranslation();
  const {
    pujaData,
    benefitList,
    temple,
    templeProfilePic,
    pujaName,
    onClickAddPuja,
    isPujaDisable,
    isPromotionalPuja = false,
  } = props;

  const [isPujaOverviewSelected, setIsPujaOverviewSelected] = useState(true);
  const [pujaInfo, setPujaInfo] = useState<any>(null);
  const [staticPrice, setStaticPrice] = useState<any>(0);
  const priceConversionState = useSelector(
    (state: any) => state.priceConversion
  );
  const {
    rate = 1,
    currencySymbols = "₹",
    isOverseas = false,
    platformFeeForChadhava,
  } = priceConversionState.priceConversionRate || {};

  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeName = getTempleName({ personalInfo: temple }, savedLanguage);

  useEffect(() => {
    let pujaDetails: any;
    if (pujaData) {
      if (isPromotionalPuja) {
        pujaDetails = pujaData;
      } else {
        pujaDetails = pujaData?.pujaDetails;
      }
      setPujaInfo(pujaData);
    }
  }, [pujaData]);

  useEffect(() => {
    if (priceConversionState.priceConversionRate) {
      let fixedPrice = 0;
      if (
        pujaInfo?.pujaDetails?.overseasBasicPrice ||
        pujaInfo?.pujaDetails?.indianBasicPrice
      ) {
        fixedPrice = pujaInfo?.pujaDetails?.overseasBasicPrice
          ? pujaInfo?.pujaDetails?.overseasBasicPrice
          : pujaInfo?.pujaDetails?.indianBasicPrice;
        fixedPrice = parseFloat(Number(fixedPrice * rate).toFixed(2)) || 0.0;
        setStaticPrice(fixedPrice);
      }
    }
  }, [priceConversionState.priceConversionRate, pujaInfo]);

  const getMaxAllowedMember = () => {
    let pujaDetails: any;
    if (isPromotionalPuja) {
      pujaDetails = pujaData;
    } else {
      pujaDetails = pujaData?.pujaDetails;
    }

    return pujaDetails?.multiplePujaDetails &&
      pujaDetails?.multiplePujaDetails?.length > 0
      ? pujaDetails?.multiplePujaDetails[
          pujaDetails?.multiplePujaDetails?.length - 1
        ].maxMemberAllowed
      : pujaDetails?.maxMemberAllowed || 1;
  };
  const [bnftText, setBnftText] = useState("");
  useEffect(() => {
    let benifitText = "";
    if (benefitList?.length > 0) {
      benefitList.map((item: any, index = 0) => {
        benifitText += `${index !== 0 ? "|" : ""}  ${item.name} `;
      });
      setBnftText(benifitText);
    }
  }, [benefitList]);

  const pujaAddress =
    (temple?.address?.street ||
      temple?.address?.city ||
      temple?.address?.area) +
    " , " +
    temple?.address?.state;

  return (
    <Box className="pujaAreaCard">
      <Box className="pujaImg">
        <img src={templeProfilePic} alt="" />
        <Badge className="devoteeBadge">
          <Box className="badgeIcon">
            <img src={devoteeIcon} alt="" />
          </Box>
          <Box className="badgeText">
            <Typography>{`${t(LOCALIZATION.Upto)} ${getMaxAllowedMember()} ${t(
              LOCALIZATION.devotees
            )} `}</Typography>
          </Box>
        </Badge>
      </Box>

      <Box className="card-body">
        <Box className="cardTitle">
          <Typography className="mainPujaTitle">{pujaName}</Typography>
          <Typography className="subTitle">{bnftText}</Typography>
        </Box>

        <Box className="pujaDescription">
          <List>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "35px" }}>
                <Avatar alt="Temple Icon" className="squreShape temple">
                  <img src={templeIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={templeName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: "35px" }}>
                <Avatar alt="Location Icon" className="squreShape location">
                  <img src={locationIcon}></img>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={pujaAddress} />
            </ListItem>
          </List>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          {staticPrice > 0 && (
            <Stack
              direction="row"
              alignItems="baseline"
              className="pujaStartPrice"
            >
              <Typography>{t(LOCALIZATION.Starts)}</Typography>
              <Typography className="rupeeSign">{currencySymbols}</Typography>
              <Typography className="priceAmount">{staticPrice}/-</Typography>
            </Stack>
          )}
          {/* <Box className="pujaStartPrice">
            <Typography>Starts <span>₹338/-</span></Typography>
          </Box> */}
          <Button
            variant="contained"
            endIcon={<KeyboardDoubleArrowRightIcon />}
            className="cardLastBtn"
            onClick={() => onClickAddPuja(pujaData)}
            disabled={isPujaDisable}
          >
            {t(LOCALIZATION.Book_Your_Puja)}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}
