import { PujaService } from "td_server";
import { setLoading, setPujaList, setTemplePujaList } from "../slices/managePujaSlice";

export const loading = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getPujasByOrgId =
  (orgId:string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PujaService.getPujasByOrgId(orgId);
      dispatch(setPujaList(response?.pujas));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
};

  export const getPujaById =
  (id: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PujaService.getPujaById(id);
      if (response) {
        dispatch(setTemplePujaList(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const addPuja =
  ( orgId:string,  pujaData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PujaService.addPuja(orgId,pujaData);
      dispatch(getPujasByOrgId(orgId));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

  export const updatePuja =
  (orgId:string,id:string,data: any) => async (dispatch: any, getState: any) => {
    
    try {
      dispatch(setLoading(true));
      const response: any = await PujaService.updatePuja(orgId,id,data);
      dispatch(getPujasByOrgId(orgId));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const deletePujaItem =
  (orgId:string,pujaId:string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await PujaService.deletePujaByPujaId(orgId,pujaId);
      dispatch(getPujasByOrgId(orgId));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };



  // export const resetChadhavaList = () => async (dispatch: any, getState: any) => {
  //   try {
     
  //     dispatch(setChadhavaForTemple([]));
  //     dispatch(setLoading(false));
  //     return Promise.resolve([]);
  //   } catch (e) {
  //     dispatch(setLoading(false));
  //     return Promise.reject("");
  //   }
  // };

  