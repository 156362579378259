import LoginView from "../../Views/Auth/Login/LoginView";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  loginWithPhoneNumber,
  resendOtpAction,
  verifyOtp,
} from "../../store/actions/authAction";
import ParentView from "../../Views/PrarentView/ParentView";
import LoadInitialData from "../../handlers/LoadInitialData";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [searchParams] = useSearchParams();
  const disableGuestButton= searchParams.get("navigatedFromCheckout");
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [phone, setPhone]: any = useState("");
  const [otp, setOtp]: any = useState("");

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [preOtpErrorMsg, setPreOtpErrorMsg] = useState("");
  const [enableResendOtp, setEnableResendOtp] = useState(false);
  const [downTimerCount, setDownTimerCount] = useState(30);
  const otpConfirmation = useSelector((state: any) => state.auth.confirmation);
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [countdown, setCountdown] = useState(120);
  const intervalId = useRef<any>(null);
  const { userInfo } = useSelector((state: any) => state?.user);
  const OTP_RESEN_DDURATION = 30000;
  // useEffect(() => {
  //   (window as any).recaptchaVerifier = new RecaptchaVerifier(
  //     auth,
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: async (response: any) => {},
  //     }
  //   );
  // }, [auth]);
  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [userInfo]);

  useEffect(() => {
    if (otpConfirmation && phone && phone.length !== 0) {
      setIsOtpScreen(true);
    }
  }, [otpConfirmation]);

  // Start countdown timer
  const startCountdown = () => {
    setEnableResendOtp(false);
    setCountdown(120);
    intervalId.current = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(intervalId.current);
          setEnableResendOtp(true);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const sendOtp = async (dialCode: string = "91") => {
    if (phone?.length === 0) {
      return;
    }
    setLoginErrorMessage("");
    setOtp("");
    dispatch(loginWithPhoneNumber(phone, dialCode))
      .then(() => {
        setPreOtpErrorMsg("");
        setIsOtpScreen(true);
        clearInterval(intervalId.current);
        startCountdown();
      })
      .catch((err: any) => {
        setIsOtpScreen(false);
        setPreOtpErrorMsg(err.errorMessage);
      });
  };

  // const resendOtp = async (e: any) => {
  //   try {
  //     dispatch(resendOtpAction(otpConfirmation));
  //   } catch (exception: any) {}
  // };

  const resendOtp = async (e: any) => {
    e.preventDefault();
    if (!enableResendOtp) return;
    // dispatch(resendOtpAction(otpConfirmation)) ( earlier using resend)
    dispatch(loginWithPhoneNumber(phone, selectedDialCode))
      .then(() => {
        clearInterval(intervalId.current);
        startCountdown();
        setOtp("");
      })
      .catch((error: any) => {
        console.error("Error:", error);
      });
  };

  const verifyOtpSubmit = async (e: any, dialCode: any = "91") => {
    e.preventDefault();
    dispatch(verifyOtp(otp, otpConfirmation, phone, dialCode))
      .then((e: any) => {
        if (e.newUser) {
          setIsOtpScreen(false);
          navigate("/choose-profile");
        } else {
          let navUri: any = localStorage.getItem("redirectUri");
          setIsOtpScreen(false);
          LoadInitialData(dispatch);
          if (navUri && navUri.length) {
            localStorage.removeItem("redirectUri");
            navigate(navUri);
          } else {
            navigate("/");
          }
        }
      })
      .catch((error: any) => {
        console.error("Error:", error);
        if (error === "InvalidOtp") {
          setLoginErrorMessage("Invalid OTP entered please re enter OTP");
        }
      });
  };

  // const addSetInterVal = ()=>{
  //   interValsetFunId = setInterval(() => {
  //     if (!enableResendOtp) {
  //       setDownTimerCount(downTimerCount - 1);
  //     }
  //     else{
  //       clearInterval(interValsetFunId);
  //     }
  //   }, 1000);
  // }

  function onClickSignUp() {
    navigate("/signup");
  }

  return (
    <div>
      <ParentView>
        <LoginView
          onClickSignUp={onClickSignUp}
          isOtpScreen={isOtpScreen}
          setIsOtpScreen={setIsOtpScreen}
          sendOtp={sendOtp}
          verifyOtpSubmit={verifyOtpSubmit}
          setOtp={setOtp}
          setPhone={setPhone}
          phone={phone}
          otp={otp}
          resendOtp={resendOtp}
          otpConfirmation={otpConfirmation}
          loginErrorMessage={loginErrorMessage}
          enableResendOtp={enableResendOtp}
          downTimerCount={downTimerCount}
          preOtpErrorMsg={preOtpErrorMsg}
          setPreOtpErrorMsg={setPreOtpErrorMsg}
          countdown={countdown}
          selectedDialCode={selectedDialCode}
          setSelectedDialCode={setSelectedDialCode}
          disableGuestButton ={disableGuestButton}
        />
      </ParentView>
    </div>
  );
}

export default LoginScreen;
