import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, IconButton, Paper, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";


interface TempleListViewProps {
  templeList: any[];
  handleEdit: (row: any) => void;
  handleAdd: () => void;
  handleVerify: (row: any) => void;
}

const TempleListView: React.FC<TempleListViewProps> = ({
  templeList,
  handleEdit,
  handleAdd,
  handleVerify,
}) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Title",
      width: 300,
      renderCell: (params) => {
        return <Typography>{params?.row?.personalInfo?.name}</Typography>;
      },
    },
    {
      field: "isPublished",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <span
            className={
              params?.row?.verifications?.status === "VERRIFIED"
                ? "statusPublished"
                : "statusUnpublished"
            }
          >
            {params?.row?.verifications?.status || "UNVERRIFIED"}
          </span>
        );
      },
    },
    {
      field: "shortDescription",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.personalInfo?.descriptions}</Typography>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleEdit(params.row as any)}>
            <EditIcon sx={{ color: "#dea400" }} />
          </IconButton>
          <Button className={
              params?.row?.verifications?.status === "VERRIFIED"
                ? "statusUnpublished"
                : "statusPublished"
            } onClick={() => handleVerify(params.row as any)} variant="contained" >
          { params?.row?.verifications?.status === "VERRIFIED" ? "Unverify": "Verify" }
          </Button>

        </>
      ),
    },
  ];

  return (
    <Box className="pageContainer">
      <Box className="topTitle">
        <Typography className="adminTitleText">Temple Onboarding</Typography>
      </Box>
      <Box className="couponCodeView">
        <Paper elevation={3}>
          <Box mb={2} className="btnAddCoupon">
            <Button onClick={handleAdd} variant="contained">
              <AddCircleOutlineIcon
                sx={{ fontSize: "20px", marginRight: "5px" }}
              />{" "}
              Add Temple Onboard{" "}
            </Button>
          </Box>
          {templeList && templeList.length > 0 && (
            <Box className="couponDataList">
              <DataGrid
                className="couponDataGrid"
                rows={templeList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                      page: 0,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                autoHeight
              />
            </Box>
          )}
          {templeList && templeList.length === 0 && (
            <Typography sx={{ padding: "1rem" }}>Empty List</Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default TempleListView;
