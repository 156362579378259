import { Box } from '@mui/material'
import ReactPlayer from 'react-player'
function PostViewer(props:any) {
    var postType = "image"
    if(props.fileType.includes("image")){
        postType = "image"
    }
    else if(props.fileType.includes("video")){
        postType="video"
    }
    switch(postType){
        case "image": return <ImageViewer url={props.url} />
        case "video" :return <VideoViewer url={props.url} fileType={props.fileType} />
        default: return null
    }
  }

  function ImageViewer(props:any) {
    return (
        <Box>
            <img src={props.url} ></img>
        </Box>
    )
  }
  
  function VideoViewer(props:any) {
    return (
        <Box>
            <video controls >
            <source src={props.url}
      />
            </video>
        </Box>
    )
  }
  export default PostViewer;
  