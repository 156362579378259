import React from "react";
import verifytick from "../../../assets/verify-tick.png";
import { Box, Typography } from "@mui/material";
import cardCircleImg from "../../../assets/pt3.jpg";

import "./searchCard.scss";
import SubtitleTypography from "../../typography/SubtitleTypography";
import ParagraphTypography from "../../typography/ParagraphTypography";
import TempleCard from "../Temple/TempleCard";
import ganpati from "../../../assets/ganpati-temple.jpg";
import TempleDetailsCard from "../Temple/TempleDetailsCard";
import PujaListCard from "../Puja/PujaListCard";
import { getOneImageForTemple } from "../../../utils/HelperFunctions";

export default function SearchCard(props: any) {
  const searchedItem = props.searchedItem;
  const personlaInfo = searchedItem?.personalInfo;
  if (searchedItem.dataType === "temple") {
    return (

      <TempleDetailsCard
        id={0}
        templeData={searchedItem}
        templePictures={
          searchedItem.personalInfo &&
          searchedItem.personalInfo.media &&
          searchedItem.personalInfo.media.pictures
            ? searchedItem.personalInfo.media.pictures
            : []
        }
        templeImage={
          searchedItem.personalInfo &&
          searchedItem.personalInfo.media &&
          searchedItem.personalInfo.media.pictures &&
          searchedItem.personalInfo.media.pictures.length > 0
            ? searchedItem.personalInfo.media.pictures[0].pictureUrl
            : ganpati
        }
        verificationStatus={searchedItem.verifications.status}
        templeName={searchedItem.personalInfo.name}
        descriptions={searchedItem.personalInfo.descriptions}
        googleData={searchedItem.googleData}
        selectTemple={props.onClickNavigateTemple}
      />
    );
  } else if (searchedItem.dataType === "templePuja") {
    let templeProfilePic = getOneImageForTemple(searchedItem.personalInfo?.media?.pictures)
    return (
      <PujaListCard
        pujaData={searchedItem}
        pujaName={searchedItem.pujaDetails.pujaName}
        templeProfilePic={searchedItem.pujaDetails.pujaBg?.uri || templeProfilePic}
        description={searchedItem.pujaDetails.description}
        benefits={searchedItem.pujaDetails.benefits}
        acctualPrice={searchedItem.pujaDetails.acctualPrice}
        onClickAddPuja={props.onClickNavigatePuja}
        temple={searchedItem.personalInfo}
        devoteeCount={searchedItem.pujaDetails?.maxMemberAllowed}
        benefitList = {searchedItem?.pujaDetails?.benefitList}
        isPujaDisable={searchedItem?.pujaDetails?.isPujaDisable}
      />
    );
  } else {
    return <Box>Nothing to show</Box>;
  }
}
