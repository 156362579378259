import React from "react";
import { Autocomplete, TextField, Chip } from "@mui/material";


const VerifyAdminAutocomplete: React.FC<any> = ({
  options,
  onChange,
  label,
  placeholder,
  alreadySelectedOptions = [],
}) => {

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {

    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      options={options || []}
      getOptionLabel={(option: any) =>
        `${option?.personalInfo?.firstName} ${option?.personalInfo?.lastName} - ${option?.phoneNumber}`
      }
      value={alreadySelectedOptions}
      onChange={handleChange}
      renderTags={(value: any[], getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            label={`${option?.personalInfo?.firstName} ${option?.personalInfo?.lastName} - ${option?.phoneNumber}`}
            {...getTagProps({ index })}
            key={option?.id}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      sx={{
        height: "50px",
        width: "50%",
        ".MuiSelect-select": {
          display: "flex",
          alignItems: "center",
        },
      }}
    />
  );
};

export default VerifyAdminAutocomplete;
