import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material';
import alertImg from "../../assets/warning.png";
import "./AlertDialog.scss";
import { useTranslation } from 'react-i18next';
import { LOCALIZATION } from '../../utils/localization';

interface AlertDialogProps {
  open: boolean;
  onClose: () => void;
  message: string;
}
 const AlertDialog: React.FC<AlertDialogProps> = ({ open, onClose, message }) => {
  const { t, i18n } = useTranslation();
  return (
    <Dialog className="pujaNotAvaliableDialog" open={open} onClose={onClose}>
      <Box className="alertImage">
        <img
          src={alertImg}
          alt="Alert"
        />
      </Box>
      <DialogTitle className="title">{t(LOCALIZATION.Alert)}</DialogTitle>
      <DialogContent className="notAvaliableDescription">
        <p>{message}</p>
      </DialogContent>
      <DialogActions sx={{ p: 0, mt: "10px" }}>
        <Button className="okBtn" onClick={onClose} variant="contained">
          {t(LOCALIZATION.OK)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

