import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import MultiDateCalendar from "../../../Screen/MultiDateCalendar/MultiDateCalendar";
import { Stack } from "react-bootstrap";
interface ChadhawaFormModalProps {
  isOpen: boolean;
  temple: any | null;
  onClose: () => void;
  onSave: (temple: any) => void;
}

const ChadhawaFormModal: React.FC<ChadhawaFormModalProps> = ({
  isOpen,
  temple,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    searchKey:"",
    itemDescription: "",
    acctualPrice: "",
    isMostOfferedTag: false,
    image: "",
    weightage: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    searchKey:"",
    itemDescription: "",
    acctualPrice: "",
    image: "",
    weightage: "",
  });
  const daysArray: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDays, setsetSelectedDays] = useState<string[]>([]);
  const [days, setDays] = useState<string[]>(daysArray);
  const [localImage, setLocalImage] = useState<any>("");

  const setSaledarDate = (date: string[]) => {
    const isDay = (item: any) => daysArray.includes(item);
    const isDate = (item: any) => /\d{4}-\d{2}-\d{2}/.test(item);
    setsetSelectedDays(date.filter(isDay));
    setSelectedDates(date.filter(isDate));
  };

  const resetForm = () => {
    setLocalImage("");
    setsetSelectedDays([]);
    setSelectedDates([]);
    setFormData({
      id: "",
      name: "",
      searchKey:"",
      itemDescription: "",
      acctualPrice: "",
      isMostOfferedTag: false,
      image: "",
      weightage: "",
    });
    setErrors({
      name: "",
      searchKey:"",
      itemDescription: "",
      acctualPrice: "",
      image: "",
      weightage: "",
    });
  };

  useEffect(() => {
    resetForm();
    if (temple) {
      if (temple?.restrictionChadavaDate) {
        setSaledarDate(temple?.restrictionChadavaDate);
      }
      setFormData(temple);
    }
  }, [temple]);

  const handleDateChange = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));



    if (name === "acctualPrice") {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(value)) return;
    }
    if (name === "weightage") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) return;
    }
    if (type === "file" && files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setLocalImage(e.target?.result);
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev) => ({
        ...prev,
        imageFile: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value?.trim(),
      }));
    }
  };
  const validateForm = () => {
    const newErrors = {
      name: formData?.name?.trim() ? "" : "Name is required",
      searchKey: formData?.searchKey?.trim() ? "" : "searchKey is required",
      itemDescription: formData?.itemDescription?.trim()
        ? ""
        : "Description is required",
      acctualPrice: formData?.acctualPrice?.trim() ? "" : "Price is required",
      image: localImage || formData?.image ? "" : "Image is required",
      weightage:
        formData?.weightage?.trim() && Number(formData?.weightage) > 0
          ? ""
          : "Weightage must be a positive number",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    const dates = [...selectedDays, ...selectedDates];
    const newData: any = Object.assign({}, formData);
    newData["restrictionChadavaDate"] = dates;
    onSave(newData);
    resetForm();
  };

  const handleSetSelectedDays = (days: any) => {
    setsetSelectedDays((prev) => [...prev, days]);
  };

  const handleRemoveSelectedDays = (dayToRemove: string) => {
    setsetSelectedDays((prev) =>
      prev.filter((day: any) => day !== dayToRemove)
    );
  };
  const handleCloseFormModal = () => {
    onClose();
    if (temple) {
      if (temple?.restrictionChadavaDate) {
        setSaledarDate(temple?.restrictionChadavaDate);
      }
      setFormData(temple);
      return;
    }
    resetForm();
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseFormModal}>
      <DialogTitle>{temple ? "Edit" : "Add"} Chadhawa Item</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <TextField
          name="name"
          label="Name"
          fullWidth
          margin="normal"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <TextField
          name="searchKey"
          label="Add Search Key"
          fullWidth
          margin="normal"
          value={formData.searchKey}
          onChange={handleChange}
          error={!!errors.searchKey}
          helperText={errors.searchKey}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <Stack className="chooseImgInfo">
          <TextField
            name="image"
            label="Upload Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
            error={!!errors.image}
            helperText={errors.image}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          {localImage?.length == 0 && formData?.image?.length > 0 && (
            <img src={formData?.image} />
          )}
          {localImage?.length > 0 && <img src={localImage} />}
        </Stack>

        <TextField
          name="itemDescription"
          label="Description"
          fullWidth
          margin="normal"
          value={formData.itemDescription}
          onChange={handleChange}
          error={!!errors.itemDescription}
          helperText={errors.itemDescription}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
          multiline
          rows={6}
          sx={{
            textarea: {
              minHeight: "20px",
            },
          }}
        />
        <TextField
          name="weightage"
          label="Weightage"
          fullWidth
          margin="normal"
          type="number"
          InputProps={{
            inputMode: "numeric",
          }}
          value={formData?.weightage}
          onChange={handleChange}
          error={!!errors.weightage}
          helperText={errors.weightage}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />
        <TextField
          name="acctualPrice"
          label="Price"
          fullWidth
          margin="normal"
          value={formData.acctualPrice}
          onChange={handleChange}
          error={!!errors.acctualPrice}
          helperText={errors.acctualPrice}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />

        <Box className="day-buttons-container">
          {days &&
            days?.map((el: any, index: number) => (
              <Box
                className={`day ${
                  selectedDays?.includes(el) ? "active_day" : ""
                }`}
                key={`days${index}`}
                onClick={(e) => {
                  selectedDays?.includes(el)
                    ? handleRemoveSelectedDays(el)
                    : handleSetSelectedDays(el);
                }}
              >
                {el}
              </Box>
            ))}
        </Box>
        <Box className="chadhavaAdminCalendar">
          <MultiDateCalendar
            selectedDates={selectedDates}
            onDateChange={handleDateChange}
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={formData.isMostOfferedTag}
              onChange={handleChange}
              name="isMostOfferedTag"
            />
          }
          label="Is Most Offered"
        />
      </DialogContent>
      <DialogActions className="chadhawaForm modalFooter">
        <Button onClick={handleCloseFormModal} className="btnCancel grey">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChadhawaFormModal;
