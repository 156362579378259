
import ParentView from "../../Views/PrarentView/ParentView";
import GlobalSearchView from "../../components/GlobalSearchItem/GlobalSearchView";


function GlobalSearchScreen() {
  

  return (
    <ParentView>
      <GlobalSearchView />
    </ParentView>
  );
}

export default GlobalSearchScreen;
