import { Navigate } from "react-router-dom";
import { APPROLES } from "../utils/Constants";
import AdminLayoutScreen from "../Screen/Admin/AdminLayoutScreen";
function AdminHandlerRoutes() {
  const userInfoFromExtractLocal = localStorage.getItem("userInfo") || "{}";
  if(userInfoFromExtractLocal === 'undefined'){
    return <Navigate to="/" />;
  } 

  const userInfoLocalJsonParsed = JSON?.parse(userInfoFromExtractLocal);
  // if (userInfo?.userType!==APPROLES.SUPERADMIN.roleId) {
  if (
    userInfoLocalJsonParsed &&
    userInfoLocalJsonParsed?.userType !== APPROLES.SUPERADMIN.roleId
  ) {
    return <Navigate to="/" />;
  } else {
    // return <Outlet />;
    return <AdminLayoutScreen />
  }
}

export default AdminHandlerRoutes;
