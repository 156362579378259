import ganpati from "../../assets/ganpati-temple.jpg";
// import "./TempleView.scss";
import TempleDetailsCard from "../../components/card/Temple/TempleDetailsCard";
import { Box, CircularProgress, Grid } from "@mui/material";
import {
  getImageListFromTemple,
  getTempleName,
} from "../../utils/HelperFunctions";
import { LOCALSTORAGE_KEY } from "../../utils/Constants";

function ChadhavaView(props: any) {
  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <Box className="templeCenterBlock">
              {props?.isLoading && (
                <Box className="loaderContainer">
                  <CircularProgress />
                </Box>
              )}
              {props.templeList &&
                props.templeList.map((item: any, id: any) => {
                  const chadhavaImagesList = getImageListFromTemple(
                    item?.personalInfo,
                    "chadavaPictures"
                  );
                  const templeName = getTempleName(item, savedLanguage);

                  return (
                    <TempleDetailsCard
                      id={id}
                      key={`templeCenterBlock${id}`}
                      templeData={item}
                      templePictures={chadhavaImagesList}
                      templeImage={
                        item.personalInfo &&
                        item.personalInfo.media &&
                        item.personalInfo.media.pictures &&
                        item.personalInfo.media.pictures.length > 0
                          ? item.personalInfo.media.pictures[0].pictureUrl
                          : ganpati
                      }
                      verificationStatus={item.verifications.status}
                      templeName={templeName}
                      descriptions={item.personalInfo.descriptions}
                      // descriptions='The Jagannath Temple is an important Hindu temple dedicated to Jagannath, a form of Vishnu–one of the trinity of supreme'
                      selectTemple={props.selectTemple}
                      googleData={item.googleData}
                    />
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ChadhavaView;
