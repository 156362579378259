import React, { useEffect } from 'react';
import ParentView from '../../Views/PrarentView/ParentView';
import CommunityGuidelinesView from '../../Views/CommunityGuidelines/CommunityGuidelinesView';



const CommunityGuidelinesScreen: React.FC = () => {
    useEffect(() => {

    }, []);

    return (
        <ParentView>
            <CommunityGuidelinesView />
        </ParentView>
    );
};

export default CommunityGuidelinesScreen;
