/* eslint-disable @typescript-eslint/no-unused-vars */
import { CouponService } from "td_server";
import { setLoading, setCouponCodeList, setActiveCouponCodeList } from "../slices/couponCodeSlice";

export const updateCouponCodeSliceAction =
  (formData: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      let couponCreateResponse: any = null;
      if (formData?.id) {
        couponCreateResponse = await CouponService.updateSingleCoupon(formData, formData.id);
      } else {
        couponCreateResponse = await CouponService.createCoupon(formData);

      }
      dispatch(getCouponCodeList());
      // if(couponCreateResponse){
      //   dispatch(getCouponCodeList);
      // }
      dispatch(setLoading(false));
      return Promise.resolve("");
    } catch (error: any) {
      console.log("Error: ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getCouponCodeList = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await CouponService.getAllActiveCoupons();
    if(response&&response.couponList){
      const fdata = response.couponList;
    dispatch(setCouponCodeList(fdata));
    }
    
    dispatch(setLoading(false));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const getAllActiveCouponsByDate = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const response: any = await CouponService.getAllActiveCouponsByDate();
    if(response&&response.couponList){
      const fdata = response.couponList;
    dispatch(setActiveCouponCodeList(fdata));
    }
    dispatch(setLoading(false));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const updateCouponsUsageAction =
  (couponCode: any, userPhoneNumber: any) =>
    async (dispatch: any, getState: any) => {
      try {
        dispatch(setLoading(true));
        const response: any = await CouponService.updateCouponsUsage(couponCode, userPhoneNumber);
        dispatch(setLoading(false));

        return Promise.resolve("");

      } catch (e) {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
    };
