import { checkUserDetails } from "../store/actions/authAction";
import { setGlobalLoader } from "../store/actions/loaderAction";
function CheckUserFromOAuth(dispatch:any){

   
  try{
    dispatch(setGlobalLoader(true))
    let userDataFromSession :any= localStorage.getItem("userInfo")
    let parsedUserData = null
    if(userDataFromSession&&userDataFromSession!=='undefined'&&userDataFromSession!=='null'){
      parsedUserData = JSON.parse(userDataFromSession)
    }
    if(parsedUserData.id){
      dispatch(setGlobalLoader(true))
     dispatch(checkUserDetails(parsedUserData.phoneNumber))
      // dispatch(checkUserDetails("+917278091832"))
    }
      else{
        dispatch(setGlobalLoader(false))
      }
  }
  catch(error) {
    console.log("Error:",error)
  }
  finally{
    dispatch(setGlobalLoader(false))
  }
 
}

export default CheckUserFromOAuth