import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import "./AIGurujiReportStringToTable.scss";

interface AIGurujiReportStringToTableProps {
  reportString: string;
  tableName: string;
}

const AIGurujiReportStringToTable: React.FC<
  AIGurujiReportStringToTableProps
> = ({ tableName, reportString }) => {
  // Split the report string into header and rows
  // const newSrting = reportString.replaceAll("-", "").replaceAll("+", "")
  // const lines: string[] = newSrting.trim().split('\n');
  // const header: string[] = lines[1].split('|').map((item) => item.trim()).filter(item => item);
  // const rows: string[][] = lines.slice(2).map(line =>
  //   line.split('|').map((item) => item.trim()).filter(item => item)
  // );

  const newString = reportString.replaceAll("-", "").replaceAll("+", "");
  const lines = newString.trim().split("\n");
  const header = lines[0]
    .split("|")
    .map((item) => item.trim())
    .filter((item) => item);
  const rows = lines.slice(2).map((line) =>
    line
      .split("|")
      .map((item) => item.trim())
      .filter((item) => item)
  );

  return (
    <>
      <Typography className="reportTableTitle">{tableName}</Typography>
      <TableContainer className="reportTableSec" component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {header.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AIGurujiReportStringToTable;
