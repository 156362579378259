import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { adminChadhawaViewLabel } from "../../utils/ConstantsMessages";

interface ChadhawaWeightageDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    weightage: string,
    indianMinimumPrice: any,
    overseasMinimumPrice: any
  ) => void;
  selectedTemple: any;
}

const ChadhawaWeightageDilog: React.FC<ChadhawaWeightageDialogProps> = ({
  open,
  onClose,
  onSubmit,
  selectedTemple,
}) => {
  const [weightage, setWeightage] = React.useState("");
  // const [formError, setFormError] = useState<boolean>(false);
  const [indianMinimumPrice, setIndianMinimumPrice] = React.useState("");
  const [overseasMinimumPrice, setOverseasMinimumPrice] = React.useState("");
  const [weightageError, setWeightageError] = useState(false);
  const [indianPriceError, setIndianPriceError] = useState(false);
  const [overseasPriceError, setOverseasPriceError] = useState(false);

  useEffect(() => {
    if (selectedTemple) {
      setWeightage(selectedTemple?.chadhavaWeightageForTemple ?? "");
      setIndianMinimumPrice(selectedTemple?.minBookingChadhavaPriceIND ?? "");
      setOverseasMinimumPrice(
        selectedTemple?.minBookingChadhavaPriceOverseas ?? ""
      );
    }
  }, [selectedTemple]);

  const handleSubmit = () => {
    let error = false;

    if (!weightage) {
      setWeightageError(true);
      error = true;
    } else {
      setWeightageError(false);
    }

    if (!indianMinimumPrice) {
      setIndianPriceError(true);
      error = true;
    } else {
      setIndianPriceError(false);
    }

    if (!indianMinimumPrice) {
      setOverseasPriceError(true);
      error = true;
    } else {
      setOverseasPriceError(false);
    }
    if (error) return;

    onSubmit(weightage, indianMinimumPrice, overseasMinimumPrice);
    handleCloseWeightageDialog();
  };

  const handleWeightageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    const newValue = e?.target?.value;
    setWeightageError(false);
    setWeightage(newValue);
  };

  const handleIndianMinimumPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    e?.preventDefault();
    const newValue = e?.target?.value;
    setIndianMinimumPrice(newValue);
    setIndianPriceError(false);
  };
  const handleOverseasMinimumPrice = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const newValue = e?.target?.value;
    setOverseasMinimumPrice(newValue);
    setOverseasPriceError(false);
  };

  const handleCloseWeightageDialog = () => {
    onClose();
    setWeightageError(false);
    setIndianPriceError(false);
    setOverseasPriceError(false);
    setWeightage(selectedTemple?.chadhavaWeightageForTemple);
    setIndianMinimumPrice(selectedTemple?.indianMinimumPriceForTemple);
    setOverseasMinimumPrice(selectedTemple?.overseasMinimumPriceForTemple);
  };
  return (
    <Dialog open={open} onClose={handleCloseWeightageDialog}>
      <DialogTitle>
        {adminChadhawaViewLabel?.chadhavaWeightageDialogTitle}
      </DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <Box>
          <TextField
            label={adminChadhawaViewLabel?.weightageLabel}
            type="number"
            fullWidth
            value={weightage || ""}
            onChange={handleWeightageChange}
            margin="normal"
            error={weightageError}
            helperText={
              weightageError
                ? adminChadhawaViewLabel?.weightageErrorMessage
                : ""
            }
          />
        </Box>
        <Box>
          <TextField
            label={adminChadhawaViewLabel?.indianPriceLabel}
            type="number"
            fullWidth
            value={indianMinimumPrice || ""}
            onChange={handleIndianMinimumPrice}
            margin="normal"
            placeholder="Enter amount"
            InputProps={{
              startAdornment: <span style={{ marginRight: "5px" }}>₹</span>,
            }}
            error={indianPriceError}
            helperText={
              indianPriceError
                ? adminChadhawaViewLabel?.indianPriceErrorMessage
                : ""
            }
          />
          <Typography sx={{color: '#ca5261'}}>{adminChadhawaViewLabel?.noteIndia}</Typography>
        </Box>
        <Box>
          <TextField
            label={adminChadhawaViewLabel?.overseasPriceLabel}
            type="number"
            fullWidth
            value={overseasMinimumPrice || ""}
            onChange={handleOverseasMinimumPrice}
            margin="normal"
            placeholder="Enter amount"
            InputProps={{
              startAdornment: <span style={{ marginRight: "5px" }}>$</span>,
            }}
            error={overseasPriceError}
            helperText={
              overseasPriceError
                ? adminChadhawaViewLabel?.overseasErrorMessage
                : ""
            }
          />
          <Typography sx={{color: '#ca5261'}}>{adminChadhawaViewLabel?.noteOverSeas}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWeightageDialog}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChadhawaWeightageDilog;
