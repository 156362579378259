import React, { useState, forwardRef, HTMLAttributes, useEffect } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CityAutocomplete from "../../../../components/AutoComplete/CityAutocomplete";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import dayjs, { Dayjs } from "dayjs";
import girlIcon from "../../../../assets/girl-birth-icon.png";
import boyIcon from "../../../../assets/boy-birth-icon.png";
import kundliDividerIcon from "../../../../assets/kundli-bind-heart.png";
import "./AIGurujiKundliMatchingForm.scss";
import { labels } from "../../../../utils/ConstantsMessages";
import { getSplicedDateData } from "../../../../utils/HelperFunctions";

interface KundliMatchingProps extends HTMLAttributes<HTMLDivElement> {
  buttonName: string;
  handleSubmitForm: (data: any) => void;
  isDisabled: any;
  selectedLang: string;
  formFilledData: any;
}

const AIGurujiKundliMatchingForm = forwardRef<
  HTMLDivElement,
  KundliMatchingProps
>(
  (
    { buttonName, handleSubmitForm, isDisabled, selectedLang, formFilledData },
    ref
  ) => {
    const [langLabels, setlangLabels] = useState<any>(
      labels[selectedLang === "EN" ? "EN" : "HI"]
    );
    const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);

    const [girlDetails, setGirlDetails] = useState({
      fullName: "",
      dateOfBirth: null as Dayjs | null,
      timeOfBirth: null as Dayjs | null,
      birthLocation: "",
      nation: "",
      latitude: "",
      longitude: "",
      originalCityAutoCompleteValue: null,
    });

    const [boyDetails, setBoyDetails] = useState({
      fullName: "",
      dateOfBirth: null as Dayjs | null,
      timeOfBirth: null as Dayjs | null,
      birthLocation: "",
      nation: "",
      latitude: "",
      longitude: "",
      originalCityAutoCompleteValue: null,
    });

    useEffect(() => {
      if (hasErrors(errors)) {
        validateForm();
      }
    }, [langLabels]);

    useEffect(() => {
      if (selectedLang && !isDisabled) {
        setlangLabels(labels[selectedLang === "EN" ? "EN" : "HI"]);
      }
    }, [selectedLang, isDisabled]);
    useEffect(() => {
      if (formFilledData) {
        const formdata = JSON.parse(formFilledData);
        setGirlDetails({
          fullName: formdata?.girlDetails?.fullName,
          dateOfBirth: dayjs(formdata?.girlDetails.dateOfBirth),
          timeOfBirth: dayjs(formdata?.girlDetails.timeOfBirth),
          birthLocation: formdata?.girlDetails?.birthLocation || "",
          nation: formdata?.girlDetails?.nation,
          latitude: formdata?.girlDetails?.lat,
          longitude: formdata?.girlDetails?.lng,
          originalCityAutoCompleteValue:
            formdata?.girlDetails?.originalCityAutoCompleteValue,
        });

        setBoyDetails({
          fullName: formdata?.boyDetails?.fullName,
          dateOfBirth: dayjs(formdata?.boyDetails.dateOfBirth),
          timeOfBirth: dayjs(formdata?.boyDetails.timeOfBirth),
          birthLocation: formdata?.boyDetails?.birthLocation || "",
          nation: formdata?.boyDetails?.nation,
          latitude: formdata?.girlDetails?.lat,
          longitude: formdata?.girlDetails?.lng,
          originalCityAutoCompleteValue:
            formdata?.boyDetails?.originalCityAutoCompleteValue,
        });
      }
    }, [formFilledData]);

    const [errors, setErrors] = useState({
      girl: {
        fullName: "",
        dateOfBirth: "",
        timeOfBirth: "",
        birthLocation: "",
      },
      boy: {
        fullName: "",
        dateOfBirth: "",
        timeOfBirth: "",
        birthLocation: "",
      },
    });

    const hasErrors = (_errors: any) => {
      for (const key in _errors) {
        if (_errors.hasOwnProperty(key)) {
          const nestedObject = _errors[key];
          for (const field in nestedObject) {
            if (nestedObject.hasOwnProperty(field)) {
              if (nestedObject[field] !== "") {
                return true;
              }
            }
          }
        }
      }
      return false;
    };
    const validateForm = () => {
      const validateDetails = (details: any) => {
        const dateIsValid =
          details.dateOfBirth &&
          dayjs(details.dateOfBirth).isValid() &&
          details.dateOfBirth.year() > 0 &&
          details.dateOfBirth.month() >= 0 &&
          details.dateOfBirth.date() > 0;

        const timeIsValid =
          details.timeOfBirth &&
          dayjs(details.timeOfBirth).isValid() &&
          details.timeOfBirth.hour() >= 0 &&
          details.timeOfBirth.minute() >= 0;

        return {
          fullName: details.fullName ? "" : langLabels.fullNameError,
          dateOfBirth: dateIsValid ? "" : langLabels.dateOfBirthError,
          timeOfBirth: timeIsValid ? "" : langLabels.timeOfBirthError,
          birthLocation: details.birthLocation
            ? ""
            : langLabels.birthLocationError,
        };
      };

      const girlErrors = validateDetails(girlDetails);
      const boyErrors = validateDetails(boyDetails);
      setErrors({ girl: girlErrors, boy: boyErrors });

      return (
        Object.values(girlErrors).every((error) => error === "") &&
        Object.values(boyErrors).every((error) => error === "")
      );
    };

    const handleInputChange = (
      type: "girl" | "boy",
      field: string,
      value: any
    ) => {
      const setDetails = type === "girl" ? setGirlDetails : setBoyDetails;
      const details = type === "girl" ? girlDetails : boyDetails;
      if (field === "birthLocation") {
        const { countryCode, label, lat, lng } = value;
        setDetails({
          ...details,
          birthLocation: label,
          nation: countryCode,
          latitude: lat,
          longitude: lng,
          originalCityAutoCompleteValue: value || null,
        });
      } else {
        setDetails({ ...details, [field]: value });
      }
    };

    const submitForm = (e: React.FormEvent) => {
      e.preventDefault();

      if (validateForm()) {
        const girlsBirthDate = getSplicedDateData(
          girlDetails?.dateOfBirth,
          "date"
        );
        const girlsBirthTime = getSplicedDateData(
          girlDetails?.timeOfBirth,
          "time"
        );
        const boysBirthDate = getSplicedDateData(
          boyDetails?.dateOfBirth,
          "date"
        );
        const boysBirthTime = getSplicedDateData(
          boyDetails?.timeOfBirth,
          "time"
        );
        const matchMakingBirthDetailsData: any = {
          first_person_details: {
            name: girlDetails?.fullName,
            city: girlDetails?.birthLocation,
            nation: girlDetails?.nation,
            gender: "female",
            ...girlsBirthDate,
            ...girlsBirthTime,
            latitude: girlDetails?.latitude,
            longitude: girlDetails?.longitude,
            originalCityAutoCompleteValue:
              girlDetails?.originalCityAutoCompleteValue,
          },
          second_person_details: {
            name: boyDetails?.fullName,
            city: boyDetails?.birthLocation,
            nation: boyDetails?.nation,
            gender: "male",
            ...boysBirthDate,
            ...boysBirthTime,
            latitude: boyDetails?.latitude,
            longitude: boyDetails?.longitude,
            originalCityAutoCompleteValue:
              boyDetails?.originalCityAutoCompleteValue,
          },
        };

        setIsFormSubmit(true);
        handleSubmitForm({
          data: matchMakingBirthDetailsData,
          formData: { boyDetails, girlDetails },
        });
      }
    };

    return (
      <Box ref={ref} className="kundliMatchingFormInfo">
        <Typography variant="h6" className="formTitle">
          {langLabels.KundaliMatchingTitle}
          <span>*</span>
        </Typography>
        <Box className="girlBirthInfo">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            className="birthInfoTitle"
          >
            <Box component="img" src={girlIcon} />
            <Typography>{langLabels.GirlsBirthDetails}</Typography>
          </Stack>
          <Grid container spacing={2} className="innerFormDetails">
            <Grid item xs={12}>
              <label>{langLabels.girlFullName}</label>
              <TextField
                variant="standard"
                placeholder={langLabels.girlFullNamePlaceholder}
                fullWidth
                onChange={(e) => {
                  const newValue = e.target.value;
                  const isValid = /^[\u0900-\u097F A-Za-z\s]*$/.test(newValue);
                  if (!isValid) {
                    setErrors((prevError) => ({
                      ...prevError,
                      girl: {
                        ...prevError.girl,
                      },
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      girl: {
                        ...prevErrors.girl,
                        fullName: "",
                      },
                    }));

                    handleInputChange("girl", "fullName", newValue);
                  }
                }}
                value={girlDetails.fullName}
                error={!!errors.girl.fullName}
                helperText={errors.girl.fullName}
                disabled={isDisabled || isFormSubmit}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <label>{langLabels.dateOfBirth}</label>
                <DatePicker
                  disabled={isDisabled || isFormSubmit}
                  value={girlDetails.dateOfBirth}
                  onChange={(newDate) => {
                    handleInputChange("girl", "dateOfBirth", newDate);
                    if (errors.girl?.dateOfBirth) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        girl: {
                          ...prevErrors.girl,
                          dateOfBirth: "",
                        },
                      }));
                    }
                  }}
                  maxDate={dayjs()}
                  sx={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors.girl?.dateOfBirth,
                      helperText: errors.girl?.dateOfBirth,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <label>{langLabels.timeOfBirth}</label>
                <TimePicker
                  disabled={isDisabled || isFormSubmit}
                  value={girlDetails.timeOfBirth}
                  ampm={false}
                  onChange={(newTime) => {
                    handleInputChange("girl", "timeOfBirth", newTime);
                    if (errors.girl?.timeOfBirth) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        girl: {
                          ...prevErrors.girl,
                          timeOfBirth: "",
                        },
                      }));
                    }
                  }}
                  sx={{ width: "100%" }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors.girl?.timeOfBirth,
                      helperText: errors.girl?.timeOfBirth,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <label>{langLabels.birthLocation}</label>
              {isDisabled || isFormSubmit ? (
                <TextField
                  value={girlDetails?.birthLocation}
                  disabled={isDisabled || isFormSubmit}
                  fullWidth
                  variant="standard"
                />
              ) : (
                <CityAutocomplete
                  onChangeHandle={(e: any) => {
                    handleInputChange("girl", "birthLocation", e);
                    if (errors.girl?.birthLocation) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        girl: {
                          ...prevErrors.girl,
                          birthLocation: "",
                        },
                      }));
                    }
                  }}
                  label={""}
                  variant="standard"
                  sx={{ width: "100%" }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors.girl?.birthLocation,
                      helperText: errors.girl?.birthLocation,
                    },
                  }}
                  cityValue={girlDetails?.originalCityAutoCompleteValue || null}
                />
              )}
              {errors?.girl?.birthLocation && (
                <Typography variant="body2" color="error">
                  {langLabels.birthLocationError}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box className="kundliDivider">
          <Box component="img" src={kundliDividerIcon} />
        </Box>
        <Box className="boyBirthInfo">
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            className="birthInfoTitle"
          >
            <Box component="img" src={boyIcon} />
            <Typography>{langLabels.BoysBirthDetails}</Typography>
          </Stack>

          <Grid container spacing={2} className="innerFormDetails">
            <Grid item xs={12}>
              <label>{langLabels.boyFullName}</label>
              <TextField
                variant="standard"
                placeholder={langLabels.boyFullNamePlaceholder}
                fullWidth
                onChange={(e) => {
                  const newValue = e.target.value;
                  const isValid = /^[\u0900-\u097F A-Za-z\s]*$/.test(newValue);
                  if (!isValid) {
                    setErrors((prevError) => ({
                      ...prevError,
                      boy: {
                        ...prevError.boy,
                      },
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      boy: {
                        ...prevErrors.boy,
                        fullName: "",
                      },
                    }));
                    handleInputChange("boy", "fullName", newValue);
                  }
                }}
                value={boyDetails.fullName}
                error={!!errors.boy.fullName}
                helperText={errors.boy.fullName}
                disabled={isDisabled || isFormSubmit}
              />
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <label>{langLabels.dateOfBirth}</label>
                <DatePicker
                  disabled={isDisabled || isFormSubmit}
                  value={boyDetails.dateOfBirth}
                  onChange={(newDate) => {
                    handleInputChange("boy", "dateOfBirth", newDate);

                    if (errors?.boy?.dateOfBirth) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        boy: {
                          ...prevErrors.boy,
                          dateOfBirth: "",
                        },
                      }));
                    }
                  }}
                  maxDate={dayjs()}
                  sx={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors?.boy?.dateOfBirth,
                      helperText: errors?.boy?.dateOfBirth,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <label>{langLabels.timeOfBirth}</label>
                <TimePicker
                  disabled={isDisabled || isFormSubmit}
                  value={boyDetails.timeOfBirth}
                  ampm={false}
                  onChange={(newTime) => {
                    handleInputChange("boy", "timeOfBirth", newTime);
                    if (errors?.boy?.timeOfBirth) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        boy: {
                          ...prevErrors.boy,
                          timeOfBirth: "",
                        },
                      }));
                    }
                  }}
                  sx={{ width: "100%" }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors?.boy?.timeOfBirth,
                      helperText: errors?.boy?.timeOfBirth,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <label>{langLabels.birthLocation}</label>
              {isDisabled || isFormSubmit ? (
                <TextField
                  value={boyDetails?.birthLocation}
                  disabled={isDisabled || isFormSubmit}
                  fullWidth
                  variant="standard"
                />
              ) : (
                <CityAutocomplete
                  onChangeHandle={(e: any) => {
                    handleInputChange("boy", "birthLocation", e);
                    if (errors.boy?.birthLocation) {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        boy: {
                          ...prevErrors.boy,
                          birthLocation: "",
                        },
                      }));
                    }
                  }}
                  label={""}
                  variant="standard"
                  sx={{ width: "100%" }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error: !!errors.boy?.birthLocation,
                      helperText: errors.boy?.birthLocation,
                    },
                  }}
                  cityValue={boyDetails?.originalCityAutoCompleteValue || null}
                />
              )}
              {errors?.boy?.birthLocation && (
                <Typography variant="body2" color="error">
                  {langLabels.birthLocationError}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Button
          variant="contained"
          className="generateBtn"
          endIcon={<KeyboardDoubleArrowRightIcon />}
          onClick={submitForm}
          disabled={isDisabled || isFormSubmit}
        >
          {/* {/ {buttonName === "Horoscope" ? langLabels.horoscope : langLabels.numerology} /} */}
          {langLabels.matchKundli}
        </Button>
      </Box>
    );
  }
);

export default AIGurujiKundliMatchingForm;
