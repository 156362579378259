import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar, PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

interface MultiDateCalendarProps {
  selectedDates: string[]; // Accept an array of strings as selected dates
  onDateChange: (dates: string[]) => void; // Function to handle date updates
}

const MultiDateCalendar: React.FC<MultiDateCalendarProps> = ({ selectedDates, onDateChange }) => {
  const handleDateChange = (newDate: Dayjs | null) => {
    if (!newDate) return;

    const dateString = newDate.format('YYYY-MM-DD'); // Convert date to string format
    const dateAlreadySelected = selectedDates.includes(dateString);

    if (dateAlreadySelected) {
      // Remove the date if it's already selected
      const updatedDates = selectedDates.filter((date) => date !== dateString);
      onDateChange(updatedDates); // Call the prop function to update the dates
    } else {
      // Add the new date
      const updatedDates = [...selectedDates, dateString];
      onDateChange(updatedDates); // Call the prop function to update the dates
    }
  };

  const CustomDay = (props: any) => {
    const isSelected = selectedDates.includes(props.day.format('YYYY-MM-DD')); 
    return (
      <PickersDay
        {...props}
        selected={isSelected}
        onClick={() => handleDateChange(props.day)}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        onChange={handleDateChange}
        slots={{ day: CustomDay }}
        disablePast={true}
      />
    </LocalizationProvider>
  );
};

export default MultiDateCalendar;
