import React from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, IconButton, Paper, Typography, Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import "./Coupon.scss";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getFormattedDate } from "../../../utils/HelperFunctions";
import DeleteIcon from "@mui/icons-material/Delete";

interface CouponCodeListProps {
  codelist: any[];
  handleEdit: (row: any) => void;
  handleAdd: () => void;
  handleDelete: (row: any) => void;
}

const CouponCodeList: React.FC<CouponCodeListProps> = ({
  codelist,
  handleEdit,
  handleAdd,
  handleDelete,
}) => {
  const columns: GridColDef[] = [
    { field: "couponCode", headerName: "Coupon Code", width: 200 },
    { field: "couponType", headerName: "Coupon Type", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "pujaType", headerName: "Puja Type", flex: 1 },
    { field: "numberOfUse", headerName: "Number of Use", flex: 1 },
    {
      field: "restrictionNumberOfUse",
      headerName: "Restriction Number of Use",
      flex: 1,
    },
    // { field: 'isActive', headerName: 'Is Active', flex: 1, type: 'boolean' },
    {
      field: "validTill",
      headerName: "Valid Till",
      flex: 1,
      renderCell: (params) => {
        const formattedDate = getFormattedDate(params.value);
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <IconButton onClick={() => handleEdit(params.row as any)}>
            <EditIcon sx={{ color: "#dea400" }} />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params?.row as any)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className="pageContainer">
      <Box className="couponCodeView">
        <Paper elevation={3}>
          <Box mb={2} className="btnAddCoupon">
            <Button onClick={handleAdd} variant="contained">
              <AddCircleOutlineIcon
                sx={{ fontSize: "20px", marginRight: "5px" }}
              />{" "}
              Add Coupon Code
            </Button>
          </Box>
          {codelist && codelist.length > 0 && (
            <Box
              className="couponDataList"
            >
              <DataGrid
                className="couponDataGrid"
                rows={codelist}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                      page: 0,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                autoHeight
              />
            </Box>
          )}

          {codelist && codelist.length == 0 && (
            <Typography sx={{ padding: "1rem" }}>Empty List</Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default CouponCodeList;
