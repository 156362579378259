import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import EastIcon from "@mui/icons-material/East";
import "./ChooseAddress.scss";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { chooseDeliveryAddressTitle } from "../../../src/utils/ConstantsMessages";
import CloseIcon from '@mui/icons-material/Close';
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";

function ChooseAddress(props: any) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const addressList =
    props.userPersonalInfo && props.userPersonalInfo.address
      ? props.userPersonalInfo.address
      : [];

  const onlyIndianAddressList = addressList?.filter((item:any)=> item?.countryCode =='IN');

  return (
    <>
      <Box className="chooseDeliveryAddressArea">
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          className="topHeader"
        >
          <Typography className="deliveryAddressTitle">
            {/* {chooseDeliveryAddressTitle.chooseTitle} */}
            {t(LOCALIZATION.Choose_a_delivery_address)}
          </Typography>
          <IconButton 
            onClick={() => props.setChooseAddressBox(false)} 
            className="topCloseIcon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {onlyIndianAddressList?.map((item: any, id: any) => {
          return (
            <Box
              className="addressArea"
              onClick={() => {
                props.changeDeliverytAddress(item);
                props.setChooseAddressBox(false);
              }}
            >
              <Box className="addressDetail">
                <Box className="addressLeft">
                  <Box className="addressIcon">
                    <PersonPinCircleOutlinedIcon />
                  </Box>
                  <Box className="addressText">
                    <Box className="addressHeading">
                      <Typography className="title">
                        {item.savedAs || item.name}
                      </Typography>
                    </Box>

                    <Box className="detailAddress">
                      <Typography>{item.formatted_address}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="addressRight">
                  <IconButton type="button">
                    <EastIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })}
        {/* <Box
          className="addAddressArea borderAdd"
          onClick={() => navigate("/profile/address")}
        >
          <Box className="addressDetail">
            <Box className="addressLeft">
              <Box className="addressIcon">
                <AddIcon />
              </Box>
              <Box className="addressText">
                <Box className="addressText">
                  <Typography style={{ fontWeight: "bold" }}>
                    Add new address
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="addressRight">
              <Button type="button"></Button>
            </Box>
          </Box>
        </Box> */}
        <Box className="addAddressArea">
          <Button 
            variant="text" 
            startIcon={<AddIcon />}
            onClick={() => navigate("/profile/address?addAddress=true")}
          >
            {t(LOCALIZATION.ADD_NEW_ADDRESS)}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ChooseAddress;
