import panditProfile1 from "../../assets/pandit-profile1.jpg";
import panditProfile2 from "../../assets/pandit-profile2.jpg";
import panditImg1 from "../../assets/pandit-img1.jpg";
import panditImg2 from "../../assets/pandit-img2.jpg";
import redlike from "../../assets/redlike.png";
import share from "../../assets/share.png";
import "./PanditBooking.scss";
import { Box, Typography } from "@mui/material";
import Link from '@mui/material/Link';



function PanditBookingView() {
  return (
    <Box className="pageContainer panditBooking">
      <Box className="panditBookingBlock">
        <Box className="row">
          <Box className="col-md-3 col-sm-12 col-xs-12">
            <Box className="panditBookingLeftBlock">
            <Typography>Live Address</Typography>
              <Typography>198/3, 3 Cross 80 Feet Road, Next..</Typography>
            </Box>
          </Box>
          <Box className="col-md-6 col-sm-12 col-xs-12">
            {/* <div className="centerBlockTop">
              <div className="topField">
                <div className="profileImg">
                  <a href="#"><img src={postprofile} alt="" /></a>
                </div>
                <div className="inputBox">
                  <input className="form-control startPostInput" type="search" placeholder="Start a post" aria-label="Search" />
                </div>
              </div>
              <div className="bottomField">
                <div className="mediaButton">
                  <a href="#"><img src={media} alt="" />Media</a>
                </div>
                <div className="dateButton">
                  <a href="#"><img src={date} alt="" />Date</a>
                </div>
                <div className="writePostButton">
                  <a href="#"><img src={writepost} alt="" />Write Post</a>
                </div>
              </div>
            </div> */}
            <Box className="panditBookingCenterBlock">
              <Box className="panditBookingBox">
                <Box className="panditBookingTop">
                  <Box className="panditBookingProfile">
                    <img src={panditProfile1} alt="" />
                  </Box>
                  <Box className="panditBookingTitle">
                    <Typography>Ganesh Puja Pandit</Typography>
                  </Box>
                </Box>
                <Box className="panditBookingImage">
                  <img src={panditImg1}></img>
                </Box>
                <Box className="panditBookingDes">
                  <Typography>Higher level pandit description...</Typography>
                </Box>

                <Box className="panditBookingBottom">
                  <Box className="ratings">
                    <i className="bi bi-star-fill"></i>
                    <Typography><span>4.9</span>(9k+ ratings)</Typography>
                  </Box>
                  <Box className="likeShare">
                    <Link href="#"><img src={redlike} alt="" /></Link>
                    <Link href="#"><img src={share} alt="" /></Link>
                  </Box>
                </Box>
              </Box>

              <Box className="panditBookingBox">
                <Box className="panditBookingTop">
                  <Box className="panditBookingProfile">
                    <img src={panditProfile2} alt="" />
                  </Box>
                  <Box className="panditBookingTitle">
                    <Typography>Kali Puja Pandit</Typography>
                  </Box>
                </Box>
                <Box className="panditBookingImage">
                  <img src={panditImg2}></img>
                </Box>
                <Box className="panditBookingDes">
                  <Typography>Higher level pandit description...</Typography>
                </Box>

                <Box className="panditBookingBottom">
                  <Box className="ratings">
                    <i className="bi bi-star-fill"></i>
                    <Typography><span>4.9</span>(7k+ ratings)</Typography>
                  </Box>
                  <Box className="likeShare">
                    <Link href="#"><img src={redlike} alt="" /></Link>
                    <Link href="#"><img src={share} alt="" /></Link>
                  </Box>
                </Box>
              </Box>
              
            </Box>
          </Box>
          <Box className="col-md-3 col-sm-12 col-xs-12">
            <Box className="panditBookingRightBlock">
            <Typography>Latest News</Typography>
              <ul>
                <li>Durga Puja Booked</li>
                <li>Pandit booked for Balaka Club</li>
                <li>Ram Mandir Inaugration</li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PanditBookingView;