import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import "./SocialMediaAdd.scss";
import { useTranslation } from 'react-i18next';
import { t } from "i18next";
import { LOCALIZATION } from '../../utils/localization';


const SocialMediaAdd = () => {
  const { t } = useTranslation();
  
  return (
    <Box className="socialMediaAddSec">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.facebook.com/officialtempledekho/"
            target="_blank"
          >
            <FacebookIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                {t(LOCALIZATION?.Facebook)}
              </Typography>
              <Typography component="p">
               {t(LOCALIZATION?.Chanel_for_Community)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.linkedin.com/company/temple-dekho/"
            target="_blank"
          >
            <LinkedInIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                {t(LOCALIZATION?.Linkedin)}
              </Typography>
              <Typography component="p">
                {t(LOCALIZATION?.Latest_News_Update)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList"
            component="a"
            href="https://www.instagram.com/temple.dekho.official?igsh=dXowdDR2b2R2eWxv"
            target="_blank"
          >
            <InstagramIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
                {t(LOCALIZATION?.Instagram)}
              </Typography>
              <Typography component="p">
              {t(LOCALIZATION?.Latest_News_Update)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            className="socialMediaList bNone"
            component="a"
            href="https://www.youtube.com/@TempleDekhoOfficial/"
            target="_blank"
          >
            <YouTubeIcon />
            <Box className="socialMediaText">
              <Typography variant="h6">
               {t(LOCALIZATION?.Youtube)}
              </Typography>
              <Typography component="p">
              {t(LOCALIZATION?.Latest_News_Update)}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SocialMediaAdd;
