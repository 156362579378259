import Carousel from "react-bootstrap/Carousel";
import pandit from "../../assets/pandit-carousel.svg";
import templeSingle from "../../assets/temple-single-carousel.svg";
import templeWide from "../../assets/temple-wide-carousel.svg";
import { Box, Typography } from "@mui/material";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";
function CarouselComponent() {
  return (
    <Box>
      <Carousel data-bs-theme="dark" className="mainCarousel">
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={templeSingle} alt=""></img>
            <Typography>
              {t(LOCALIZATION.Experience_divine_convenience_Book_temple_pujas_anytime_anywhere_with_Temple_Dekho)}
            </Typography>
          </Box>
        </Carousel.Item>
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={templeWide} alt=""></img>
            <Typography>
              {t(LOCALIZATION.Elevate_your_spiritual_journey_with_seamless_temple_puja_booking_through_Temple_Dekho)}
            </Typography>
          </Box>
        </Carousel.Item>
        <Carousel.Item>
          <Box className="carouselInnerItem">
            <img src={pandit} alt=""></img>
            <Typography>
              {t(LOCALIZATION.Elevate_your_home_pujas_with_ease_Book_a_pandit_for_hassle_free_house_pujas_at_your_fingertips)}
            </Typography>
          </Box>
        </Carousel.Item>
      </Carousel>
    </Box>
  );
}

export default CarouselComponent;
