import { createTheme} from '@mui/material/styles';
export const theme = createTheme({
    palette: {
      primary: {
        main:"#d56939"
      }
    },
    typography:{
      fontFamily: "Roboto",
    },
  });
