import { useEffect, useState } from "react";
import ProfileView from "../../Views/Profile/ProfileView";
import ProfileViewMobile from "../../Views/Profile/ProfileViewMobile";
import { useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
import { useNavigate } from "react-router-dom";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";
function ProfileScreen() {
  const navigate = useNavigate();
  const profileMenu = [
    {
      cardId: "personalInfo",
      cardTitle: t(LOCALIZATION.Personal_Information),
      cardDesc: t(LOCALIZATION.Name_photo_email_Number_Birthday),
    },
    {
      cardId: "address",
      cardTitle: t(LOCALIZATION.My_Addresse),
      cardDesc: t(LOCALIZATION.Add_edit_delete_address),
    },
    // {
    //   cardId: "payment",
    //   cardTitle: "Payments",
    //   cardDesc: "Payment options",
    // },
    // {
    //   cardId: "family",
    //   cardTitle: "Family Members",
    //   cardDesc: "Add family member details",
    // },
    // {
    //   cardId: "account",
    //   cardTitle: "Account settings",
    //   cardDesc: "Refer and earn notifications",
    // },
  ];

  const [profilePicChange, setProfilePicChange] = useState(false);

  const { userInfo } = useSelector((state: any) => state.user);
  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : {};
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );


  useEffect(() => {

    var userInfoFromExtractLocal = localStorage.getItem("userInfo") || "{}";
    var userInfoLocalJsonParsed = JSON.parse(userInfoFromExtractLocal);
    if(!userInfoLocalJsonParsed?.id) {
      navigate("/");
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ParentView>
      {windowDimensions.width < 900 ? (
        <ProfileViewMobile
          profileMenu={profileMenu}
          userDetails={userInfo}
          userPersonalInfo={userPersonalInfo}
          profilePicChange={profilePicChange}
          setProfilePicChange={setProfilePicChange}
        />
      ) : (
        <ProfileView
          profileMenu={profileMenu}
          userDetails={userInfo}
          userPersonalInfo={userPersonalInfo}
          profilePicChange={profilePicChange}
          setProfilePicChange={setProfilePicChange}
        />
      )}
    </ParentView>
  );
}

export default ProfileScreen;
