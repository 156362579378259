import React, { useEffect } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
import PujaVideoView from "../../Views/PujaVideo/PujaVideoView";

const PujaVideoScreen: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <ParentView>
      <PujaVideoView />
    </ParentView>
  );
};

export default PujaVideoScreen;
