import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./ManagePrasadView.scss";
import ManagePrasadFormModal from "./ManagePrasadForm";
import DeleteConfirmDialog from "../../../components/dialog/DeleteConfirmDialog";
import { adminPrasadViewLabel } from "../../../utils/ConstantsMessages";
import ManagePrasadBenefitDilog from "../../../components/dialog/ManagePrasadBenefitDilog";
import ManagePrasadWeightageDilog from "../../../components/dialog/ManagePrasadWeightageDilog";
import ManagePrasadOverviewDialog from "../../../components/dialog/ManagePrasadOverviewDialog";

interface ManagePrasadViewProps {
  temples: any[];
  setSelectedTemple: any;
  prasadList: any[];
  savePrasadItem: (temple: any) => void;
  benefitsList: any[];
  handleBenefitsSelect: any;
  selectedBenefitsOptions: any[];
  handleBenefitsSubmit: () => void;
  selectedTempleId: string;
  deletePrasadItem: (item: any) => void;
  selectedTemple: any;
  handleTempleWeightageSubmit: (weightage: any) => void;
  handleTempleOverViewSubmit: () => void;
  prasadOverViewForTemple: any;
  setPrasadOverViewForTemple: (data: boolean) => any;
}

const ManagePrasadView: React.FC<ManagePrasadViewProps> = ({
  temples,
  setSelectedTemple,
  prasadList,
  savePrasadItem,
  benefitsList,
  handleBenefitsSelect,
  selectedBenefitsOptions,
  handleBenefitsSubmit,
  selectedTempleId,
  deletePrasadItem,
  selectedTemple,
  handleTempleWeightageSubmit,
  handleTempleOverViewSubmit,
  prasadOverViewForTemple,
  setPrasadOverViewForTemple,
}) => {
  const [open, setOpen] = useState(false);
  const [openSuccessMessage, setOpenSuccessMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedChadhavaItem, setSelectedChadhavaItem] = useState<any>(null);
  const [isWeightageDialogOpen, setIsWeightageDialogOpen] = useState(false);
  const [isPrasadOverviewDialogOpen, setIsPrasadOverviewDialogOpen] =
    useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {}, [prasadList]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSM = () => {
    setOpenSuccessMessage(false);
  };

  const handleEditItem = (item: any) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleAddItem = () => {
    setSelectedItem(null);
    setIsModalOpen(true);
  };

  const handleSavePrasadItem = (temple: any) => {
    savePrasadItem(temple);
    setIsModalOpen(false);
  };

  const handleBenefitsSubmitForm = (data: any) => {
    handleBenefitsSubmit();
    setOpen(false);
  };

  const handleDeleteClick = (item: any) => {
    setIsDialogOpen(true);
    setSelectedChadhavaItem(item);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedChadhavaItem(null);
  };

  const handleConfirmDelete = () => {
    deletePrasadItem(selectedChadhavaItem);
    setIsDialogOpen(false);
  };
  const handleWeightageDialogOpen = () => {
    setIsWeightageDialogOpen(true);
  };

  const handleWeightageDialogClose = () => {
    setIsWeightageDialogOpen(false);
  };
  const handlePrasadDialogClose = () => {
    setIsPrasadOverviewDialogOpen(false);
    const templfeOvData = selectedTemple?.prasadOverview
      ? selectedTemple?.prasadOverview
      : "";
    setPrasadOverViewForTemple(templfeOvData);
  };
  const handlePrasadDialogOpen = () => {
    setIsPrasadOverviewDialogOpen(true);
  };
  return (
    <Box className="pageContainer managePrasadAdmin">
      <Box sx={{ textAlign: "center" }}>
        <Typography className="dpDnText">
          {adminPrasadViewLabel?.Title}
        </Typography>
      </Box>
      <Stack
        direction="row"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
        mt={4}
      >
        <Autocomplete
          options={temples}
          getOptionLabel={(option) => option?.personalInfo?.name}
          style={{ width: 300 }}
          onChange={(event, newValue) => setSelectedTemple(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Choose Temples" variant="outlined" />
          )}
        />
        <Box>
          {selectedTempleId && selectedTempleId?.length > 0 && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="flex-end"
              spacing={2}
            >
              <Button variant="outlined" onClick={handlePrasadDialogOpen}>
                Add Prasad Overview
              </Button>
              <Button variant="outlined" onClick={handleWeightageDialogOpen}>
                {adminPrasadViewLabel?.Weightage}
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleAddItem();
                }}
              >
                {adminPrasadViewLabel?.PrasadItem}
              </Button>
              <Button
                variant="outlined"
                onClick={(e) => {
                  handleClickOpen();
                }}
              >
                {adminPrasadViewLabel?.PrasadBenefits}
              </Button>
            </Stack>
          )}
        </Box>
      </Stack>

      {prasadList && prasadList?.length > 0 && (
        <List className="prasadItemListAdmin">
          {prasadList &&
            prasadList?.map((temple, index) => (
              <ListItem
                key={temple.id}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#d569391a",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={temple?.image}
                    sx={{
                      width: 80,
                      height: 80,
                      marginRight: 2,
                      borderRadius: "10px",
                      border: "1px solid #cdcdcd",
                    }}
                  />
                </ListItemAvatar>
                <ListItemText
                  className="listText"
                  primary={temple?.name}
                  secondary={
                    <>
                      <span className="templeDesc">
                        {temple?.itemDescription}
                      </span>

                      <span className="templePujaAmount">
                        {temple?.acctualPrice ? temple?.acctualPrice : 0} रुपये
                      </span>
                    </>
                  }
                />
                <IconButton
                  sx={{
                    background: `linear-gradient(-45deg, rgba(0, 0, 0, 0) 33%, hsla(0, 0%, 100%, 0.5) 50%, rgba(0, 0, 0, 0) 66%), 
                  linear-gradient(-45deg, #d8a820, #d66f35)`,
                    backgroundSize: "250% 250%, 100% 100%",
                    animation: "gradient 15s ease infinite",
                    "@keyframes gradient": {
                      "0%": { backgroundPosition: "0% 50%" },
                      "50%": { backgroundPosition: "100% 50%" },
                      "100%": { backgroundPosition: "0% 50%" },
                    },
                    color: "white",
                    marginRight: "8px",
                  }}
                  aria-label="edit"
                  onClick={(e) => {
                    handleEditItem(temple);
                  }}
                >
                  <EditIcon sx={{ fontSize: "18px" }} />
                </IconButton>
                <IconButton
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    transition: "0.5s ease",
                    "&:hover": {
                      backgroundColor: "#af1919",
                    },
                  }}
                  aria-label="delete"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteClick(temple);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </ListItem>
            ))}
        </List>
      )}

      <ManagePrasadBenefitDilog
        open={open}
        onClose={onClose}
        benefitsList={benefitsList}
        selectedBenefitsOptions={selectedBenefitsOptions}
        handleBenefitsSelect={handleBenefitsSelect}
        handleBenefitsSubmit={handleBenefitsSubmit}
      />
      <ManagePrasadFormModal
        isOpen={isModalOpen}
        temple={selectedItem}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSavePrasadItem}
      />
      <DeleteConfirmDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        msg={adminPrasadViewLabel?.DeleteMessage}
      />
      <ManagePrasadWeightageDilog
        open={isWeightageDialogOpen}
        onClose={handleWeightageDialogClose}
        onSubmit={handleTempleWeightageSubmit}
        selectedTemple={selectedTemple}
      />
      <ManagePrasadOverviewDialog
        open={isPrasadOverviewDialogOpen}
        onClose={handlePrasadDialogClose}
        handleTempleOverViewSubmit={handleTempleOverViewSubmit}
        prasadOverViewForTemple={prasadOverViewForTemple}
        setPrasadOverViewForTemple={setPrasadOverViewForTemple}
      />
    </Box>
  );
};

export default ManagePrasadView;
