import { Box, Grid, Typography, Button } from "@mui/material";
import {
  calculateOverSeasPrice,
  getDateFormatedForBookingDate,
  getDateFormatedOrderTime,
  isValidDate,
  removeTimeFromOrderDate,
} from "../../utils/HelperFunctions";
import "./MyOrdersView.scss";
import { getStatusImage, orderType } from "../../utils/Constants";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";

function MyOrdersView(props: any) {
  const handleOpenViewDetails = (orderId: string) => {
    props?.OpenViewDetailsModal(orderId);
  };

  return (
    <>
      <Box className="pageContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className="pageTopTitle">
              {t(LOCALIZATION.Order_history)}
            </Typography>
            {props.orderHistory.map((item: any, id: any) => {
              let orderStatus = item.orderStatus?.length
                ? item.orderStatus[item.orderStatus.length - 1].orderStatus
                : "";
              // orderStatus = "DELIVERED"
              // orderStatus = "DELIVERED"
              return (
                <Box className="pujaAddedArea">
                  <Box className="pujaAddedDetails">
                    <Box className="pujaAddedLeft">
                      <Typography>
                        {t(LOCALIZATION.Order_id)}: #{item.orderId}
                      </Typography>
                    </Box>
                    <Box className="pujaAddedRight">
                      <Typography>
                        <Typography style={{ fontWeight: "bold" }}>
                          {orderStatus !== "NEW" ? orderStatus : ""}{" "}
                        </Typography>
                        {/* <img src={getStatusImage(orderStatus)} alt=""></img> */}
                        <img
                          src={getStatusImage(orderStatus)}
                          style={{ marginLeft: "20px" }}
                          alt=""
                        ></img>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="pujaAddedMember">
                    <Box className="pujaAddedinfo">
                      <Typography className="title">{item.pujaName}</Typography>
                      <Typography>
                        {t(LOCALIZATION.Booked_on)}:{" "}
                        {getDateFormatedOrderTime(item.orderBookingDate)}
                      </Typography>

                      {item?.orderType !== orderType.prasad && (
                        <Typography>
                          {t(LOCALIZATION.Booked_for)}:{" "}
                          {removeTimeFromOrderDate(item.pujaDate)}
                        </Typography>
                      )}

                      <Typography>
                        {/* Total: ₹ {item?.orderData?.payment?.orderValue} */}
                        {t(LOCALIZATION.Total)}:{" "}
                        {item?.orderData?.currencySymbols
                          ? item?.orderData?.currencySymbols
                          : "₹"}
                        {/* { item?.orderData?.isOverseas === true ? calculateOverSeasPrice(item?.orderData?.payment?.orderValue,item?.orderData?.rate) :
                         item?.orderData?.payment?.orderValue} */}
                        {item?.orderData?.isOverseas
                          ? item?.orderData?.payment?.orderValueOv
                            ? item?.orderData?.payment?.orderValueOv
                            : calculateOverSeasPrice(
                                item?.orderData?.payment?.orderValue,
                                item?.orderData?.rate
                              )
                          : item?.orderData?.payment?.orderValue}
                      </Typography>
                    </Box>
                    <Box className="viewDetailsBtn">
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenViewDetails(item.orderId);
                        }}
                      >
                        {" "}
                        {t(LOCALIZATION.VIEW_DETAILS)}{" "}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MyOrdersView;
