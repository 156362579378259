import { io, Socket } from "socket.io-client";
import { socketUrl } from "../env";

let socket: Socket | null = null;
export const connectSocket = (): Socket => {
  if (!socket) {
    socket = io(socketUrl, {
      transports: ["websocket"],
      timeout: 300000, 
    });
    socket.on("connect", () => {
    });
    socket.on("disconnect", () => {
    });
    socket.on("connect_error", (error: Error) => {
      console.error("Error:", error);
    });
  }
  return socket;
};

export const disconnectSocket = (): void => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};

export const getSocket = (): Socket | null => socket;
