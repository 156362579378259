import { Outlet, Navigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/header/Header";
import { LOCALSTORAGE_KEY } from "../utils/Constants";
function AuthHandlerRoutes() {
  var { userInfo } = useSelector((state: any) => state.user);
  const [searchParams] = useSearchParams();

  const ln = searchParams.get("ln")
    ? searchParams.get("ln")
    : localStorage?.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";

  return (
    <>
      <Header ln={ln} />
      <Outlet />
    </>
  );

  //Older code
  if (userInfo?.id) {
    return <Navigate to="/" />;
  } else {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }
}

export default AuthHandlerRoutes;
