import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import "./styles.scss";
interface AnalyticFilterProps {
  handleFilter: (filterType: string) => void;
  activeFilter: any;
}

const AnalyticFilter: React.FC<AnalyticFilterProps> = ({
  handleFilter,
  activeFilter,
}) => {
  const handleButtonClick = (filterType: string) => {
    handleFilter(filterType);
  };

  return (
    <Box className="userAnalyticsFilter">
      <ButtonGroup
        variant="outlined"
        aria-label="analytic filter buttons"
        size="large"
      >
        <Button
          className="btngroupanalytics"
          onClick={() => handleButtonClick("7day")}
          color={activeFilter === "7day" ? "primary" : "inherit"}
        >
          Last 7 Days
        </Button>
        <Button
          className="btngroupanalytics"
          onClick={() => handleButtonClick("today")}
          color={activeFilter === "today" ? "primary" : "inherit"}
        >
          Today's
        </Button>
        <Button
          className="btngroupanalytics"
          onClick={() => handleButtonClick("30day")}
          color={activeFilter === "30day" ? "primary" : "inherit"}
        >
          Last 30 Days
        </Button>
      </ButtonGroup>
    </Box>
  );
};
export default AnalyticFilter;
