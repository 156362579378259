import {createSlice} from '@reduxjs/toolkit';

const ManagePujaSlice = createSlice({
  name: "managePuja",
  initialState: {
    isLoading: false,
    pujaList:[],
    templePujaList:[],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPujaList: (state, action) => {
      state.pujaList = action.payload;
    },
    setTemplePujaList: (state, action) => {
      state.templePujaList = action.payload;
    },
  },
});

export const {
  setLoading,
  setPujaList,
  setTemplePujaList,
} = ManagePujaSlice.actions;

export default ManagePujaSlice.reducer;

