import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from "@mui/icons-material/Close";
import spencerLogo from "../../assets/spancer-logo.png";
import bigbasketLogo from "../../assets/bigbasket-logo.png";
import flipkartLogo from "../../assets/flipkart-logo.png";
import "./AdBannerItem.scss";
import { LOCALIZATION } from "../../utils/localization";
import { t } from "i18next";

const AdBannerItem = () => {
  const [sobiscoBannerOpen, setSobiscoBannerOpen] = useState(false);

  const handleSobiscoBannerOpen = () => setSobiscoBannerOpen(true);
  const handleSobiscoBannerClose = () => setSobiscoBannerOpen(false);

  return (
    <>
      <Box>
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/offerModal%2Fgroup-27616%403x.png?alt=media&token=123296dc-210e-4603-9f45-e49b56d34af5"
          }
          alt="Sobisco Banner"
          className="bannerSobisco"
          onClick={handleSobiscoBannerOpen}
        />
      </Box>

      {/* Popup Dialog */}
      <Dialog
        open={sobiscoBannerOpen}
        onClose={handleSobiscoBannerClose}
        className="adBannerDialog"
      >
        <DialogTitle className="dialogTitle">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className="topItem"
          >
            <span>{t(LOCALIZATION.Select_a_Platform)}</span>
            <IconButton
              onClick={handleSobiscoBannerClose}
              className="topCloseIcon"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Link
            href="https://www.spencers.in/catalogsearch/result/?q=sobisco"
            target="_blank"
            className="linkWrapper"
          >
            <Box className="onlinePlatformCard">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="twoItem"
              >
                <img src={spencerLogo} alt="Spencer's" />
                <IconButton>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Box>
          </Link>
          <Link
            href="https://www.bigbasket.com/ps/?q=sobisco&nc=as"
            target="_blank"
            className="linkWrapper"
          >
            <Box className="onlinePlatformCard">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="twoItem"
              >
                <img src={bigbasketLogo} alt="Big Basket" />
                <IconButton>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Box>
          </Link>
          <Link
            href="https://www.flipkart.com/search?q=sobisco"
            target="_blank"
            className="linkWrapper"
          >
            <Box className="onlinePlatformCard">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="twoItem"
              >
                <img src={flipkartLogo} alt="Flipkart" />
                <IconButton>
                  <EastIcon />
                </IconButton>
              </Stack>
            </Box>
          </Link>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdBannerItem;
