import { Box } from "@mui/material";
import Carousel from "react-multi-carousel";

export default function TempleDetailsBanner(props: any) {
  const responsive3 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Box
      className="templeDetailTopBanner"
      // style={{ backgroundImage: `url(${props.templeProfilePic})` }}
    >
      <Box className="carouselImages" style={{ zIndex: -10 }}>
        {props?.templeMediaList?.length > 0 ? (
          <Carousel
            responsive={responsive3}
            swipeable={true}
            draggable={true}
            showDots={true}
            keyBoardControl={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {props?.templeMediaList?.map((item2: any, id: any) => {
              return <img key={id} src={item2.pictureUrl}></img>;
            })}
          </Carousel>
        ) : null}
      </Box>

      <Box className="bannerContent">
        <img src={props.templeProfilePic} alt="" />
      </Box>
    </Box>
  );
}
