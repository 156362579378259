import { useState } from "react";
import SendPushNotificationView from "../../Views/Admin/Notification/SendPushNotificationView";
import { UserService } from "td_server";
import { env } from "../../env";

function SendPushNotification() {
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [sendNotfnStatus, setSendNotifnStatus] = useState(false);
  const handleSubmit = async (notification: any) => {
    setIsSendingNotification(true);
    let isSendToSpecificNumbers = notification.isSendToSpecificNumbers;
    if (env === "prod" && isSendToSpecificNumbers === false) {
      try {
        var notificationImage = "";
        if (notification.image) {
          const sourceData = {
            uri: notification.image,
            platform: "web",
          };
          notificationImage = await UserService.uploadImageForPushNotification(
            sourceData
          );
        }

        UserService.sendPushNotificationToAllDevice(
          [],
          notification.title,
          notification.message,
          notificationImage,
          { details: JSON.stringify(notification.pushDetails) }
        )
          .then((res) => {
            setIsSendingNotification(false);
            setSendNotifnStatus(true);
          })
          .catch((err) => console.log("Error:", err));
      } catch (error) {
        console.log("Error:", error)
        setIsSendingNotification(false);
      }
    } else {
      try {
        
        let isSendToSpecificNumbers = notification.isSendToSpecificNumbers;
        let pushToUserList = notification.pushToUserList;
        let pushTokens: any = [];
        if (isSendToSpecificNumbers) {
          for (let i = 0; i < pushToUserList.length; i++) {
            if (pushToUserList[i].pushToken) {
              pushTokens = [...pushTokens, pushToUserList[i].pushToken];
            }
          }
        } else {
          //avinash token
          pushTokens.push(
            "fxg1rBpTS5uVomEFw5AKg0:APA91bGc0lwhvGPErfbtSYTRSr0u-BAjhUYmMRMWNHfu8JQWo5GnHIOfFdCLjMmUmoPNdGe4J7PjNpuAUDoaa4fjARs7bmhE0ji32rb5Ncf6O5tr0R7lvBJdUNuUzTp07QIpYfckoUHK"
          );
        }

        var notificationImage = "";
        if (notification.image) {
          const sourceData = {
            uri: notification.image,
            platform: "web",
          };
          notificationImage = await UserService.uploadImageForPushNotification(
            sourceData
          );
          
        }
        UserService.sendUserPushNotificaion(
          pushTokens,
          notification.title,
          notification.message,
          notificationImage,
          { details: JSON.stringify(notification.pushDetails) }
        )
          .then((res) => {
            setIsSendingNotification(false);
            setSendNotifnStatus(true);
          })
          .catch((err) => console.log("Error:", err));
      } catch (error) {
        console.log("Error:",error);
        setIsSendingNotification(false);
      }
    }
  };

  return (
    <SendPushNotificationView
      onSubmit={handleSubmit}
      isLoading={isSendingNotification}
      setSendNotifnStatus={setSendNotifnStatus}
      sendNotfnStatus={sendNotfnStatus}
    ></SendPushNotificationView>
  );
}

export default SendPushNotification;
