import { Box, Typography, Button } from "@mui/material";
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import "./LeftAddressCard.scss";
import { useSelector } from "react-redux";



export default function LeftAddressCard(props: any) {
    const { userInfo} = useSelector((state: any) => state.user);
  
  return (
    <Box className="pageLeftBlock">
      {props.header?<Typography>{props.header}</Typography>:null}
      
      <Typography>{props.address}</Typography>
      <Button onClick={()=>props.onClick()}>{props.footerButton}
        
      {userInfo && <ModeEditRoundedIcon />}
        
        </Button>
    </Box>
  )
}