import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import "./DeleteConfirmDialog.scss";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "../../utils/localization";

interface DeleteConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  msg: string;
}

const DeleteConfirmDialog: React.FC<DeleteConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  msg,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Dialog className="deleteConfirmModal" open={open} onClose={onClose}>
      <DialogTitle className="deleteConfirmModal">{t(LOCALIZATION.Confirm_Deletion)}</DialogTitle>
      <Divider className="titleDivider" />
      <DialogContent>
        <Typography sx={{ fontWeight: 500, color: "#6e6e6e" }}>
        {t(LOCALIZATION.Do_You_Really_Want_To_Clear_Everything_From_Your_Cart)}
        </Typography>
      </DialogContent>
      <DialogActions className="confirmBtn">
        <Button
          className="confirmBtnCancel"
          onClick={onClose}
          color="primary"
          variant="outlined"
        >
          {t(LOCALIZATION.Cancel)}
        </Button>
        <Button
          className="confirmBtnDelete"
          onClick={onConfirm}
          color="primary"
          variant="contained"
        >
          {t(LOCALIZATION.Delete)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmDialog;
