import React from 'react'
import { Box, Typography } from '@mui/material';
import "./AIGurujiBirthChart.scss";

const AIGurujiBirthChart:React.FC<any> = ({birthChartUrl,text}) => {
  return (
    <Box className="birthChartSection">
      <Typography className="birthChartTitle">
        { text}
      </Typography>
      {/* {birthChartUrl} */}
      <Box
        component="img"
        src={birthChartUrl}
        className="birthChartImg"
      />
    </Box>
  )
}

export default AIGurujiBirthChart
