import Button from "@mui/material/Button";
import verifytick from "../../assets/verify-tick.png";
import "./TempleDetailsView.scss";
import GoogleMap from "../../components/map/GoogleMap";
import TempleDetailsBanner from "../../components/banner/TempleDetailsBanner";
import { Box, Fab, Grid, Stack, Zoom } from "@mui/material";
import TextArea from "../../components/textarea/Textarea";
import Typography from "@mui/material/Typography";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ShareLink from "../../components/sharelink/sharelink";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect, useState } from "react";
import { templeClickAddIcon } from "../../utils/ConstantsMessages";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import { LOCALIZATION } from "../../utils/localization";
import { useTranslation } from "react-i18next";
import { LOCALSTORAGE_KEY } from "../../utils/Constants";
import { getTempleName } from "../../utils/HelperFunctions";

function TempleDetailsView(props: any) {
  // const checkDetails = props.templeDetails.
  const { t } = useTranslation();
  const [mobileAddIconOpen, setMobileAddIconOpen] = useState(false);
  const [urlToShare, setUrlToShare] = useState<string>("");
  const [desktopButtonItem, setdDsktopButtonItem] = useState<any>([]);
  const [mobileButtonItem, setMobileButtonItem] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const savedLanguage =
    localStorage.getItem(LOCALSTORAGE_KEY.LANG_CODE) ?? "en";
  const templeName = getTempleName(props.templeDetails, savedLanguage);

  useEffect(() => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    setUrlToShare(
      `${hostname}/pagelink?templeNameKey=${props?.templeDetails?.nameKey}`
    );
  }, [props.templeDetails]);

  const [isAboveFooter, setIsAboveFooter] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footerArea");
      const button = document.querySelector(".sticky-button");
      if (footer && button) {
        const footerRect = footer.getBoundingClientRect();
        const buttonRect = button.getBoundingClientRect();
        if (
          footerRect.top < window.innerHeight &&
          footerRect.bottom >= buttonRect.bottom
        ) {
          setIsAboveFooter(true);
        } else {
          setIsAboveFooter(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const templeOpeningTime =
    props?.templePersonalInfo?.operatingHours?.period.length > 0
      ? props?.templePersonalInfo?.operatingHours?.period[0]?.openingTime
        ? props?.templePersonalInfo?.operatingHours?.period[0]?.openingTime
        : ""
      : "";
  const templeCloseingTime =
    props?.templePersonalInfo?.operatingHours?.period.length > 0
      ? props?.templePersonalInfo?.operatingHours?.period[0]?.closingTime
        ? props?.templePersonalInfo?.operatingHours?.period[0]?.closingTime
        : ""
      : "";
  const templeProfilePic = props.templeProfilePic();

  let imageArrayForTemple =
    props.templePersonalInfo &&
    props.templePersonalInfo.media &&
    props.templePersonalInfo.media.pictures &&
    props.templePersonalInfo.media.pictures.length
      ? props.templePersonalInfo.media.pictures
      : [];

  useEffect(() => {
    if (props?.templeDetails) {
      const templeDetails = props?.templeDetails;

      const _desktopButtonItem = [
        !templeDetails?.isClosedBooking && (
          <Button onClick={() => props.goToBookPujaScreen()}>
            {t(LOCALIZATION.Book_Puja)}
          </Button>
        ),
        templeDetails?.isPrasadEnabled && (
          <Button onClick={() => props.goToBookPrasadScreen()}>
            {t(LOCALIZATION.BOOK_PRASAD)}
          </Button>
        ),
        templeDetails?.isChadhavaEnabled && (
          <Button onClick={() => props.goToBookChadhavaScreen()}>
            {t(LOCALIZATION.Book_Chadhava)}
          </Button>
        ),
      ].filter(Boolean);
      setdDsktopButtonItem(_desktopButtonItem);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props?.templeDetails]);

  useEffect(() => {
    if (props?.templeDetails) {
      const templeDetails = props?.templeDetails;
      const _mobileButtonItem = [
        templeDetails?.isPrasadEnabled && (
          <Zoom in={mobileAddIconOpen} key="prasad">
            <button
              className="fabAction"
              onClick={() => props.goToBookPrasadScreen()}
            >
              {t(LOCALIZATION.BOOK_PRASAD)}
            </button>
          </Zoom>
        ),
        templeDetails?.isChadhavaEnabled && (
          <Zoom in={mobileAddIconOpen} key="chadhava">
            <button
              className="fabAction"
              onClick={() => props.goToBookChadhavaScreen()}
            >
              {t(LOCALIZATION.BOOK_CHADHAVA)}
            </button>
          </Zoom>
        ),
        !templeDetails?.isClosedBooking && (
          <Zoom in={mobileAddIconOpen} key="puja">
            <button
              className="fabAction"
              onClick={() => props.goToBookPujaScreen()}
            >
              {t(LOCALIZATION.BOOK_PUJA)}
            </button>
          </Zoom>
        ),
      ].filter(Boolean);
      setMobileButtonItem(_mobileButtonItem);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [props?.templeDetails, mobileAddIconOpen]);

  return (
    <>
      {isLoading ? (
        <Box className="pageContainer templeDetailsPage">
          <Box className="templeDetailsBlock">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={3}
                  xl={3}
                  className="pagehdLeftBlock"
                ></Grid>
                <Grid item xs={12} md={8} lg={6} xl={6}>
                  <Box className="loader">
                    <CircularProgress />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="pageContainer templeDetailsPage">
          <Box className="templeDetailsBlock">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={3}
                  xl={3}
                  className="pagehdLeftBlock"
                >
                  {/* <LeftAddressCard
                header="Current Address"
                address="198/3, 3 Cross 80 Feet Road, Next.."
                footerButton="Edit or add an address"
              /> */}
                </Grid>
                <Grid item xs={12} md={8} lg={6} xl={6}>
                  <Box className="templeDetailsCenterBlock">
                    <TempleDetailsBanner
                      templeProfilePic={templeProfilePic}
                      templeMediaList={imageArrayForTemple}
                    />
                    <Box className="shareIcon">
                      <ShareLink urlToShare={urlToShare}></ShareLink>
                    </Box>
                    <Box className="ganpatiTempleDetails">
                      <Typography className="templeDetailsTitle">
                        {templeName}
                        <ImageViewer pictureUrl={verifytick} alt="" />
                      </Typography>
                      <Box className="ratings">
                        <i className="bi bi-star-fill"></i>
                        <Typography>
                          <span>
                            {props?.templeDetails?.googleData?.rating}
                          </span>{" "}
                          <span className="ratingCount">
                            (
                            {
                              props?.templeDetails?.googleData
                                ?.user_ratings_total
                            }
                            + ratings)
                          </span>
                        </Typography>
                      </Box>
                      <Box className="disclaimerText">
                        <Typography>
                          <ErrorOutlineIcon />
                          {t(LOCALIZATION.CLICK_ON_PLUS_ICON_MESSAGE)}
                        </Typography>
                      </Box>

                      <Box className="templeDetailsContent">
                        <Box className="aboutTemple">
                          <TextArea
                            title={
                              props.templePersonalInfo.descriptions
                                ? props.templePersonalInfo.descriptions
                                : ""
                            }
                            elevation={0}
                          ></TextArea>
                        </Box>
                        <Box className="addressTemple">
                          <Box>
                            <LocationOnOutlinedIcon
                              sx={{ fontSize: 20, opacity: 0.5 }}
                            />
                          </Box>
                          <Box>
                            <TextArea
                              title={
                                props.templePersonalInfo.address &&
                                props.templePersonalInfo.address
                                  .formatted_address
                                  ? `${props?.templePersonalInfo?.name} ${props?.templePersonalInfo?.address.formatted_address}`
                                  : ""
                              }
                              elevation={0}
                            ></TextArea>
                          </Box>
                        </Box>
                        <Box className="openingPujaTime">
                          <Box className="openingTime">
                            <Box>
                              <AccessTimeIcon
                                sx={{ fontSize: 20, opacity: 0.5 }}
                              />
                            </Box>
                            <Box>
                              <TextArea
                                title={`${t(
                                  LOCALIZATION.Open
                                )} - ${templeOpeningTime}`}
                                elevation={0}
                              ></TextArea>
                              <TextArea
                                title={`${t(
                                  LOCALIZATION.Close
                                )} - ${templeCloseingTime}`}
                                elevation={0}
                              ></TextArea>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="phnTemple">
                          <Box>
                            <CallOutlinedIcon
                              sx={{ fontSize: 20, opacity: 0.5 }}
                            />
                          </Box>
                          <Box>
                            <TextArea
                              title={props.templePersonalInfo.phoneNumber}
                              elevation={0}
                            ></TextArea>
                          </Box>
                        </Box>
                        {/* {(props?.templeDetails?.isClosed ||
                      props?.templeDetails?.isClosedBooking) && ( */}
                        {props?.templeDetails?.isClosed === true && (
                          <Box>
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: 20,
                                paddingTop: "0.5rem",
                              }}
                            >
                              {t(LOCALIZATION.Temple_is_closed)}
                            </Typography>
                          </Box>
                        )}
                        <Box className="googleMap">
                          <GoogleMap personalInfo={props.templePersonalInfo} />
                        </Box>
                        {/* <Box className="likeSharebtn">
                      <Box className="likeBtn">
                        <ImageViewer pictureUrl={whitelike} alt="" />
                      </Box>
                      <Box className="shareBtn">
                        <ImageViewer pictureUrl={share} alt="" />
                      </Box>
                    </Box> */}
                      </Box>
                      {/* <Box className="bottomTitle">
                    <TitleBlockCard title="Offerings" />
                  </Box> */}
                      <Box className="templeDetailsButton">
                        <Box
                          className={`bookYourPujaBtn sticky-button ${
                            props?.templeDetails?.isClosed ||
                            desktopButtonItem.length === 0
                              ? "templeClosedBtn"
                              : ""
                          }`}
                          sx={{
                            position: `${isAboveFooter ? "absolute" : "fixed"}`,
                            width: `${
                              isAboveFooter ? "100%" : "-webkit-fill-available"
                            }`,
                          }}
                        >
                          {props?.templeDetails?.isClosed ||
                          desktopButtonItem.length === 0 ? (
                            t(LOCALIZATION.Temple_Closed)
                          ) : (
                            <>
                              <Stack
                                direction="row"
                                justifyContent={
                                  desktopButtonItem.length <= 1
                                    ? "center"
                                    : "space-between"
                                }
                                alignItems="center"
                                spacing={0}
                                className="desktopBookingBtnRow"
                              >
                                {desktopButtonItem}
                              </Stack>

                              <Box
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setMobileAddIconOpen(!mobileAddIconOpen);
                                }}
                                className={`mobileFabContainer ${
                                  mobileAddIconOpen ? "openBg" : ""
                                } ${
                                  props?.templeDetails?.isClosed ||
                                  props?.templeDetails?.isClosedBooking
                                    ? "templeClosedBtn"
                                    : ""
                                }`}
                              >
                                {mobileButtonItem}

                                <Fab
                                  className="fabMainAdd"
                                  onClick={() =>
                                    setMobileAddIconOpen(!mobileAddIconOpen)
                                  }
                                >
                                  {mobileAddIconOpen ? (
                                    <CloseIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </Fab>
                              </Box>
                            </>
                          )}
                        </Box>
                        {/* <Button className="makeDonation">Make donation</Button> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  lg={3}
                  xl={3}
                  className="pagehdRightBlock"
                >
                  {/* <RightBlockCard /> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export default TempleDetailsView;
