import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
} from "@mui/material";
import "./EventView.scss";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";

const EventView = (props: any) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (id: any) => {
    setActiveItem(id);
  };
  const nativeViewCheck = props.parentViewCheck;

  //     const sortPackageData = (multiplePujaDetails: any) => {
  //         let multiplePuja = [...multiplePujaDetails];
  //         let data = multiplePuja.sort((a: any, b: any) =>
  //           Number(a.maxMemberAllowed) > Number(b.maxMemberAllowed) ? 1 : -1
  //         );

  //         return data;
  //       };

  //     const pujaBgImage =
  //     props.pujaDetails &&
  //     props.pujaDetails.pujaBg &&
  //     props.pujaDetails.pujaBg.uri
  //       ? props.pujaDetails.pujaBg.uri
  //       : pujaMainImg;
  //   let pujaPackages =
  //     props.pujaDetails && props.pujaDetails.multiplePujaDetails
  //       ? sortPackageData(props.pujaDetails.multiplePujaDetails)
  //       : [];

  return (
    <Box
      className={`${
        props.parentViewCheck ? "pageContainer" : "pageContainerEventViewNative"
      } specialPujaEvent`}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={2}
          lg={3}
          xl={3}
          className="pagehdLeftBlock"
        ></Grid>
        <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
          <Box className="specialPujaAddOn">
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/templedekhodev.appspot.com/o/offerModal%2FDay%208-%20Ashtami_Mahagauri.png?alt=media&token=c8b153ff-0e83-4fec-9308-3a48daa0a31c"
                      }
                      alt=""
                    ></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="pujaNameInfo">
                      <Typography sx={{ color: "#d56939" }}>
                        Shardiya Navratri Ashtami: Maa Mahagauri Ki Puja Aur
                        Kanya Puja
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="overViewText">
                <SubtitleTypographyPrimary title="Puja Overview"></SubtitleTypographyPrimary>
                <label>
                  <Typography>Om Devi Mahagauryai Namah 🙏✨</Typography>
                  <Typography sx={{ mb: 1 }}>
                    Maa Mahagauri, Devi Durga ka aathva roop hai, jinki pooja
                    Navratri ke aathve din ki jaati hai. Unka naam "Maha" aur
                    "Gauri" ka milan hai, jiska matlab hai "mahan" aur "gora,"
                    yaani sundarta. 🕊️🌼 Unka rang hamesha safed hota hai, jo
                    unki shuddhata aur shanti ko darshata hai. Aaj ka rang hai
                    gulabi (pink) 💖 jo prem, sanrakshan aur daya ka pratik hai.
                    🌸💕
                  </Typography>
                  {/* <Typography sx={{ mb: 1 }}>Ku 👉 chhoti 🔆</Typography>
                  <Typography sx={{ mb: 1 }}>Ushma 👉 garmi 🔥</Typography>
                  <Typography sx={{ mb: 1 }}>
                    Anda 👉 brahmandik anda 🌌
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    Aaj ka rang narangi (orange) 🔶 hai, jo sakaratmak urja aur
                    jeevan ki prateek hai. ✨🌈
                  </Typography> */}
                  <Typography sx={{ mb: 1 }}>
                    Devi Durga ke anya roop—Kali, Chamunda, aur Kaushiki—ne
                    rakshas Shumbha, Nishumbha, Chanda, Munda, aur Raktabija ka
                    nash ⚔️ karne ke baad Parvati ke saath milkar apne aathve
                    roop, Mahagauri, ko prakat kiya tha. ✨ Unki safed rangat ki
                    wajah se unhe shankh, chandrama aur safed kunda ke phool ke
                    saath tulna kiya jaata hai. 🐚🌕
                  </Typography>
                  <Typography>
                    Maa Mahagauri ke do haathon mein trishul 🔱 aur damru 🥁
                    hote hain, aur baaki do haath Abhaya 🙏 aur Ashirwad mudra
                    mein hote hain. 🚩 Unka vahan ek safed bail hai, isliye unhe
                    Vrisharudha bhi kaha jaata hai. 🐂 Maa Mahagauri ka sambandh
                    𝗩𝗶𝘀𝗵𝘂𝗱𝗵𝗵𝗮 𝗖𝗵𝗮𝗸𝗿𝗮, yani throat chakra se hai, jo bolne aur
                    vyakt karne ki shakti ko prakat karta hai. 💫✨
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    Durga Ashtami ke din, Devi Durga ke liye vishesh puja ki
                    jaati hai. Iss din Maa Mahagauri ki puja mein prasad ke roop
                    mein nariyal 🥥 diye jaate hain aur unhe mogra ke phool 🌺
                    chadhaye jaate hain. 🌼 Bhakt ghar ya mandir mein pavitra
                    sthal tayaar karte hain aur devi ko phool, agarbatti,
                    naiveya aur prasad chadhakar prarthna karte hain. 🌟 Kanya
                    Puja, jise Kanjak Puja bhi kaha jaata hai, Ashtami par ek
                    mahatvapurn riwaj hai. Devi ki shakti ka pooja karne ke
                    liye, 2 se 10 saal ki chhoti ladkiyon ko devi ka avatar
                    maankar unhe bhojan, upahaar aur aashirwad diya jaata hai.
                    💝🎁
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    Kuch bhakt Ashtami par vrat rakhte hain, sirf satvic bhojan
                    ka sevan karte hain. 🌞 Is din, bhakt Devi Mahagauri se
                    prarthna karte hain ki Maa ki kripa se unki zindagi se
                    rukavatein aur nakaratmakta door ho aur unhe sukh, samriddhi
                    aur aarthik vikas prapt ho. ✨
                  </Typography>
                  <Typography sx={{ mb: 1 }}>
                    Aaj ke din Maa Mahagauri se prarthna karke apni atma ko
                    shuddh karein 🕉️ aur naye shuruaat ki aasha rakhein. 🌈🌞
                  </Typography>
                </label>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          lg={3}
          xl={3}
          className="pagehdRightBlock"
        ></Grid>
      </Grid>
    </Box>
  );
};

export default EventView;
