import { useEffect, useState } from "react";
import AboutUsView from "../../Views/AboutUs/AboutUsView";
import { useNavigate } from "react-router-dom";

import ParentView from "../../Views/PrarentView/ParentView";

import { isAndroid, isIOS } from "react-device-detect";
import OpenInAppModal from "../../components/dialog/OpenInAppModal";
function AboutUsScreen() {
  const urlApp = {
    dev: "...",
    staging: "...",
    prod: "com./myapp://reset-password?user=test&token=13245",
    fallbackIosURL: "https://apps.apple.com/us/app/id6467179618",
    fallbackAndroidURL:
      "https://play.google.com/store/apps/details?id=com.omkara.templedekho.dev",
  };
  const navigate = useNavigate();

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const visheshpuja = urlParams.get("visheshpuja");
  //   if (visheshpuja) {
  //     window.location.href = `https://templedekho.com/promotionalPuja/${visheshpuja}`;
  //   } else {
  //     // Handle case where visheshpuja is not present
  //     window.location.href = "https://templedekho.com/login"; // Replace with your default page URL
  //   }
  // }, [navigate]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // if (isAndroid) {
    //   const url =
    //     "intent://templedekho.com/#Intent;scheme=https;package=com.omkara.templedekho.dev;end";
    //   window.location.replace(url);
    // } else if (isIOS) {
    //   window.location.replace("pagelink://");
    //   setTimeout(() => {
    //     window.location.replace(urlApp.fallbackIosURL);
    //   }, 10000);
    // }
    if (isAndroid) {
      handleOpenModal();
    }
  }, []);
  const isFacebookBrowser = () => {
    const ua = navigator.userAgent || navigator.vendor;
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  };
  return (
    <>
      <ParentView>
        <AboutUsView />
      </ParentView>
      <OpenInAppModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
}

export default AboutUsScreen;
