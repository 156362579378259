import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import pujaStepsBanner from "../../assets/Puja-steps-img/puja-steps-banner.png";
import badgeIcon from "../../assets/Puja-steps-img/badge-icon.png";
import startUpIndia from "../../assets/Puja-steps-img/startup-india-logo.png";
import isoLogo from "../../assets/Puja-steps-img/iso-logo.png";
import socLogo from "../../assets/Puja-steps-img/soc-2-type-2-logo.png";
import pujaStepsImg1 from "../../assets/Puja-steps-img/steps-img1.png";
import pujaStepsImg2 from "../../assets/Puja-steps-img/steps-img2.png";
import pujaStepsImg3 from "../../assets/Puja-steps-img/steps-img3.png";
import pujaStepsImg4 from "../../assets/Puja-steps-img/steps-img4.png";
import pujaStepsImg5 from "../../assets/Puja-steps-img/steps-img5.png";
import pujaStepsImg6 from "../../assets/Puja-steps-img/steps-img6.png";
import './PujaStepsView.scss';
import { LOCALIZATION } from '../../utils/localization';
import { useTranslation } from 'react-i18next';

const steps = [
    'Puja/Chadhava Selection',
     'Add Devotee Details',
    'Review Details & Checkout'
];

const PujaStepsView = () => {
     
    const { t, i18n } = useTranslation();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    // Awesome BTN to Home Page
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = () => {
        navigate('/');
    };

    // navigate back to same pujaInfo page
    const queryParams = new URLSearchParams(location.search);
    const templeNameKey = queryParams.get('templeNameKey');
    const pujaNameKey = queryParams.get('pujaNameKey');
    const handleNavigateBackToTemplePujaInfo =()=>{
        navigate(`/templePujaInfo?templeNameKey=${templeNameKey}&pujaNameKey=${pujaNameKey}`)
    }

    return (
        <Box className="pageContainer pujaSteps">
            <Box className="templeBlock">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdLeftBlock"></Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="pujaStepsCenterBlock">
                            <Box className="pujaStepsBanner">
                                <img src={pujaStepsBanner} alt="" />
                            </Box>
                            <Box className="pujaStepsBody">
                                <Typography className="mainHeading">{t(LOCALIZATION.Temple_Dekho)}</Typography>
                                <Typography>{t(LOCALIZATION.Temple_Dekho_leads_in_virtual_puja_services)}</Typography>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                    className="templeDekhoInfo"
                                >
                                    <img src={badgeIcon} alt="" />
                                    <Typography>{t(LOCALIZATION.Temple_Dekho_leads_in_virtual_puja_services)}</Typography>
                                </Stack>
                                <Typography className="securityInfo">{t(LOCALIZATION.Temple_Dekho_is_Indias_leading_most_reliable)} <span>{t(LOCALIZATION.SOC_2_type_2_compliant)}</span> {t(LOCALIZATION.and)} <span>{t(LOCALIZATION.ISO_27001_certified)}</span>,{t(LOCALIZATION.ensuring_the_highest_standards)}</Typography>
                                <Box className="certificateImg">
                                    <Typography>{t(LOCALIZATION. Recognised_and_Certified_by)}</Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                        className="imgList"
                                    >
                                        <img className="startUpIndiaLogo" src={startUpIndia} alt="" />
                                        <img src={isoLogo} alt="" />
                                        <img src={socLogo} alt="" />
                                    </Stack>
                                </Box>
                            </Box>

                            <Box className="whatWeOffer">
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img src={pujaStepsImg1} alt="" />
                                    <Typography>{t(LOCALIZATION.Seamless_Online_Puja_Booking_and_Chadhava)} <span>{t(LOCALIZATION.Temple_Dekho )} </span> {t(LOCALIZATION.app)}</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography>{t(LOCALIZATION.Skilled_pandits_conduct_Pujas_offer)} <span>{t(LOCALIZATION.Name_Gotram)}</span>.</Typography>
                                    <img src={pujaStepsImg2} alt="" />
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img className="pujaStepsImg3" src={pujaStepsImg3} alt="" />
                                    <Typography>{t(LOCALIZATION.Receive_notifications_directly_to_your)} <span>{t(LOCALIZATION.WhatsApp_number)}</span>.</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography>{t(LOCALIZATION.Receive_prasad_at)} <span>{t(LOCALIZATION.your_doorstep_directly)}</span>{t(LOCALIZATION.from_temples)}.</Typography>
                                    <img className="pujaStepsImg4" src={pujaStepsImg4} alt="" />
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img className="pujaStepsImg5" src={pujaStepsImg5} alt="" />
                                    <Typography><span>{t(LOCALIZATION.Panchang_calendar)}</span>.{t(LOCALIZATION.You_can_access_panchang_for_various_Tithis)}.</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography><span>{t(LOCALIZATION.Live_darshan)}</span> {t(LOCALIZATION.of_various_temples_of_India)}.</Typography>
                                    <img className="pujaStepsImg6" src={pujaStepsImg6} alt="" />
                                </Stack>
                            </Box>

                            <Box className="awesomeBtnArea">
                                <Typography>{t(LOCALIZATION.This_is_how)} <span> {t(LOCALIZATION.Temple_Dekho)} </span> {t(LOCALIZATION.operates)}.</Typography>
                                <Button onClick={templeNameKey && pujaNameKey ? handleNavigateBackToTemplePujaInfo : handleClick} variant="contained">{t(LOCALIZATION.This_is_Awesome)}</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdRightBlock"></Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PujaStepsView;
