import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  CardActions,
  Stack,
  Box,
} from "@mui/material";
import "./BlogPostCard.scss";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

const BlogPostCard: React.FC<any> = ({
  image,
  title,
  description,
  blogId,
  onReadMore,
  author,
  updatedAt
}) => {
  return (
    <Card className="blogCardMain">
      <CardMedia
        component="img"
        image={image}
        alt={title}
        className="blogCardBanner"
      />
      <CardContent className="blogCardContent">
        <Typography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography component="p" variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions className="blogCardBottom">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Box>
            <Typography>{author}</Typography>
            <Typography>{updatedAt}</Typography>
          </Box>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              onReadMore(blogId);
            }}
          >
            {t(LOCALIZATION.Read_More)}
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default BlogPostCard;
