import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
} from "@mui/material";
import { Stack } from "react-bootstrap";
import "./LIveDarshanTempleForm.scss";
import GooglePlaceAutoComplete from "../../../components/AutoComplete/GooglePlaceAutoComplete";

interface LIveDarshanTempleForm {
  isOpen: boolean;
  onClose: () => void;
  onSave: (formData: any) => void;
  selectedLiveItem: any;
}

const LIveDarshanTempleForm: React.FC<LIveDarshanTempleForm> = ({
  isOpen,
  onClose,
  onSave,
  selectedLiveItem,
}) => {
  const [formData, setFormData] = useState<any>({
    id: "",
    templeName: "",
    searchKey: "",
    googlePlaceId: "",
    position: "",
    ytEmbedCode: "",
    state: "",
    city: "",
    media: null,
  });
  const [errors, setErrors] = useState<any>({
    templeName: "",
    searchKey: "",
    position: "",
    googlePlaceId: "",
    ytEmbedCode: "",
    state: "",
    city: "",
    media: null,
  });
  const [localImageLiveDarshan, setLocalImageLiveDarshan] = useState<any>("");
  const [localImagePujaList, setLocalImagePujaList] = useState<any>("");
  useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  useEffect(() => {
    if (selectedLiveItem) {
      let loadFormData: any = {
        id: selectedLiveItem?.id,
        templeName: selectedLiveItem?.templeName,
        searchKey: selectedLiveItem?.searchKey,
        googlePlaceId: selectedLiveItem?.googlePlaceId,
        position: selectedLiveItem?.position,
        ytEmbedCode: selectedLiveItem?.ytEmbedCode,
        state: selectedLiveItem?.state,
        city: selectedLiveItem?.city,
        media: selectedLiveItem?.media,
      };
      setFormData({ ...loadFormData });
    }
  }, [selectedLiveItem]);

  const resetForm = () => {
    setLocalImageLiveDarshan("");
    setLocalImagePujaList("");
    setFormData({
      id: "",
      templeName: "",
      searchKey: "",
      googlePlaceId: "",
      position: "",
      ytEmbedCode: "",
      state: "",
      city: "",
      media: {},
    });
    setErrors({
      templeName: "",
      searchKey: "",
      googlePlaceId: "",
      position: "",
      state: "",
      city: "",
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    setErrors((prev: any) => ({
      ...prev,
      [name]: "",
    }));
    if (name === "position") {
      const regex = /^[0-9]*$/;
      if (!regex.test(value)) return;
    }
    if (type === "file" && files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLocalImageLiveDarshan(e.target?.result);
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev: any) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {
      templeName: formData?.templeName?.trim() ? "" : "Name is required",
      searchKey: formData?.searchKey?.trim() ? "" : "Search Key is required",
      position:
        formData?.position?.trim() && Number(formData?.position) > 0
          ? ""
          : "Weightage must be a positive number",
      state:
        localImageLiveDarshan || formData?.state ? "" : "State is required",
      city: localImagePujaList || formData?.city ? "" : "City is required",
      media:
        localImageLiveDarshan || formData?.media?.url
          ? ""
          : "Image is required",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsFormSubmit(true);
    await onSave(formData);
    resetForm();
    setIsFormSubmit(false);
    onClose();
  };

  const handleCloseFormModal = () => {
    onClose();
    resetForm();
  };

  const onSelectGooglePlaceAutoComplete = (data: any) => {
    let city = "";
    let state = "";
    try {
      if (data.address_components.length) {
        data.address_components.forEach((item: any, i: number) => {
          if (item?.types?.includes("administrative_area_level_1")) {
            state = item.long_name;
          }
          if (item?.types?.includes("locality")) {
            city = item.long_name;
          }
        });
      }
      setFormData((prev: any) => ({
        ...prev,
        templeName: data?.name || "",
        googlePlaceId: data?.place_id || "",
        city: city || "",
        state: state || "",
      }));
    } catch (exception: any) {}
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleCloseFormModal}
        className="specialPujaAdminDialog"
      >
        <DialogTitle className="spAdminDialogTitle">
          {selectedLiveItem ? "Edit" : "Add"} Live Temple
        </DialogTitle>
        <DialogContent className="innerContent" sx={{ minWidth: 600 }}>
          <Box className="itemList">
            <GooglePlaceAutoComplete
              onChangeHandle={onSelectGooglePlaceAutoComplete}
            />
          </Box>
          <TextField
            className="itemList"
            name="templeName"
            label="Name of Temple"
            fullWidth
            margin="normal"
            value={formData?.templeName}
            onChange={handleChange}
            error={!!errors?.templeName}
            helperText={errors?.templeName}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="searchKey"
            label="Add Search Key"
            fullWidth
            margin="normal"
            value={formData?.searchKey}
            onChange={handleChange}
            error={!!errors?.searchKey}
            helperText={errors?.searchKey}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="googlePlaceId"
            label="Google place Id"
            fullWidth
            margin="normal"
            value={formData?.googlePlaceId}
            onChange={handleChange}
            error={!!errors?.googlePlaceId}
            helperText={errors?.googlePlaceId}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="ytEmbedCode"
            label="YouTube Embed Code "
            fullWidth
            margin="normal"
            value={formData?.ytEmbedCode}
            onChange={handleChange}
            error={!!errors?.ytEmbedCode}
            helperText={errors?.ytEmbedCode}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="city"
            label="Temple City "
            fullWidth
            margin="normal"
            value={formData?.city}
            onChange={handleChange}
            error={!!errors?.city}
            helperText={errors?.city}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="state"
            label="Temple State "
            fullWidth
            margin="normal"
            value={formData?.state}
            onChange={handleChange}
            error={!!errors?.state}
            helperText={errors?.state}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />
          <TextField
            className="itemList"
            name="position"
            label="Temple Weightage"
            fullWidth
            margin="normal"
            type="number"
            InputProps={{
              inputMode: "numeric",
            }}
            value={formData?.position}
            onChange={handleChange}
            error={!!errors?.position}
            helperText={errors?.position}
            FormHelperTextProps={{
              sx: { marginLeft: "3px" },
            }}
          />

          <Stack className="chooseImgInfo itemList">
            <TextField
              name="file"
              label="Select Image"
              type="file"
              fullWidth
              margin="normal"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                accept: "image/*",
              }}
              error={!!errors?.media}
              helperText={errors?.media}
              FormHelperTextProps={{
                sx: { marginLeft: "3px" },
              }}
            />
            {localImageLiveDarshan?.trim()?.length == 0 &&
              formData?.media?.url && <img src={formData?.media?.url} />}
            {localImageLiveDarshan?.trim()?.length > 0 && (
              <img src={localImageLiveDarshan} />
            )}
          </Stack>
        </DialogContent>
        <DialogActions className="spAdminModalFooter">
          <Button onClick={handleCloseFormModal} className="btnCancel grey">
            Cancel
          </Button>
          <Button
            className="saveBtn"
            onClick={handleSubmit}
            variant="outlined"
            color="primary"
            disabled={isFormSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LIveDarshanTempleForm;
