import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import "./TempleTimings.scss";

interface TempleTimingsProps {
  isOpen24Hour: any;
  timings: any;
  handleTempleTimeChange: (updateTimeing:any)=> void;
  handle24TimingCheckbox: (timingType:boolean)=>void;
  editMode:string ;
}

const formatTime = (time: dayjs.Dayjs | null): string => {
  if (!time) return '';
  return time.format('hh:mm A'); // Day.js format for "hh:mm AM/PM"
};

const TempleTimings: React.FC<TempleTimingsProps> = (props:any) => {
  const {
    isOpen24Hour,
    timings,
    handleTempleTimeChange,
    handle24TimingCheckbox,
    editMode
  } = props

  const [editIndex, setEditIndex] = useState<{
    day: number;
    type: "openingTime" | "closingTime" | null;
  }>({ day: -1, type: null });
  const handleTimeChange = (
    newTime: Dayjs | null,
    index: number,
    type: "openingTime" | "closingTime"
  ) => {
    if (!newTime) return;
    console.log("newTime",newTime)
    console.log("index",index)
    console.log("type",type)
    console.log("formatTimeFromDayjs",formatTime(newTime));
    let updatedTimings: any[] = [...timings];
    updatedTimings[index] = {
      ...updatedTimings[index], // Copy all existing properties
      [type]: formatTime(newTime) // Update the specific property
    };
    // let updatedTimings: any[] = [...timings];
    // updatedTimings[index][type] = formatTime(newTime);
    handleTempleTimeChange(updatedTimings);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="templeTiming">
        <Typography className="titleBlock">Temple Timings</Typography>
        <Box className="checkBoxList">
          <FormControlLabel
            control={
              <Checkbox
                name="Opened 24 Hours"
                checked={isOpen24Hour}
                onChange={(e) => handle24TimingCheckbox(e.target.checked)}
                disabled = {editMode === "Verify"}
              />
            }
            label="Opened 24 Hours"
          />
        </Box>
  
        {!isOpen24Hour &&
          timings?.map((day:any, index:any) => (
            <Box key={day?.id} className="templeTimingCard">
              <Typography variant="h6" className="dayName">{day?.day}</Typography>
  
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box className="cardLeftContent">
                  <Typography>
                    Opening: {timings[index].openingTime}
                  </Typography>
                  <Typography>
                    Closing: {timings[index].closingTime}
                  </Typography>
                </Box>
  
                <Box className="cardRightContent">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    {editIndex.day === index && editIndex.type === "openingTime" && (
                      <Box className="timePicker left">
                        <TimePicker
                          label="Change Opening Time"
                          value={timings[index].openingTime}
                          onChange={(newTime) =>
                            handleTimeChange(newTime, index, "openingTime")
                          }
                          onClose={() => setEditIndex({ day: -1, type: null })}
                        />
                      </Box>
                    )}
  
                    {editIndex.day === index && editIndex.type === "closingTime" && (
                      <Box className="timePicker right">
                        <TimePicker
                          label="Change Closing Time"
                          value={timings[index].closingTime}
                          onChange={(newTime) =>
                            handleTimeChange(newTime, index, "closingTime")
                          }
                          onClose={() => setEditIndex({ day: -1, type: null })}
                        />
                      </Box>
                    )}
  
                    <Stack direction="column">
                      <Button
                        className="changeButton"
                        onClick={() =>
                          setEditIndex({ day: index, type: "openingTime" })
                        }
                      >
                        Change Opening
                      </Button>
                      <Button
                        className="changeButton"
                        onClick={() =>
                          setEditIndex({ day: index, type: "closingTime" })
                        }
                      >
                        Change Closing
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          ))}
      </Box>
    </LocalizationProvider>
  );
}  

export default TempleTimings;
