import React from 'react';

import { Container, Typography, Box } from '@mui/material';
import "./ContactUsView.scss";


const ContactUsView = () => {
    return (
        <Box className="pageContainer privacyPolicy">
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        Contact <span> Us</span>
                    </Typography>
                </div>
                <div className="privacyInfoDetails mb-40">
                    <Typography paragraph>
                        Thank you for your interest in Temple Dekho! Whether you have inquiries about our services, need assistance with a booking, or simply want to share your feedback, we're here to assist you. For urgent inquiries, or if you wish to raise a query or complaint, please contact our team. We are dedicated to ensuring your spiritual journey with Temple Dekho is enriching and seamless.
                    </Typography>
                    <Typography paragraph>
                        Please feel free to reach out to us through the following channels:
                    </Typography>
                    <Typography paragraph>
                        <strong>Email:</strong> <a href="mailto:support@templedekho.com">support@templedekho.com</a>
                    </Typography>
                    <Typography paragraph>
                        <strong>Phone: +91 90733 00055</strong>
                    </Typography>
                    <Typography paragraph>
                        We promise to acknowledge complaints within 24 hours of receipt and strive to resolve them promptly within 15 days. Our dedicated team is committed to ensuring that your spiritual journey with Temple Dekho is enriching and seamless.
                    </Typography>
                </div>
            </Container>
        </Box>
    );
};

export default ContactUsView;
