import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
const subscriptionKey = '0aa3523aa55d4a0a95c94aac3ab9f987';
const region = 'centralindia';

export const startSpeechRecognition = (onRecognized, onError) => {
  var autoDetectSourceLanguageConfig = SpeechSDK.AutoDetectSourceLanguageConfig.fromLanguages(["en-IN","hi-IN"]);
  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  speechConfig.setProperty(SpeechSDK.PropertyId.Speech_SegmentationSilenceTimeoutMs, "3000")
  //speechConfig.speechRecognitionLanguage = 'ar-AE';

  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = SpeechSDK.SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);


  recognizer.recognizeOnceAsync(result => {
    if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      let languageDetectionResult = SpeechSDK.AutoDetectSourceLanguageResult.fromResult(result);
      let detectedLanguage = languageDetectionResult.language;
      onRecognized(result.text,detectedLanguage);
    } else {
      onError(result.errorDetails);
    }
  });

  return recognizer;
};

export const startSpeechRecognitionWithLang = (language, onRecognized, onError) => {
  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  speechConfig.speechRecognitionLanguage = language === 'EN'?'en-IN':'hi-IN';
  speechConfig.setProperty(SpeechSDK.PropertyId.Speech_SegmentationSilenceTimeoutMs, "3000");
  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
  recognizer.recognizeOnceAsync(result => {
    if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      onRecognized(result.text, language);
    } else {
      onError(result.errorDetails);
    }
  });
  return recognizer;
};


export const startSpeechRecognitionWithLangRealTime = (language, onRecognized,onRecognizing, onError) => {
  const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, region);
  speechConfig.speechRecognitionLanguage = language === 'EN' ? 'en-IN' : 'hi-IN';
  speechConfig.setProperty(SpeechSDK.PropertyId.Speech_SegmentationSilenceTimeoutMs, "1000");
  
  const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
  const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
  
  // Event handler for when the recognizer is continuously recognizing speech
  recognizer.recognizing = (s, e) => {
    // Real-time recognition text (during speaking)
    onRecognizing(e.result.text, language);
  };

  // Event handler for when the recognition is complete
  recognizer.recognized = (s, e) => {
    if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
      onRecognized(e.result.text, language);
    }
  };

  // Event handler for errors or cancellation
  recognizer.canceled = (s, e) => {
    if (e.errorDetails) {
      onError(e.errorDetails);
    }
  };

  // Start continuous recognition
  recognizer.startContinuousRecognitionAsync();

  return recognizer;
};



export const stopSpeechRecognition = (recognizer) => {
  if (recognizer) {
    try {
      recognizer?.close();
    } catch (error) {
      console.log('Error',error);
    }
   
  }
};

export const stopContinuousRecognitionAsync = (recognizer) => {
  recognizer.stopContinuousRecognitionAsync();
};

