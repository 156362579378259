import { useNavigate } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import AdminLayoutView from "../../Views/Admin/AdminLayoutView";
function AdminLayoutScreen() {
  const navigate = useNavigate();
  return (
    <ParentView>
      <AdminLayoutView />
    </ParentView>
  );
}

export default AdminLayoutScreen;
