import CarouselComponent from "../../../components/carousel/Carousel";
import otpImg from "../../../assets/otpImg.png";

import MuiOtpInput from "react-otp-input";
import Dialog from "@mui/material/Dialog";
import "./LoginView.scss";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import RoundedButton from "../../../components/button/RoundedButton";
import CountryAutocomplete from "../../../components/CountryPhoneCode/CountryAutocomplete";
import { CloseIcon } from "@chakra-ui/icons";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

function LoginView(props: any) {
  return (
    <Box className="loginViewArea">
      <Box className="container">
        <Grid container spacing={4} className="loginBlock">
          <Grid xs={12} sm={12} md={6} lg={6} className="loginLeft">
            <Box className="leftCarousel">
              <CarouselComponent />
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} className="loginRight">
            <CountryAutocomplete
              isOtpScreen={props.isOtpScreen}
              loginErrorMessage={props.loginErrorMessage}
              sendOtp={props.sendOtp}
              setPhone={props.setPhone}
              preOtpErrorMsg={props.preOtpErrorMsg}
              setPreOtpErrorMsg={props.setPreOtpErrorMsg}
              setSelectedDialCode={props.setSelectedDialCode}
              selectedDialCode={props.selectedDialCode}
              disableGuestButton={props.disableGuestButton}
            />
          </Grid>
        </Grid>
        {/* OTP Dialog */}
        <Dialog
          open={props.isOtpScreen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              props.setIsOtpScreen(false);
              props.setPreOtpErrorMsg("");
            }
          }}
          disableEscapeKeyDown
          sx={{
            "& .MuiPaper-root": {
              margin: "15px",
              width: "auto !important",
              height: "auto",
              position: "relative",
            },
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={() => {
              props.setIsOtpScreen(false);
              props.setPreOtpErrorMsg("");
            }}
            className="otpCloseIconBtn"
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              fontSize: "14px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box className="otpScreen">
            <form
              onSubmit={(e) => props.verifyOtpSubmit(e, props.selectedDialCode)}
            >
              <Box className="otpHeadingText">
                <Typography className="veryfyNo">{t(LOCALIZATION.Verify_your_number)}</Typography>
                <Typography>
                  {t(LOCALIZATION.Code_is_sent_to)} xxxxxx
                  {props.phone.substring(6, props.phone.length)}
                </Typography>
                <Box className="otpImgBlock">
                  <img src={otpImg} alt="OTP Illustration"></img>
                </Box>
              </Box>

              <Box className="otpFields">
                <MuiOtpInput
                  // autoFocus={true}
                  inputType={"tel"}
                  numInputs={6}
                  value={props.otp}
                  onChange={props.setOtp}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{
                    border: "none",
                    justifyContent: "space-between",
                    maxWidth: "100%",
                    gap: "15px",
                  }}
                  inputStyle={{
                    width: "40px",
                    maxWidth: "10%",
                    border: "none",
                    borderBottom: "1px solid #242424",
                    borderRadius: 0,
                    fontSize: 24,
                    fontWeight: 600,
                    color: "#242424",
                    caretColor: "#d56939",
                  }}
                  skipDefaultStyles={false}
                />
              </Box>

              <Box id="recaptcha-container"></Box>
              <Stack direction="column" spacing={2}>
                <Box
                  className="countdownAndResend"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mt: 2,
                  }}
                >
                  <Typography className="countdownTimer">
                    {t(LOCALIZATION.Resend_available_in)} {Math.floor(props.countdown / 60)}:
                    {String(props.countdown % 60).padStart(2, "0")}
                  </Typography>

                  <Typography className="resendOtps">
                    <a
                      className="resendotp"
                      onClick={(e) => props.resendOtp(e)}
                      style={{
                        color: props.enableResendOtp ? "#d56939" : "#888",
                        cursor: props.enableResendOtp
                          ? "pointer"
                          : "not-allowed",
                        textDecoration: props.enableResendOtp
                          ? "underline"
                          : "none",
                      }}
                    >
                      {t(LOCALIZATION.Resend_OTP)}
                    </a>
                  </Typography>
                </Box>

                <Box className="otpConfirm">
                  <RoundedButton
                    type="submit"
                    className="btn btn-primary btnRound"
                    title={t(LOCALIZATION.Confirm)}
                    onClick={(e: any) =>
                      props.verifyOtpSubmit(e, props.selectedDialCode)
                    }
                  ></RoundedButton>
                </Box>
              </Stack>
            </form>
            {props.loginErrorMessage?.length > 0 ? (
              <Typography style={{ marginTop: "1em" }}>
                {props.loginErrorMessage}
              </Typography>
            ) : null}
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
}
export default LoginView;
