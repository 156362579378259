import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllOrder,
  getAllOrderByDateRange,
} from "../../store/actions/orderAction";
import { getAllUsers } from "../../store/actions/userAction";
import AdminView from "../../Views/Admin/AdminView";
import {
  getDateFormatedOrderTime,
  getDateFormatedForBookingDate,
  splitCamelCase,
  isValidDate,
  getFormattedAddressForDelivary,
} from "../../utils/HelperFunctions";
import moment from "moment";
import { orderType } from "../../utils/Constants";

function AdminScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { allTempleList } = useSelector((state: any) => state.temple);
  const { allNormalUsers } = useSelector((state: any) => state.user);
  const { userOrder } = useSelector((state: any) => state.order);
  const [flatOrderList, setFlatOrderList] = useState<any>([]);
  const [exportOrderList, setExportOrderList] = useState<any>([]);
  const [exportUserList, setExportUserList] = useState<any>([]);

  useEffect(() => {
    let exportUserList: any = [];
    if (allNormalUsers && allNormalUsers.length > 0) {
      allNormalUsers.map((user: any, index: number) => {
        let itemOfFlat: any = {};
        itemOfFlat.id = index;
        itemOfFlat.docId = user.id;
        itemOfFlat.userType = user.userType;
        itemOfFlat.name =
          user.personalInfo.firstName + " " + user.personalInfo.lastName;
        itemOfFlat.gender = user?.personalInfo?.gender;
        itemOfFlat.dateOfBirth = user?.personalInfo?.dateOfBirth;
        itemOfFlat.phoneNumber = user?.phoneNumber;
        itemOfFlat.email = user?.personalInfo?.email;
        itemOfFlat.address =
          user?.personalInfo?.address?.length > 0
            ? user?.personalInfo?.address
            : " ";
        itemOfFlat.userCreatedAt = user.userCreatedAt;
        exportUserList.push(itemOfFlat);
      });
      setExportUserList(exportUserList);
    }
  }, [allNormalUsers]);

  useEffect(() => {
    //if (userOrder && userOrder.length > 0) {
    let flatList: any = [];
    let exportList: any = [];
    userOrder?.map((userOdItem: any, index: number) => {
      let itemOfFlat: any = {};
      itemOfFlat.id = index;
      itemOfFlat.docId = userOdItem.id;
      itemOfFlat.templeName = userOdItem?.templeDetails?.name;
      itemOfFlat.orderId = userOdItem?.orderId;
      itemOfFlat.orderStatus =
        userOdItem?.orderStatus[
          userOdItem?.orderStatus.length - 1
        ]?.orderStatus;
      itemOfFlat.bookingDevice = userOdItem?.deviceType || "";
      itemOfFlat.optedForPrasad =
        (userOdItem?.orderType === orderType?.puja &&
          userOdItem?.orderData?.isPrasad) ||
        userOdItem.orderType === orderType.prasad
          ? "YES"
          : "NO";
      itemOfFlat.awbNumber =
        userOdItem?.orderStatus[userOdItem?.orderStatus.length - 1]?.awbNumber;

      if (userOdItem?.orderType !== orderType.prasad) {
        itemOfFlat.pujaDate = getDateFormatedForBookingDate(
          userOdItem?.pujaDate
        );
      } else {
        itemOfFlat.pujaDate = "";
      }

      itemOfFlat.orderUserName = userOdItem?.orderUserDetails?.orderUserName;
      itemOfFlat.phoneNumber = userOdItem?.orderUserDetails?.phoneNumber;
      itemOfFlat.whatsAppNumber = userOdItem?.whatsAppNumber || "";
      itemOfFlat.orderBookingDate = getDateFormatedOrderTime(
        userOdItem?.orderBookingDate
      );

      if (userOdItem?.orderType === orderType.puja) {
        itemOfFlat.pujaName = userOdItem?.pujaName;
      } else if (userOdItem?.orderType === orderType.chadhava) {
        itemOfFlat.pujaName = "Chadhava";
      } else {
        itemOfFlat.pujaName = "Prasad";
      }

      let groupDetails = "";
      userOdItem?.orderUserDetails?.groupDetails?.map(
        (member: any, index: number) => {
          if (index !== 0) {
            groupDetails = `,` + groupDetails;
          }
          groupDetails =
            groupDetails +
            `( Name: ${member.fullName}, Gotram : ${
              member?.gotram ? member?.gotram : "Kashyap"
            },)`;
        }
      );
      itemOfFlat.groupDetails = groupDetails;
      //payment deatils
      itemOfFlat.paymentStatus =
        userOdItem?.orderData?.payment?.paymentStatus?.toUpperCase();
      //itemOfFlat.transactionNumber = userOdItem?.orderData?.payment?.transactionId;
      itemOfFlat.paymentDate = getDateFormatedOrderTime(
        userOdItem?.orderData?.payment?.paymentDateTime
      );

      const {
        basic = 0,
        adminCommission = 0,
        goodies = 0,
        gst = 0,
        packaging = 0,
        platformFee = 0,
        delivery = 0,
      } = userOdItem?.orderData?.priceBreakup || {};

      const { orderValue } = userOdItem?.orderData?.payment;
      itemOfFlat.totalAmount = (
        Number(basic) +
        Number(adminCommission) +
        Number(goodies) +
        Number(gst) +
        Number(packaging) +
        Number(platformFee) +
        Number(delivery)
      ).toFixed(2);

      const {
        area = "",
        city = "",
        latitude = 0,
        longitude = 0,
        formatted_address = "",
        savedAs = "",
        state = "",
        street = "",
        zip = "",
        phoneNumber = "",
      } = userOdItem?.deliveryAddress || {};
      itemOfFlat.formattedAddress = formatted_address;
      itemOfFlat.FullDeliveryAddress = `${
        itemOfFlat.orderUserName
      }, Phone Number: ${phoneNumber}, Street: ${street}, Area: ${
        area || " "
      }, City: ${city}, State: ${state}, Zip: ${zip}`;

      itemOfFlat.zip = zip;
      itemOfFlat.state = state;
      // itemOfFlat.coordinateAddress = `Latitude=${latitude}, Longitude=${longitude}`;

      // itemOfFlat.pujaDateTime ="";
      flatList.push(itemOfFlat);
    });

    const sortedFlatList = flatList?.sort((a: any, b: any) => {
      const dateA: any = moment(a.orderBookingDate, "DD-MM-YYYY hh:mm a");
      const dateB: any = moment(b.orderBookingDate, "DD-MM-YYYY hh:mm a");
      return dateB - dateA;
    });
    setFlatOrderList(sortedFlatList);

    userOrder.map((userOdItem: any, index: number) => {
      let itemOfFlat: any = {};
      itemOfFlat.id = index;
      itemOfFlat.templeName = userOdItem?.templeDetails?.name;
      itemOfFlat.orderId = userOdItem?.orderId;
      itemOfFlat.orderStatus =
        userOdItem?.orderStatus[
          userOdItem?.orderStatus.length - 1
        ]?.orderStatus;

      itemOfFlat.bookingDevice = userOdItem?.deviceType || "";
      itemOfFlat.optedForPrasad =
        (userOdItem?.orderType === orderType?.puja &&
          userOdItem?.orderData?.isPrasad) ||
        userOdItem.orderType === orderType.prasad
          ? "YES"
          : "NO";
      // itemOfFlat.pujaDate = getDateFormatedForBookingDate(
      //   userOdItem?.pujaDate
      // );

      if (userOdItem?.orderType !== orderType.prasad) {
        itemOfFlat.pujaDate = getDateFormatedForBookingDate(
          userOdItem?.pujaDate
        );
      } else {
        itemOfFlat.pujaDate = "";
      }

      itemOfFlat.awbNumber =
        userOdItem?.orderStatus[userOdItem?.orderStatus.length - 1]?.awbNumber;
      itemOfFlat.orderUserName = userOdItem?.orderUserDetails?.orderUserName;
      itemOfFlat.phoneNumber = userOdItem?.orderUserDetails?.phoneNumber;
      itemOfFlat.whatsAppNumber = userOdItem?.whatsAppNumber || "";
      if (userOdItem?.orderType !== orderType.prasad) {
        itemOfFlat.pujaName = userOdItem?.pujaName;
        itemOfFlat.orderBookingDate = getDateFormatedOrderTime(
          userOdItem?.orderBookingDate
        );
      } else {
        itemOfFlat.pujaName = "Prasad";
        itemOfFlat.orderBookingDate = getDateFormatedOrderTime(
          userOdItem?.orderBookingDate
        );
      }

      let groupDetails = "";
      userOdItem?.orderUserDetails?.groupDetails?.map(
        (member: any, index: number) => {
          if (index !== 0) {
            groupDetails = `,` + groupDetails;
          }
          groupDetails =
            groupDetails +
            `( Name: ${member.fullName}, Gotram : ${
              member?.gotram ? member?.gotram : "Kashyap"
            }, DOB: ${member.dateOfBirth}, Gender: ${member.gender}, Rashi: ${
              member.rashi
            },)`;
        }
      );
      itemOfFlat.groupDetails = groupDetails;

      itemOfFlat.paymentDate = getDateFormatedOrderTime(
        userOdItem?.orderData?.payment?.paymentDateTime
      );

      const {
        basic = 0,
        adminCommission = 0,
        goodies = 0,
        gst = 0,
        packaging = 0,
        platformFee = 0,
        delivery = 0,
      } = userOdItem?.orderData?.priceBreakup || {};
      itemOfFlat.basicPrice = basic;
      itemOfFlat.adminCommissionPrice = adminCommission;
      itemOfFlat.goodiesPrice = goodies;

      itemOfFlat.packagingPrice = packaging;
      itemOfFlat.platformFeePrice = platformFee;
      itemOfFlat.deliveryPrice = delivery;
      let tamount =
        Number(basic) +
        Number(adminCommission) +
        Number(goodies) +
        Number(packaging) +
        Number(platformFee) +
        Number(delivery);
      let _gst: Number = (18 / 100) * tamount;
      itemOfFlat.totalAmount = tamount.toFixed(2);
      itemOfFlat.gstPrice = _gst?.toFixed(2);
      let totalAmountForTemple = Number(basic) + Number(adminCommission);
      itemOfFlat.totalAmountForTemple = totalAmountForTemple.toFixed(2);
      let tds = basic * 0.01;
      itemOfFlat.tdsDetuction = tds.toFixed(2);
      let amountToPay = totalAmountForTemple - tds;
      itemOfFlat.amountToPay = amountToPay.toFixed(2);

      const {
        paymentStatus,
        transactionId,
        paymentMode,
        paymentOrderId,
        orderValue,
      } = userOdItem?.orderData?.payment;
      itemOfFlat.orderValue = orderValue;
      itemOfFlat.paymentStatus = paymentStatus;
      itemOfFlat.transactionNumber = transactionId;
      //itemOfFlat.paymentDateTime = getDateFormatedOrderTime(paymentDateTime);
      itemOfFlat.awbNumber =
        userOdItem?.orderStatus[userOdItem?.orderStatus.length - 1]?.awbNumber;
      itemOfFlat.paymentMode = paymentMode;
      itemOfFlat.paymentOrderId = paymentOrderId;
      itemOfFlat.platformFeePrice = platformFee;
      itemOfFlat.couponCode =
        userOdItem?.orderData?.payment?.couponCode?.couponCode;

      //itemOfFlat.deliveryAddress = flattenJSON(userOdItem?.deliveryAddress);
      // const {
      //   area,
      //   city,
      //   latitude,
      //   longitude,
      //   formatted_address,
      //   savedAs,
      //   state,
      //   street,
      //   zip,
      //   phoneNumber,
      // } = userOdItem?.deliveryAddress;

      const {
        area = "",
        city = "",
        latitude = 0,
        longitude = 0,
        formatted_address = "",
        savedAs = "",
        state = "",
        street = "",
        zip = "",
        phoneNumber = "",
      } = userOdItem?.deliveryAddress || {};

      itemOfFlat.DeliveryAddress = formatted_address;
      itemOfFlat.FullDeliveryAddress = `${itemOfFlat.orderUserName}, Phone Number: ${phoneNumber}, Street: ${street}, Area: ${area}, City: ${city}, State: ${state}, Zip: ${zip}`;
      itemOfFlat.zip = zip;
      itemOfFlat.state = state;

      //itemOfFlat.formattedAddress = formatted_address;
      // itemOfFlat.coordinateAddress = `Latitude=${latitude}, Longitude=${longitude}`;

      // itemOfFlat.pujaDateTime ="";
      exportList.push(itemOfFlat);
    });
    const sortedExportList = exportList?.sort((a: any, b: any) => {
      const dateA: any = moment(a.orderBookingDate, "DD-MM-YYYY hh:mm a");
      const dateB: any = moment(b.orderBookingDate, "DD-MM-YYYY hh:mm a");
      return dateB - dateA; // descending order
    });
    setExportOrderList(sortedExportList);
    // }
  }, [userOrder]);

  useEffect(() => {
    getDataSelectedDateRange();
    dispatch(getAllUsers())
      .then(() => {})
      .catch((e: any) => {});
  }, [dispatch]);

  const convertArrayOfObjectsToCSV = (data: any[]): string => {
    if (data.length === 0) return "";
    const headers = Object.keys(data[0]);
    const csv = [
      headers?.map((item) => splitCamelCase(item))?.join(","),
      ...data.map((item) =>
        headers.map((fieldName) => JSON.stringify(item[fieldName])).join(",")
      ),
    ].join("\n");

    return "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  };

  function prepareDataForSelectedOrderExport(selectedDocids: any) {
    if (!Array.isArray(selectedDocids) && !(selectedDocids.length > 0)) return;

    let selectedOrdersToExport = userOrder?.filter((item: any) =>
      selectedDocids?.includes(item.id)
    );
    let rowDataForOrderExport: any = [];
    rowDataForOrderExport = selectedOrdersToExport.map(
      (orderItem: any, index: number) => {
        let payload: any = {};
        payload["S.No"] = index + 1;
        payload["Order ID"] = orderItem?.orderId || "";
        //showing last order status
        payload["Order Status"] = orderItem?.orderStatus?.length
          ? orderItem?.orderStatus?.[orderItem?.orderStatus?.length - 1]
              ?.orderStatus ?? ""
          : "";
        payload["Opted for Prasad"] =
          (orderItem?.orderType === orderType?.puja &&
            orderItem?.orderData?.isPrasad) ||
          orderItem.orderType === orderType.prasad
            ? "YES"
            : "NO";
        payload["Temple Name"] = orderItem?.templeDetails?.name ?? "";
        let pujaChadhavaPrasadName = "";
        let pujaChadhavaDate = "";
        let delivaryAddress = "";
        let dakshina = 0;
        const {
          area = "",
          city = "",
          state = "",
          street = "",
          zip = "",
          countryCode,
        } = orderItem?.deliveryAddress || {};
        if (orderItem?.orderType === orderType?.puja) {
          let isPrasadOrdered = !!orderItem?.orderData?.isPrasad;
          pujaChadhavaPrasadName = orderItem?.pujaDetails?.pujaName ?? "";
          pujaChadhavaDate = orderItem?.pujaDate ? orderItem?.pujaDate : "";
          // only export delivary address when user want prasad delivary for puja
          delivaryAddress = isPrasadOrdered
            ? getFormattedAddressForDelivary(
                street,
                area,
                city,
                state,
                zip,
                countryCode
              )
            : "";
        } else if (orderItem?.orderType === orderType?.chadhava) {
          if (
            orderItem?.chadhavaList &&
            Array.isArray(orderItem?.chadhavaList) &&
            orderItem?.chadhavaList.length > 0
          ) {
            let isFirst: boolean = true;
            let chadahavaItemName = "";
            orderItem?.chadhavaList?.map((chadahavaItem: any) => {
              if (isFirst) {
                chadahavaItemName = `${chadahavaItem?.name} * ${chadahavaItem?.count}`;
                isFirst = false;
              } else {
                chadahavaItemName =
                  chadahavaItemName +
                  ` + ${chadahavaItem?.name} * ${chadahavaItem?.count}`;
              }
            });
            pujaChadhavaPrasadName = chadahavaItemName;
          }
          pujaChadhavaDate = orderItem?.pujaDate ? orderItem?.pujaDate : "";
          dakshina = orderItem?.orderData?.priceBreakup?.dakshina || 0;
        } else if (orderItem?.orderType === orderType?.prasad) {
          if (
            orderItem?.chadhavaList &&
            Array.isArray(orderItem?.chadhavaList) &&
            orderItem?.chadhavaList.length > 0
          ) {
            let isFirst: boolean = true;
            let chadahavaItemName = "";
            orderItem?.chadhavaList?.map((chadahavaItem: any) => {
              if (isFirst) {
                chadahavaItemName = `${chadahavaItem?.name} * ${chadahavaItem?.count}`;
                isFirst = false;
              } else {
                chadahavaItemName =
                  chadahavaItemName +
                  ` + ${chadahavaItem?.name} * ${chadahavaItem?.count}`;
              }
              return null;
            });
            pujaChadhavaPrasadName = chadahavaItemName;
          }
          delivaryAddress = getFormattedAddressForDelivary(
            street,
            area,
            city,
            state,
            zip,
            countryCode
          );
        }

        payload["Puja / Chadhava/ Prasad Name"] = pujaChadhavaPrasadName;
        payload["Booking Date"] =
          orderItem?.orderBookingDate &&
          isValidDate(orderItem?.orderBookingDate)
            ? getDateFormatedForBookingDate(orderItem?.orderBookingDate)
            : "";
        payload["Puja/Chadhava  Date"] = pujaChadhavaDate;
        let gotra = "";
        let wish = "";
        let devoteeNames =
          orderItem?.orderUserDetails?.groupDetails &&
          Array.isArray(orderItem?.orderUserDetails?.groupDetails) &&
          orderItem?.orderUserDetails?.groupDetails.length
            ? orderItem?.orderUserDetails?.groupDetails.reduce(
                (acc: any, curr: any, index: number) => {
                  if (index === 0) {
                    acc = `${curr.fullName}`;
                    gotra = curr.gotram || "";
                    wish = curr.manokamna || "";
                  } else {
                    acc = acc + ` + ${curr.fullName}`;
                  }
                  return acc;
                },
                ""
              )
            : "";
        payload["Devotee Names"] = devoteeNames;
        payload["Gotra"] = ["TEMPLEPUJA", "TEMPLECHADHAVA"].includes(
          orderItem.orderType
        )
          ? gotra
          : "";
        payload["Manokamna/Wish"] = ["TEMPLEPUJA", "TEMPLECHADHAVA"].includes(
          orderItem.orderType
        )
          ? wish
          : "";
        payload["Delivary Address"] = delivaryAddress;
        payload["User Number"] = `'${orderItem?.orderUserDetails?.phoneNumber}`;
        payload["Whatsapp Number"] = `'${
          orderItem?.whatsAppNumberdialCode
            ? orderItem?.whatsAppNumberdialCode
            : ""
        }${orderItem?.whatsAppNumber}`;
        payload["Dakshina"] = dakshina?.toFixed(2);
        let amountToPayTemple = 0;
        if (orderItem?.orderData?.priceBreakup) {
          if (orderItem?.orderData?.priceBreakup?.adminCommission) {
            amountToPayTemple +=
              orderItem?.orderData?.priceBreakup?.adminCommission;
          }
          if (orderItem?.orderData?.priceBreakup?.basic) {
            amountToPayTemple += orderItem?.orderData?.priceBreakup?.basic;
          }
          if (orderItem?.orderData?.priceBreakup?.dakshina) {
            amountToPayTemple += orderItem?.orderData?.priceBreakup?.dakshina;
          }
        }
        payload["Temple Amount"] = amountToPayTemple?.toFixed(2);
        payload["Amount paid by user( INR)"] =
          orderItem?.orderData?.payment?.orderValue?.toFixed(2);

        return payload;
      }
    );
    return rowDataForOrderExport;
  }

  function convertAndDownload(jsonData: any) {
    const csvData = jsonToCSV(jsonData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = "AllUserList.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function jsonToCSV(jsonData: any) {
    const headers = Object.keys(jsonData[0]);
    const csv = jsonData.map((row: any) =>
      headers.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
    );
    return [headers.join(","), ...csv].join("\n");
  }

  const handleDownloadExcel = (type: string, ids: any) => {
    let rawData: any = [];
    let fileName: string = "";
    if (type === "users") {
      convertAndDownload(exportUserList);
      return;
    } else if (type === "order") {
      fileName = "Orders";
      rawData =
        flatOrderList?.filter((item: any) => ids.includes(item.orderId)) || [];
    } else if (type === "settelementorder") {
      fileName = "Settlements";
      rawData =
        exportOrderList?.filter((item: any) => ids.includes(item.orderId)) ||
        [];
    } else if (type === "selectedOrder") {
      let rawData = prepareDataForSelectedOrderExport(ids);
      const csvData = convertArrayOfObjectsToCSV(rawData);
      fileName = "SelectedOrders";
      const link = document.createElement("a");
      link.setAttribute("href", csvData);
      link.setAttribute("download", fileName + ".csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    const csvData = convertArrayOfObjectsToCSV(rawData);
    const link = document.createElement("a");
    link.setAttribute("href", csvData);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDataSelectedDateRange = (dateRange = null) => {
    let nDate = null;
    if (dateRange) {
      nDate = dateRange;
    } else {
      const startDateOfToday = moment(moment(new Date()).startOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");
      const endDateOfToday = moment(moment(new Date()).endOf("day"))
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss[Z]");

      nDate = {
        startDate: startDateOfToday,
        endDate: endDateOfToday,
      };
    }
    if (nDate?.startDate && nDate?.endDate) {
      dispatch(getAllOrderByDateRange(nDate));
    }
  };

  return (
    <AdminView
      allTempleList={allTempleList.organisation}
      allOrder={flatOrderList}
      settlementsOrder={exportOrderList}
      handleDownloadExcel={handleDownloadExcel}
      getDataSelectedDateRange={getDataSelectedDateRange}
    />
  );
}

export default AdminScreen;
