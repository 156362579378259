import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import ExportIcon from "../../../assets/export-icon.png";
import React, { useState, useEffect, useRef } from "react";
import "./AIGurujiFooter.scss";
import {
  startSpeechRecognition,
  startSpeechRecognitionWithLang,
  stopSpeechRecognition,
} from "../../../utils/speechRecognition";
import AIGurujiBackToMainMenu from "../AIGurujiChatItem/AIGurujiBackToMainMenu/AIGurujiBackToMainMenu";
import AIGurujiExportPDFDialog from "../AIGurujiChatItem/AIGurujiExportPDFDialog/AIGurujiExportPDFDialog";

const AIGurujiFooter: React.FC<any> = ({
  sendInpute,
  isLoading,
  isChatInputBoxDisebal,
  selectedLang,
  chatMessageLength,
  handleBackToMainMenu,
  lastChatItem,
  handleDownload,
  isMobileView,
}) => {
  const placeholders = [
    "Religious Chat",
    "Vastu Shastra",
    "Astrology",
    "Numerology",
    "Birth Chart",
    "Lagna Chart",
  ];
  const placeholders2 = ["Guruji is typing"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [hideText, setHideText] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const recognizerRef = useRef<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  useEffect(() => {
    if (!inputValue && !hideText) {
      const interval = setInterval(() => {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 500);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [placeholders.length, hideText, inputValue]);

  const handleFocus = () => setHideText(true);
  const handleBlur = () => setHideText(false);
  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const handleSend = () => {
    const val = inputValue;
    sendInpute(val);
    setInputValue("");
  };

  const [listening, setListening] = useState<boolean>(false);
  const handleMicClick = () => {
    if (listening) {
      stopSpeechRecognition(recognizerRef.current);
      setListening(false);
    } else {
      setListening(true);
      recognizerRef.current = startSpeechRecognitionWithLang(
        selectedLang,
        (recognizedText: string, detectedLanguage: string) => {
          sendInpute(recognizedText);
          setListening(false);
        },
        (error: any) => {
          console.error("Recognition error:", error);
          setListening(false);
        }
      );
    }
  };

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress === 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        setProgress(progress + 1);
        if (buffer < 100 && progress % 5 === 0) {
          const newBuffer = buffer + 1 + Math.random() * 10;
          setBuffer(newBuffer > 100 ? 100 : newBuffer);
        }
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="buffer"
            value={progress}
            valueBuffer={buffer}
          />
        </Box>
      )}

      {isLoading === false && chatMessageLength > 2 && (
        <AIGurujiBackToMainMenu handleBackToMainMenu={handleBackToMainMenu} />
      )}
      <Box className="aiGurujiFooter">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          //spacing={2}
          className="aiGurujiFooterContent"
        >
          <Box className="aiGurujiFooterLeft">
            {/* <TextField
                              variant="outlined"
                              placeholder="Type your message..."
                              className="chatTextInputArea"
                              InputProps={{
                                   endAdornment: (
                                        <>
                                             <InputAdornment position="end">
                                                  <IconButton className="footerIcon">
                                                       <MicIcon />
                                                  </IconButton>
                                             </InputAdornment>

                                             <Box className="buttonDivider" />

                                             <InputAdornment position="end">
                                                  <IconButton className="footerIcon">
                                                       <SendIcon />
                                                  </IconButton>
                                             </InputAdornment>
                                        </>
                                   ),
                              }}
                         /> */}
            <div className="placeholder-container">
              <TextField
                type="text"
                className="placeholder-input chatTextInputArea"
                value={inputValue}
                disabled={isChatInputBoxDisebal}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                style={{ padding: "0", fontSize: "16px" }}
                placeholder=""
                InputProps={{
                  endAdornment: (
                    <>
                      {
                        <InputAdornment position="end">
                          <IconButton
                            className="footerIcon"
                            disabled={isChatInputBoxDisebal}
                            onClick={() => {
                              handleMicClick();
                            }}
                          >
                            {listening ? (
                              <div className="pulsating-circle box" />
                            ) : (
                              <MicIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      <Box className="buttonDivider" />
                      <InputAdornment position="end">
                        <IconButton
                          className="footerIcon"
                          disabled={isChatInputBoxDisebal}
                          onClick={(e:any) => {
                            e.preventDefault();
                            handleSend();
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }}
              />
              {/* { !inputValue && (
                                   <span className="placeholder-static">Please ask on</span>
                              )} */}
              {isLoading === false && !inputValue && (
                <span
                  className={`placeholder-dynamic ${animate ? "animate" : ""} ${
                    hideText ? "hide" : ""
                  }`}
                >
                  <span>Please</span> Ask on "{placeholders[currentIndex]}"
                </span>
              )}
              {isLoading === true && (
                <span className={"placeholder-static-typing"}>
                  {placeholders2[0]}
                  <span className="typing-dots"></span>
                </span>
              )}
            </div>
          </Box>
          <Box className="aiGurujiFooterRight">
            {/* <Box className="chatExport" >
              <Box
                component="img"
                src={ExportIcon}
                sx={{
                  border: 'none',
                  background: 'none',
                  // cursor: 'not-allowed',
                  // opacity: 0.5,
                  // pointerEvents: 'none',
                }}
                onClick={e=>{setIsModalOpen(true)}}
              />
              <Typography >Export</Typography>
            </Box> */}
          </Box>
        </Stack>
      </Box>
      {/* <AIGurujiExportPDFDialog openModal ={isModalOpen} closeModal={handleClose} handleDownload={handleDownload}/> */}
    </>
  );
};

export default React.memo(AIGurujiFooter);
