
import "react-multi-carousel/lib/styles.css";

import Footer from "../../components/footer/Footer";
import { useSelector } from "react-redux";

function ParentView(props: any) {


  const { userInfo } = useSelector((state: any) => state.user);
  const { isToShowBottomNavForMobile = false } = props;

  return (
    <>
      {props.children}
      <div className="bottomFooter">
        {/* {userInfo?.id ? <Footer /> : null} */}
        <Footer isToShowBottomNavForMobile={!isToShowBottomNavForMobile} />
      </div>
    </>
  );
}

export default ParentView;
