import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

interface BookingDateConfirmDialogProps {
  open: boolean;
  bookingDate: string;
  newDate: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const BookingDateConfirmDialog: React.FC<BookingDateConfirmDialogProps> = ({
  open,
  bookingDate,
  newDate,
  onConfirm,
  onCancel,
}) => {
    const displayDateFormat = (date: string) => new Date(date).toLocaleDateString();
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <Typography>
          {`${displayDateFormat(bookingDate)} is unavailable. The next available date is ${displayDateFormat(newDate)}. Would you like to proceed with the booking?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDateConfirmDialog;