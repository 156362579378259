import { useNavigate } from "react-router-dom";
import { Box, Grid, TextField, InputAdornment } from "@mui/material";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import { getOneImageForTemple } from "../../utils/HelperFunctions";
import Search from "@mui/icons-material/Search";
import BlogContent from "../../components/BlogContent/BlogContent";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { useSelector } from "react-redux";
import "./PujaView.scss";
import { useTranslation } from "react-i18next";
import { LOCALIZATION } from "../../utils/localization";

function PujaView({
  searchPujaForUser,
  allPujaList,
  onClickAddPuja,
  selectTemple,
  cartDetails,
}: any) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isLoading } = useSelector((state: any) => state.puja);
  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
            <TextField
              className="searchView"
              onChange={(e: any) => searchPujaForUser(e.target.value)}
              id="outlined-basic"
              // label="Search for temples and pujas"
              variant="outlined"
              placeholder={t(LOCALIZATION.Search_for_pujas)}
              sx={{
                "& fieldset": { border: "none" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "#000" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Box className="templeCenterBlock pujaBlock">
              {allPujaList &&
                allPujaList.map((item: any, id: any) => {
                  let templeProfilePic = getOneImageForTemple(
                    item.personalInfo?.media?.pictures
                  );
                  return (
                    <PujaListCard
                      pujaData={item}
                      pujaName={item.pujaDetails.pujaName}
                      templeProfilePic={
                        item.pujaDetails.pujaBg?.uri || templeProfilePic
                      }
                      description={item.pujaDetails.description}
                      benefits={item.pujaDetails.benefits}
                      acctualPrice={item.pujaDetails.acctualPrice}
                      onClickAddPuja={onClickAddPuja}
                      temple={item.personalInfo}
                      devoteeCount={item.pujaDetails?.maxMemberAllowed}
                      benefitList={item?.pujaDetails?.benefitList}
                      isPujaDisable={item?.pujaDetails?.isPujaDisable}
                    />
                  );
                })}
              {allPujaList.length === 0 && !isLoading && <NoDataFound />}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
        <Box className="bottomBlogContent">
          <BlogContent />
        </Box>
      </Box>
    </Box>
  );
}

export default PujaView;
