import { useEffect, useState } from "react";
import { CommonService } from "td_server";
import { useDispatch, useSelector } from "react-redux";
import ManagePujaView from "../../Views/Admin/ManagePuja/ManagePujaView";

import {
  addPuja,
  deletePujaItem,
  getPujasByOrgId,
} from "../../store/actions/managePujaAction";
import { updatePuja } from "../../store/actions/managePujaAction";
import { replaceSpacesWithHyphen } from "../../utils/HelperFunctions";
function ManagePujaScreen() {
  const dispatch: any = useDispatch();
  const { benefitsList, allTempleList } = useSelector(
    (state: any) => state.temple
  );
  const { pujaMergedSubCategoryList } = useSelector((state: any) => state.puja);

  const [selectedTempleId, setSelectedTempleId] = useState<string>("");
  const [selectedPujaItem, setSelectedPujaItem] = useState<any>(null);
  const [isFormSubmit, setIsFormSubmit] = useState<boolean>(false);
  const { pujaList } = useSelector((state: any) => state.managePuja);

  useEffect(() => {
    if (selectedTempleId) {
      try {
        dispatch(getPujasByOrgId(selectedTempleId));
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }, [selectedTempleId]);

  const handleSetSelectedTemple = (temple: any) => {
    setSelectedTempleId(temple?.id);
  };

  // Add and edit puja
  const handleSavePujaItem = async (item: any) => {
    try {
      setIsFormSubmit(true)
      let imageUrl = "";
      const data = Object.assign({}, item);
      data.orgId = selectedTempleId;
      let priceInfo = 0;
      data.priceInfo = priceInfo;
      delete data.imageFile;
      if (item?.imageFile) {
        imageUrl = await CommonService.fileUpload(
          {
            uri: item?.imageFile,
            platform: "web",
          },
          "users_details/temple_picture"
        );
        data.pujaBg = {
          uri: imageUrl,
          Platform: "web",
          multipleArr: [],
        };
      }
      if (selectedPujaItem) {
        let updatePayload: any = { ...selectedPujaItem, ...data };
        dispatch(updatePuja(selectedTempleId, item.pujaId, updatePayload)).then(()=>{
          setIsFormSubmit(false)
        });
      } else {
        delete data.id;
        delete data.pujaId;
        data["pujaNameKey"] = data?.pujaName
          ? replaceSpacesWithHyphen(data?.pujaName)
          : "";
        dispatch(addPuja(selectedTempleId, data)).then((res:any)=>{
          setIsFormSubmit(false)
        });
      }
    } catch (error) {
      console.log("Error: ", error);
    }
    setSelectedPujaItem(null);
  };

  const handleDeletePujaItem = (item: any) => {
    try {
      dispatch(deletePujaItem(selectedTempleId, item?.pujaId));
      setSelectedPujaItem(null);
    } catch (error) {
      console.log("Error:", error);
    }
  };
  
  return (
    <ManagePujaView
      temples={allTempleList}
      setSelectedTemple={handleSetSelectedTemple}
      pujaListAdmin={pujaList}
      savePujaItem={handleSavePujaItem}
      benefitsList={benefitsList}
      selectedTempleId={selectedTempleId}
      deletePujaItem={handleDeletePujaItem}
      pujaMergedSubCategoryList={pujaMergedSubCategoryList}
      selectedItem={selectedPujaItem}
      setSelectedItem={setSelectedPujaItem}
      isFormSubmit={isFormSubmit}
    />
  );
}

export default ManagePujaScreen;
