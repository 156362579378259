import { Box, Grid, Typography } from "@mui/material";
import RoundedButton from "../../button/RoundedButton";
import ganpatiTemple from "../../../assets/pt3.jpg";
import { LOCALIZATION } from "../../../utils/localization";
import { t } from "i18next";

export default function LiveDarshan(props: any) {
  const boxDefaultCss = {
    borderRadius: "20px",
    backgroundColor: "#FFFFFF",
    margin: "10px",
    color: "#767676",
    width: "100%",
    padding: "15px",
  };
  return (
    <>
    {/* {templeData.map((item, index) => ( */}
    <Box key={"asd"}
      className="liveDarshanCardDesign"
      style={{
        borderRadius: props.borderRadius || boxDefaultCss.borderRadius,
        backgroundColor: props.backgroundColor || boxDefaultCss.backgroundColor,
        margin: props.margin || boxDefaultCss.margin,
        color: props.color || boxDefaultCss.color,
        width: props.width || boxDefaultCss.width,
        padding: props.padding || boxDefaultCss.padding,
      }}
      
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <Box className="liveDarshanCardLt">
            <Box className="mandirImg">
                <Box className="mandirImgB"><img src={props.image}></img></Box>
            </Box>
            <Box className="mandirDetails">
            <Typography>
              Temple: <span className="templeName">{props.name}</span>
            </Typography>
            <Typography>
              Location: <span className="pujaName">{props.location}</span>
            </Typography>
            </Box>
            
          </Box>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Box className="liveDarshanCardRt">
            <RoundedButton onClick={()=>props.goToTempleDarshan(props.templeData?.id)} title={t(LOCALIZATION.Darshan)}></RoundedButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
    {/* ))} */}
    </>
  );
}
