import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewPaginatedAnalyticsList,
  getViewedPaginatedAnalyticsList,
} from "../../store/actions/AnalyticsAction";
import AnalyticsView from "../../Views/Admin/Analytics/AnalyticsView";
import {
  downloadExcelSheetFromObject,
  splitCamelCase,
} from "../../utils/HelperFunctions";
import {
  setAnalyticsList,
  setPaginatedAnalyticsList,
} from "../../store/slices/analyticsSlice";
import moment from "moment";
function UserAnalyticsScreen() {
  const dispatch: any = useDispatch();
  const { analyticsList, paginatedAnalytics } = useSelector(
    (state: any) => state.analytics
  );
  const isLoading = useSelector((state: any) => state.analytics.isLoading);
  const [analyticsfilterdata, SetAnalyticsfilterdata] = useState<any>([]);
  const [analyticsfilterExportdata, SetAnalyticsfilterExportdata] =
    useState<any>([]);
  const [analyticsKeys, setAnalyticsKeys] = useState<any>([]);
  //const [ filterAnalyticslist, filterAnalyticslist ] = useState<any>()
  const [activeFilter, setActiveFilter] = useState<string>("today");
  const [selectedEventKey, setSelectedEventKey] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("1");
  const [dateRange,setDateRange] = useState<any>(null)
  const [loading,setLoading] = useState<boolean>(false)
  const pageLimit = 500;
  useEffect(() => {
  
    //SetAnalyticsfilterdata(analyticsList.analyticsList)
    const tempKeys: any = [];
    handleCurrentFilter(activeFilter);
    if (analyticsList) {
      analyticsList?.map((item: any) => {
        tempKeys.push(item.key);
      });
      const uniqueArray = Array.from(new Set(tempKeys));
      setAnalyticsKeys(uniqueArray);
    }
    setLoading(false)
  }, [analyticsList]);

  useEffect(() => {
    // dispatch(getNewAnalyticsList())
    //   .then(() => {})
    //   .catch((e: any) => {
    //   });
    setLoading(true)
    dispatch(getNewPaginatedAnalyticsList(null, pageLimit));
  }, [dispatch]);

  useEffect(() => {
    if (activeFilter === "today") {
      let dateRangeSelect = {
        startDate: moment()
          .utc()
          // .set({ hours: 0, minutes: 0, seconds: 0 })
          .subtract(1, "days")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      setDateRange(dateRangeSelect);
    } else if (activeFilter === "7day") {
      let dateRangeSelect = {
        startDate: moment()
          .utc()
          // .set({ hours: 0, minutes: 0, seconds: 0 })
          .subtract(7, "days")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      setDateRange(dateRangeSelect);
    } else if (activeFilter === "30day") {
      let dateRangeSelect = {
        startDate: moment()
          .utc()
          // .set({ hours: 0, minutes: 0, seconds: 0 })
          .subtract(30, "days")
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      setDateRange(dateRangeSelect);
    } else {
      //Only today date || Later change it to date range select
      let dateRangeSelect = {
        startDate: moment()
          .utc()
          .set({ hours: 0, minutes: 0, seconds: 0 })
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
      setDateRange(dateRangeSelect);
    }
  }, [activeFilter]);

  const handleCurrentFilter = (ftype: string) => {
    setLoading(false)
    const fdata = analyticsList?.filter((item: any) =>
      analyticsFilter(item, ftype)
    );
    setFilterData(fdata);
    setActiveFilter(ftype);
  };

  const setFilterData = (data: any) => {
   
    //let uniqueArray = filterUniqueUserEvents(data);
    let uniqueArray = data;
    let exportData:any[] = []; 
    uniqueArray?.forEach((item:any) => {
     
      
      const obj : { [key: string]: any } = {
        "Key":item?.key,
        "Phone Number":item?.eventData?.phoneNumber,
        "Count": item?.count + 1
      }                  
      
      if(item && item?.cartInfo && item?.cartInfo?.length && item?.cartInfo[0])
      {
        const cartItem = item?.cartInfo[0];
        obj["Puja Booking Date"] = `${cartItem?.date ? cartItem?.date : cartItem?.pujaDateTime?.pujaDate }`;
        obj["Puja Name"] = cartItem?.pujaDetails?.pujaName;
        obj["Temple Name"] = cartItem?.templeDetails?.personalInfo?.name;
      }

      if(item?.eventData?.whatsAppNumber){
        obj["Whatsapp Number"] = item?.eventData?.whatsAppNumber ? item?.eventData?.whatsAppNumber : " "
      }

      Object.entries(item?.eventData).map((item: any, index) => {
        obj[splitCamelCase(item[0])] = item[1];
      });

      exportData.push(obj);
    });

   
    SetAnalyticsfilterExportdata(exportData);
    SetAnalyticsfilterdata(uniqueArray);
    //setLoading(true)
  };

  const downloadAnalytics = (e: any) => {
    downloadExcelSheetFromObject("Analytics", analyticsfilterExportdata);
  };

  // Define filter functions
  const last7DaysFilter = (item: any) => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
    const itemDate = new Date(item.dateUTC);
    if (selectedEventKey.length > 0) {
      return (
        itemDate >= sevenDaysAgo &&
        itemDate <= new Date() &&
        item.key === selectedEventKey
      );
    } else {
      return itemDate >= sevenDaysAgo && itemDate <= new Date();
    }
  };

  const todayFilter = (item: any) => {
    const today = new Date().toDateString();
    const itemDate = new Date(item.dateUTC).toDateString();
    if (selectedEventKey.length > 0) {
      return today === itemDate && item.key === selectedEventKey;
    } else {
      return today === itemDate;
    }
  };

  const last30DaysFilter = (item: any) => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
    const itemDate = new Date(item.dateUTC);

    if (selectedEventKey.length > 0) {
      return (
        itemDate >= thirtyDaysAgo &&
        itemDate <= new Date() &&
        item.key === selectedEventKey
      );
    } else {
      return itemDate >= thirtyDaysAgo && itemDate <= new Date();
    }
  };

  const analyticsFilter = (item: any, filterType: string) => {
    switch (filterType) {
      case "7day":
        return last7DaysFilter(item);
      case "today":
        return todayFilter(item);
      case "30day":
        return last30DaysFilter(item);
      default:
        return true;
    }
  };

  useEffect(() => {
    const fdata = analyticsList?.filter((item: any) =>
      analyticsFilter(item, activeFilter)
    );
    setFilterData(fdata);
  }, [selectedEventKey]);

  const handleKeyFilter = (key: string) => {
    setSelectedEventKey(key);
  };

  const fetchNextData = () => {
    if (statusFilter === "1") {
      dispatch(
        getNewPaginatedAnalyticsList(paginatedAnalytics.lastSnapshot, pageLimit)
      );
    }
    if (statusFilter === "2") {
      dispatch(
        getViewedPaginatedAnalyticsList(
          paginatedAnalytics.lastSnapshot,
          pageLimit
        )
      );
    }
  };

  const handleFilterByStatus = (status: any) => {
    dispatch(setPaginatedAnalyticsList({}));
    dispatch(setAnalyticsList([]));
    if (status === "new") {
      setStatusFilter("1");
      dispatch(getNewPaginatedAnalyticsList(null, pageLimit));
    }

    if (status === "viewed") {
      setStatusFilter("2");
      // dispatch(getNewPaginatedAnalyticsList(null,pageLimit))
      dispatch(getViewedPaginatedAnalyticsList(null, pageLimit));
    }
  };

  return (
    <>
        <AnalyticsView
          analyticslist={analyticsfilterdata}
          activeFilter={activeFilter}
          analyticsKeys={analyticsKeys}
          setCurrentFilter={handleCurrentFilter}
          setKeyFilter={handleKeyFilter}
          filterByStatus={handleFilterByStatus}
          currentFilterStatus={statusFilter}
          fetchNextData={fetchNextData}
          dateRange={dateRange}
          downloadAnalytics={downloadAnalytics}
          loading={loading}
        ></AnalyticsView>
    </>
  );
}

export default UserAnalyticsScreen;
