import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Stack,
  Checkbox,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
  Divider,
  Chip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MultiDateCalendar from "../../../Screen/MultiDateCalendar/MultiDateCalendar";
import MultipleSelectBeneFits from "../../../components/AutoComplete/MultipleSelectBeneFits";
import MultipleSelectSubCetegoryPuja from "../../../components/AutoComplete/MultipleSelectSubCetegoryPuja";
import "./ManagePujaForm.scss";
import { uid } from "../../../utils/HelperFunctions";

interface PujaItem {
  pujaName: string;
  searchKey: string;
  acctualPrice: number;
  maxMemberAllowed: string;
  isMostOfferedTag: boolean;
  isPromotePujatag: boolean;
  isDisablePuja: boolean;
  description: "";
  image: "";
  benefits: "";
  position: 0;
  indianBasicPrice: string;
  overseasBasicPrice: string;
}

interface ManagePujaFormModalProps {
  isOpen: boolean;
  pujaInfo: any | null;
  onClose: () => void;
  onSave: (pujaInfo: any) => void;
  benefitsList: any[];
  pujaMergedSubCategoryList: any[];
  isFormSubmit: boolean;
}

const ManagePujaFormModal: React.FC<ManagePujaFormModalProps> = ({
  isOpen,
  pujaInfo,
  onClose,
  onSave,
  benefitsList,
  pujaMergedSubCategoryList,
  isFormSubmit
}) => {
  const [formData, setFormData] = useState<PujaItem>({
    pujaName: "",
    searchKey: "",
    acctualPrice: 0,
    description: "",
    maxMemberAllowed: "",
    isMostOfferedTag: false,
    isPromotePujatag: false,
    isDisablePuja: false,
    image: "",
    benefits: "",
    position: 0,
    indianBasicPrice: "",
    overseasBasicPrice: "",
  });

  const [multiplePujaDetails, setMultiplePujaDetails] = useState<any[]>([]);
  const [localImage, setLocalImage] = useState<any>("");
  const daysArray: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedDays, setsetSelectedDays] = useState<string[]>([]);
  const [days, setDays] = useState<string[]>(daysArray);
  const [packagePrice, setPackagePrice] = useState<any>(0);
  const [packageMaxMemberAllowed, setPackageMaxMemberAllowed] =
    useState<any>(0);
  const [packageIsMostPopular, setPackageIsMostPopular] =
    useState<boolean>(true);
  const [currentEditPackageId, setCurrentEditPackageId] = useState<any>("");
  const [errors, setErrors] = useState<any>({
    priceError: "",
    memberError: "",
    nameError: "",
    position: "",
    description: "",
    pujaName: "",
    searchKey: "",
    acctualPrice: "",
    maxMemberAllowed: "",
    image: "",
  });

  const [alreadySelectedBanefits, setAlreadySelectedBanefits] = useState<any[]>(
    []
  );
  const [selectedBenefitList, setSelectedBenefitList] = useState<any[]>([]);
  const [alreadySelectedSubCategory, setAlreadySelectedSubCategory] = useState<
    any[]
  >([]);
  const [selectedSelectedSubCategory, setselectedSelectedSubCategory] =
    useState<any[]>([]);
  const setRestrictionDate = (date: string[]) => {
    const isDay = (item: any) => daysArray.includes(item);
    const isDate = (item: any) => /\d{4}-\d{2}-\d{2}/.test(item);
    setsetSelectedDays(date.filter(isDay));
    setSelectedDates(date.filter(isDate));
  };

  const resetForm = () => {
    setLocalImage("");
    setsetSelectedDays([]);
    setMultiplePujaDetails([]);
    setSelectedDates([]);
    setFormData({
      pujaName: "",
      searchKey: "",
      description: "",
      acctualPrice: 0,
      isMostOfferedTag: false,
      isPromotePujatag: false,
      isDisablePuja: false,
      image: "",
      maxMemberAllowed: "",
      benefits: "",
      position: 0,
      indianBasicPrice: "",
      overseasBasicPrice: "",
    });
  };
  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    resetForm();
    if (pujaInfo) {
      setFormData(pujaInfo);
      if (pujaInfo?.restrictionDate) {
        setRestrictionDate(pujaInfo?.restrictionDate);
      }
    }
  }, [pujaInfo]);

  useEffect(() => {
    if (pujaInfo?.benefitList?.length > 0) {
      setAlreadySelectedBanefits(
        pujaInfo?.benefitList?.map((el: any) => el?.benefitId) || []
      );
    }
    if (pujaInfo?.pujaSubCategory?.length > 0) {
      setAlreadySelectedSubCategory(
        pujaInfo?.pujaSubCategory?.map((el: any) => el?.subCategoryId) || []
      );
    }
    if (pujaInfo?.multiplePujaDetails?.length > 0) {
      setMultiplePujaDetails(pujaInfo?.multiplePujaDetails);
    }
  }, [pujaInfo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked, files } = e.target;
    if (name === "pujaName") {
      setFormData((prev) => ({
        ...prev,
        [name]: value?.trim(),
      }));
      return;
    }
    if (type === "file" && files) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setLocalImage(e.target?.result);
      };
      reader.readAsDataURL(files[0]);

      setFormData((prev) => ({
        ...prev,
        imageFile: files[0],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleSubmit = () => {
    if (isFormSubmit) {
      console.log("already submit");
      return;
    }
    if (validateFieldsMain()) {
      const dates = [...selectedDays, ...selectedDates];
      const newData: any = Object.assign({}, formData);
      newData["restrictionDate"] = dates;
      newData["multiplePujaDetails"] = [...multiplePujaDetails];
      if (selectedBenefitList.length > 0) {
        newData["benefitList"] = selectedBenefitList;
      }
      if (selectedSelectedSubCategory.length > 0) {
        newData["pujaSubCategory"] = selectedSelectedSubCategory;
      }
      onSave(newData);
    } else {
      console.log("Validation failed");
    }
  };

  const handleSavePackages = (e: any) => {
    if (validateFields()) {
      const tempPackage = {
        isMostPopular: packageIsMostPopular,
        acctualPrice: packagePrice,
        couponCode: "",
        packageId: currentEditPackageId || uid(),
        maxMemberAllowed: packageMaxMemberAllowed,
      };
      setMultiplePujaDetails((prevmultiplePujaDetails) => {
        const packageIndex = prevmultiplePujaDetails.findIndex(
          (pkg) => pkg?.packageId === tempPackage?.packageId
        );

        if (packageIndex !== -1) {
          const updatedPackages = [...prevmultiplePujaDetails];
          updatedPackages[packageIndex] = tempPackage;
          return updatedPackages;
        } else {
          return [...prevmultiplePujaDetails, tempPackage];
        }
      });
      resetPackageform();
    }
  };

  const handleDateChange = (dates: string[]) => {
    setSelectedDates(dates);
  };

  const handleSetSelectedDays = (days: any) => {
    setsetSelectedDays((prev) => [...prev, days]);
  };

  const handleRemoveSelectedDays = (dayToRemove: string) => {
    setsetSelectedDays((prev) =>
      prev.filter((day: any) => day !== dayToRemove)
    );
  };

  const resetPackageform = () => {
    setPackageIsMostPopular(false);
    setPackageMaxMemberAllowed(0);
    setPackagePrice(0);
    setCurrentEditPackageId("");
  };

  const handleEditPackageItem = (data: any) => {
    setPackageIsMostPopular(data?.isMostPopular || false);
    setPackageMaxMemberAllowed(data?.maxMemberAllowed || 0);
    setPackagePrice(data?.acctualPrice || 0);
    setCurrentEditPackageId(data?.packageId);
  };

  const handleDeletePackageItem = (packageId: any) => {
    const filteredmultiplePujaDetails = multiplePujaDetails?.filter(
      (puja) => puja?.packageId !== packageId
    );
    setMultiplePujaDetails(filteredmultiplePujaDetails);
  };

  const handleSelectBenefitList = (list: any) => {
    setSelectedBenefitList([...list]);
  };

  const handleSelectSubCategoryPuja = (pujaCatList: any) => {
    setselectedSelectedSubCategory([...pujaCatList]);
  };

  const validateFields = () => {
    let valid = true;
    let newErrors = {
      priceError: "",
      memberError: "",
      actualPriceError: "",
      acctualPrice: "",
      maxMemberAllowed: "",
    };
    if (packagePrice === "" || parseFloat(packagePrice) <= 0) {
      newErrors.priceError = "Puja Price must be greater than 0.";
      valid = false;
    }
    if (
      packageMaxMemberAllowed === "" ||
      parseInt(packageMaxMemberAllowed) <= 0
    ) {
      newErrors.memberError = "Max Members Allowed must be greater than 0.";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const validateFieldsMain = (): boolean => {
    let valid = true;
    let newErrors = {
      position: "",
      pujaName: "",
      searchKey: "",
      image: "",
      description: "",
      multiplePujaDetailsError: "",
    };

    // Validate Puja Name
    if (!formData?.pujaName?.trim()) {
      newErrors.pujaName = "Puja Name is required.";
      valid = false;
    }

    if (!formData?.searchKey?.trim()) {
      newErrors.searchKey = "Search key is required.";
      valid = false;
    }
    // Validate Position
    if (!formData?.position || formData?.position < 0) {
      newErrors.position = "Weightage must be a positive number.";
      valid = false;
    }

    // Validate Description
    if (!formData?.description?.trim()) {
      newErrors.description = "Description is required.";
      valid = false;
    }

    if (multiplePujaDetails?.length <= 0) {
      newErrors.multiplePujaDetailsError =
        "Please provide valid Puja Price and Max Members Allowed.";
      valid = false;
    }

    if (
      !localImage &&
      (!pujaInfo?.pujaBg?.uri || pujaInfo?.pujaBg?.uri.length === 0)
    ) {
      newErrors.image = "Image is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="ManagePujaForm">
      <DialogTitle>{pujaInfo ? "Edit" : "Add"} Puja Item</DialogTitle>
      <DialogContent className="ManagePujaFormItem">
        <Box className="benefitLists">
          <MultipleSelectBeneFits
            options={benefitsList}
            label="Benefits List"
            placeholder="Choose Benefits"
            onChange={handleSelectBenefitList}
            alreadySelectedOptions={pujaInfo ? alreadySelectedBanefits : []}
          />
        </Box>
        <MultipleSelectSubCetegoryPuja
          options={pujaMergedSubCategoryList}
          label="Sub-Categories"
          placeholder="Choose Sub-categories"
          onChange={handleSelectSubCategoryPuja}
          alreadySelectedOptions={pujaInfo ? alreadySelectedSubCategory : []}
        />

        <TextField
          name="pujaName"
          label="Puja Name*"
          fullWidth
          margin="normal"
          value={formData.pujaName}
          onChange={handleChange}
          error={!!errors.pujaName}
          helperText={errors.pujaName}
        />
        <TextField
          name="searchKey"
          label="Add Search Key"
          fullWidth
          margin="normal"
          value={formData.searchKey}
          onChange={handleChange}
          error={!!errors.searchKey}
          helperText={errors.searchKey}
          FormHelperTextProps={{
            sx: { marginLeft: "3px" },
          }}
        />

        <Divider className="multiplePujaSec">
          <Chip label="Add Minimum Price" className="multiplePujaTitle" />
        </Divider>
        <Box className="multiplePujaSubCategoryTextArea">
          <Stack direction="row" spacing={2}>
            <TextField
              name="indianBasicPrice"
              label="Indian Basic INR Price"
              fullWidth
              type="text"
              margin="normal"
              value={formData?.indianBasicPrice}
              onChange={handleChange}
              InputProps={{
                startAdornment: <span style={{ marginRight: "5px" }}>₹</span>,
              }}
            />

            <TextField
              name="overseasBasicPrice"
              label="Overseas Basic INR Price"
              fullWidth
              margin="normal"
              type="text"
              value={formData?.overseasBasicPrice}
              onChange={handleChange}
              InputProps={{
                startAdornment: <span style={{ marginRight: "5px" }}>₹</span>,
              }}
            />
          </Stack>
        </Box>

        <Divider className="multiplePujaSec">
          <Chip label="Multiple Puja Details" className="multiplePujaTitle" />
        </Divider>
        <Box className="multiplePujaSubCategoryTextArea">
          <Stack direction="row" spacing={2}>
            <TextField
              name="acctualPrice"
              label="Puja Price*"
              fullWidth
              type="number"
              margin="normal"
              value={packagePrice}
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => {
                e.preventDefault();
                setPackagePrice(e.target.value);
              }}
              error={!!errors.priceError}
              helperText={errors.priceError}
            />

            <TextField
              name="maxMemberAllowed"
              label="Max Members Allowed*"
              fullWidth
              margin="normal"
              type="number"
              value={packageMaxMemberAllowed}
              onChange={(e) => {
                e.preventDefault();
                setPackageMaxMemberAllowed(e.target.value);
              }}
              InputProps={{ inputProps: { min: 0, max: 50 } }}
              error={!!errors.memberError}
              helperText={errors.memberError}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            className="checkboxAndAddpackageInfo"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={packageIsMostPopular}
                  name="isMostOfferedTag"
                  className="checkboxInfo"
                  onChange={(e) => {
                    e.preventDefault();
                    setPackageIsMostPopular(e.target.checked);
                  }}
                />
              }
              label="Check for Most Popular"
            />

            <Button
              variant="contained"
              color="primary"
              className="addPackageSec"
              onClick={handleSavePackages}
            >
              Add Packages
            </Button>
          </Stack>
        </Box>
        {errors.multiplePujaDetailsError && (
          <Typography color="error">
            {errors.multiplePujaDetailsError}
          </Typography>
        )}
        <TableContainer className="tableDataInfo">
          <Table sx={{ minWidth: 650, backgroundColor: "#f4f4f4" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#cacaca" }}>
                <TableCell
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#cacaca",
                  }}
                >
                  Price
                </TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#cacaca",
                  }}
                >
                  Max Members
                </TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#cacaca",
                  }}
                >
                  Most Popular
                </TableCell>
                <TableCell
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#cacaca",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: "auto" }}>
              {multiplePujaDetails.map((pujaItem) => (
                <TableRow key={pujaItem.pujaId}>
                  <TableCell>{pujaItem.acctualPrice}</TableCell>
                  <TableCell>{pujaItem.maxMemberAllowed}</TableCell>
                  <TableCell>{pujaItem.isMostPopular ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        aria-label="edit"
                        color="primary"
                        onClick={() => handleEditPackageItem(pujaItem)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        onClick={() =>
                          handleDeletePackageItem(pujaItem.packageId)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="column" margin="10px 0">
          <FormControlLabel
            control={
              <Checkbox
                checked={formData?.isPromotePujatag}
                onChange={handleChange}
                name="isPromotePujatag"
              />
            }
            label="Check to promote Puja"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData?.isDisablePuja}
                onChange={handleChange}
                name="isDisablePuja"
              />
            }
            label="Check to disable Puja"
          />
        </Stack>
        <Stack className="chooseImgInfo">
          <TextField
            name="image"
            label="Upload Image"
            type="file"
            fullWidth
            margin="normal"
            onChange={handleChange}
            error={!!errors.image}
            helperText={errors.image}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              accept: "image/*",
            }}
          />
          {localImage?.length === 0 && pujaInfo?.pujaBg?.uri?.length > 0 && (
            <img src={pujaInfo?.pujaBg?.uri} alt="temple puja" />
          )}
          {localImage?.length > 0 && <img src={localImage} alt="temple puja" />}
        </Stack>
        <TextField
          name="description"
          label="Description"
          fullWidth
          margin="normal"
          value={formData?.description}
          onChange={handleChange}
          error={!!errors.description}
          helperText={errors.description}
          multiline
          sx={{ mt: "20px" }}
          rows={5}
        />

        <TextField
          name="position"
          label="Weightage"
          fullWidth
          type="number"
          margin="normal"
          value={formData.position}
          onChange={handleChange}
          error={!!errors.position}
          helperText={errors.position}
        />

        <Box className="day-buttons-container">
          {days &&
            days?.map((el: any, index: number) => (
              <Box
                className={`day ${
                  selectedDays?.includes(el) ? "active_day" : ""
                }`}
                key={`days${index}`}
                onClick={(e) => {
                  selectedDays?.includes(el)
                    ? handleRemoveSelectedDays(el)
                    : handleSetSelectedDays(el);
                }}
              >
                {el}
              </Box>
            ))}
        </Box>
        <Box className="chadhavaAdminCalendar">
          <MultiDateCalendar
            selectedDates={selectedDates}
            onDateChange={handleDateChange}
          />
        </Box>
      </DialogContent>
      <DialogActions className="managePujaBottomPart modalFooter">
        <Button onClick={onClose} className="btnCancel grey">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="outlined" color="primary">
          {isFormSubmit ? "saving" : "save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagePujaFormModal;
