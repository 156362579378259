import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  TextField,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import micIcon from "../../assets/mic-icon.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import "./GlobalSearchView.scss";
import {
  startSpeechRecognitionWithLangRealTime,
  stopContinuousRecognitionAsync,
  stopSpeechRecognition,
} from "../../utils/speechRecognition";
import { getGlobalSearchResultAction } from "../../store/actions/templeAction";
import { useDispatch, useSelector } from "react-redux";
import audioTrack from "../../assets/voice-icon-Animation.gif";
import { GLOBAL_SEARCH_TYPE } from "../../utils/Constants";
import { LOCALIZATION } from "../../utils/localization";
import i18next, { t } from "i18next";
import { useTranslation } from "react-i18next";

const placeholders = ["Puja", "Temples", "Chadhava"];

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

const GlobalSearchView: React.FC<any> = () => {
  const currentLanguage = i18next.language;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const { getGlobalSearchResult } = useSelector((state: any) => state.temple);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [speechModalOpen, setSpeechModalOpen] = useState(false);
  const [recognizedText, setRecognizedText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputResult, setInputResult] = useState<any>([]);
  const [globalSearchLoaded, setGlobalSearchLoaded] = useState(false);
  const { t, i18n } = useTranslation();

  const recognizerRef = useRef<any>(null);
  const [listening, setListening] = useState<boolean>(false);

  const removeFullStop = (recognizedText: string) => {
    if (recognizedText.endsWith(".")) {
      return recognizedText.slice(0, -1);
    } else {
      return recognizedText;
    }
  };

  useEffect(() => {
    if (inputValue?.length > 2) {
      setInputResult(getGlobalSearchResult);
    } else {
      setInputResult([]);
    }
  }, [getGlobalSearchResult]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleMicClick = () => {
    if (listening) {
      stopSpeechRecognition(recognizerRef.current);
      setListening(false);
    } else {
      setListening(true);
      setSpeechModalOpen(true);

      recognizerRef.current = startSpeechRecognitionWithLangRealTime(
        "EN",
        (recognizedText: string, detectedLanguage: string) => {
          const _recognizedText = recognizedText || "";
          const finalString = removeFullStop(_recognizedText);
          setInputValue(finalString);
          setListening(false);
          setRecognizedText(_recognizedText);
          setSpeechModalOpen(false);
          stopContinuousRecognitionAsync(recognizerRef.current);
          setInputValue(removeFullStop(_recognizedText).toLowerCase());
        },
        (_recognizedText: string, detectedLanguage: string) => {
          setListening(false);
          setRecognizedText(_recognizedText);
        },
        (error: any) => {
          console.error("Recognition error:", error);
          setListening(false);
          setSpeechModalOpen(false);
        }
      );
    }
  };

  const serviceRoutes: Record<string, string> = {
    Temples: "/temples",
    Pujas: "/pujas",
    Chadhava: "/chadhavaTemples",
    "Prasad Delivery": "/prasadTemples",
  };

  const handleButtonClick = (service: string) => {
    const route = serviceRoutes[service];
    if (route) {
      navigate(route);
    }
  };

  useEffect(() => {
    if (!inputValue && !isFocused) {
      const interval = setInterval(() => {
        setIsAnimating(true);

        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
          setIsAnimating(false);
        }, 500);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [inputValue, isFocused]);

  const handleClearInput = () => {
    setInputValue("");
  };

  const handleNavigateBackToPrevious = () => {
    navigate(-1);
  };

  useEffect(() => {
    const searchText = location.state?.text || "";
    const finalString = removeFullStop(searchText);
    setInputValue(finalString);
  }, [location]);

  useEffect(() => {
    dispatch(getGlobalSearchResultAction(removeFullStop(inputValue)));
  }, [inputValue]);

  const handleClose = () => {
    setSpeechModalOpen(false);
    stopSpeechRecognition(recognizerRef.current);
    setListening(false);
  };

  const handleClickOpen = () => {
    setSpeechModalOpen(true);
    handleMicClick();
  };
  const handleClickSearchCard = (item: any) => {
    if (!item) return;
    const id = item?.id;
    const secondaryId = item?.secondaryId;
    const type = item?.type;

    if (type === "live_darshan" && id) {
      navigate(`/livedarshantemple/${id}`);
    }
    if (type === "templepuja" && id && secondaryId) {
      navigate(
        `/templePujaInfo?templeNameKey=${secondaryId}&pujaNameKey=${id}`
      );
    }
    if (type === "chadhavaitems" && secondaryId) {
      navigate(`/chadhavaDetails/${secondaryId}`);
    }
    if (type === "organisation" && id) {
      navigate(`/templeInfo?templeNameKey=${id}`);
    }
    if (type === "prasaditems" && secondaryId) {
      navigate(`/prasadDetails/${secondaryId}`);
    }
    if (type === "shivling_saktipeeth") {
      navigate(`/specialTemples/saktipeeth`);
    }
    if (type === "promotional_puja_list" && id) {
      navigate(`/specialPuja?pujaNameKey=${id}`);
    }
    if (type === "temple_puja_category_list" && id) {
      navigate(`/search/category/${id}`);
    }
    if (type === "subcategory" && id) {
      navigate(`/pujaSubCategory/${id}`);
    }
  };

  useEffect(() => {
    setTimeout(() => setGlobalSearchLoaded(true), 10);
  }, []);

  const dynamicPosition = () => {
    if (currentLanguage === "en") {
      return { left: "70px" };
    } else if (currentLanguage === "hi") {
      return { left: "80px" };
    } else if (currentLanguage === "bn") {
      return { left: "98px" };
    }
  };
  console.log("kjhsdh", i18next.language);

  console.log("jsvfdf", dynamicPosition());
  return (
    <Box className={globalSearchLoaded ? "pageEnterActive" : "pageEnter"}>
      <Box className="pageContainer">
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdLeftBlock"
          ></Grid>
          <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
            <Box className="globalSearchPage">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                mb={2}
                className="searchPageTopContent"
              >
                <KeyboardBackspaceIcon onClick={handleNavigateBackToPrevious} />
                <Typography className="topTitle">
                  {t(LOCALIZATION.Search_for_Temples_Pujas_Chadhava_and_Prasad)}
                </Typography>
              </Stack>

              <Box className="searchWrapper">
                {/* Animated Placeholder */}
                {!inputValue && !isFocused && (
                  <span className="animatedPlaceholder">
                    {t(LOCALIZATION.Search_for)} {"  "}
                    <span
                      style={dynamicPosition()}
                      className={`changingText ${
                        isAnimating ? "slide-up" : ""
                      }`}
                    >
                      {" "}
                      '{t(placeholders[currentIndex])}'
                    </span>
                  </span>
                )}

                {/* Search Field */}
                <TextField
                  className="GlobalSearchView"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  variant="outlined"
                  //onFocus={handleSearchFocus}
                  onBlur={() => setIsFocused(false)}
                  inputRef={inputRef}
                />

                {/* Close Button (Appears when typing) */}
                {inputValue && (
                  <IconButton
                    onClick={handleClearInput}
                    className="closeButton"
                  >
                    <CloseIcon />
                  </IconButton>
                )}

                {/* Mic Button */}
                <IconButton onClick={handleClickOpen} className="micButton">
                  {listening ? (
                    <div className="micRecordingEffect" />
                  ) : (
                    <Box component="img" src={micIcon} />
                  )}
                </IconButton>
              </Box>

              <Box className="searchResults">
                {inputResult && inputResult.length > 0 ? (
                  <Grid container spacing={1}>
                    {inputResult.map((item: any, index: number) => (
                      <Grid item xs={12} md={6} key={index}>
                        <Box
                          className="searchResultItem"
                          onClick={() => handleClickSearchCard(item)}
                        >
                          <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                          >
                            <Box className="imgBox">
                              <Box
                                component="img"
                                sx={{ width: "100%" }}
                                src={item.imageUrl}
                              />
                            </Box>
                            <Box className="infoContent">
                              <Typography className="itemTitle">
                                {item?.name}
                              </Typography>
                              <Typography className="typeDefineText">
                                {GLOBAL_SEARCH_TYPE[item?.type]}{" "}
                                {[
                                  "templepuja",
                                  "chadhavaitems",
                                  "prasaditems",
                                ].includes(item?.type) && item?.templeName ? (
                                  <span>at</span>
                                ) : (
                                  ""
                                )}
                              </Typography>
                              {[
                                "templepuja",
                                "chadhavaitems",
                                "prasaditems",
                              ].includes(item?.type) &&
                                item?.templeName && (
                                  <Typography className="templeName">
                                    {item?.templeName}
                                  </Typography>
                                )}
                            </Box>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  inputValue?.length > 2 &&
                  getGlobalSearchResult.length <= 0 && (
                    <Typography className="noResultsText">
                      {t(LOCALIZATION.No_results_found_for)}{" "}
                      <span>&nbsp;"{inputValue}"</span>
                    </Typography>
                  )
                )}
              </Box>
              {(!inputResult || inputResult.length <= 0) && (
                <Box className="bottomServices" mb={5}>
                  <Box className="titleBlock">
                    <Typography className="serviceTitleText">
                      {t(LOCALIZATION.OUR_SERVICES)}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    className="serviceButton"
                    spacing={2}
                    justifyContent="center"
                  >
                    {Object.keys(serviceRoutes).map((service) => (
                      <Grid item xs={6} key={service}>
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => handleButtonClick(service)}
                          className="serviceButton"
                        >
                          {t(service?.replaceAll(" ", "_")).toUpperCase()}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {/* Speech Recognition Modal */}
              <Dialog
                open={speechModalOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                className="speechRecogModal"
              >
                <DialogContent className="modalInnerContent">
                  <DialogContentText id="alert-dialog-slide-description">
                    <Typography>
                      {t(LOCALIZATION.Hi_I_am_listening_Try_saying)}
                    </Typography>
                    <Typography variant="h5" className="speechText">
                      {listening
                        ? t(LOCALIZATION.Listening)
                        : `"${recognizedText || "Temples"}"`}
                    </Typography>
                    <Box className="audioTrackIcon">
                      <iframe src="https://lottie.host/embed/ffce58a6-8260-403d-8ae0-d342b86f214a/aoe57tiKze.lottie"></iframe>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={3}
            xl={3}
            className="pagehdRightBlock"
          ></Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GlobalSearchView;
