import { useNavigate, Navigate ,useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
import CheckoutV2View from "../../Views/CheckoutV2/CheckoutV2View";
function CheckoutV2Screen() {
  const navigate = useNavigate();
  const location = useLocation();
  const pjuaDetails = useSelector(
    (state: any) => state.temple.selectedPuja
  );
  const { selectedPuja } = useSelector((state: any) => state.temple);
  //Go back to puja list screen
  const { cartInfo } = useSelector(
    (state: any) => state.cart
  );

  const isCartEnabled = cartInfo&&cartInfo.length>0?true:false
  const getStepNumber = ()=>{
    let pathName = location.pathname
    if(pathName==="/checkoutV2/addDevotee"){
      return 1
    }
    else if(pathName==="/checkoutV2/reviewCart"){
      return 2
    }
    else{
      return 0
    }
  }
  return (
    <ParentView>
      <CheckoutV2View isCartEnabled={isCartEnabled} getStepNumber={getStepNumber}/>
    </ParentView>
  );
}

export default CheckoutV2Screen;
