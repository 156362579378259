import playstore from "../../assets/play-store.png";
import appstore from "../../assets/app-store.png";
import promotionbanner from "../../assets/promotion-banner.png";

import "./AboutUsView.scss";
import { Box, Button, Grid, Typography } from "@mui/material";

function AboutUsView() {
  //https://apps.apple.com/in/app/temple-dekho/id6467179618
  const redirectToAppStore = () => {
    window.location.href = "https://apps.apple.com/us/app/id6467179618";
    // Replace 'your-app-id' with the actual App Store ID for your iOS app
  };

  //https://play.google.com/store/apps/details?id=com.omkara.templedekho.dev
  const redirectToPlayStore = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.omkara.templedekho.dev";
    // Replace 'your.package.name' with the actual package name for your Android app
  };
  return (
    <Box className="promotionScreen">
      <Box className="container">
        <Grid container spacing={2} className="align-items-center">
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box className="leftText">
              <Box className="onlyText">
                <Typography className="smTitle">Temple Dekho</Typography>
                <Typography className="lgTitle">
                  Get ready for a new spiritual experience
                </Typography>
                <Typography className="genContent">
                  Download the application and have an experience with Temples,
                  Puja Booking, Pandit Booking etc.
                </Typography>
              </Box>
              <Box className="downloadOption">
                <Typography className="smTitle">Avalaible for</Typography>
                <Typography className="dwnldText">Download</Typography>
                <Box className="downloadBtn">
                  <Button type="button">
                    <img
                      src={playstore}
                      alt=""
                      onClick={() => {
                        redirectToPlayStore();
                      }}
                    />
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      redirectToAppStore();
                    }}
                  >
                    <img src={appstore} alt="" />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box className="rightImg">
              <img src={promotionbanner} alt="" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AboutUsView;
