/* eslint-disable @typescript-eslint/no-unused-vars */
import {OverseasPriceService } from "td_server";
import {
    setLoading,
    setPriceConversionRate,
    setIsCountryDisabled
  } from "./../slices/priceConversionSlice";
export const getPriceConversionRate =
  (location:any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await OverseasPriceService.getOverSeasPriceRate(location);
      if(response?.isError === true){
        dispatch(setIsCountryDisabled(response?.isCountryDisabled))
      }else{
        dispatch(setPriceConversionRate(response));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  