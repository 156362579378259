import * as React from "react";
import "./ImageViewerWithBlur.scss";

export default function ImageViewerWithBlur({ url, id }: any) {
  return (
    <div className="blurImgcontainer">
      <img
        className="backgroundBlurImgcontainer"
        key={`${id}blurimage`}
        alt=""
        src={url}
      />

      <img
        className="foregroundImgcontainer"
        key={`${id}foregroundimage`}
        alt=""
        src={url}
      />
    </div>
  );
}
